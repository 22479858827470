import { getDatabase } from "firebase/database"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import _ from 'lodash'
import { AsYouType } from 'libphonenumber-js'
import { useDispatch, useSelector } from 'react-redux'
import EntityModal2 from './EntityModal2'
import * as Utils from '../utils/index.js'

export default function(props) {

  const {
    existingDriver,
    handleClose,
    open,
    filteredIds
  } = props

  //Drivers should be all drivers belonging to
  //the selected group of the company (max 20)...
  const members = useSelector(state => state.app.members)
  const [ filteredDrivers, setFilteredDrivers ] = useState([])

  //Effect to keep the filtered drivers the same as drivers...
  useEffect(() => {
    const drivers =
      Utils.filterOnlyDrivers(members)
      .filter(memberNotIn(filteredIds))

    setFilteredDrivers(drivers)
  }, [ members, filteredIds ])

  //Handle when the user searches for drivers...
  const handleSearch = async text => {
    if ( _.isEmpty( text ) ) {

      const drivers =
        Utils.filterOnlyDrivers(members)
        .filter(memberNotIn(filteredIds))

      setFilteredDrivers(drivers)
    } else {
      setFilteredDrivers(Utils.filterOnlyDrivers(members).filter(d => {
        return d.name.toUpperCase().includes(text.toUpperCase())
      }))
      .filter(memberNotIn(filteredIds))
    }
  }

  return (
    <EntityModal2
      allowSaves={ false } //Not just for selecting existing contacts...
      entityName="Driver"
      existingEntity={ existingDriver }
      modalLabelSelectOnly="Select Driver"
      options={ filteredDrivers }
      onSearch={ handleSearch }
      //onOptionSelected={ handleDriverSelect }
      open={ open }
      optionLabelFieldKey="name"
      handleClose={ handleClose }
      entityFields={ CONTACT_ENTITY_FIELDS }
      searchFieldKey="name"
    />
  )
}

const CONTACT_ENTITY_FIELDS = [
  { label: 'Name', key: 'name' },
  { label: 'Phone', key: 'phone', type: 'phone' },
  { label: 'Email', key: 'email' },
]

const memberNotIn = (members=[]) => {
  return (member) => members.includes(member.id) === false
}

