import { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FirestoreContext } from '../contexts'

export const useCompanyId = () => {
  const companyId = useSelector(state => state.app.companyId)
  return companyId
}

export const useGroupId = () => {
  const groupId = useSelector(state => state.app.groupId)
  return groupId
}

export const useDatabase= () => {
  const firestoreDb = useContext(FirestoreContext)

  return firestoreDb
}
