import React from 'react'
import Grid from '@mui/material/Grid';

const InputRightContainer = React.forwardRef((props, ref ) => {

  const { children, displayShort } = props
  return (
    <Grid ref={ ref } item xs={ displayShort ? 4 : 8 }>
      { children }
    </Grid>
  )
})

export default InputRightContainer
