import React, { useContext, useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client';
import { useCustomer } from '../redux/dashboardSlice'
import EnhancedTable from '../components/Table'
import _ from 'lodash'
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import * as GanttUtil from '../utils/GanttUtil'
import { actions } from '../redux/dashboardSlice'
import { DELETE_CONTACTS_MUTATION } from '../utils/api'

import { DashboardContext, OrderPageContext } from '../contexts'

//Show the contacts for the customer & orders relating to the customer...
export default function CustomerTab(props) {
  const {
    contacts,
    orders=[],
    customerId,
    refetchContacts,
  } = props

  const [deleteContacts]  = useMutation(DELETE_CONTACTS_MUTATION)
  const dispatch          = useDispatch()
  const {
    openSnackbar,
    handleBackdropOpen,
    handleBackdropClose
  } = useContext(DashboardContext)
  const navigate          = useNavigate()

  const { customer }  = useCustomer(customerId)
  const [ contactRows, setContactRows ] = useState([])
  const backdropOpen  = useSelector(state => state.dashboard.backdropOpen)

  useEffect(() => {
    if ( contacts ) {
      setContactRows(contacts.map(c => ({
        data: c
      })))
    }
  }, [ contacts ])

  //Delete entities...
  const onDeleteContacts = (ids=[]) => {
    if ( _.isEmpty( ids ) || _.isEmpty(customerId) ) {
      return;
    }

    deleteContacts({
      variables: {
        contactIds: ids
      }
    })
    .then( response => {
      if ( response.data ) {
        //Refetch contacts...
        refetchContacts({
          customerId
        })
      }
    })
  }

  const onDeleteOrders = (ids=[]) => {
    openSnackbar("Pending post-launch testing")
    if ( _.isEmpty( ids ) || _.isEmpty(customerId) ) {
      return;
    }
  }

  const handleClickContactRow = () => {
  }

  const handleOpenOrderModal = (orderId ) => {
    dispatch(actions.hydratePage({ orderId, openOrderModal: true }))
  }


  const handleClickOrderRow = rowIndex => {
    const orderId = orders[ rowIndex ].metadata?.order?.id

    if ( orderId ) {
      openSnackbar("Viewing details")
      handleBackdropOpen()
      setTimeout(() => {
        handleBackdropClose()
        handleOpenOrderModal(orderId)
      }, 300)
    }
  }

  const handleArchiveOrder = () => {
  }

  const [ pageNum, setPageNum ] = useState(0)

  return (
    <div>
      <h1 style={{ margin: 0, fontWeight: 500, marginBottom: 20 }}>
        Customer: { customer?.name }
      </h1>
      <EnhancedTable
        sx={{ mb: 2 }}
        page={ pageNum }
        pageSize={ contactRows.length }
        columns={ CONTACT_HEAD_CELLS }
        handleRowClick={ handleClickContactRow }
        rowsPerPageOptions={[contactRows.length]}
        onDelete={ onDeleteContacts }
        orderByInitial="name"
        header="Contacts"
        rows={ contactRows }
      />

      <EnhancedTable
        columns={ GanttUtil.DASHBOARD_COLUMNS }
        allowsDelete={ false }
        rowsPerPageOptions={[orders.length]}
        useDashboardHeadCells
        pageSize={ orders.length }
        renderTableCell={ GanttUtil.renderTableCell }
        totalRecordCount={ orders.length }
        page={ pageNum }
        handleRowClick={ handleClickOrderRow }
        onDelete={ onDeleteOrders }
        orderByInitial="description"
        header="Recent Trips"
        rows={ orders }
      />
    </div>
  )
}

const CONTACT_HEAD_CELLS  = [
  {
    field: 'name',
    label: 'Name'
  },
  {
    field: 'phone',
    label: 'Phone',
    type: 'phone'
  },
  {
    field: 'email',
    label: 'Email',
    type: 'email'
  },
]
