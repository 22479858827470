import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useCompanyId, useDatabase } from '../hooks'
import { gql, useMutation } from '@apollo/client';
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import EntityModal2 from './EntityModal2'
import { actions } from '../redux/dashboardSlice'
import { DELETE_CONTACTS_MUTATION } from '../utils/api'

export default function ContactModal(props) {

  const {
    customer,
    existingContact,
    contacts,
    refetchContacts,
    handleClose,
    handleSelectContact,
    open,
    orderId
  } = props

  const [createContact, { data, loading, error }] = useMutation(CREATE_CONTACT_MUTATION)
  const [updateContact]   = useMutation(UPDATE_CONTACT_MUTATION)
  const [deleteContacts]  = useMutation(DELETE_CONTACTS_MUTATION)
  
  const dispatch  = useDispatch()
  const companyId = useCompanyId()
  const db        = useDatabase()

  const handleDeleteOption = option => {
    if ( option.id ) {
      deleteContacts({
        variables: {
          contactIds: [ option.id ]
        }
      }).then(() => {
        return refetchContacts({
          customerId: customer.id
        })
      })
    }
  }

  const [ filteredContacts, setFilteredContacts ] = useState([])

  //Effect to keep the filtered contacts the same as contacts...
  useEffect(() => {
    setFilteredContacts(contacts)
  }, [ contacts ])

  useEffect(() => {
    setFilteredContacts(contacts)
  }, [ open ])

  //Handle when the user searches for contacts...
  const handleSearch = async text => {
    if ( _.isEmpty(text) ) {
      setFilteredContacts(contacts)
    } else {
      setFilteredContacts(contacts.filter(c => {
        return c.name.toUpperCase().includes(text.toUpperCase())
      }))
    }
  }

  //Handle when an existing contact is saved...
  const onCreateNewEntity = async contact => {

    return createContact({
      variables: {
        contact: {
          ...contact,
          customerId: customer.id,
        }
      }
    })
    .then( response => {
      const { data: { createContact } } = response
      const { contact } = createContact

      //Set the contact in the store so
      //it is available everywhere...
      dispatch(actions.setContactInStore({ contact }))

      //Update the UI
      refetchContacts({
        customerId: customer.id
      })

      return contact//Return the contact...
    })
      
  }

  const onSaveEntity = async contact => {

    if ( !contact.id ) {
      console.log("Contact has no id for save!!")
      return;
    }

    if ( !customer.id ) {
      console.log("No customerId for save!!")
      return;
    }

    return updateContact({
      variables: {
        contact: _.pick({
          ...contact,
        }, PERMITTED_CONTACT_FIELDS)
      }
    })
    .then( response => {
      const { data: { updateContact } } = response
      const { contact } = updateContact

      refetchContacts({
        customerId: customer.id
      })

      //Set the contact in the store so
      //it is available everywhere...
      dispatch(actions.setContactInStore({ contact }))

      //Update the UI
      return contact//Return the contact...
    })

  }

  return (
    <EntityModal2
      allowSaves={ true } //Not just for selecting existing contacts...
      emptyEntity={ EMPTY_CONTACT }
      existingEntity={ existingContact }
      entityName="Contact"
      modalLabelAdd="Add Contact"
      modalLabelEdit="Edit Contact"
      entityFields={ CONTACT_ENTITY_FIELDS }
      options={ filteredContacts }
      onSearch={ handleSearch }
      onOptionSelected={ handleSelectContact }
      onCreateNewEntity={ onCreateNewEntity }
      onSaveEntity={ onSaveEntity }
      onDeleteOption={ handleDeleteOption }
      open={ open }
      optionLabelFieldKey="name"
      handleClose={ handleClose }
      searchFieldKey="name"
    />
  )
}

const EMPTY_CONTACT = {
  name: '',
  phone: '',
  email: ''
}

const CONTACT_ENTITY_FIELDS = [
  { label: 'Name', key: 'name' },
  { label: 'Phone', key: 'phone', type: 'phone' },
  { label: 'Email', key: 'email' },
]

const CREATE_CONTACT_MUTATION = gql`
mutation Mutation($contact: CreateContactInput!) {
  createContact(contact: $contact) {
    message
    code
    contact {
      id
      customerId
      name
      email
      phone
    }
  }
}
`

const UPDATE_CONTACT_MUTATION = gql`
mutation Mutation($contact: UpdateContactInput!) {
  updateContact(contact: $contact) {
    message
    code
    contact {
      id
      customerId
      name
      email
      phone
    }
  }
}
`
const PERMITTED_CONTACT_FIELDS = ['id', 'name', 'email', 'phone']
