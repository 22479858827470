import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PencilIcon } from '../../icons'
import { PatternFormat, NumericFormat } from 'react-number-format';
import { orderBy, startAt, endAt, query, } from "firebase/firestore";
import { ColorSelector } from '../../components/ColorSelector'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const DEFAULT_ENTITY_VALIDATOR = entity => {
  return true
}

export default function EntityModal(props) {
  const {
    entity,
    entityName,
    handleClose,
    getOptionLabel,
    entityFields,
    handleSave,
    isValidEntity=DEFAULT_ENTITY_VALIDATOR,
    onChange,
    open,
  } = props

  const [ isNew, setIsNew ]                   = useState(false)
  const [ submitDisabled, setSubmitDisabled ] = useState(true)
  const [ enabledQuickbooksField, setEnabledQuickbooksField ] = useState(false)

  const handleSubmit = () => {
    const isExisting = _.isNil(entity.id) === false
    handleSave(entity)
  }

  //Effect to enable the submit button...
  useEffect(() => {

    const hasValidationErrors = isValidEntity(entity) === false

    //Enable the button based on validations
    if ( hasValidationErrors !== submitDisabled ) {
      setSubmitDisabled(hasValidationErrors)
    }

  }, [ submitDisabled, entity ])

  useEffect(() => {
    if ( !open ) {
      setEnabledQuickbooksField(false)
    }
  }, [ open ])

  function displayField(option, enabled=true) {
    //If we have a custom way of displaying the option, use that...
    if ( option.renderInput ) {
      return option.renderInput({
        value: entity[ option.field ],
      })
    }

    switch (option.type) {
      case 'roles':
        return (
          <div>
            <Checkbox
              {...label }
              checked={ entity.admin }
              icon={<AdminPanelSettingsOutlinedIcon />}
              onChange={ e => onChange('admin', e.target.checked) }
              checkedIcon={<AdminPanelSettingsIcon />} />
            <Checkbox
              {...label }
              checked={ entity.driver }
              onChange={ e => onChange('driver', e.target.checked) }
              icon={<LocalShippingOutlinedIcon />}
              checkedIcon={<LocalShippingIcon/>} />
            <Checkbox
              {...label }
              checked={ entity.dispatcher }
              onChange={ e => onChange('dispatcher', e.target.checked) }
              icon={<PhoneForwardedOutlinedIcon />}
              checkedIcon={<PhoneForwardedIcon />} />
          </div>
        )
      case 'check':
        return (
          <Checkbox
            {...label}
            checked={ _.isNil(entity[ option.field ]) ? false : entity[ option.field ] }
            onChange={ e => onChange(option.field, e.target.checked) }
            size="small" />
        )
      case 'color':
        return (
          <ColorSelector
            onChange={ color => onChange(option.field, color) }
            color={ entity[ option.field ]}
          />
        )
      case 'phone':
        const { format="+1 (###) ### ####" } = option.options ? option.options : {}
        return (
          <PatternFormat
            format={ format }
            value={ parseInt(entity[ option.field ]) }
            onValueChange={(values, sourceInfo) => {
              onChange(option.field, values.value)
            }}
            customInput={ MyCustomInput }
            mask="_"
          />
        )
      case 'number':
        const { showThousandsSeparator=true } = option.options ? option.options : {}
        return (
          <NumericFormat
            value={ parseFloat(entity[ option.field ]) }
            thousandSeparator={ showThousandsSeparator ? ',' : false }
            onValueChange={(values, sourceInfo) => {
              onChange(option.field, values.floatValue)
            }}
            customInput={ MyCustomInput }
          />
        )
      case 'select':
        return (
          <CoreAutocomplete
            entity={ entity }
            option={ option }
            getOptionLabel={ getOptionLabel }
            onChange={ (e, value) => onChange(option.field, value) }
          />
        )
      default:
        return (
          <TextField
            size="small"
            maxLength={ 6 }
            disabled={ !enabled }
            inputProps={ { ...( option.maxLength ? { maxLength: option.maxLength } : {})} }
            onChange={ e => onChange(option.field, e.target.value) }
            type={ option.type }
            fullWidth
            value={ entity[ option.field ] }
            className="contact-field" id="filled-basic" label=""
          />
        )
    }
  }

  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ ...modalStyle }}>
        <div style={{ padding: '20px 40px' }}>
          <h2
            style={{
                borderBottom: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
          { ( _.isEmpty( entity.id ) ? "ADD " : "EDIT " ) +
            entityName.toUpperCase() }<PencilIcon />
          </h2>
          <hr />
          <Box sx={{ maxHeight: 110 * 5, padding: 2, overflow: 'scroll' }} className="snap-parent">
            { entityFields.map( option => {
              const isQuickbooksField = option.label === 'Quickbooks ID'
              return (
                <div className="snap-child">
                  <label>{ option.label }</label><br />
                  { displayField(option, isQuickbooksField ? enabledQuickbooksField : true) }
                  <br />
                  { isQuickbooksField &&
                    <Typography
                      onClick={ () => {
                        if (! enabledQuickbooksField ) {
                          //No longer disabled
                          setEnabledQuickbooksField(true)
                        }
                      }}
                      className="pointer"
                      color="primary"
                      variant="button">
                      Edit
                    </Typography>
                  }
                </div>
              )
            }) }
          </Box>
          <Button
            disabled={ submitDisabled }
            sx={{ mt: 3, width: '100%' }}
            onClick={ handleSubmit }
            variant="contained">
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

const CoreAutocomplete = props => {
  const { getOptionLabel, option, entity, onChange } = props

  const members     = useSelector(state => state.app.members)

  //Function to get additional options...
  const { getOptions } = option

  const [ options, setOptions ]       = useState([])
  const [ inputValue, setInputValue ] = useState('')

  //Effect to getOptions whenever the input value changes...
  useEffect(() => {

    getOptions({ text: inputValue, context: { members } }, options => {
      setOptions(options)
    })

  }, [ inputValue, members ])

  return (
    <Autocomplete
      blurOnSelect
      value={ entity[ option.field ] ? entity[ option.field ] : null }
      onChange={ (e, value) => onChange(e, value) }
      onInputChange={ (e, value) => setInputValue(value) }
      getOptionLabel={ getOptionLabel }
      isOptionEqualToValue={ (option, value ) => {
        console.log({ option, value })
        if ( _.isObject( value ) === false ) {
          return value === option.value
        }
        return option.value === value.value
      }}
      options={ options }
      renderInput={(params) => (
        <TextField
          id="filled-basic"
          className="contact-field" label=""
          fullWidth {...params}
        />
      )}
    />
  )
}

const MyCustomInput = props => (
  <TextField { ...props }
    size="small"
    fullWidth
    className="contact-field"
  />
)

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(80vh)',
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  borderRadius: 2,
  outline: 0
}
