import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { AppContext } from '../contexts'

export default function Tags(props) {

  const { handleTagsChanged, tags } = props
  const itemTags = DEFAULT_ITEM_TAGS

  return (
    <Box sx={{ pl: 1, pr: 1 }}>
      <Typography variant="button">
        Attachments
      </Typography>
      <Autocomplete
        sx={{ mb: 3 }}
        onChange={ handleTagsChanged }
        value={tags}
        isOptionEqualToValue={ ( option, value ) => option.value === value.value }
        multiple
        id="tags-outlined"
        options={itemTags}
        getOptionLabel={ option => option.label }
        filterSelectedOptions
        renderTags={ (values, getTagProps) => (
          values.map( (value, index) => (
            <Chip variant="outlined" label={ value.label }
              { ...getTagProps({ index })}>
            </Chip>
          )
        ))}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            label=""
          />
        )}
      />
    </Box>
  )
}

const DEFAULT_ITEM_TAGS = [
  { label: 'hammer' },
  { label: 'plate compactor' },
  { label: 'brush cutter' },
  { label: 'stump shredder' },
  { label: 'pallete forks' },
  { label: 'bale spears' },
  { label: 'rotary cutter' },
  { label: 'mulching head' },
  { label: 'backhoe' },
  { label: 'bucket' },
  { label: 'broom' },
  { label: 'shredder' },
  { label: 'fork' },
  { label: 'grapple' },
  { label: 'planer' },
  { label: 'roller' },
  { label: 'rake' },
  { label: 'power rake' },
  { label: 'root rake' },
  { label: 'snow attachment' },
]
.map(option => ({ ...option, value: option.label }))
.sort(( o1, o2 ) => o1.label.localeCompare(o2.label))
