import { createSlice } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'

const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    loading: false,
    data: {} //Map of customers
  },
  reducers: {
    setCustomers(state, action) {
      const { customerMap } = action.payload

      state.data = {
        ...state.data,
        ...customerMap
      }
    }
  }
})

export const useCustomer = customerId => {
  const customer = useSelector(state => state.customers.data[ customerId ])
  return customer
}

export const { setCustomers } = customersSlice.actions
export default customersSlice.reducer
