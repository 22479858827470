import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { gql, useMutation } from '@apollo/client';
import { DashboardContext, OrderPageContext, AppContext } from '../contexts'
import Tooltip from '@mui/material/Tooltip';
import * as TripUtils from '../utils/TripUtils'
import * as Utils from '../utils'
import Alert from '@mui/material/Alert';
import CalendarDialog from '../components/CalendarDialog'
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import ReactDragListView from 'react-drag-listview';
import RouteStop from '../components/RouteStop'
import { EquipmentAutocomplete } from '../components/CustomerAutocomplete'
import { wait } from '../utils'
import { actions, usePageSelector } from '../redux/dashboardSlice'
import { useMember, useEquipment } from '../redux/appSlice'

export default function Trip(props) {
  const page = usePageSelector(page => page)

  const { tripWarnings={}, itemMap, order } = page
  const { trips: tripMap } = order

  const {
    trip,
    tripErrors=[],
    tripIndex,
    tripId,
    stops,
  } = props

  const { tripErrorsGlobal=[] } = trip ? trip : {}

  const [addReturnTrip]   = useMutation(ADD_RETURN_TRIP_MUTATION)
  const thisTripsWarnings = _.defaultTo(tripWarnings[ tripId ], [])

  const {
    overrideShowErrors,
    selectedEquipmentMap,
    selectedEquipment,
    showWarnings,
    tripWarningsGlobal,
    orderWarnings,
    handleDriverModalOpen,
    orderId,
    showErrors,
    orderErrors,
    handleQuantityChange,
    useStopOptions
  } = useContext(OrderPageContext)

  const dispatch = useDispatch()

  const { openSnackbar } = useContext(DashboardContext)

  const allEquipment  = useSelector(state => state.app.equipment)

  //Array of array errors...
  const all = arr => {
    if ( !arr ) {
      return []
    }

    return arr.reduce(( arr, innerArr ) => {
      return [ ...arr, ...innerArr ]
    }, [])
  }

  const tripValidationsAll = [
    ...tripErrors,
    ...thisTripsWarnings,
    ...all(trip.stopErrors),
    ...all(trip.stopWarnings),
    ...all(trip.stopErrorsPresave)
  ]

  const primaryDriverId = usePageSelector(page => page.order.trips[ trip.id ].equipment?.primaryDriverId)

  const [ instructionOptions, setInstructionOptions ]   = useState([])
  const [ calendarDialogOpen, setCalendarDialogOpen ]   = useState(false)
  const [ loadingRouteDetails, setLoadingRouteDetails ] = useState(false)

  const toggleLoadingRouteDetails = useCallback(loading => {
    setLoadingRouteDetails(loading)
  }, [])

  const stopCount     = _.keys(stops).length
  const stopOrStops   = stopCount === 1 ? 'stop' : 'stops'

  //Trip Menu
  const [ anchorEl, setAnchorEl ] = useState(null);
  const menuOpen                  = Boolean(anchorEl);

  useStopOptions([ trip.equipment ])

  const { equipmentOption, equipment }                  = useEquipment(trip.equipment?.id)
  const { member: driver, memberOption: driverOption }  = useMember(primaryDriverId)

  const [ returnTripDate, setReturnTripDate ] = useState(null)

  const handleSelectReturnDate = date => {
    setReturnTripDate(date)
  }

  const handleAddReturnTrip = tripId => {
    //Open the calendar dialog...
    setCalendarDialogOpen(true)
  }

  const handleMoreIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleCloseReturnTripModal = () => {
    handleMenuClose()
    setCalendarDialogOpen(false)
  }

  const handleConfirmReturnTrip = async () => {
    handleMenuClose()
    setCalendarDialogOpen(false)
    await wait(1000)

    if ( returnTripDate ) {
      addReturnTrip({
        variables: {
          orderId,
          returnTripDate: returnTripDate.format('YYYY-MM-DD')
        }
      })
      .then(response => {
        if ( response.data ) {
          const { addReturnTrip: { order } } = response.data
          if ( order ) {
            dispatch(actions.hydratePage({ orderId, fetchedOrder: order }))
          }
        }
      })
    }
  }

  //Handle when the user selects the create new trip button in the menu...
  const handleCreateNewTrip = () => {

    //Close the menu
    handleMenuClose()

    setTimeout(() => {
      dispatch(actions.addEmptyTrip())

      //Show the confirmation...
      openSnackbar('Added trip')
    }, 200)

  }

  //Delete the selected trip from the map...
  const handleDeleteThisTrip = () => {

    dispatch(actions.deleteTrip(tripId))
    handleMenuClose()
    openSnackbar('Deleted trip')

  }

  const handleReverseThisTrip = () => {
    dispatch(actions.reverseTrip(tripId))
    handleMenuClose()
  }

  const addDriverToTrip = useCallback(driver => {
    dispatch(actions.addDriverToTrip({ tripId, driverId: driver.id }))
  }, [ tripId ])

  const handleEquipmentChange = useCallback(option => {

    const equipmentId     = option ? option.value : null
    const equipment       = allEquipment[ equipmentId ]

    dispatch(actions.addEquipmentToTrip({ tripId, equipment }))

  }, [ tripId ])

  //Stops
  const removeStop = stopId => {
    dispatch(actions.removeStop({ tripId: trip.id, stopId }))
  }

  //Handle when the order of the stops changes...
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      dispatch(actions.handleDrag({ fromIndex, toIndex, tripId: trip.id }))
    },
    nodeSelector: 'li',
    handleSelector: 'a'
  }

  const hasTripErrors   = !_.isEmpty(tripErrorsGlobal) || !_.isEmpty(tripErrors)
  const hasTripWarnings = !_.isEmpty(tripWarningsGlobal) || !_.isEmpty(thisTripsWarnings)

  return (
    <div style={{ minWidth: 1000 }}>
      <CalendarDialog
        title="Add Return Trip"
        date={ returnTripDate }
        handleDateChange={ handleSelectReturnDate }
        handleClose={ handleCloseReturnTripModal }
        handleConfirm={ handleConfirmReturnTrip }
        enableTimeSelection={ false }
        text=""
        open={ calendarDialogOpen } />
      <div style={{
          display: "flex",
          alignItems: "center"
      }}>
        <h3>
          <i>TRIP { tripIndex + 1 } ({ Utils.truncateId(trip.id )})</i>&nbsp;
          { /* •  54mi • 2hr 54min*/ }
        </h3> 
        &nbsp;
        <IconButton onClick={ handleMoreIconClick }>
          <MoreHorizIcon style={{ color: '#666' }} />
        </IconButton>
      </div>
      <Grid xs={ 10 }>
      <Stack spacing={ 1 } sx={{ mb: 2 }}>
        { trip.completed === true ?
          <Alert severity="success">
            This trip was completed {
                ( trip?.deliveryStatus?.timestamp ?
                  moment(trip.deliveryStatus.timestamp * 1000).format('LLL') : '')
            }
          </Alert>
          :
          null
        }

        { trip.completed && trip?.deliveryStatus?.durationMinutes ?
          <Alert severity="success"
            sx={{ }}>
            Total time: {
              moment.duration(trip.deliveryStatus.durationMinutes, 'minutes').hours() + ' hours ' +
              moment.duration(trip.deliveryStatus.durationMinutes, 'minutes').minutes() + ' minutes'
            }
          </Alert>
          :
          null
        }

        { trip.completed && trip?.deliveryStatus?.notes ?
          <Alert severity="success"
          >
            Driver notes: { trip.deliveryStatus.notes }
          </Alert>
          :
          null
        }

    </Stack>

        { overrideShowErrors === false ?
          <Stack sx={{
              width: '100%',
              mt: ( showWarnings && hasTripWarnings || hasTripErrors ) ? 1 : 0,
              mb: ( showWarnings && hasTripWarnings || hasTripErrors ) ? 2 : 0,
            }} spacing={1}>
            <>
              { //Always show global trip errrors, display global first...
                [ ...tripErrorsGlobal, ...tripErrors ].map(( error, i ) => (
                  <Alert key={ i } severity={ error.type }>
                    { error.message }
                  </Alert>
                ))
              }
            </>
            <>
              { showWarnings ?
                [ ...tripWarningsGlobal, ...thisTripsWarnings ].map(( warning, i ) => (
                  <Alert severity={ warning.type }>
                    { warning.message }
                  </Alert>
                ))
                :
                null
              }
            </>
          </Stack>
          :
          null
        }
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={ handleMenuClose }
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={handleCreateNewTrip}>
          Add trip after this one
        </MenuItem>
        {
          _.keys(trip.stops).length === 2 && _.isEmpty(tripValidationsAll) &&
          _.keys(tripMap).length === 1 ?
          <MenuItem onClick={handleAddReturnTrip}>Add return trip</MenuItem>
          :
          null
        }
        { _.keys(tripMap).length > 1 ?
          <MenuItem onClick={handleDeleteThisTrip}>Delete this trip</MenuItem>
          :
          null
        }
        {
          false ?
            <MenuItem onClick={handleReverseThisTrip}>Reverse this trip</MenuItem>
            :
            null
        }
      </Menu>
      <Grid
        xs={ 10 }
        style={{ display: 'flex', backgroundColor: '#eee', padding: '5px 10px', alignItems: 'center', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>

        <LocalShippingIcon fontSize="small" sx={{ mr: 2 }} />
        <EquipmentAutocomplete
          timestampKey="lastUsedTimestamp"
          textFieldProps={{ size: 'small', variant: 'standard' }}
          equipmentOption={ equipmentOption }
          onValueChange={ handleEquipmentChange }
        />
        { trip.equipment?.id ?
          <Button
            sx={{ p: 1 }}
            onClick={ () => handleDriverModalOpen(addDriverToTrip, driver ) }>
            { driverOption?.label ? driverOption.label : "Select Driver" }
          </Button>
          :
          null
        }
      </Grid>

      <ReactDragListView { ...dragProps }>
        <ul>
        { TripUtils.sortStops(stops).map( ( stop, stopIndex, sortedStops ) => (
          <li key={ stopIndex }>
            <a>
              <RouteStop
                loadingRouteDetails={ loadingRouteDetails }
                toggleLoadingRouteDetails={ toggleLoadingRouteDetails }
                tripId={ tripId }
                stopErrors={ trip.stopErrors ? trip.stopErrors[ stopIndex ] : [] }
                stopErrorsPresave={ trip.stopErrorsPresave ? trip.stopErrorsPresave[ stopIndex ] : [] }
                stopWarnings={ trip.stopWarnings ?
                  trip.stopWarnings[ stopIndex ] : [] }
                addInstructionOptions={ trip.addInstructionOptions ?
                  trip.addInstructionOptions[ stopIndex ] : [] }
                index={ stopIndex }
                editedStop={ stop }
                stop={ stop }
                stops={ sortedStops }
                stopCount={ sortedStops.length }
                toggleInstructionIsPick=""
                removeStop={ () => removeStop(stop.id) }
              />
            </a>
          </li>
        )) }
        </ul>
      </ReactDragListView>

      <RouteStop
        tripId={ tripId }
        isPlaceholder={ true }
        addStop={ null } />
    </div>
  )
}

const ADD_RETURN_TRIP_MUTATION = gql`
mutation AddReturnTripMutation($orderId: String!, $returnTripDate: String!) {
  addReturnTrip(orderId: $orderId, returnTripDate: $returnTripDate) {
    message
    code
    order {
      id
      bolUpload {
        timestamp
        location
      }
      createdTime
      customer {
        id
        lastUsedTime
        createdTime
        name
        phone
        billingEmail
        addressHistory {
          placeId
          description
          textShort
          structured_formatting {
            main_text
            secondary_text
          }
          lastUsedTime
        }
      }
      dispatcher {
        id
        name
        email
        phone
      }
      groupId
      items {
        id
        baseRate
        description
        height
        length
        manufacturer
        model
        weight
        width
        quantity
        records {
          id
          values {
            key
            value
          }
        }
        tags {
          id
        }
      }
      status
      trips {
        id
        completed
        deliveryStatus {
          timestamp type durationMinutes notes
        }
        dispatched
        origin
        destination
        equipment {
          id
          createdTime
          primaryDriverId
          axels
          color
          description
          make
          model
        }
        groupId
        startTime
        endTime
        maxGrossWeight
        order
        stops {
          id
          address {
            placeId
            description
            textShort
            lastUsedTime
          }
          contact {
            id name phone email
          }
          instructions {
            id
            type
            itemId
            order
            quantity
          }
          isComplete
          order
          note
          locationMarker
          time
          timeChoice
        }
      }
      issues {
        message
        severity
      }
    }
  }
}
`
