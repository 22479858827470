import { useCallback, useContext, useRef, useState } from 'react'
import { gql, useLazyQuery, } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import CheckMUI from '@mui/icons-material/Check';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import TableInput from '../components/TableInput'
import RemoveIcon from '@mui/icons-material/Remove';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { documentId, collection, limit, getDocs, orderBy, query, where } from "firebase/firestore";
import { useGroupId, useDatabase } from '../hooks'
import { CustomAutocomplete } from '../components/RouteStopInputBase'
import Popper from '@mui/material/Popper';
import OrderInputContainer from './OrderInputContainer'
import LabelLeftContainer from './LabelLeftContainer'
import InputRightContainer from './LabelRightContainer'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { OrderPageContext } from '../contexts'
import { useItem } from '../redux/dashboardSlice'
import { handleSearch } from '../utils'
import { actions } from '../redux/dashboardSlice'
import { usePageSelector } from '../redux/dashboardSlice'
import { DashboardContext } from '../contexts'

export default function MultiItemInput(props) {

  const dispatch  = useDispatch()
  const db        = useDatabase()
  const { openSnackbar } = useContext(DashboardContext)

  const {
    items=[],
    handleAddItem,
    handleItemValueChange,
    handleQuantityChange,
    customerId,
    setAdditionalItemInfo,
    additionalItemInfo
  } = props

  const elementRef = useRef()

  /*
   * inputValue: ''
   * items: {} //No items
   *
   * //Then...
   * items: {
   *  itemId: {
   *    quantity: 1, //Change this every user interaction...
   *    metadata: [] //Remove everything else from map...
   *  }
   * }
   */

  const showFieldsItemId = usePageSelector(page => page.showFieldsItemId)

  const [ itemMenuAnchorEl, setItemMenuAnchorEl ]   = useState(null);
  const [ openItemMenuIndex, setOpenItemMenuIndex ] = useState(null)

  const [ itemQuantityPopperAnchorEl, setItemQuantityPopperAnchorEl ] = useState(null)
  const [ itemQuantityPopperValue, setItemQuantityPopperValue ]       = useState("1")
  const [ itemQuantityPopperIndex, setItemQuantityPopperIndex ]       = useState(null)

  /*
   * items: {
   *  "item123": {
   *    "quantity": 2,
   *    "records": [
   *      {
   *        "poNumber": 123,
   *        "stockNumber": 456
   *      }
   *    ],
   *    "tags": {
   *      "backhoe": true,
   *      "fork": true
   *    }
   *  }
   * }
   */
  const handleAdditionalItemDone = useCallback(( itemId, rows, tags ) => {
    dispatch(actions.setShowFieldsItemId(null))

    const tagMap = tags.reduce((map, option) => {
      return {
        ...map,
        //Can add other info associated with the tag...
        [ option.value ]: true
      }
    }, {})

    const filteredValues = rows.filter(value => {
      const keys = _.keys(value).filter(k => k !== 'id')
      const allPropertiesEmpty =
        _.every(keys, key => _.isEmpty(value[key]))
      return !allPropertiesEmpty
    })

    setTimeout(() => {
      dispatch(actions.updateAdditionalItemInfo({ itemId, tagMap, records: filteredValues }))
    }, 700)
  }, [])

  //Effect to keep the itemQuantity... input in sync with item
  const handleItemQuantyPopperChange = e => {
    const { value } = e.currentTarget

    if ( _.isEmpty(value) ) {
      setItemQuantityPopperValue('')
    } else {
      const number = parseInt(value.replace(/\D/g,''))
      setItemQuantityPopperValue( number ? number : '' )
    }
  }

  const handleOpenItemQuanityPopper = (event, index) => {
    setItemQuantityPopperAnchorEl(event.currentTarget)
    setItemQuantityPopperValue(items[ index ].quantity)
    setItemQuantityPopperIndex(index)
  }

  const handleCloseItemQuanityPopper = () => {
    setItemQuantityPopperAnchorEl(null)
    setItemQuantityPopperIndex(null)
  }

  const handleOpenItemMenu = (event, index) => {
    setItemMenuAnchorEl(event.currentTarget)
    setOpenItemMenuIndex(index)
  }

  const handleItemMenuClose = () => {
    setItemMenuAnchorEl(null)
    setOpenItemMenuIndex(null)
  }

  const addItem = () => {
    handleAddItem()
    handleItemMenuClose()
  }

  const handleCloseAdditionalFields = (records, tags) => {
    handleAdditionalItemDone(showFieldsItemId, records, tags)
  }

  const addAdditionalFields = id => {
    dispatch(actions.setShowFieldsItemId(id))
    handleItemMenuClose()
  }

  const handleItemSearch = useCallback(handleSearch, [])

  const handleItemQuantityKeyDown = ( e, index, value ) => {
    if ( e.code === 'Enter' ) {
      const itemId = items[ index ].value
      handleQuantityChange({ itemId, quantity: value })
      handleCloseItemQuanityPopper()
    }
  }

  const onDelete = deletedIndex => {
    dispatch(actions.removeItemFromOrder({ deletedIndex }))
    handleItemMenuClose()
  }

  return (
    <OrderInputContainer elementRef={ elementRef }>
      <Popper id={null}
        open={ itemQuantityPopperIndex !== null }
        anchorEl={itemQuantityPopperAnchorEl}
        placement="bottom-start"
        sx={{ zIndex: 2000 }}>
        <ClickAwayListener onClickAway={ handleCloseItemQuanityPopper }>
          <Paper sx={{ padding: 2, mt: 1, alignItems: 'center', display: 'flex' }}>
          { itemQuantityPopperAnchorEl ?
          <TextField size="small"
            autoFocus
            placeholder="Enter quantity"
            onChange={ handleItemQuantyPopperChange }
            onKeyDown={ e => handleItemQuantityKeyDown(e, itemQuantityPopperIndex, itemQuantityPopperValue) }
            value={ itemQuantityPopperValue }
            sx={{
              width: 150,
              mr: 2,
              mt: 1
            }}/>
            : null
          }
            <Button onClick={ () => {
              handleQuantityChange(itemQuantityPopperIndex, itemQuantityPopperValue)
              handleCloseItemQuanityPopper()
              }}>
              Add
            </Button>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <LabelLeftContainer label={ "Cargo" }>
      </LabelLeftContainer>
      <InputRightContainer>
        { items.map(( item, index) => (
          <ItemFields
            addAdditionalFields={ addAdditionalFields }
            openItemMenuIndex={ openItemMenuIndex }
            itemMenuAnchorEl={ itemMenuAnchorEl }
            handleQuantityChange={ handleQuantityChange }
            handleAdditionalItemDone={ handleAdditionalItemDone }
            handleItemValueChange={ handleItemValueChange }
            handleOpenItemQuanityPopper={ handleOpenItemQuanityPopper }
            handleOpenItemMenu={ handleOpenItemMenu }
            handleItemMenuClose={ handleItemMenuClose }
            handleCloseAdditionalFields={ handleCloseAdditionalFields }
            customerId={ customerId }
            onDelete={ onDelete }
            showFieldsItemId={ showFieldsItemId }
            item={ item }
            items={ items }
            index={ index }
            addItem={ addItem }/>
        )) }
      </InputRightContainer>
    </OrderInputContainer>
  )
}

const ItemFields = props => {
  const {
    addAdditionalFields,
    customerId,
    addItem,
    item,
    items,
    onDelete,
    openItemMenuIndex,
    itemMenuAnchorEl,
    index,
    showFieldsItemId,
    handleAdditionalItemDone,
    handleQuantityChange,
    handleCloseAdditionalFields,
    handleItemValueChange,
    handleOpenItemMenu,
    handleItemMenuClose,
    handleOpenItemQuanityPopper,
    handleAddItem
  } = props

  const dispatch        = useDispatch()
  const db              = useDatabase()
  const groupId         = useGroupId()

  const [getAutocompleteItems]  = useLazyQuery(GET_AUTOCOMPLETE_ITEMS_QUERY, {
    fetchPolicy: 'network-only'
  })
  const [getRecentlyUsedItems]  = useLazyQuery(GET_RECENTLY_USED_ITEMS_QUERY, {
    fetchPolicy: 'network-only'
  })
  const { itemOption }          = useItem(item.value)

  const fetchInitialOptions = useCallback(async () => {

    return getRecentlyUsedItems({
      variables: {
        customerId
      }
    })
    .then( response => {

      const { data } = response

      if ( data ) {
        const { getRecentlyUsedItems: items } = data

        dispatch(actions.setAutocompleteItems(items))

        const options = items ? items.map( i => ({
          value: i.id,
          label: i.description,
          timestamp: i.lastUsedTime,
          createdTime: i.createdTime,
          secondaryLabel: `${ i.manufacturer } ${ i.model }`
        })) : []

        return options
      }

      return []

    })

    /*
     * OPTIONS FORMAT
     * return {
     *   value: snapshot.id,
     *   label: customer.name,
     *   timestamp: customer[ groupUsageKey ]
     * }
     */

  }, [ getRecentlyUsedItems, customerId ])

  const fetchAutocompleteOptions = useCallback(async ({ text }) => {
    if ( _.isEmpty( text ) ) {
      return []
    }

    return getAutocompleteItems({
      variables: { text }
    })
    .then( response => {

      const { data : { getAutocompleteItems: items } } = response
      dispatch(actions.setAutocompleteItems(items))

      const options = items ? items.map( item => ({
        value: item.id,
        label: item.description,
        createdTime: item.createdTime,
        timestamp: item.lastUsedTime,
        secondaryLabel: `${ item.manufacturer } ${ item.model }`
      })) : []

      return options
    })
  }, [])

  const getSecondaryLabel = useCallback(option => {
    if ( option.secondaryLabel ) {
      return option.secondaryLabel
    }
  }, [])

  return (
    <>
      <div style={{
        display: 'flex',
          alignItems: 'center',
          width: '100%',
          marginBottom: index !== items.length -1 ? 10 : 0
        }}>
        <CustomAutocomplete
          searchField="searchTerm"
          placeholder="i.e. Excavator, Cat 332"
          getSecondaryLabel={ getSecondaryLabel }
          getOptionLabel={ option => {
            if ( option.secondaryLabel ) {
              return option.label + option.secondaryLabel
            }
            return option.label
          }}
          disabled={ _.isEmpty(customerId) }
          Icon={ Inventory2OutlinedIcon }
          dep={ customerId }
          fetchInitialOptions={ fetchInitialOptions }
          fetchAutocompleteOptions={ fetchAutocompleteOptions }
          noOptionsText="No cargo to display"
          optionKey="description"
          value={ itemOption }
          loadingValue={ false }
          onValueChange={ value => handleItemValueChange(value, index) }
          used={ items }
        />
        <ButtonGroup
          variant="outlined"
          size="small"
          sx={{ flex: 0, height: 40, ml: 2, mr: 2 }}
          aria-label="outlined primary button group">
          <Button
            sx={{ width: 50 }}
            disabled={ _.isEmpty( item.value ) }
            onClick={ () => {
              handleQuantityChange({
                index,
                quantity: item.quantity - 1,
                itemId: item.value
              })
            }}>
            <RemoveIcon size="small"/>
          </Button>
          <Button
            onClick={ event => handleOpenItemQuanityPopper(event, index) }
            disabled={ _.isEmpty( item.value ) }
            sx={{ fontSize: 'large', width: 100 }}>
            { item.quantity }
          </Button>
          <Button
            sx={{ width: 50 }}
            disabled={ _.isEmpty( item.value ) }
            onClick={ () => {
              handleQuantityChange({
                index,
                quantity: item.quantity + 1,
                itemId: item.value
              }) 
            }}>
            <AddIcon size="small"/>
          </Button>
        </ButtonGroup>

        <IconButton
          disabled={ _.isEmpty(item.value ) && items.length === 1 }
          size="small" onClick={ event => {
          handleOpenItemMenu(event, index)
        }}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={ itemMenuAnchorEl }
          open={ openItemMenuIndex === index }
          onClose={ handleItemMenuClose }
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem onClick={() => onDelete(index) }>
            <ListItemIcon>
              <DeleteOutlinedIcon size="small"/>
            </ListItemIcon>
            <ListItemText>
              Remove cargo
            </ListItemText>
          </MenuItem>
          { _.isEmpty(item.value) === false ?
            <>
              <MenuItem onClick={() => addAdditionalFields(item.value) }>
                <ListItemIcon>
                  <Inventory2OutlinedIcon size="small"/>
                </ListItemIcon>
                <ListItemText>
                  Add S/N, Stock #, PO #
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={addItem}>
                <ListItemIcon>
                  <AddIcon size="small"/>
                </ListItemIcon>
                <ListItemText>
                  Add more cargo
                </ListItemText>
              </MenuItem>
            </>
            :null
          }
        </Menu>
      </div>
      { ( _.isEmpty(item.records) === false || _.isEmpty( item.tags ) === false ) ?
        <Typography
          className="pointer"
          onClick={ () => addAdditionalFields(item.value) }
          variant="button" sx={{ mb: 1, mr: 2 }}>
          PO, S/N, ATTACHED
        </Typography>
        :
        null
      }
      <TableInput
        max={ item.quantity }
        itemId={ item.value }
        records={ item.records }
        tags={ item.tags }
        handleClose={ handleCloseAdditionalFields }
        open={ showFieldsItemId === item.value } />
    </>
  )
}

const GET_AUTOCOMPLETE_ITEMS_QUERY = gql`
query GetAutocompleteItemsQuery($text: String!) {
  getAutocompleteItems(text: $text) {
    id
    baseRate
    lastUsedTime
    description
    height
    length
    manufacturer
    model
    weight
    width
  }
}
`
const GET_RECENTLY_USED_ITEMS_QUERY= gql`
query GetRecentlyUsedItems($customerId: String) {
  getRecentlyUsedItems(customerId: $customerId) {
    id
    createdTime
    baseRate
    lastUsedTime
    description
    height
    length
    manufacturer
    model
    weight
    width
  }
}
`
