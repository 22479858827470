import { useState, useRef } from 'react';
import { signOut, getAuth } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import * as api from '../utils/api'
import { useMember, useCompanyId } from '../redux/appSlice'

export default function SettingsPage() {
  const userId = getAuth().currentUser.uid
  
  const navigate  = useNavigate()
  const iframeRef = useRef()
  const inputFile = useRef()

  const { memberOption, member }  = useMember(userId)
  const companyId                 = useCompanyId()

  const groupId = useSelector(state => state.app.groupId)
  const [ loadingQuickbooksWindow, setLoadingQuickbooksWindow ] = useState(false)

  const handleAvatarUpload = e => {
    const location    = `companies/${ companyId }/members/${ userId }/profile`
    api.handleFileUpload(e, location)
  }

  const handleAvatarClick = () => {
    inputFile.current.click()
  }

  //Call the authUri API to get the quickbooks authUri
  const connectToQuickbooks = () => {

    //Create an iframe on the page...
    setLoadingQuickbooksWindow(true)

    api.connectToQuickbooks(groupId)
    .then(response => response.json())
    .then(data => {

      const { companyId, token } = data

      //TODO: Should generate HTML containing
      setLoadingQuickbooksWindow(false)

      //Create the iframe...
      const ifrm = document.createElement("iframe")

      const quickbooksUri =
        process.env.REACT_APP_BACK_END_URI +
        `/quickbooks?token=${ token }&companyId=${ companyId }`

      ifrm.setAttribute("src", quickbooksUri)
      ifrm.style.display  = "none";
      ifrm.style.width    = "0";
      ifrm.style.height   = "0";

      iframeRef.current.appendChild(ifrm)

      //After 5 minutes, remove the iframe.
      //The token has expired...
      setTimeout(() => {
        iframeRef.current.removeChild(ifrm)
      }, 1000*60*5)

    })
  }

  console.log({ member })
  return (
    <div className="page" style={{ padding: 40 }}>
      <Box sx={{ maxWidth: 500, display: 'flex', flexDirection: 'column', border: 'primary' }}>
        <Avatar
          src={ member?.avatarUrl }
          onClick={ handleAvatarClick }
          sx={{ mb: 4, mr: 2, alignSelf: 'center' }} />
        <input
          type='file'
          id='file'
          accept=".png,.jpeg"
          onChange={ handleAvatarUpload }
          ref={inputFile} style={{display: 'none'}}/>
        <TextField variant="outlined" size="small" label="Name" sx={{ maxWidth: 400, mb: 2 }} value={ member?.name } />

        <Typography variant="h5" gutterBottom>
          Email Preferences
        </Typography>

        <FormGroup sx={{ mb: 4 }}>
          <FormControlLabel control={<Checkbox defaultChecked />}
            label="Send customer email when in route to pickup/dropoff location" />
          <FormControlLabel control={<Checkbox defaultChecked />}
            label="Send customer email on arrival to pickup/dropoff location" />
          <FormControlLabel control={<Checkbox defaultChecked />}
            label="Send customer email on all items picked/up + delivered" />
        </FormGroup>
        <Box sx={{ mb: 4 }}>
          <Button variant="contained" onClick={ connectToQuickbooks }>
            Connect Quickbooks
            { loadingQuickbooksWindow ?
              <CircularProgress />
              :
              null
            }
          </Button>
        </Box>
        <div id="iframe-holder" ref={ iframeRef }>
        </div>
      </Box>
    </div>
  )
}
