import _ from 'lodash'
import { PatternFormat, NumericFormat } from 'react-number-format';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import moment from 'moment'
import * as Utils from '../utils'

//Called when the groups change (from row change, refresh, date change etc)...
export const createAnimatedGroups = options => {

  const { showFinancialInfo, shouldResetAnimations, groups=[], currentGroups=[] } = options

  //Add 2 animated props to each row in the group:
  //  1. `expanded (true|false)`
  //  2. `animatedProps({})`
  const animatedGroups = groups.map((group, index) => {
    const { id, rows=[] }           = group
    const { rows: currentRows=[] }  = currentGroups[ index ] ? currentGroups[ index ] : {}

    const mappedRows = rows.map(row => {

      const currentRow = currentRows[ index ] ? currentRows[ index ] : {}
      const {
        animatedProps: currentAnimatedProps={},
        collapseProps: currentCollapseProps={}
      } = currentRow

      return {
        ...row,
        expanded: false,
        //For animating the color...
        animatedProps: shouldResetAnimations ? {} : currentAnimatedProps,
        //For animating the collapse...
        collapseProps: shouldResetAnimations ? {} : currentCollapseProps
      }
    })

    return {
      ...group,
      rows: mappedRows
    }
  })

  const totalHeight = calculateTotalHeight({ animatedGroups, showFinancialInfo })

  return { totalHeight, animatedGroups }
}

const calculateTotalHeight = options => {

  const { animatedGroups=[], showFinancialInfo } = options

  let totalHeight = 0

  for ( const group of animatedGroups ) {

    let groupHeight =
      TABLE_TOOLBAR_HEIGHT +
      TABLE_HEAD_HEIGHT +
      TABLE_PAGINATION_HEIGHT +
      (showFinancialInfo ? ROW_HEIGHT * 3 : 0 )

    const { rows } = group

    const totalRowHeight = rows.reduce((height, row) => {
      const { collapseProps={} } = row
      const expanded = collapseProps.animate?.height

      return height + ( expanded ? COLLAPSE_OPEN_HEIGHT : ROW_HEIGHT )

    }, 0)

    groupHeight += totalRowHeight
    totalHeight += groupHeight
  }

  if ( totalHeight === 0 ) {

    totalHeight =
      TABLE_TOOLBAR_HEIGHT +
      TABLE_HEAD_HEIGHT +
      ( ROW_HEIGHT * 1 ) +
      TABLE_PAGINATION_HEIGHT
  }

  return totalHeight
}

export const getSoonestPosition = animatedGroups => {

  let soonestTime = null
  let hasIssues   = false

  for ( const group of animatedGroups ) {

    const { rows } = group

    for ( const row of group.rows ) {

      //Choose the first segment from the row
      if ( _.isEmpty( row.issues) === false ) {
        hasIssues = true
      }

      const startTime = parseFloat(row?.metadata?.order?.trip?.startTime)

      if ( ( soonestTime === null && startTime ) || //We have a startTime and there's nothing else...
        ( soonestTime !== null && startTime && moment(startTime).isBefore(soonestTime) )) {
        soonestTime = startTime
      }
    }
  }

  if ( hasIssues ) {
    return 7 * COLUMN_WIDTH
  } else if ( soonestTime ) {
    return moment(soonestTime).hour() * COLUMN_WIDTH
  }
}

export const handleExpandRow = options => {
  const {
    animatedGroups,
    groupIndex,
    rowIndex
  } = options

  const group = animatedGroups[ groupIndex ]

  const update = animatedGroups.map((group, index ) => {

    //See if the group is expanded
    if ( index === groupIndex ) {
      const { rows=[] }           = group
      const row                   = rows[ rowIndex ]
      let { collapseProps={} }    = row
      const { expanded }          = row

      collapseProps = collapseProps.animate?.height ?
        { animate: { height: 0 } } :
        { animate: { height: COLLAPSE_OPEN_HEIGHT } }

      const mappedRows = rows.map(( row, index ) => {
        if ( index === rowIndex ) {
          return {
            ...row,
            //Leave animatedProps for the row alone...
            expanded: !expanded,
            //Change collapse props...
            collapseProps,
          }
        }
        return row
      })

      return {
        ...group,
        rows: mappedRows
      }
    }

    return group

  })

  return update
}
export const GANTT_CHART_WIDTH        = 500
export const TABLE_TOOLBAR_HEIGHT     = 64
export const TABLE_HEAD_HEIGHT        = 58
export const TABLE_PAGINATION_HEIGHT  = 50
export const TABLE_MARGIN_HEIGHT      = 10
export const ROW_HEIGHT               = 50
export const COLUMN_WIDTH             = 100
export const COLLAPSE_OPEN_HEIGHT     = 250 
export const DASHBOARD_COLUMNS        = [
  {
    field: 'orderId',
    label: 'Order ID',
  },
  {
    field: 'id',
    label: 'Trip ID',
  },
  {
    field: 'dispatcher',
    center: true,
    label: 'Dispatcher',
  },
  {
    field: 'issues',
    label: <CheckIcon />,
  },
  {
    field: 'date',
    label: 'Date',
  },
  {
    field: 'time',
    label: 'Time',
  },
  {
    field: 'customer',
    label: 'Customer',
  },
  {
    field: 'status',
    label: 'Status',
  },
  {
    field: 'origin',
    label: 'Origin',
  },
  {
    field: 'destination',
    label: 'Destination'
  },
  {
    field: 'items',
    label: 'Cargo',
  },
  {
    field: 'maxGrossWeight',
    label: 'WT',
    type: 'number'
  }
]

export const renderTableCell = (field, value, context) => {

  switch (field) {
    case 'checkField':
      return value ? 'Yes' : 'No'
    case 'phone':
      const format ="+1 (###) ### ####"
      return (
        <PatternFormat
          format={ format }
          displayType="text"
          value={ parseInt(value) }
          mask="_"
        />
      )
    //Link example...
    case 'linkExample':
      return (
        <Link href="#">
          {value}
        </Link>
      )
    //Number Fields...
    case 'maxGrossWeight':
      return (
        <NumericFormat
          value={ parseFloat(value) }
          thousandSeparator=","
          displayType="text"
        />
      )
    case 'dispatcher':
      const src   = _.defaultTo(value?.avatarUrl, null)
      const name  = value?.name
      const label = name ? name[ 0 ] : '-'

      return (
        <Avatar
          src={ src }
          onClick={ e => e.stopPropagation() }
          sx={{ ml: -3 }}>{ src ? null : label}</Avatar>
      )
    case 'status':
      return (
        <Chip
          color={ value === 'DISPATCHED' ? "primary" : "info"}
          variant="outlined"
          label={ value === 'DISPATCHED' ? 'DISPATCHED': 'NEW' }
          size="small"
          icon={ <CheckIcon /> }>
        </Chip>
      )
    case 'id':
      return Utils.truncateId(value)
    case 'issues':
      //Show warnings related to the trip...
      const hasIssues = value
      if ( hasIssues ) {
        return <WarningAmberIcon />
      }
      return <CheckIcon />
    case 'amount':
      return (
        <NumericFormat
          value={ parseFloat(value) }
          fixedDecimalScale
          decimalScale={ 2 }
          thousandSeparator=","
          displayType="text"
        />
      )
    default:
      return value
  }
}

const getAllItems = trip => {
  const { stops=[] } = trip
  const itemIds = []

  //Collect a list of all item ids...
  for ( const stop of stops ) {
    const { instructions=[] } = stop
    for ( const instruction of instructions ) {
      itemIds.push(instruction.itemId)
    }
  }
}

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}
