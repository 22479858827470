import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import CircularProgress from '@mui/material/CircularProgress';
import { DashboardContext, OrderPageContext } from '../contexts'
import { Document, Page, pdfjs } from 'react-pdf';
import { gql, useMutation, useLazyQuery, } from '@apollo/client';
import { useAPIOrder, useOrderId, useContact } from '../redux/dashboardSlice'
import { useSelector, useDispatch } from 'react-redux'
import InvoiceStatus from '../components/InvoiceStatus'
import * as api from '../utils/api'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { useCompanyId } from '../hooks'
import { actions, useCustomer, usePageSelector } from '../redux/dashboardSlice'
import { InvoiceIcon, PencilIcon } from '../icons'

//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

export default function QuoteModal(props) {
  const {
    open,
    handleClose,
    onCreateInvoice,
    refetchCustomerOrders
  } = props

  const apiOrder  = useAPIOrder()
  const companyId = useCompanyId()
  const orderId   = useOrderId()
  const dispatch  = useDispatch()

  const [ templateHtml, setTemplateHtml ] = useState('')
  const [ templateVariables, setTemplateVariables ] = useState({})
  const [ htmlContents, setHtmlContents ] = useState('')
  const [ pendingTemplateVariables, setPendingTemplateVariables ] = useState({})
  const [ lineItems, setLineItems ]       = useState([])
  const [ pendingLineItems, setPendingLineItems] = useState([])

  const quoteUrl  = "rj"
  const token     = useSelector(state => state.app.token)
  const [ loadingQuote, setLoadingQuote ] = useState(false)

  const getOptions = () => {
    const companyId = localStorage.getItem("companyId")
    const groupId   = localStorage.getItem("groupId")
    return {
      'Authorization': 'Bearer ' + token,
      'Company-Id': companyId,
      'Group-Id': groupId,
      'Content-Type': 'text/plain',
    }
  }

  useEffect(() => {
    setPendingLineItems(lineItems)
  }, [ lineItems ])

  //Effect to keep template variables
  //synced with template variables...
  useEffect(() => {
    setPendingTemplateVariables(templateVariables)
  }, [ templateVariables ])

  //Effect to get the quote when 
  useEffect(() => {
    if ( open ) {
      setLoadingQuote(true)

      api.getQuote(orderId).then(response => {
        const { html, lineItems } = response
        setTemplateHtml(html)
        setLineItems(lineItems)
        setLoadingQuote(false)
      })
    }
  }, [ open, apiOrder ])

  //Effect to compute the **default** variables for
  //the quote based on the api order...
  useEffect(() => {
    if ( !apiOrder ) {
      return;
    }
    const quoteMessage    = "This is the quote for a payment of $SUB_TOTAL for your review."
    const referenceName   = apiOrder.customer.name
    const validUntilDate  = moment.tz().add(1, 'day').format('MMM DD, YYYY hh:mma')
    const extraNotes      = "Thank you for your speedy review!!"
    const subtotal        = "0.00"
    const discount        = "0.00"
    const total           = "0.00"

    setTemplateVariables({
      quoteMessage,
      referenceName,
      validUntilDate,
      extraNotes,
      subtotal,
      discount,
      total
    })

  }, [ apiOrder ])

  //Change the html contents each time the
  //template variables change...
  useEffect(() => {


    let subtotal = 0

    let replacedContent =
      templateHtml
      .replaceAll(/\$REFERENCE_NAME/g, templateVariables.referenceName)
      .replaceAll(/\$QUOTE_MESSAGE/g, templateVariables.quoteMessage)
      .replaceAll(/\$VALID_UNTIL_DATE/g, templateVariables.validUntilDate)
      .replaceAll(/\$EXTRA_NOTES/g, templateVariables.extraNotes)
      .replaceAll(/\$DISCOUNT/g, templateVariables.discount)
      .replaceAll(/\$TOTAL/g, templateVariables.total)

    //Handle line items...
    let [ content1, content2, content3 ] = replacedContent.split("<!--ITEMS-->")

    //Content 2 represents our line items...
    const lineItemHtml = lineItems.map(item => {
      const content = content2
      subtotal += parseInt(item.amount)
      return (
        content
        .replaceAll(/\$LINE_ITEM_DESCRIPTION/g, item.description)
        .replaceAll(/\$LINE_ITEM_QUANTITY/g, item.quantity)
        .replaceAll(/\$LINE_ITEM_AMOUNT/g, item.amount)
      )
    })
    .join('<br/>')

    replacedContent =
      (content1 + lineItemHtml + content3)
      .replaceAll(/\$SUB_TOTAL/g, '<strong>$'+ subtotal + '</strong>')

    setHtmlContents(replacedContent)

  }, [ templateVariables, lineItems, templateHtml ])

  const orderModalOpen  = useSelector(state => state.dashboard.orderModalOpen)

  const { customer, customerOption }  = useContext(OrderPageContext)
  const { openSnackbar } = useContext(DashboardContext)

  const [ numPages, setNumPages ]     = useState(null);
  const [ pageNumber, setPageNumber ] = useState(1);

  function handleCreateInvoice() {
  }

  function handleTemplateVariableChange(name, value) {
    setPendingTemplateVariables({
      ...templateVariables,
      [ name ]: value
    })
  }

  function handleLineItemChange(index, field, value) {
    const lineItem        = lineItems[ index ]
    const updatedLineItem = {
      ...lineItem,
      [ field ]: value
    }

    const updatedLineItems =
      lineItems.map((item, i) => i === index ? updatedLineItem : item)

    setPendingLineItems(updatedLineItems)
  }

  function handleTemplateUpdate() {
    if ( _.isEqual(templateVariables, pendingTemplateVariables) &&
      _.isEqual(pendingLineItems, lineItems)) {
      return;
    }
    setLoadingQuote(true)
    setTemplateVariables(pendingTemplateVariables)
    setLineItems(pendingLineItems)
    setTimeout(() => {
      setLoadingQuote(false)
    }, 500)
  }

  function handleSendInvoice() {
    openSnackbar("Pending post-launch testing. Edit invoice via Quickbooks ✨")
    return;
  }

  const height        = orderModalOpen ? 'calc(60vh)' : 'calc(80vh)'
  const sectionHeight = orderModalOpen ? 'calc(50vh)' : 'calc(70vh)'

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: orderModalOpen ? '60%': '80%',
    transform: 'translate(-50%, -50%)',
    minHeight: height,
    maxHeight: height,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    outline: 0
  }

  return (
    <>
      <Modal
        open={ open }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Box sx={{ ...modalStyle, borderRadius: 2, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Grid container className="invoice-modal">
              <Grid
                item
                xs={ 4 }
                style={{
                    paddingBottom: 40,
                    paddingRight: 20,
                    paddingLeft: 40,
                    height: height,
                    overflowY: 'scroll',
                    boxShadow: '4px 0px 16px rgba(0, 0, 0, 0.06)'
                }}>
                  <div style={{ padding: 40 }}>
                    <h2
                      style={{
                          borderBottom: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                      }}>
                      QUOTE<PencilIcon />
                    </h2>
                    <div className="document-title">
                      <InvoiceIcon />&nbsp;
                      <p style={{ fontSize: 20 }}>
                        { customerOption?.label }
                      </p>
                    </div>
                    <h5 style={{ margin: 0, marginTop: 5 }}>Not opened</h5>
                    <hr />
                    { quoteUrl ?
                      <>
                        <h3>
                          Template variables
                        </h3>
                        <label>Contact Name</label><br />
                        <TextField
                          value={ pendingTemplateVariables.referenceName }
                          onChange={ e => handleTemplateVariableChange('referenceName', e.target.value)}
                          onBlur={ handleTemplateUpdate }
                          size="small"
                          sx={{ width: '100%' }}
                          className="contact-field"
                          id="filled-basic" label="" />

                        <label>Message</label><br />
                        <TextField
                          value={ pendingTemplateVariables.quoteMessage }
                          onChange={ e => handleTemplateVariableChange('quoteMessage', e.target.value)}
                          onBlur={ handleTemplateUpdate }
                          size="small"
                          sx={{ width: '100%' }}
                          className="contact-field"
                          id="filled-basic" label="" />

                        <label>Valid Until</label><br />
                        <TextField
                          value={ pendingTemplateVariables.validUntilDate}
                          onChange={ e => handleTemplateVariableChange('validUntilDate', e.target.value)}
                          onBlur={ handleTemplateUpdate }
                          size="small"
                          sx={{ width: '100%' }}
                          className="contact-field"
                          id="filled-basic" label="" />
                        <label>Extra Notes</label><br />
                        <TextField
                          value={ pendingTemplateVariables.extraNotes }
                          onChange={ e => handleTemplateVariableChange('extraNotes', e.target.value)}
                          onBlur={ handleTemplateUpdate }
                          size="small"
                          sx={{ width: '100%' }}
                          className="contact-field"
                          id="filled-basic" label="" />
                        <br />
                        <hr />
                        <h3>
                          Line Items
                        </h3>
                        {
                          pendingLineItems.map(( item, itemIndex ) => (
                            <div>
                              <h5><em>Line Item { itemIndex + 1}</em></h5>
                              { [{ label: 'Description', field: 'description' },
                                { label: 'Amount', field: 'amount' },
                                { label: 'Quantity', field: 'quantity' },
                              ].map( e =>
                                <>
                                  <label>{ e.label }</label><br />
                                  <TextField
                                    value={ item[ e.field ] }
                                    type="text"
                                    onBlur={ handleTemplateUpdate }
                                    onChange={ event => {
                                      handleLineItemChange(itemIndex, e.field, event.target.value)
                                    }}
                                    size="small"
                                    sx={{ width: '100%' }}
                                    className="contact-field"
                                    id="filled-basic" label="" />
                                  <br />
                                </>
                              )
                              }
                            </div>
                          ))
                        }
                        {/*
                        <Button
                          onClick={ null }
                          sx={{ backgroundColor: 'transparent !important', p: 0, mb: 3 }}>Add line item</Button>
                        <Button sx={{ mt: 3, width: '100%' }}
                          onClick={ () => openSnackbar("Pending post-launch testing. Edit invoice via Quickbooks ✨") }
                          variant="contained">
                          Update
                        </Button>
                        */
                        }
                      </>
                      :
                      <Button variant="contained"
                        onClick={ handleCreateInvoice }
                        sx={{ mb: 1,mt:1, mr: 3, width: '100%' }}>
                        Send Quote
                      </Button>
                    }
                  </div>
              </Grid>
              <Grid item xs={ 8 } sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                <div style={{ ...(noInvoiceStyles), maxHeight: sectionHeight, minHeight: sectionHeight, overflowY: 'scroll', padding: 10 }}>
                  { loadingQuote ?
                    <CircularProgress size={ 30 } />
                    :
                    <iframe
                      style={{ margin: 0, display: 'block', width: '100%', height: '100%', border: 'none', outline: 'none' }}
                      src={"data:text/html;charset=utf-8," + escape(htmlContents)} />
                  }
                </div>
                { quoteUrl ?
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained"
                      onClick={ handleSendInvoice }
                      sx={{ mb: 1,mt:1, mr: 3 }}>
                      Send Quote
                    </Button>
                  </div>
                  :
                  null
                }
              </Grid>
            </Grid>
        </Box>
      </Modal>
    </>
  )
}

const PDF_URL = process.env.REACT_APP_BACK_END_URI

//https://www.chartjs.org/docs/latest/charts/bubble.html
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CREATE_INVOICE_MUTATION = gql`
mutation Mutation($orderId: String!) {
  createInvoice(orderId: $orderId) {
    message
    code
    invoice {
      id
      pdfUrl
      lineItems {
        id description amount quantity metadata { tripId }
      }
    }
  }
}
`
const GENERATE_INVOICE_URL_MUTATION = gql`
mutation Mutation($invoiceId: String!, $orderId: String!) {
  generateInvoiceURL(invoiceId: $invoiceId, orderId: $orderId) {
    message
    code
    url
  }
}
`
const SEND_INVOICE_MUTATION = gql`
mutation SendInvoiceMutation($orderId: String!) {
  sendInvoice(orderId: $orderId) {
    message
    code
  }
}
`
const noInvoiceStyles = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
