import React, { useEffect, useCallback, useState, useRef } from 'react'
import _ from 'lodash'
import ClickAwayListener from '@mui/base/ClickAwayListener';
import moment from 'moment'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Button from '@mui/material/Button';
import Box from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import { motion } from 'framer-motion'

export default function DateTimePickerPopper(props) {

  const {
    onDateChange,
    disablePast=false,
    displayStatic=false,
    enableTimeSelection=false,
    anchorEl,
    selectedDate,
    open,
    id,
    handleCloseDatePicker
  } = props

  const didAnimateRef                     = useRef()
  const timeDivRef                        = useRef()
  const [ times, setTimes ]               = useState([])
  const [ selectedTime, setSelectedTime ] = useState(null)

  const calcScrollPosition = ( hour, minute=0 ) => {

    const position = hour * HOUR_PARTITIONS * TIME_HEIGHT_TOTAL +
      ( minute ? 1 * TIME_HEIGHT_TOTAL : 0 ) - ( TIME_HEIGHT_TOTAL * 3 )

    return position
  }

  const scrollTimeIntoView = useCallback( selectedTime => {

    const desiredScrollPosition = moment(selectedTime).second() === 1 ?
      calcScrollPosition(moment(selectedTime).hour(), moment(selectedTime).minute())
      :
      calcScrollPosition(9) //Automatically scroll to 9:00am

    timeDivRef.current.scrollTo(0, desiredScrollPosition)
  }, [])

  const computeTimes = selectedDate => {
    //Take the selected date and form several selectable times...
    const times       = []
    const currentTime = moment(selectedDate).clone().hour(0).minute(0).second(0).millisecond(0)

    while ( currentTime.date() === moment(selectedDate).date() ) {
      const disabled = !currentTime.isAfter(moment())
      times.push({
        disabled: disabled,
        value: currentTime.clone()
      })
      currentTime.add(30, 'minutes')
    }

    return times
  }

  const runTimeEffect = times => {
    setTimeout(() => {
      //Update the times...
      setTimes(times)
    }, 500)
  }

  //Effect to reset the times...
  useEffect(() => {
    if ( !open ) {
      //Empty allow times to be created again...
      setTimes([])
    }
  }, [ open ])

  //Effect to automatically run the effect
  //if the user has already selected a time..
  useEffect(() => {
    if ( open && selectedDate && _.isEmpty(times) ) {
      //Check if the second is set to 1...
      if ( moment(selectedDate).second() === 1 ) {
        //The user has already selected the time...
        const times = computeTimes(selectedDate)
        runTimeEffect(times)
        setTimeout(() => {
          if ( timeDivRef.current ) {
            scrollTimeIntoView(selectedDate)
          }
        }, 1000)
      }
    }
  }, [ scrollTimeIntoView, open, selectedDate, times ])

  //Effect to automatically scroll to a reasonable time IF
  //the user is just selecting the time...
  useEffect(() => {
    if ( _.isEmpty( times ) === false && moment(selectedDate).second() !== 1 ) {
      scrollTimeIntoView(selectedDate)
    }
  }, [ times, selectedDate, scrollTimeIntoView ])

  //When the user selects the date...
  const handleDateChange = selectedDate => {
    //First, make sure the date is 'zeroed'
    moment(selectedDate).hour(0).minute(0).second(0).millisecond(0)
    onDateChange(selectedDate)

    //Check if we should allow them to select a time...
    if ( enableTimeSelection ) {
      const times = computeTimes(selectedDate)
      runTimeEffect(times)
    }
  }

  //Effect to notify the parent once the selected time changes...
  const handleTimeChange = date => {
    console.log("HERE")
    //Set the second to 1 to indicate the time has been set...
    date.value.second(1)

    //Now we're able to inform the parent with the selected time...
    if ( onDateChange ) {
      onDateChange(date.value)
    }
  }

  const ReactDiv = ({ children, ...props }) => {
    return (
      <div { ...props }>
        { children }
      </div>
    )
  }
  const ContainerElement = displayStatic ? ReactDiv : Popper

  return (
    <ContainerElement
      id={id}
      component="div"
      open={open}
      anchorEl={anchorEl}
      sx={{ zIndex: 2000 }} placement="bottom">
      <ClickAwayListener onClickAway={handleCloseDatePicker}>
        <Paper sx={{ zIndex: 20000, p: 3, width: 'fit-content' }} elevation={3}>
          <motion.div layout className="flex" style={{ }}>
            <StaticDatePicker
              disablePast={ disablePast }
              orientation="landscape"
              //displayStaticWrapperAs="desktop"
              openTo="day"
              componentsProps={{
                actionBar: {
                  actions: []
                }
              }}
              slots={{
                actionBar: () => null
              }}
              views={['day']}
              sx={{ border: 0 }}
              value={ selectedDate }
              onChange={ date => {
                handleDateChange(date)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            { enableTimeSelection ?
              <div
                className="snap-parent"
                ref={ timeDivRef }
                style={{ maxHeight: TIME_HEIGHT_TOTAL * 6, overflow: 'scroll' }}>
                { times.map( t => {

                  //Find out if the time is the same as the one selected...
                  const isSameAsSelected =
                      selectedDate &&
                      moment(selectedDate).second() === 1 /* Selecttion indicator */ &&
                      t.value.hour() === moment(selectedDate).hour() &&
                      t.value.minute() === moment(selectedDate).minute()

                  return (
                    <div className="snap-child">
                      <Button
                        disabled={ t.disabled }
                        variant="outlined"
                        onClick={ () => handleTimeChange(t) }
                        sx={{
                            width: 200,
                            height: TIME_HEIGHT,
                            marginBottom: `${ TIME_MARGIN }px`,
                            backgroundColor: isSameAsSelected ? '#eee !important' : 'auto'
                        }}>
                        { t.value.format('h:mm a') }
                      </Button>
                    </div>
                  )
                })}
              </div>
              : null
            }
          </motion.div>
        </Paper>
      </ClickAwayListener>
    </ContainerElement>
  )
}

const HOUR_PARTITIONS   = 2
const TIME_MARGIN       = 5
const TIME_HEIGHT       = 30
const TIME_HEIGHT_TOTAL = TIME_MARGIN + TIME_HEIGHT
