import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import _ from 'lodash'
import { motion } from 'framer-motion'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DateTimePickerPopper from '../components/DateTimePickerPopper'

export default function CalendarDialog(props) {

  const {
    title,
    text,
    open,
    handleDateChange,
    enableTimeSelection=false,
    handleConfirm,
    date,
    handleClose
  } = props

  const contentRef = useRef()
  const [ anchorEl, setAnchorEl ] = useState(null)

  useEffect(() => {
    setAnchorEl(contentRef.current)
  }, [ open ])

  return (
    <Dialog open={open} onClose={handleClose} sx={{ zIndex: 90000}}>
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent component="div">
        <DialogContentText>
          { text }
        </DialogContentText>
        <motion.div layout style={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
          <DateTimePickerPopper
            selectedDate={ date }
            onDateChange={ handleDateChange }
            open={ true }
            displayStatic={ true }
            disablePast={ true }
            enableTimeSelection={ enableTimeSelection }
          />
        </motion.div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={ _.isNil(date) }
          onClick={handleConfirm}>Select Date</Button>
      </DialogActions>
    </Dialog>
  )
}
