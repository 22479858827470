import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BarLoader from "react-spinners/BarLoader";


const style = {
  position: 'absolute',
  display: 'flex',
  flexDirection:"column",
  justifyContent: 'center',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4
};

export default function LoadingModal(props) {
  const {
    title,
    open,
    handleOpen,
    handleClose
  } = props

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="button" sx={{ mb: 3 }}>
            { title }
          </Typography>
          <BarLoader size={ 15 } color={ "#2e7d32" }/>
        </Box>
      </Modal>
    </div>
  );
}
