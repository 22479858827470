import React from 'react'

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const ColorSelector = ({ color, onChange }) => {
  return (
    <Paper elevation={ 1 } sx={{ padding: 2, display: "flex" }}>
      { COLORS.map( colorValue => (
        <ColorBox
          colorValue={ colorValue }
          selected={ colorValue === color  }
          onChange={ onChange }
        />
      ))}
    </Paper>
  )
}

export const ColorBox = ({ colorValue, selected, onChange=NO_OP }) => {
  return (
    <Box sx={{
        p:1,
        borderRadius: 2,
        border: selected ? '1px solid #BEBEBE': '1px solid transparent',
        mr: 1
      }}
      onClick={ () => onChange(colorValue) }>
      <Box sx={{
          borderRadius: 1,
          outlineOffset: '.2rem',
          backgroundColor: colorValue,
          width: 20,
          height: 20
      }}>
      </Box>
    </Box>
  )
}

const NO_OP = () => null

const COLORS = [ "#EEE", "#3A963A", "#2D3A3A", "#858AE3", "#F7B05B", "#247BA0", "#70ABAF" ]
