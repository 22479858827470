import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { getAnalytics } from "firebase/analytics";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, gql } from '@apollo/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { setContext } from '@apollo/client/link/context';
import { FirestoreContext } from './contexts'
import { app, db } from './config'
import { store } from './store'

const analytics = getAnalytics(app);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACK_END_URI + '/graphql',
})

const authLink = setContext(async (_, { headers }) => {
  const token     = localStorage.getItem('token')
  const companyId = localStorage.getItem('companyId')
  const groupId   = localStorage.getItem('groupId')

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "Company-Id": companyId,
      "Group-Id": groupId
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      TripEquipment: {
        keyFields: false
      },
      LineItem: {
        keyFields: false
      },
      InvoiceLineItem: {
        keyFields: false
      },
      Order: {
        keyFields: false
      },
      Stop: {
        keyFields: false
      },
      Instruction: {
        keyFields: false
      },
      Trip: {
        keyFields: false
      },
      OrderItem: {
        keyFields: false
      }
    }
  }),
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={ store }>
      <ApolloProvider client={client}>
        <FirestoreContext.Provider value={ db }>
          <LocalizationProvider dateAdapter={ AdapterMoment }>
            <App />
          </LocalizationProvider>
        </FirestoreContext.Provider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
