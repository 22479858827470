import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client';
import {
  CheckIconEmpty,
  CheckIconPlaceholder,
  CheckIcon,
  DriverIcon,
} from '../icons'
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import * as TripUtils from '../utils/TripUtils'
import IconButton from '@mui/material/IconButton';
import { NumericFormat } from 'react-number-format';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { collection, limit, getDocs, orderBy, query } from "firebase/firestore";
import { ErrorBoundary } from "react-error-boundary";
import { getDistance } from '../utils/api'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useHover from '@react-hook/hover'
import { motion } from 'framer-motion'
import InstructionDialog from './InstructionDialog'
import { GoogleMapsAutocomplete } from './RouteStopInputBase'
import LocationMarkerDialog from './LocationMarkerDialog'
import ComparableDateInput from './ComparableDateInput'
import Grid from '@mui/material/Grid';
import moment from 'moment'

import { OrderPageContext } from '../contexts'
import StopInstruction from './StopInstruction'
import { actions, useContact } from '../redux/dashboardSlice'
import { withErrorBoundary } from '../utils'

const RouteStop = withErrorBoundary((props) => {
  const {
    setTripMap,
    tripId,
    stops=[],
    stopErrors=[],
    stopWarnings=[],
    stopErrorsPresave=[],
    stop={},
    stopCount,
    addInstructionOptions=[],
    toggleLoadingRouteDetails,
    loadingRouteDetails,
    index,
    removeStop,
    isPlaceholder=false,
  } = props

  const {
    handlePickupDateChange,
    handleDeliveryDateChange,
    tripMapRef,
    tripMapInitialRef,
    itemMap,
    customer,
    contactRefreshMap,
    setOrderErrors,
    setTripErrorsGlobal,
    showWarnings,
    setOrderWarnings,
    setStopWarnings,
    pickupDate,
    handleCloseDialog,
    itemMapRef,
    availableItems,
    setActiveDialogData,
    handleContactModalOpen,
    handleClose,
    showErrors,
    overrideShowErrors,
    useStopOptions
  } = useContext(OrderPageContext)

  const dispatch  = useDispatch()
  const stopId    = stop.id

  const [getRouteDetails, { loading, error, data }] =
    useLazyQuery(GET_ROUTE_DETAILS_QUERY, { fetchPolicy: 'network-only' })

  //Animations...
  const HOVER_OPTIONS = { enterDelay: 50, leaveDelay: 50 }
  const skippedDistanceCalculationInitial = useRef(false)
  const lastAddressUpdateRef    = useRef(null)
  const elementRef              = useRef()
  const isHovering              = useHover(elementRef, HOVER_OPTIONS)

  //Use autocomplete customers to get
  //initial options...
  const autocompleteCustomers = useSelector(state => state.dashboard.autocompleteCustomers)

  const [ instructionDialogOpen, setInstructionDialogOpen ] = useState(false)

  const { contactOption, contact } = useContact(stop.contact?.id)

  const handlePickupOrDropoffTimeChange = time => {
    dispatch(actions.editTime({ tripId, stopId: stop.id, time }))
  }

  //Effect to set the pickup or delivery date for the trip...
  useEffect(() => {
    if ( index === 0 && stop.time ) {
      handlePickupDateChange(tripId, stop.time)
    } else if ( index === stops.length -1 ) {
      //TODO:
      //handleDeliveryDateChange(tripId, stop.time)
    }
  }, [ index, stops.length, stop.time, tripId ])

  useEffect(() => {
    if (! lastAddressUpdateRef.current ) {
      return; //Wait until the user does something...
    }

    const stopInfo =
      TripUtils.createRouteDistanceArray({ tripMap: tripMapRef.current, tripId })

    //Only call the api when both placeId and time is
    //present on all stops...
    if ( stopInfo ) {

      //toggleLoadingRouteDetails(true)

      getRouteDetails({
        variables: {
          stops: stopInfo
        }
      })
      .then( response => {
        if ( response.data ) {
          const { getRouteDetails: routeDetails } = response.data
          dispatch(actions.updateRouteInfo({ tripId, routeDetails }))
        }
      })
    }

  }, [ stop.address?.placeId, tripId ])

  const handleInstructionDialogOpen = () => {
    setInstructionDialogOpen(true);
  }

  const handleInstructionDialogClose = () => {
    setInstructionDialogOpen(false)
  };

  const handleAddSelectedInstructions = selectedInstructions => {

    dispatch(actions.addSelectedInstructions({ tripId, stopId: stop.id, selectedInstructions }))
    handleInstructionDialogClose()
  }

  //Marker Dialog
  const [ locationMarkerDialogOpen, setLocationMarkerDialogOpen ] = useState(false)

  //Dialog
  const handleOpenLocationMarkerDialog= () => {
    setLocationMarkerDialogOpen(true)
  }

  const handleCloseLocationMarkerDialog = () => {
    setLocationMarkerDialogOpen(false)
  }

  const handleConfirmLocationMarkerDialog = value => {
    dispatch(actions.editLocationMarker({ tripId, stopId, marker: value }))
    setLocationMarkerDialogOpen(false)
  }

  //Choice can be AM, PM
  const toggleTimeChoice = choice => {
    dispatch(actions.toggleTimeChoice({ tripId, stopId: stop.id, choice }))
  }

  const onAddressChange = ( address ) => {
    lastAddressUpdateRef.current = true
    dispatch(actions.editAddress({ tripId, stopId, address }))
  }

  //Handle when the user inserts a location marker pin...
  const handleLocationMarkerChange = marker => {
    dispatch(actions.editLocationMarker({ tripId, stopId, marker }))
  }

  const addInstruction = () => {
    dispatch(actions.quickAddInstruction({ tripId, stopId, stopType: stop.type }))
  }

  const removeInstruction = instructionIndex => {
    dispatch(actions.removeInstruction({ tripId, stopId, instructionIndex }))
  }

  const fetchInitialOptions = useCallback(async () => {

    if ( !customer.id ) {
      return []
    }

    //First see if we can get the customer's address history
    //from the autocomplete items...
    const autocompleteCustomer = autocompleteCustomers.find( c => c.id === customer.id)

    if ( autocompleteCustomer ) {

      if ( _.isEmpty( autocompleteCustomer.addressHistory ) ) {
        return [] //the customer has no address history
      } else {
        return autocompleteCustomer.addressHistory.map(history => ({
          ...history,
          timestamp: history.lastUsedTime
        }))
      }

    }

    //Fetch the customer...
    //TODO
    return []

    const currentAddressPlaceId = stop.address?.placeId

    const slightDelay = new Promise( resolve => {
      setTimeout(() => {
        resolve(null)
      }, 600)
    })

    //Get the most recent addresses...
    const options =
      []
      .sort((o1, o2) => {
        if ( o1.placeId === currentAddressPlaceId ) {
          return -100
        } else if ( o2.placeId === currentAddressPlaceId ) {
          return 100
        }
        else {
          return o2.timestamp - o1.timestamp
        }
      })

    return (
      Promise
      .all([ slightDelay, options ])
      .then(() => {
        return options
      })
    )

  }, [ customer.id, stop.address, autocompleteCustomers ])

  //Effect to calculate the instruction options available
  //each time the instructions this stop was given changes
  useStopOptions([ stop.instructions, stops.length, stop.address, stop.time ])

  //Effect to determine the stop type based on the instructions
  useEffect(() => {
    const typeCount   = _.keys(stop.instructions).length
    const hasPickup   = _.isEmpty(stop.instructions?.pickup) === false
    const hasDropoff  = _.isEmpty(stop.instructions?.dropoff) === false

    let stopType;

    if ( typeCount === 2 ) {
      stopType = 'multiple'
    } else if ( hasPickup ) {
      stopType = 'pickup'
    } else if ( hasDropoff ) {
      stopType = 'dropoff'
    } else if ( stop.order === 0 ) {
      stopType = 'pickup'
      //User can decide, we won't take action...
    } else {
      stopType = 'dropoff'
    }

    if ( stop.id && _.isNil( stopType ) === false && stopType !== stop.type ) {
      dispatch(actions.togglePickupOrDropoff({ stopId, tripId, desiredType: stopType }))
    }

  }, [ stop.instructions, stop.type, tripId, stop.id ])

  useEffect(() => {
    dispatch(actions.calculateGrossWeights({ tripId }))
  }, [ stop.instructions, tripId, itemMap ])

  /* This isn't the last stop, and there are multiple stops" */
  const IS_FIRST_STOP_OTHER_STOPS_EXIST     = index === 0 && stopCount > 1
  const IS_AFTER_FIRST_NO_OTHER_STOPS_EXIST = index > 0 && stopCount === index + 1
  const IS_AFTER_FIRST_OTHER_STOPS_EXISTS   = index > 0 && stopCount > index + 1

  /* Add top border if previous route is same type */
  const SHOULD_ADD_TOP_BORDER = ( index > 0 &&  //Greater than the first stop
    stops[ index -1 ].type === stop.type //not the same as the last stop
  )

  const topBorderStyle = stop.type !== 'pickup' ?
    '1px dashed #fff' : '1px dashed #eee'

  const connectionType =
    isPlaceholder ? "NONE" :
    (IS_FIRST_STOP_OTHER_STOPS_EXIST ? "BELOW" :
    ( IS_AFTER_FIRST_OTHER_STOPS_EXISTS ? "ABOVE_AND_BELOW" : "ABOVE" ))

  const switchToPickupOrDropoff = () => {
    dispatch(actions.togglePickupOrDropoff({ tripId, stopId }))
  }

  const addStop = () => {
    dispatch(actions.addStop({ tripId }))
  }

  const toggleInstructionIsPick = instructionIndex => {
    dispatch(actions.toggleInstructionIsPick({ tripId, stopId, instructionIndex }))
  }

  const addContactToStop = contact => {
    dispatch(actions.addContactToStop({ tripId, stopId, contactId: contact.id }))
  }

  const handleAddNote = (value, done=false) => {
    const valueIfEmpty = ( _.isEmpty(value) && done ) ? null : value
    dispatch(actions.addNoteToStop({ tripId, stopId, note: valueIfEmpty }))
  }

  if ( !stop ) {
    return; //Must have a stop //TODO: Find out why stops are sometimes null
  }

  const showingErrors =
    _.isEmpty(stopErrors) === false ||
      ( showWarnings &&
        ( _.isEmpty(stopErrorsPresave ) === false ||
          _.isEmpty(stopWarnings) === false
        )
      )

  return (
    <motion.div layout>
      <InstructionDialog
        addInstructionOptions={ addInstructionOptions }
        instructionDialogOpen={ instructionDialogOpen }
        handleInstructionDialogOpen={ handleInstructionDialogOpen }
        handleInstructionDialogClose={ handleInstructionDialogClose }
        handleAddSelectedInstructions={ handleAddSelectedInstructions }
        />
      <LocationMarkerDialog
        stopIndex={ index }
        value={ stop.locationMarker }
        open={ locationMarkerDialogOpen }
        onChange={ handleLocationMarkerChange }
        handleClose={ handleCloseLocationMarkerDialog }
        handleConfirm={ handleConfirmLocationMarkerDialog } />
      <Grid
        ref={ elementRef }
        sx={{
            paddingLeft: '40px',
            borderBottomLeftRadius: index === stops.length -1 ? 10 : 0,
            borderBottomRightRadius: index === stops.length -1 ? 10 : 0,
            mb: 2,
            borderTopRightRadius: index !== 0 ? 10 : 0,
            borderTopLeftRadius: index !== 0 ? 10 : 0,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            borderTop: SHOULD_ADD_TOP_BORDER ?
              topBorderStyle : '1px solid transparent'
        }}
        xs={ 10 }
        container
        className={ "noselect stp " +
          ( stop.type === 'pickup' ? 'stp-pkup' : (isPlaceholder ? '' : 'stp-drp') )}>
        <Grid item xs={ 1 }
          className={ "stop" }>
          <div
            className="stop-half"
            style={{
              zIndex: 200,
              visibility:
                ['ABOVE_AND_BELOW', 'ABOVE']
                .includes(connectionType) ?
                'visible' : 'hidden' }}>
          </div>
          { stop.deliveryStatus?.type === 'PICKED_UP_OR_DELIVERED' ||
            stop.deliveryStatus?.type === 'COMPLETED' ?
            <CheckIcon sx={{ mb: -1, zIndex: 1, mr: '-2px' }} />
            : ( isPlaceholder ?
              <CheckIconPlaceholder
                sx={{
                    mb: -1,
                    zIndex: 1,
                    mr: '-2px'
                }} /> :
              <CheckIconEmpty sx={{ mb: -1, zIndex: 1, mr: '-2px' }} /> )
          }
          <div className="stop-half"
            style={{
              visibility:
                ['ABOVE_AND_BELOW', 'BELOW']
                .includes(connectionType) ? 'visible' : 'hidden'
            }}>
          </div>
        </Grid>
        <Grid item xs={ 10 } className="pkup-container">
          { isPlaceholder === false ?
            <div>
            <Grid container>
              <Grid item xs={ 4 } sx={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ whiteSpace: 'pre', margin: 0 }}>
                  { stop.type === 'pickup' ? "PICKUP ADDRESS" : "DROP OFF ADDRESS" }
                </p>
              </Grid>
              <Grid item xs={ 8 } sx={{ }}>
              <div className="input-container noselect">
                <GoogleMapsAutocomplete
                  fetchInitialOptions={ fetchInitialOptions }
                  onValueChange={ onAddressChange }
                  value={ stop.address }
                  onIconLeftClick={ switchToPickupOrDropoff }
                  stopType={ stop.type }/>
              </div>
                <p
                  onClick={ () => {
                    handleOpenLocationMarkerDialog()
                  }}
                  style={{
                      margin: 0,
                      marginTop: 2,
                      marginBottom: 10,
                      width: 'fit-content',
                      fontSize: 12,
                      color: '#666',
                      cursor: 'pointer'
                  }}>Marker</p>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={ 4 }>
                <p style={{ whiteSpace: 'pre' }}>
                  { stop.type === 'pickup' ? "PICKUP TIME" : "DROP OFF TIME" }
                </p>
              </Grid>
              <Grid item xs={ 8 }>
              <ComparableDateInput
                buttonSx={{ margin: 0 }}
                handleSelectedDateChange={ handlePickupOrDropoffTimeChange }
                label={ stop.type === 'pickup' ?
                  'SELECT PICKUP DATE' :
                  'SELECT DROP OFF DATE' }
                selectedDate={ stop.time } />
              </Grid>
            </Grid>
            <Grid container className="flex center">
              <Grid item xs={ 4 }>
                <p className="bold">INSTRUCTIONS</p>
              </Grid>
              <Grid item xs={ 8 }>
              { TripUtils.sortInstructions(stop.instructions)
                .map( ( instruction, iIndex ) => (
                  <StopInstruction
                    key={ iIndex }
                    tripId={ tripId }
                    isHoveringOnStop={ true }
                    stopId={ stop.id }
                    stopIndex={ index }
                    toggleInstructionIsPick={ () => toggleInstructionIsPick(iIndex) }
                    removeInstruction={ () => removeInstruction(iIndex) }
                    instruction={ instruction }
                    index={ iIndex } />
                ))
              }
                <Typography variant="button" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  { !_.isNil(stop.grossWeightAfter) ?
                    <NumericFormat
                      value={ parseFloat(stop.grossWeightAfter) }
                      prefix="GWT "
                      thousandSeparator={','}
                      displayType="text"
                    />
                    :
                    null
                  }
                </Typography>
                <div className="" style={{ display: 'flex' }}>
                  <IconButton size="small"
                    onClick={ handleInstructionDialogOpen }
                    sx={{
                      visibility: _.isEmpty(addInstructionOptions) ? 'hidden' : 'normal',
                      mr: 3
                    }}>
                    <SubjectOutlinedIcon
                      fontSize="small"
                      />
                    </IconButton>
                  <IconButton size="small"
                    onClick={ () => addInstruction({ stopId: stop.id }) }
                    sx={{
                      visibility: _.isEmpty(addInstructionOptions) ?
                        'hidden' : 'normal'
                    }}>
                    <AddIcon
                      fontSize="small"
                    />
                    </IconButton>
                </div>
                { overrideShowErrors === false ?
                  <Stack
                    sx={{
                      width: '100%',
                      mt: ( showingErrors === false && _.isEmpty(stop.instructions) ) ? 0 : 1,
                      mb: ( showingErrors === false && _.isEmpty(stop.instructions) ) ? 0 : 1,
                    }} spacing={1}>
                      { showWarnings ?
                        stopErrorsPresave.map(error => (
                          <Alert severity={ error.type }>
                            { error.message }
                          </Alert>
                        ))
                        :
                        null
                      }
                      { stopErrors.map(error => (
                        <Alert severity={ error.type }>
                          { error.message }
                        </Alert>
                      )) }
                      { showWarnings ?
                        stopWarnings.map(error => (
                          <Alert severity={ error.type }>
                            { error.message }
                          </Alert>
                        ))
                        :
                        null
                      }
                  </Stack>
                  :
                  null
                }
              </Grid>
            </Grid>
            <Grid container className="flex center">
              <Grid item xs={ 4 }>
                <div>
                  <Tooltip title="Driver can arrive anytime in the morning">
                    <Chip
                      label="AM"
                      variant="outlined"
                      onClick={ () => toggleTimeChoice("AM") }
                      { ...( stop.timeChoice === 'AM' ? { color: "primary" } : {} ) }
                      size="small"
                      sx={{ mr: 1, fontSize: 10 }} />
                    </Tooltip>
                    <Tooltip title="Driver can arrive anytime in the evening">
                      <Chip
                        label="PM"
                        variant="outlined"
                        size="small"
                        { ...( stop.timeChoice === 'PM' ? { color: "primary" } : {} ) }
                        onClick={ () => toggleTimeChoice("PM") }
                        sx={{ mr: 1, fontSize: 10 }} />
                    </Tooltip>
                </div>
              </Grid>
              <Grid item xs={ 8 }>
                <motion.div
                  style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                  }}>
                  { _.isNil( stop.note ) ?
                    <Button
                      onClick={ () => handleAddNote('') }>Add note</Button>
                    :
                    <div>
                      <Note
                        value={ stop.note }
                        onBlur={ value => handleAddNote(value, true) }
                      />
                    </div>
                  }
                  { /* Either add a contact to the stop or open the existing contact */ }
                  <Button
                    disabled={ _.isNil(customer.id) }
                    onClick={ ()=> handleContactModalOpen(addContactToStop, contact) }
                    sx={{ mt: 1, }} endIcon={<DriverIcon />}>
                    { contactOption?.label ? contactOption.label : "Add Contact" }
                  </Button>
                </motion.div>
              </Grid>
            </Grid>
          </div>
        : <p style={{ margin: 0, width: 'fit-content' }}
            onClick={ addStop }>Add stop</p> }
        </Grid>
        { isPlaceholder === false ?
          <Grid
            item
            xs={ 1 }
            className={ `stp-remove ${ stop?.type === 'pickup' ? "" : 'stp-remove-alt'}` }
            onClick={ removeStop }
            sx={ isHovering ? {
              //marginTop: 10,
              //marginBottom: 10,
              //borderRadius: 20
            } : { display: 'none' }}>
            <CloseIcon />
          </Grid>
        : null }
      </Grid>
      { index >= 0 && index < stopCount -1 ?
        <Box sx={{ ml: 6, mb: 1 }}>
          { loadingRouteDetails ?
              <CircularProgress size={ 20 }/>
            :
            <Typography variant="button">
              { stop.distanceMeters ? toMiles(stop.distanceMeters) + " mi" : null }
              { stop.duration ? ' / ' + toDuration(stop.duration) : null }
            </Typography>
          }
        </Box>
        :
        null
      }
    </motion.div>
  )
})

const Note = ({ value, onBlur }) => {
  const [ note, setNote ] = useState('')

  useEffect(() => {
    setNote(value ? value : '')
  }, [ value ])

  return (
    <TextField
      value={ note }
      onBlur={ () => onBlur(note) }
      onChange={ e => setNote(e.target.value) }
      sx={{ flex: 1 }}/>
  )
}

const GET_ROUTE_DETAILS_QUERY = gql`
query GetRouteDetails($stops: [RouteStop!]!) {
  getRouteDetails(stops: $stops) {
    distanceMeters
    duration
  }
}
`

export default RouteStop

const toMiles = meters => {
  return (meters * 0.00062137).toFixed(1)
}

const toDuration = key => {
  const durationString = key.replace('s', '')
  return moment.duration(durationString, "seconds").humanize()
}
