import { isRejectedWithValue, configureStore } from '@reduxjs/toolkit'

import appReducer from './redux/appSlice'
import contactsReducer from './redux/contactsSlice'
import customersReducer from './redux/customersSlice'
import driversReducer from './redux/driversSlice'
import equipmentReducer from './redux/equipmentSlice'
import itemsReducer from './redux/itemsSlice'
import dashboardReducer, { listenerMiddleware } from './redux/dashboardSlice'

export const errorCatchingMiddleware = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!')
    //toast.warn({ title: 'Async error!', message: action.error.data.message })
  }

  return next(action)
}

function logger({ getState }) {
  return next => action => {
    //console.log('will dispatch', action)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

    //console.log('state after dispatch', getState())
    console.log({ action })

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}

export const store = configureStore({
  reducer: {
    app: appReducer,
    contacts: contactsReducer,
    customers: customersReducer,
    dashboard: dashboardReducer,
    drivers: driversReducer,
    equipment: equipmentReducer,
    items: itemsReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().prepend(errorCatchingMiddleware).prepend(listenerMiddleware.middleware).prepend(logger)
  }
})

store.subscribe(() => {
  //const state = store.getState()
  const lastAction = store.getState().lastAction
  //console.log({ lastAction })
})
