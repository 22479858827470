import { useEffect, useState } from 'react'

import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useSearchParams, useNavigate } from "react-router-dom";

export default function() {
  const [ searchParams ]  = useSearchParams()
  const navigate          = useNavigate()

  const [ showBackdrop, setShowBackdrop ] = useState(false)

  useEffect(() => {
    const code = searchParams.get("code")
    if ( !code ) {
      navigate('/')
    } else {

      setShowBackdrop(true)
      //Now call the back-end with the code...

    }
  }, [ searchParams ])

  return (
    <Backdrop
      sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
      }} open={showBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
