import _ from 'lodash'
import { diff } from 'deep-object-diff';
import moment from 'moment'

import * as TripUtils from '../utils/TripUtils'
import * as Utils from '../utils'

/*
 * Extract all related fields from apiOrders
 */
export const getRelatedFields = apiOrders => {
  const contacts  = {}
  const customers = {}
  const drivers   = {}
  const equipment = {}
  const items     = {}

  for (const order of apiOrders) {

    //Customer
    const { customer } = order
    customers[ customer.id ] = customer

    //Items
    const { items: itemsFromOrder=[] } = order
    for ( const item of itemsFromOrder ) {
      items[ item.id ] = item
    }

    //Contacts
    const { dispatcher={} } = order
    if ( dispatcher?.id ) {
      contacts[ dispatcher.id ] = dispatcher
    }

    const { trips=[] } = order
    for (const trip of trips ) {

      //Equipment
      const { equipment: tripEquipment={} } = trip

      if ( tripEquipment?.id ) {
        equipment[ tripEquipment.id ] = { ...tripEquipment }

        //Drivers
        const drivers = tripEquipment.drivers ? tripEquipment.drivers : []
        for (const driver of drivers ) {
          //drivers[ driver.id ] = driver TODO
        }
      }

      const { stops=[] } = trip
      //Contact
      for (const stop of stops ) {
        const { contact } = stop
        if ( contact?.id ) {
          contacts[ contact.id ] = contact
        }
      }
    }
  }

  return {
    contacts,
    customers,
    drivers,
    equipment,
    items
  }
}

export const changeItemQuantity = (state, action) => {

  const orderId   = state.activeId
  const { items } = state.pages[ orderId ]

  const { itemId, quantity } = action.payload

  if ( quantity === 0 || quantity === '' ) {
    return; //Must have at least one of the item...
  }

  const item = items.find(item => item.value === itemId)

  if ( item ) {

    item.quantity = quantity

    //Change items for listeners...
    const { items: currentItems } = state.pages[ orderId ]
    state.pages[ orderId ].items  = [ ...currentItems ]
  }

}

export const changeAdditionalItemInfo = (state, action) => {

  const orderId = state.activeId
  const {
    itemId,
    records,
    tagMap
  } = action.payload

  const items   = state.pages[ orderId ].items ? state.pages[ orderId ].items : []
  const item    = items.find( item => item.value === itemId )

  if ( item ) {
    item.records  = _.isEmpty(records) ? [] : records
    item.tags     = _.isEmpty(tagMap) ? {} : tagMap
  }
}

/*
 * Function to get all items from
 * the stops of all trips in the order...
 */
const getAllItems = order => {

  const trips = order.trips ? order.trips : {}

  for ( const tripId in trips ) {
    const trip  = trips[ tripId ]
    const stops = trip.stops ? trip.stops : {}
    for ( const stopId in stops ) {
    }
  }
}

export const mapAPIOrderToPage = orderFromAPI => {
  const order = mapAPIOrderToPageOrder(orderFromAPI)
  const page  = orderPageStateDefault()

  //Initialize items...
  const { items: itemsFromAPI=[] } = orderFromAPI

  const itemsFromTrips = getAllItems(order)

  //Initialize the itemMap
  const itemMap = itemsFromAPI.reduce((map, item) => {
    return {
      ...map,
      [ item.id ]: _.omit(item, 'id') //Has description for map...
    }
  }, {})

  const items = itemsFromAPI.map(item => ({
    value: item.id,
    label: itemMap[ item.id ].description,
    quantity: item.quantity ? item.quantity : 1,
    tags: item.tags ? item.tags.reduce((map, tag) => ({
      ...map,
      [ tag.id ]: true
    }), {}) : {},
    //Put item records in the right shape...
    records: item.records ? item.records.reduce(( records, record ) => {
      return [
        ...records,
        record.values.reduce(( values, value ) => {
          return {
            ...values,
            [ value.key ]: value.value
          }
        }, { id: record.id })
      ]
    }, []) : []
  }))

  page.items = items

  page.itemMap = itemMap

  //Dispatched
  const { dispatched, dispatchedTime } = hasDispatchedTrips(orderFromAPI)
  page.dispatched     = dispatched
  page.dispatchedTime = dispatchedTime

  //Set the order
  page.order = order

  return page
}

/*
 * Should be called by Dashboard util
 * to check whether changes exist...
 */
export const checkIfChangesExists = (apiOrder, page ) => {

  const result = getOrderDifferences({ apiOrder, page })

  const { orderDiff } = result

  if ( _.isEmpty( orderDiff ) === false ) {
    return true
  }

  return false
}

export const getOrderUpdate = ({ orderDiff, orderId, requestingDispatch=false, revokeDispatch=false }) => {

  if ( _.isEmpty( orderDiff ) && ( !requestingDispatch && !revokeDispatch ) ) {
    return null
  }

  //Make sure the order id is present...
  const update = { id: orderId }

  if ( requestingDispatch ) {
    update.dispatchTrips = requestingDispatch
  } else if ( revokeDispatch ) {
    update.dispatchTrips = false
  }

  //Misc order properties...

  if ( orderDiff.customerId ) {
    update.customerId = orderDiff.customerId
  }

  if ( orderDiff.dispatcherId ) {
    update.dispatcherId = orderDiff.dispatcherId
  }

  //ITEMS
  if ( orderDiff.items ) {

    const orderItems = []

    for ( const itemId in orderDiff.items ) {

      const item = orderDiff.items[ itemId ]

      //Deleted item
      if ( _.isNil( item ) ) {
        orderItems.push({
          id: itemId,
          deleted: true
        })
      } else {

        item.id = itemId

        //Tags
        if ( item.tags ) { //Updated tags...
          const tags = []

          for ( const tagId in item.tags ) {
            const tag = item.tags[ tagId ]

            if ( _.isNil( tag ) ) { //Deleted tag
              tags.push({
                id: tagId,
                deleted: true
              })
            } else { //Updated or Added tag
              tags.push({
                id: tagId,
              })
            }
          }

          item.tags = tags //Update the value...
        }

        //Records
        if ( item.records ) { //Updated records...

          const records = []

          for ( const recordId in item.records ) {
            const record = item.records[ recordId ]

            if ( _.isNil( record ) ) { //Deleted record
              records.push({
                id: recordId,
                deleted: true
              })
            } else { //Updated or Added record
              records.push({
                id: recordId,
                values: _.keys(_.omitBy(record, 'id')).map(key => ({
                  key: key,
                  value: record[ key ]
                }), {})
              })
            }
          }

          item.records = records //Update the value...

        }

        orderItems.push(item)

      }
    }

    update.items = orderItems
  }

  //Trips
  if ( orderDiff.trips ) {

    const tripUpdates = []

    for ( const tripId in orderDiff.trips ) {

      const trip = orderDiff.trips[ tripId ]

      if ( _.isNil( trip ) ) { //Deleted
        tripUpdates.push({
          id: tripId,
          deleted: true
        })
      } else {

        //Any of the trip fields that might
        //have been updated...
        const tripUpdate = { ...trip, id: tripId }

        if ( trip.stops ) { //Stops updated...

          const stopUpdates = []

          for ( const stopId in trip.stops ) {

            const stop = trip.stops[ stopId ]

            if ( _.isNil( stop ) ) { //Deleted

              stopUpdates.push({
                id: stopId,
                deleted: true
              })

            } else {

              let stopUpdate = { ...stop, id: stopId }

              //If the time was updated, convert into a string...
              if ( stopUpdate.time ) {
                stopUpdate.time = stopUpdate.time.toString()
              }

              if ( stopUpdate.contact ) { //Updated contact...
                stopUpdate.contactId = stopUpdate.contact.id
                stopUpdate = _.omit(stopUpdate, 'contact') //Remove the contact id...
              }

              if ( stop.instructions ) { //Updated
                const instructionUpdates = []

                for ( const instructionId in stop.instructions ) {

                  const instruction = stop.instructions[ instructionId ]

                  if ( _.isNil( instruction ) ) { //Deleted

                    instructionUpdates.push({
                      id: instructionId,
                      deleted: true
                    })

                  } else { //Updated or Added
                    instructionUpdates.push({
                      id: instructionId,
                      ...instruction,
                    })
                  }
                }

                stopUpdate.instructions = instructionUpdates
              }

              stopUpdates.push(stopUpdate)

            }

          }

          tripUpdate.stops = stopUpdates

        }

        tripUpdates.push(tripUpdate)

      }
    }

    update.trips = tripUpdates
  }

  return update
}

export const getOrderDifferences = ({ apiOrder, page, groupId, dispatching }) => {

  const orderBefore = _.isEmpty(apiOrder) ? {} : getOrderObject(apiOrder)

  //Put the order into a similar format as the apiOrder...
  const customer  = page.order.customer
  const trips     = page.order.trips
  const items     = page.items.map( item => ({ id: item.value, ...item }))

  //Now make a map for the order based on the page...
  const orderCurrent = {
    ...page.order,
    customerId: customer?.id,
    items,
    trips
  }

  const orderAfter  = getOrderObject(orderCurrent)
  const orderDiff   = diff(orderBefore, orderAfter)

  return { orderDiff }
}

/*
 * Pass either an API order
 * or the constructed order from
 * the page to get an object with
 * permitted keys.
 */
const reduce = () => {
  return null
}

const getOrderObject = order => {

  const result = {
    id: order.id,
    customerId: order.customer.id,
    dispatcherId: order.dispatcher?.id,
    items: getItems(order.items).reduce((map, item) => ({
      ...map,
      [ item.id ]: {
        quantity: item.quantity,
        //On the order it is a list,
        //on the API it is a list...
        records: item.records ?
          getRecordMap(item.records) : undefined,
        tags: getTagMap(item.tags)
      }
    }), {}),
    trips: _.reduce(getTrips(order.trips), (map, trip) => ({
      ...map,
      [ trip.id ]: {
        equipment: trip.equipment?.id ? {
          id: trip.equipment.id,
          primaryDriverId: trip.equipment.primaryDriverId
        } : undefined,
        dispatched: trip.dispatched,
        order: trip.order,
        stops: getStops(trip.stops).reduce((map, stop) => ({
          ...map,
          [ stop.id ]: {
            address: stop.address?.placeId ? {
              description: stop.address?.description,
              placeId: stop.address?.placeId,
              textShort: stop.address?.textShort,
              structured_formatting: stop.address.structured_formatting ? {
                main_text: stop.address.structured_formatting.main_text,
                secondary_text: stop.address.structured_formatting.secondary_text
              }
              :
              {
                main_text: stop.address.textShort,
                secondary_text: stop.address.textShort
              }
            } : undefined,
            contactId: getContactId(stop),
            duration: stop.duration,
            distanceMeters: stop.distanceMeters,
            isComplete: stop.isComplete,
            order: stop.order,
            note: stop.note,
            locationMarker: stop.locationMarker,
            time: getStopTime(stop.time),
            timeChoice: stop.timeChoice,
            instructions: getInstructions(stop.instructions).reduce((map, instruction) => ({
              ...map,
              [ instruction.id ]: {
                type: getInstructionType(instruction.type),
                itemId: instruction.itemId,
                order: instruction.order,
                quantity: instruction.quantity
              }
            }), {})
          }
        }), {})
      }
    }), {})
  }

  return result

}

function getItems(items) {
  return getArray(items)
}

function getRecords(records) {
  if ( _.isArray( records ) ) {
    return records
  }
}

function getTagMap(tags) {
  if ( _.isEmpty(tags) ) {
    return {}
  }

  if ( _.isArray( tags ) ) { //From API
    return tags.reduce(( map, tag) => ({
      ...map,
      [ tag.id ]: true
    }), {})
  }

  //Tags from UI is already a map...
  return tags
}

function getRecordMap(records=[]) {
  if ( _.isEmpty( records ) ) {
    return undefined
  }

  //Determine whether the record is from the API or UI...
  const recordsAreFromApi = _.has(records[ 0 ], 'values')

  let recordChanges = null
  if ( recordsAreFromApi ) {
    recordChanges = records.reduce((map, record) => {
      return {
        ...map,
        [ record.id ]: record.values.reduce(( map, value ) => ({
          ...map,
          [ value.key ]: value.value,
        }), {})
      }
    }, {})
  } else { //Records are from UI...
    recordChanges = records.reduce((map, record ) => ({
      ...map,
      [ record.id ]: _.omit(record, 'id')
    }), {})
  }

  return recordChanges

}

function getTrips(trips) {
  return getArray(trips)
}

function getContactId(stop) {
  if ( stop.contact?.id ) {
    return stop.contact.id
  }

  if ( stop.contactId ) {
    return stop.contactId
  }

  return null
}

function getStops(stops) {
  return getArray(stops)
}

function getInstructions(instructions) {
  if ( _.isArray( instructions ) ) {
    return instructions
  }

  const array = _.keys(instructions).reduce((arr, instructionType) => {
    const itemInstructions =
      _.keys(instructions[ instructionType ]).map(itemId => ({
        id: instructions[ instructionType ][ itemId ].id,
        itemId,
        type: getInstructionType(instructionType),
        quantity: instructions[ instructionType ][ itemId ].quantity,
        order: instructions[ instructionType ][ itemId ].order
      }))

    return [ ...arr, ...itemInstructions ]

  }, [])

  return array
}

function getInstructionType(type) {
  return ( type === 'pickup' || type === 'PICK_UP' ) ? 'PICK_UP' : 'DROP_OFF'
}

function getStopTime(time) {
  if ( _.isNil(time) ) {
    return
  }

  if ( _.isNumber(time) ) {
    return time.toString()
  }

  return time
}

function getArray(collection) {
  if ( _.isNil( collection ) ) {
    return []
  }

  if ( _.isArray( collection ) ) {
    return collection
  }

  //Assume object with each key being a unique object...
  const array = _.keys(collection).map(objId => {
    return {
      id: objId,
      ...collection[ objId ]
    }
  })

  return array
}

/*
 * Function to construct an
 * equipmentMap from orders returned by API
 */
export const constructEquipmentMap = (orders=[]) => {

  const equipmentMap = {}

  for ( const order of orders ) {
    const { trips=[] } = order
    for ( const trip of trips ) {
      const { equipment } = trip
      if ( equipment ) {
        equipmentMap[ equipment.id ] = equipment
      }
    }
  }

  return equipmentMap
}

/*
 * Map orders from API
 * into dashboard rows
 */
export const mapOrdersToOrderRows = options => {

  const {
    apiOrders=[],
    equipmentMap,
    memberMap,
    headerOverride,
    includeDisplayData=true,
    selectedDate,
    itemMap
  } = options

  const rows = []

  for ( const order of apiOrders ) {

    const { trips=[] } = order

    for ( const tripIndex in trips ) {
      const trip = trips[ tripIndex ]

      const startTime = trip.startTime ? parseFloat(trip.startTime) : null
      if ( selectedDate ) {
        const selectedDateStart = selectedDate.clone().startOf('day')
        const selectedDateEnd   = selectedDate.clone().endOf('day')

        /*
        if ( !headerOverride && startTime && moment(startTime).isBetween(selectedDateStart, selectedDateEnd) === false ) {
          continue; //Skip adding this row...
        }
        */
      }

      const { equipment } = trip

      const indicatorColor =
        equipment ? equipmentMap[ equipment.id ].color : 'green'

      const indicatorPrefix =
        equipment ? equipmentMap[ equipment.id ].description : null

      const { width, offset } = includeDisplayData ?
        calculateDisplayData(trip, selectedDate) : {}

      const columnData = getRawColumnData({ order, trip, itemMap, memberMap })

      const row = {
        id: trip.id,
        indicatorColor,
        indicatorPrefix,
        width,
        offset,
        initialPosition: offset,
        hasWarnings: _.isEmpty(order.warnings) === false,
        hasErrors: _.isEmpty(order.errors) === false,
        data: columnData,
        metadata: {
          order: {
            ...order,
            trip: {
              ...trip
            }
          },
        },
      }
      rows.push(row)
    }
  }

  return rows
}

function getRawColumnData(options) {
  const {
    itemMap,
    memberMap={},
    order,
    trip
  } = options

  //OrderId
  const orderIdColumnValue = Utils.truncateId(order.id)

  //Id
  const idColumnValue = Utils.truncateId(trip.id)

  //Dispatcher
  const dispatcherColumnValue = trip.dispatcher?.id ? memberMap[ trip.dispatcher.id ] : null

  //Warnings
  const warningsColumnValue = _.isEmpty(order.warnings) === false

  //Date
  const dateColumnValue =
    trip.startTime ? moment(parseFloat(trip.startTime)).format('MM/DD') : null

  //Time
  const timeColumnValue =
    trip.startTime ? moment(parseFloat(trip.startTime)).format('h:mma') : null

  //Customer...
  const customerColumnValue = order.customer.name

  //Status
  const statusColumnValue = trip.dispatched ? 'DISPATCHED' : 'NEW'

  //Origin
  const originColumnValue = trip.origin

  //Destination
  const destinationColumnValue = trip.destination

  //Determine items being picked up or dropped off...
  const { stops=[] } = trip
  const itemIds = {}

  const getTripSubtotal = (order, tripId) => {

    if ( _.isNil( order.invoice ) ) {
      return 0
    }

    const { lineItems } = order.invoice

    if ( lineItems ) {
      const tripSubtotal = lineItems.reduce(( amount, line ) => {
        if ( line.metadata?.tripId === tripId ) {
          return amount + line.amount
        }

        return amount
      }, 0)

      return tripSubtotal
    }

    return 0
  }

  const amountColumnValue = getTripSubtotal(order, trip.id)

  //Collect a list of all item ids...
  for ( const stop of stops ) {
    const instructions = stop.instructions ? stop.instructions : []
    for ( const instruction of instructions ) {
      itemIds[ instruction.itemId ] = true
    }
  }
  const firstItemId     = _.keys(itemIds)[ 0 ]
  const firstItemLabel  = firstItemId ? itemMap[ firstItemId ]?.description : null

  const itemsColumnValue =
    _.keys(itemIds).length > 1 ? 'Multiple Items' : firstItemLabel

  //Weight column
  const weightColumnValue = trip.maxGrossWeight

  return {
    id: trip.id, //idColumnValue,
    orderId: orderIdColumnValue,
    dispatcher: dispatcherColumnValue,
    warnings: warningsColumnValue,
    date: dateColumnValue,
    time: timeColumnValue,
    customer: customerColumnValue,
    status: statusColumnValue,
    origin: originColumnValue,
    destination: destinationColumnValue,
    items: itemsColumnValue,
    maxGrossWeight: weightColumnValue,
    amount: amountColumnValue
  }
}

function calculateDisplayData(trip, selectedDate) {
  const startOfSelectedDate = selectedDate.clone().startOf('day')
  const endOfSelectedDate   = selectedDate.clone().endOf('day')

  //Handle when the trip doesn't have a start or end time...
  if ( trip.startTime && !trip.endTime ) {
    const start   = moment(parseFloat(trip.startTime))
    const offset  = start.hour() * 100 + (start.minute() * (100/60))
    return { offset, width: 200 }
  }

  if (! ( trip.startTime && trip.endTime) ) {
    return { offset: 700, width: 200 }
  }

  const startTime       = moment(parseFloat(trip.startTime))
  const endTime         = moment(parseFloat(trip.endTime))
  const durationMinutes = endTime.diff(startTime, 'minutes')

  //Every hour represents 100 pixels...
  let width = (durationMinutes/60) * 100

  //Step 2: Truncate the width if trip extends past the selected date...
  if ( endTime.isAfter(endOfSelectedDate) ) {
    const comparisonDate  = Math.max(startOfSelectedDate.valueOf(), startTime.valueOf())
    const durationMinutes = endOfSelectedDate.diff(comparisonDate, 'minutes')
    width = (durationMinutes/60) * 100
  } else if ( startTime.isBefore(startOfSelectedDate) && endTime.isBefore(startOfSelectedDate) ) {
    width = 0
  } else if ( startTime.isBefore(startOfSelectedDate) && endTime.isBefore(endOfSelectedDate) ) {
    const durationMinutes = endTime.diff(startOfSelectedDate, 'minutes')
    width = (durationMinutes/60) * 100
  }

  //Step 3: Determine how much to offset the segment from the left...
  const offset = startTime.isBetween(startOfSelectedDate, endOfSelectedDate) ?
    startTime.hour() * 100 + (startTime.minute() * (100/60)) : 0

  //TODO: Make sure returned orders are within start of selected date...
  return { width, offset }
}

/*
 *  //Requirements:
 *  - Returns list
 *  - Never empty
 *  - Needs to be sorted
 *  - Needs to be filterable (by equipment)
 *  - Needs to have title
 *
 *  const result = [
 *    {
 *      title: "9997",
 *      indicatorColor: (null|"#..."),
 *      rows: [
 *        {
 *          id: "tripId",
 *          hasWarnings: false,
 *          hasErrors: false,
 *          indicatorColor: (null||"#...")
 *          initialPosition: 700,
 *          width: 500,
 *          data: { ... } //Representing the information
 *        }
 *      ]
 *    }
 *  ]
 *
 */
export const mapAPIOrdersToGanttGroups = options => {
  const {
    apiOrders,
    itemMap,
    memberMap,
    shouldGroup,
    sortProperty,
    headerOverride,
    selectedDate,
    selectedEquipment
  } = options

  const equipmentMap = constructEquipmentMap(apiOrders)

  //Now we have the rows with indicatorColor, width etc...
  const rows =
    mapOrdersToOrderRows({ headerOverride, apiOrders, selectedDate, equipmentMap, itemMap, memberMap })

  const activeRows =
    rows
    .filter(filterRowsByActivity(selectedDate))

  const nonActiveRows =
    rows
    .filter(filterRowsByActivity(selectedDate, true))
    .sort(sortBy(sortProperty))

  const totalValue = 50 //getTotalValue(rows)

  //If we just want to show orders belonging to a customer...
  if ( headerOverride ) {
    const customerRows =
      rows //Don't do any filtering...
      .sort(sortBy(sortProperty))
    const group = createGroupWithRows(headerOverride, customerRows, totalValue)
    return [ group ]
  }

  const rowsWithSelectedEquipment =
    activeRows
    .filter(filterRowsByEquipment(selectedEquipment))
    .sort(sortBy(sortProperty))

  const rowsMissingEquipment =
    activeRows.filter(filterRowsContainingEquipment)
    .sort(sortBy(sortProperty))

  const groupsWithEquipment = shouldGroup ?
    groupRowsByEquipment(rowsWithSelectedEquipment) :
    [ createGroupWithRows('All Equipment', rowsWithSelectedEquipment) ]

  const groupForMissingEquipment =
    _.isEmpty(rowsMissingEquipment) ? null :
    createGroupWithRows('No equipment selected', rowsMissingEquipment)

  const groupForNonActive =
    _.isEmpty(nonActiveRows) ? null :
    createGroupWithRows('No activity', nonActiveRows)

  //No rows available at all...
  if ( _.isEmpty( groupsWithEquipment ) && _.isNil(groupForMissingEquipment) ) {
    const group = createGroupWithRows('No orders')
    return [ group ]
  }

  //Possibly rows with equipment and rows without equipment...
  return [
    ...(groupsWithEquipment.map(g => addAmounts(g))),
    ...(groupForMissingEquipment ? [ addAmounts(groupForMissingEquipment) ] : []),
    ...(groupForNonActive ? [ addAmounts(groupForNonActive) ] : [])
  ]
}

function addAmounts(group) {
  let amount = 0
  if ( group?.rows ) {
    amount = group.rows.reduce((amount, row) => {
      if ( row?.data?.amount ) {
        return amount + row.data.amount
      }
      return amount
    }, 0)
  }

  return {
    ...group,
    amount
  }
}

export const sortBy = (sortProperty, reverse=false) => {
  return ( row1, row2 ) => {
    const sortOrder1 = getRowSortValue(sortProperty, row1)
    const sortOrder2 = getRowSortValue(sortProperty, row2)
    const value = sortOrder1 - sortOrder2
    if ( value === 0 ) { //Will cause bugs when happening at the same time...
      let otherValue = row1.metadata.order.trip.id.localeCompare(row2.metadata.order.trip.id)
      return otherValue
    }

    if ( reverse ) {
      return sortOrder2 - sortOrder1
    }

    return sortOrder1 - sortOrder2
  }
}

function getRowSortValue(sortProperty, row) {
  switch ( sortProperty ) {
    case 'date':
    case 'time':
    default:
      const { metadata } = row
      return metadata.order?.trip?.startTime ?
        parseFloat(metadata.order.trip.startTime) : 0
  }
}

//Trips that are active on the selected date...
const filterRowsByActivity = ( selectedDate, flip=false ) => {

  return row => {

    const { data, metadata }  = row
    const { order: { trip } } = metadata

    // trip:          April 1 April 3
    // selectedDate:  April 2
    //
    // trip:          April 2
    // selectedDate:  April 2
    let { startTime, endTime } = trip
    startTime = parseInt(startTime)
    endTime   = parseInt(endTime)

    const todayIsDuringTrip =
      selectedDate
      .startOf('day')
      .isBetween(startTime, endTime) //could be in the past or today...
      ||
      selectedDate.format('DD-MM-YYYY') == moment(endTime).format('DD-MM-YYYY')
      ||
      selectedDate.format('DD-MM-YYYY') == moment(startTime).format('DD-MM-YYYY')

    return flip ? !todayIsDuringTrip : todayIsDuringTrip
  }
}
const filterRowsByEquipment = selectedEquipment => {

  return row => {

    const { data, metadata }  = row
    const { order: { trip } } = metadata

    if ( _.isNil( trip.equipment?.id ) ) {
      return false
    }

    return selectedEquipment.find(option => option.value === trip.equipment.id)
  }
}

const filterRowsContainingEquipment = row => {
  const { metadata } = row
  const { order: { trip } } = metadata 

  if ( ! trip.equipment?.id ) {
    return true
  }
  return false
}

const createGroupWithRows = (title, rows=[], value) => {
  //Return a single group with all the rows...
  return {
    indicatorColor: null,
    title,
    value,
    rows: rows
  }
}

const groupRowsByEquipment = rows => {
  const groups = {}

  for ( const row of rows ) {
    const { metadata } = row

    const { order: { trip }} = metadata

    if ( trip.equipment?.id ) {

      //Get the equipment information from the trip
      const { equipment } = trip

      //First see if the group has been created...
      let group = groups[ equipment.id ]
      if ( !group ) {
        group = {
          title: equipment.description,
          id: equipment.id,
          indicatorColor: equipment.color,
          rows: []
        }
      }

      //Add the trip to the group
      group.rows.push(row)

      //Update the group
      groups[ equipment.id ] = group
    }
  }

  //Map the (map) into an array of groups...
  const groupsArr = _.keys(groups).map(groupId => groups[ groupId ])

  return groupsArr
}

/* Get validation information for the page */
export const getValidationInfo = page => {

  const {
    order,
    tripErrors,
    tripWarnings,
    orderErrorsPreSave,
    orderErrors,
    tripWarningsGlobal=[],
  } = page

  const { trips: tripMap={} } = order

  const allGlobalTripErrors = _.keys(tripMap).reduce((arr, tripId ) => {
    const trip = tripMap[ tripId ]
    return [
      ...arr,
      ...(trip.tripErrorsGlobal ? trip.tripErrorsGlobal : [])
    ]
  }, [])

  const allGlobalTripWarnings = _.keys(tripMap).reduce((arr, tripId ) => {
    const trip = tripMap[ tripId ]
    return [
      ...arr,
      ...(trip.tripWarningsGlobal ? trip.tripWarningsGlobal: [])
    ]
  }, [])

  const allTripErrors = _.keys(tripErrors).reduce((arr, tripId ) => {
    return [
      ...arr,
      tripErrors[ tripId ]
    ]
  }, [])

  const allTripWarnings = _.keys(tripWarnings).reduce((arr, tripId ) => {
    return [
      ...arr,
      ...tripWarnings[ tripId ]
    ]
  }, [])

  const getStopRelatedErrorsOrWarnings = (errorOrWarningKey) => {
    const all = _.keys(tripMap).reduce((arr, tripId ) => {

      const trip = tripMap[ tripId ]

      const stopErrorsOrWarnings = trip[ errorOrWarningKey ] ?
        trip[ errorOrWarningKey ] : []

      return [
        ...arr,
        ...stopErrorsOrWarnings.reduce((arr, errorsOrWarnings ) => {
          return [ ...arr, ...errorsOrWarnings ]
        }, [])
      ]
    }, [])
    return all
  }

  const stopErrors        = getStopRelatedErrorsOrWarnings('stopErrors')
  const stopWarnings      = getStopRelatedErrorsOrWarnings('stopWarnings')
  const stopErrorsPresave = getStopRelatedErrorsOrWarnings('stopErrorsPresave')

  const allErrors = [
    ...orderErrorsPreSave,
    ...orderErrors,
    ...allGlobalTripErrors,
    ...stopErrors,
    ...stopErrorsPresave
  ]

  const allWarnings = [
    ...allGlobalTripWarnings,
    ...tripWarningsGlobal,
    ...allTripWarnings,
    ...stopWarnings
  ]

  const hasErrors   = allErrors.length    > 0
  const hasWarnings = allWarnings.length  > 0

  return { hasErrors, hasWarnings, allWarnings }
}

const mapAPIOrderToSaveOrder = apiOrder => {
  //Exclude the trips field...
  const mappedOrder = _.omit(mapAPIOrderToPageOrder(apiOrder), 'trips')
  return mappedOrder
}

const mapAPIOrderTripsToSaveTrips = apiOrder => {
  const { trips } = mapAPIOrderToPageOrder(apiOrder)
  return trips
}

const getTripMetadata = page => {

  const tripMap = page.order.trips

  //Find the create origin city and state fields...
  const sortedTrips = TripUtils.sortTrips(tripMap)
  const firstTrip   = sortedTrips[ 0 ]
  const equipment   = sortedTrips.reduce((map, trip ) => {

    if ( trip.equipment?.id ) {
      return {
        ...map,
        [ trip.equipment.id ]: true
      }
    }
    return map
  }, {})

  let origin        = null
  let destination   = null
  let tripStart, tripEnd;

  if ( firstTrip ) {
    //Sort the stops
    const sortedStops = TripUtils.sortStops(firstTrip.stops)
    const firstStop   = sortedStops[ 0 ]
    const lastStop    = sortedStops[ sortedStops.length - 1]

    if ( firstStop ) {
      origin = firstStop?.address?.textShort ? firstStop.address.textShort : null
    }

    if ( firstStop !== lastStop && lastStop ) {
      destination = lastStop?.address?.textShort ? lastStop.address.textShort : null
    }

    tripStart = firstStop?.time ? moment(firstStop.time).unix() : null
    tripEnd   = lastStop?.time ? moment(lastStop.time).unix()   : null
  }

  return { origin, tripStart, tripEnd, destination, equipment }
}

const mapAPIOrderToPageOrder = orderFromAPI => {

  //customer
  const { id: customerId } = orderFromAPI?.customer ? orderFromAPI.customer : {}

  //dispatcher
  const { dispatcher }    = orderFromAPI ? orderFromAPI : {}
  const mappedDispatcher  = dispatcher? { id: dispatcher.id } : {}

  //items
  const items       = orderFromAPI?.items ? orderFromAPI.items : []
  const mappedItems = items.map(item => ({ id: item.id }))

  //trips
  const { trips=[] } = orderFromAPI

  const mappedTrips = trips.reduce((map, trip) => {
    const { equipment={} }  = trip

    const mappedEquipment = { ...equipment }

    const stops = trip.stops ? trip.stops : []

    const mappedStops = stops.reduce((map, stop) => {

      const mappedInstructions = {}

      //Contact
      const contact = stop.contact?.id ? {
        id: stop.contact.id
      } : {}

      const instructions =
        stop.instructions ? stop.instructions : []

      for ( const instruction of instructions ) {
        const instructionType = instruction.type === "PICK_UP" ? "pickup" : "dropoff"

        if ( !mappedInstructions[ instructionType ] ) {
          mappedInstructions[ instructionType ] = {}
        }

        mappedInstructions[ instructionType ][ instruction.itemId ] = {
          id: instruction.id,
          quantity: instruction.quantity,
          order: instruction.order
        }
      }

      return {
        ...map,
        [ stop.id ]: {
          ...(_.omit(stop, "id")),
          ...(stop.time ? { time: parseFloat(stop.time) } : {}),
          contact,
          instructions: mappedInstructions
        }
      }
    }, {})

    const mappedTrip = {
      ...(_.omit(trip, "id")),
      equipment: mappedEquipment,
      stops: mappedStops,
    }

    return {
      ...map,
      [ trip.id ]: mappedTrip
    }
  }, {})

  const order = {
    ...orderFromAPI,
    dispatcher: mappedDispatcher,
    customer: {
      id: customerId
    },
    items: _.isEmpty(mappedItems) ? orderPageStateDefault().items : mappedItems,
    trips: mappedTrips,
  }

  return order
}

export const hasDispatchedTrips = orderFromAPI => {
  const trips = _.defaultTo(orderFromAPI?.trips, null)
  if ( trips ) {
    for (const trip of trips ) {
      if ( trip.dispatched === true ) {
        return { dispatched: true, dispatchedTime: trip.dispatchedTime }
      }
    }
  }
  return { dispatched: false, dispatchedTime: null }
}

export const orderPageStateDefault = () => {
  return {
    //menus
    dispatched: null,
    changesExist: false,
    dispatchedTime: null,
    orderMenuAnchorEl: null,
    confirmationMessage: null,
    contacts: [],
    copyOrderDate: null,
    customerOrders: [],
    items: [ Utils.EMPTY_ITEM ],
    itemMap: {},
    //errors
    tripErrors: {},
    tripWarnings: {},
    tripErrorsGlobal: [],
    tripWarningsGlobal: [],
    loadingPrintPreview: false,
    overrideShowErrors: false,
    overrideDispatchStatus: false,
    requestedDispatch: false,
    saveDialogOpen: false,
    saveDialogTitle: null,
    showPresaveErrors: false,
    showFieldsItemId: null,
    orderErrorsPreSave: [],
    orderErrors: [],
    showWarnings: false,
    showErrors: false,
    topErrorsAndWarnings: [],
    selectedItemsMap: {},
    pickupDateMap: {},
    deliveryDateMap: {},
    sortedPickupDates: [],
    //modals
    modalContact: null,
    modalDriver: null,
    unsavedDialogOpen: false,
    copyModalOpen: false,
    contactModalOpen: false,
    invoiceModalOpen: false,
    driverModalOpen: false,
    requestedDispatch: false,
    saveDialogOpen: false,
    saveTimestamp: null,
    saveDialogTitle: null,
    snackbarMessage: null,
    snackbarOpen: false,
    doDispatch: false,
    tabValue: 0,
    loadingCustomerOrders: false,
    orderMenuAnchorEl: null,
    order: {
      createdTime: moment().unix(),
      customer: {},
      trips: {}
    }
  }
}

export const getWarningsForGroups = groups => {

  const warnings = []

  for ( const group of groups ) {

    //Look at all the orders in the group...
    const { rows=[] } = group

    for ( const row of rows ) {

      const { data: { order={} } }          = row
      const { warnings: orderWarnings=[] }  = order

      if ( _.isEmpty(orderWarnings) === false ) {
        warnings.push({ orderId: row.id, warnings: orderWarnings })
      }
    }
  }

  return warnings
}

const getDeletedTripIds = diffMap => {
  const deletedTripIds =
    _.keys(diffMap)
    .filter( id => _.isNil(diffMap [ id ]))
  return deletedTripIds
}

function mapObject(template, object) {
  const regexTemplates  = createTemplateRegexPatterns(template)
  const objectMap       = convertToObjectMap(object)
  const objectPaths     = createObjectPaths(objectMap)
  const output          = applyRegexTemplate(objectMap, regexTemplates, objectPaths)

  return output
}

function createTemplateRegexPatterns( template ) {
  const templates = []
  for ( const key in template ) {
    const regexTemplate = key.replace(/\{\}/g, '([a-zA-Z]|[0-9]|\-)+') //+ '$'
    templates.push(new RegExp(regexTemplate))
  }
  return templates
}

function applyRegexTemplate( object, regexTemplates, objectPaths ) {
  const output = {}
  for ( const path of objectPaths ) {
    if ( regexTemplates.find( regex => regex.test(path) ) ) {
      const value = _.at(object, path)[ 0 ]
      _.set(output, path, value)
    }
  }
  return output
}

function convertToObjectMap(obj, dest={}) {
  if ( _.isArray( obj ) ) {  //true
    for ( const item of obj ) {     // { id: "item123", ... }
      const { id } = item           //item123
      dest[ id ] = _.omit(convertToObjectMap(item), 'id')
    }
  } else if ( _.isObject( obj ) ) { //{ id: item123, records: [] }
    for ( const key in obj ) { //id
      dest[ key ] = convertToObjectMap(obj[ key ])
    }
  } else {
    return obj
  }

  return dest
}

function createObjectPaths(obj, path="") {
  let paths = []

  if ( _.isObject( obj ) ) {
    for ( const key in obj ) {
      const updatedPath     = _.isEmpty(path) ? key : path + "." + key
      const additionalPaths = createObjectPaths(obj[ key ], updatedPath)
      paths = [ ...paths, ...additionalPaths ]
    }
  } else {
    return [ path ]
  }

  return paths
}
