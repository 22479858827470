import React, { useContext, useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useOrderId, useCustomer } from '../redux/dashboardSlice'
import { gql, useMutation, useQuery } from '@apollo/client';
import EnhancedTable from '../components/Table'
import _ from 'lodash'
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import * as GanttUtil from '../utils/GanttUtil'
import { actions } from '../redux/dashboardSlice'
import * as Utils from '../utils'

import { AppContext, DashboardContext } from '../contexts'

//Show the contacts for the customer & orders relating to the customer...
export default function OrderActivityTab(props) {

  const dispatch = useDispatch()
  const orderId  = useOrderId()

  const { data, error } = useQuery(GET_ORDER_ACTIVITY, {
    skip: _.isNil(orderId),
    variables: {
      orderId
    }
  })

  const [ activity, setActivity ] = useState([])

  const mapActivityRows = rows => {
    return rows.map((activity, index) => {
      switch ( activity.type ) {
        case 'ORDER_ITEM_CHANGED':
          const { quantity: quantityBefore, description } = activity.itemBefore
          const { quantity } = activity.itemAfter
          return {
            id: index,
            data: {
              after: `${ description } changed to ${ quantity }`,
              before: quantityBefore
            }
          }
      }
    })
    .filter( r => _.isNil(r) === false)
  }

  //
  useEffect(() => {
    if ( data ) {
      const { getOrderActivity: activity } = data
      const rows = mapActivityRows(activity)
      console.log({ activity, rows })
      setActivity(rows)
    }
  }, [ data ])

  const { openSnackbar }  = useContext(DashboardContext)

  const handleActivityRowClick = () => {
  }

  const [ pageNum, setPageNum ] = useState(0)

  return (
    <div>
      <h1 style={{ margin: 0, fontWeight: 500, marginBottom: 20 }}>
        Order Id: { Utils.truncateId(orderId ) }
      </h1>

      <EnhancedTable
        columns={ ACTIVITY_COLUMNS }
        allowsDelete={ false }
        rowsPerPageOptions={[activity.length]}
        useDashboardHeadCells
        pageSize={ activity.length }
        //renderTableCell={ GanttUtil.renderTableCell }
        totalRecordCount={ activity.length }
        page={ pageNum }
        handleRowClick={ handleActivityRowClick }
        header="Order Activity"
        rows={ activity }
      />
    </div>
  )
}

const ACTIVITY_COLUMNS = [
  {
    field: 'description',
    label: 'Description'
  },
  {
    field: 'before',
    label: 'Before',
  },
  {
    field: 'after',
    label: 'After',
  }
]

const GET_ORDER_ACTIVITY = gql`
query GetOrderActivity($orderId: String!) {
  getOrderActivity(orderId: $orderId) {
    ... on DeletedOrderItemActivity {
      type
      createdBy {
        id
        name
      }
      itemBefore {
        id
        description
        quantity
      }
    }
    ... on OrderItemAddedActivity {
      type
      createdBy {
        id
        name
      }
      itemAfter {
        id
        description
        quantity
      }
    }
    ... on OrderItemChangedActivity {
      type
      createdBy {
        id
        name
      }
      itemBefore {
        id
        description
        quantity
      }
      itemAfter {
        id
        description
        quantity
      }
    }
    ... on TripRemovedActivity {
      type
      createdBy {
        id
        name
      }
      tripId
    }
    ... on EquipmentChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      equipmentBefore {
        id
        description
      }
      equipmentAfter {
        id
        description
      }
    }
    ... on EquipmentAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      equipmentAfter {
        id
        description
      }
    }
    ... on DriverChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      driverBefore {
        id
        name
      }
      driverAfter {
        id
        name
      }
    }
    ... on DriverAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      driverAfter {
        id
        name
      }
    }
    ... on AddressChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      addressBefore {
        placeId
        description
      }
      addressAfter {
        placeId
        description
      }
    }
    ... on LocationMarkerChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      locationMarkerBefore
      locationMarkerAfter
    }
    ... on LocationMarkerRemovedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
    }
    ... on LocationMarkerAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      locationMarkerAfter
    }
    ... on StopTimeChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      timeBefore
      timeAfter
    }
    ... on StopTimeAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      timeAfter
    }
    ... on InstructionRemovedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      instructionBefore {
        itemId
        type
        quantity
      }
    }
    ... on InstructionAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      instructionAfter {
        itemId
        type
        quantity
      }
    }
    ... on InstructionChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      instructionBefore {
        itemId
        type
        quantity
      }
      instructionAfter {
        itemId
        type
        quantity
      }
    }
    ... on TimeChoiceChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      address
      timeChoiceBefore
      timeChoiceAfter
    }
    ... on TimeChoiceAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      address
      timeChoiceAfter
    }
    ... on TimeChoiceRemovedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      address
      timeChoiceBefore
    }
    ... on ContactChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      stopOrder
      contactBefore {
        id
        name
      }
      contactAfter {
        id
        name
      }
    }
    ... on ContactAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      stopOrder
      contactAfter {
        id
        name
      }
    }
    ... on NoteChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      noteSnippetBefore
      noteSnippetAfter
    }
    ... on NoteRemovedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      noteSnippetBefore
    }
    ... on DispatcherAddedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      dispatcherAfter {
        id
        name
      }
    }
    ... on StopOrderChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      address
      orderBefore
      orderAfter
    }
    ... on EstimatesChangedActivity {
      type
      tripId
      createdBy {
        id
        name
      }
      durationAfter
      distanceMetersAfter
      fromAddress
      toAddress
    }
  }
}
`
