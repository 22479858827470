import React from 'react'

/*
 * Trip Status
 */
export default function () {
  return (
    <div className="invoice-status not-paid">
      <em>Not Paid</em>
    </div>
  )
}
