import * as React from 'react';
import { useContext } from 'react'
import _ from 'lodash'
import useMeasure from 'react-use-measure'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { useTheme } from '@mui/material/styles';
import Draggable from 'react-draggable'; // The default
import { brown, orange } from '@mui/material/colors';
import { useDispatch } from 'react-redux'
import { PatternFormat, NumericFormat } from 'react-number-format';
import Link from '@mui/material/Link';
import MoonLoader from "react-spinners/MoonLoader";
import Stepper from '@mui/material/Stepper';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import DateTimePickerPopper from '../components/DateTimePickerPopper'
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { motion, AnimatePresence } from 'framer-motion'

import { DashboardContext, AppContext } from '../contexts'
import { actions } from '../redux/dashboardSlice'
import * as GanttUtil from '../utils/GanttUtil'

const { hydratePage } = actions
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    columns,
    onSelectAllClick,
    order,
    orderBy,
    allowCollapse,
    numSelected,
    rowCount,
    onRequestSort,
    height
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ height: height }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        { columns
          .filter(c => c.hide !== true)
          .map(column => (
            <TableCell
              key={column.field}
              align={column.center ? 'center' : 'left'}
              padding={'none'}
              sx={{ whiteSpace: 'pre', paddingLeft: 1 }}
              sortDirection={orderBy === column.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.field }
                direction={orderBy === column.field ? order : 'asc'}
                onClick={createSortHandler(column.field)}
              >
                {column.label}
                {orderBy === column.field ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
        { false /* allowCollapse */ ?
          <TableCell>
          </TableCell>
          :
          null
        }
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    showColorIndicator,
    allowsDispatch,
    allowsDelete,
    heading,
    numSelected,
    onDelete,
    setSelected,
    indicatorColor,
    onDispatch
  } = props;

  const { appHeaderTitle } = React.useContext(AppContext)

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <div style={{ display: 'flex', alignItems: 'center' }} >
            <div>{ heading ? heading :
              ( showColorIndicator ? heading : appHeaderTitle ) }</div>
            { showColorIndicator && heading && !heading.startsWith('All')?
              <Paper sx={{
                  ml: '2px',
                  width: 15,
                  height: 15,
                  backgroundColor: indicatorColor
              }} elevation={0}>
              </Paper>
              :
              null
            }
          </div>
        </Typography>
      )}

      {numSelected > 0 ? (
        <div style={{ display: 'flex' }}>
          { allowsDelete ?
            <Tooltip title="Delete">
              <IconButton onClick={ onDelete }>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            :
            null
          }
          { allowsDispatch ?
            <Tooltip title="Dispatch All Trips">
              <Button
                sx={{ borderRadius: 2 }}
                onClick={ () => {
                  onDispatch()
                  setSelected([])
                }} variant="standard">
                Dispatch
              </Button>
            </Tooltip>
            : null
          }
        </div>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onDelete: PropTypes.func
};

export default function EnhancedTable(props) {

  const {
    //Animated props by row...
    allowsDispatch=false,
    totalAmount=0,
    allowsDelete=true,
    enforceMinWidth=false,
    showColorIndicator=false,
    showChart=false,
    allowCollapse=false,
    showFinancialInfo=false,
    animatedProps=[],
    rows,
    renderTableCell=DEFAULT_RENDER_TABLE_CELL,
    page,
    pageSize,
    handleChangePage,
    totalRecordCount,
    groupIndex,
    handleExpandRow,
    header,
    indicatorColor,
    columns,
    handleRowClick,
    onDelete,
    rowsPerPageOptions=[5, 10,25],
    onDispatch,
    handleSort,
    orderByInitial="name"
  } = props

  const [order, setOrder]       = React.useState('asc');
  const [orderBy, setOrderBy]   = React.useState(orderByInitial);
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense]       = React.useState(false);

  const [chartContainerRef, bounds] = useMeasure()

  //Animations
  const handleRequestSort = (event, property) => {
    if ( handleSort ) {
      handleSort(property)
    } else {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.data.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation()
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  /*
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  */

  const handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
  }

  const isSelected = id => selected.indexOf(id) !== -1

  //Function to return the primary cells for a given row...
  const renderRow = ( row, rowIndex ) => {

    const { animatedProps={} } = row

    return  columns.filter(c => c.hide !== true).map( (column, index) => {

      const columnValue = row.data ? row.data[ column.field ] : row[ column.field ]

      return (
        <TableCell
          padding="none"
          align="center"
          height={ GanttUtil.ROW_HEIGHT } sx={{ paddingLeft: 1 }}>
          <motion.div
            { ...animatedProps }
            style={{ display: 'flex', whiteSpace: 'nowrap', justifyContent: column.center ? 'center' : 'flex-start', alignItems: 'center' }}>
            { renderTableCell(column.field, columnValue, row) }
          </motion.div>
        </TableCell>
      )
    })
  }

  console.log({ enforceMinWidth, showChart })
  return (
    <Box sx={{ flexGrow: 0, width: '100%' }} ref={chartContainerRef}>
      <Paper sx={{
          ml: 1,
          mb: TABLE_MARGIN_BOTTOM,
          ...(showChart ? { maxWidth: 'calc(50vw)'} : { maxWidth: enforceMinWidth ? 'calc(70vw)' : undefined , overflow: 'hidden'}),
          border: 1,
          borderColor: 'divider'
      }} elevation={ 1 }>
        <EnhancedTableToolbar
          allowsDelete={ allowsDelete }
          setSelected={ setSelected }
          showColorIndicator={ showColorIndicator }
          allowsDispatch={ allowsDispatch }
          numSelected={selected.length}
          height={ GanttUtil.TABLE_TOOLBAR_HEIGHT }
          heading={ header }
          indicatorColor={ indicatorColor }
          onDispatch={ () => onDispatch(selected) }
          onDelete={ () => {
            onDelete(selected)
            setSelected([])
          }} />
        <TableContainer sx={{}}>
          <Table
            sx={ ( enforceMinWidth && showChart ) ? { minWidth: 1100 } : {}}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              columns={ columns }
              showChart={ showChart }
              allowCollapse={ allowCollapse }
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              height={ `${ GanttUtil.TABLE_HEAD_HEIGHT }px !important` }
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, rowIndex) => {
                  const isItemSelected = isSelected(row.data?.id);
                  const labelId = `enhanced-table-checkbox-${rowIndex}`;
                  const animationProps =
                    animatedProps[ rowIndex ] ? animatedProps[ rowIndex ] : {}
                  const { collapseProps={} } = row

                  return (
                    <>
                      <TableRow
                        hover
                        onClick={(event) => {
                          handleRowClick(rowIndex)
                        }}
                        sx={{
                            minWidth: ( enforceMinWidth && showChart ) ? 1200 : undefined,
                            borderBottom: 1, borderColor: 'divider' }}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={ (event) => {
                              handleClick(event, row.data.id)
                            }}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        { renderRow(row, rowIndex) }
                        { false /* allowCollapse*/ ?
                          <TableCell sx={{ padding: 0, ml: 1 }}>
                            <IconButton onClick={ e => {
                              e.stopPropagation()
                              //handleExpandRow(groupIndex, rowIndex, true) 
                            }}>
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          </TableCell>
                          :
                          null
                        }

                      </TableRow>
                      { /*
                      <TableRow
                        sx={{ margin: 0, '& > *': { borderBottom: 'unset' }}}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2 }>
                          <AnimatePresence>
                            { collapseProps.animate?.height ?
                              <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}>
                                <HistoryLog row={ row } open={ collapseProps.animate.height > 0 } />
                              </motion.div>
                              :
                              null
                            }
                          </AnimatePresence>
                        </TableCell>
                      </TableRow>
                      */}
                    </>
                  )
                })}
              { showFinancialInfo && rows.length > 0 ?
                <>
                  <TableRow>
                    <TableCell padding="none" height={ GanttUtil.ROW_HEIGHT } rowSpan={3} colSpan={8}/>
                    <TableCell padding="none" sx={{ pl: 1 }} height={ GanttUtil.ROW_HEIGHT } colSpan={columns.length -8}>Subtotal</TableCell>
                    <TableCell padding="none" sx={{ pl: 1 }} height={ GanttUtil.ROW_HEIGHT } align="left" colSpan={2}>{ ccyFormat(totalAmount) }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="none" sx={{ pl: 1 }} height={ GanttUtil.ROW_HEIGHT }>Tax</TableCell>
                    <TableCell padding="none" sx={{ pl: 1 }} height={ GanttUtil.ROW_HEIGHT } align="left" colSpan={3}></TableCell>
                    <TableCell padding="none" sx={{ pl: 1 }} height={ GanttUtil.ROW_HEIGHT } align="left" colSpan={3}>-</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="none" sx={{ pl: 1 }} height={ GanttUtil.ROW_HEIGHT } colSpan={columns.length - 8}>Total</TableCell>
                    <TableCell padding="none" sx={{ pl: 1 }} height={ GanttUtil.ROW_HEIGHT } align="left" colSpan={3}><Typography variant="button">{ ccyFormat(totalAmount) }</Typography></TableCell>
                  </TableRow>
                </>
                :
                null
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ rowsPerPageOptions }
          sx={{ height: GanttUtil.TABLE_PAGINATION_HEIGHT, overflow: 'hidden' }}
          component="div"
          count={_.isNil(totalRecordCount) ? rows.length : totalRecordCount }
          rowsPerPage={ _.isNil(pageSize) ? rows.length : pageSize }
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

//Parent can use the imperative handle to add groups
//and remove groups...
export const GanttDisplay = props => {

  const { openSnackbar } =
    useContext(DashboardContext)

  const {
    handleSort,
    handleTimeChange,
    resetTripIndidcator,
    refreshDashboardOrders,
    shouldResetTableAnimationsRef,
    isGrouped,
    loading,
    showChart,
    showFinancialInfo,
    allowCollapse,
    groups,
    selectedDate,
    setSelectedDate
  } = props

  const dispatch      = useDispatch()
  const containerRef  = React.useRef()
  const navigate      = useNavigate()
  const [ columns, setColumns ] = useState(GanttUtil.DASHBOARD_COLUMNS)

  const [ chartAnimationProps, setChartAnimationProps ]   = useState({})
  const [ expandAnimationProps, setExpandAnimationProps ] = useState({
    left: { animate:  { flex: 0.55 }},
    right: { animate: { flex: 0.45 }}
  })

  const [ collapseTimestamp, setCollapseTimestamp ] = useState(null)

  useEffect(() => {

    if ( !showChart ) {
      setExpandAnimationProps({
        left: {
          animate: {
            flex: 1,//Animate flex to 1
          }
        },
        right: {
          animate: {
            flex: 0,//Animate flex to 0
          }
        },
      })
    } else {
      setExpandAnimationProps({
        left: {
          animate: {
            flex: 0.55,//Animate flex to 1
          }
        },
        right: {
          animate: {
            flex: 0.45,//Animate flex to 0
          }
        },
      })
    }
  }, [ showChart ])

  useEffect(() => {
    if ( showFinancialInfo ) {
      setColumns(DASHBOARD_COLUMNS_AR)

      return () => {
        setColumns(GanttUtil.DASHBOARD_COLUMNS)
      }
    }
  }, [ showFinancialInfo ])

  const [ scrollXPosition, setScrollXPosition ] = useState(null)

  const handleRowClick = (groupIndex, rowIndex ) => {
    const row = groups[ groupIndex ].rows[ rowIndex ]
    if ( row.metadata ) {
      const { id: orderId } = row.metadata.order
      dispatch(hydratePage({ orderId }))
      navigate('orders/' + orderId + '?showWarnings=true')
    }
  }

  const [ currentTimeOffset, setCurrentTimeOffset ] = useState(null)

  //List of Animated Props by group/row
  const [ animatedGroups, setAnimatedGroups ] = useState([])
  const animatedGroupsRef = React.useRef()

  //Effect to keep ref in-sync with state...
  useEffect(() => {
    animatedGroupsRef.current = animatedGroups
  }, [ animatedGroups ])

  //Effect to create animated groups each time the groups prop changes...
  useEffect(() => {

    const { animatedGroups, totalHeight } =
      GanttUtil.createAnimatedGroups({
        groups,
        showFinancialInfo,
        shouldResetAnimations: shouldResetTableAnimationsRef.current,
        currentGroups: animatedGroupsRef.current
      })

    //Expand the chart to include all the groups/rows...
    setChartAnimationProps({
      animate: {
        height: totalHeight + 50
      }
    })

    setAnimatedGroups(animatedGroups)

  }, [ groups, showFinancialInfo, collapseTimestamp ])

  //Effect to scroll to the correct position
  useEffect(() => {
    if ( selectedDate &&
      selectedDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {

      //Every minute, move the currentTimeOffset
      const moveCurrentTimeOffset = () => {
        const offset =
          moment().hour() * GanttUtil.COLUMN_WIDTH +
          ( moment().minute()/60 ) * GanttUtil.COLUMN_WIDTH
        setCurrentTimeOffset(offset)
      }

      moveCurrentTimeOffset()
      const interval = setInterval(moveCurrentTimeOffset, ONE_MINUTE)

      const moveScrollPosition = () => {
        //Only move to the nearest hour...
        const offset = moment().hour() * 100
        setScrollXPosition(offset)
      }

      moveScrollPosition()
      //Every 30 minutes, move to the nearest hour...
      const xOffsetInterval = setInterval(moveScrollPosition, THIRTY_MINUTES)

      return () => {
        clearInterval(interval)
        clearInterval(xOffsetInterval)
        setCurrentTimeOffset(null)
      }
    }
  }, [ selectedDate ])

  //Effect to scroll to the position of the nearest trip...
  useEffect(() => {

    if ( selectedDate &&
      selectedDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      return; //Allow other effect to manage scrolling to the current time...
    }

    const soonestPosition = GanttUtil.getSoonestPosition(animatedGroups)

    if ( soonestPosition ) {
      setScrollXPosition(soonestPosition)
    }

  }, [ animatedGroups, selectedDate ])

  const handleExpandRow = ( groupIndex, rowIndex, forCollapse=false) => {

    const modifiedAnimatedGroups = GanttUtil.handleExpandRow({
      animatedGroups,
      groupIndex,
      rowIndex,
      forCollapse
    })

    //Will trigger scroll position effect...
    setAnimatedGroups(modifiedAnimatedGroups)
    shouldResetTableAnimationsRef.current = false
    setCollapseTimestamp(moment().unix()) //TODO
  }

  const handleDispatchOrders = (groupIndex, ids=[]) => {

    if ( _.isEmpty( ids ) ) {
      return;
    }
    openSnackbar("Pending post-launch testing ✨")
    //TODO: Call Dispatch GraphQL API
    //refreshDashboardOrders()
  }

  const handleDeleteOrders = (groupIndex, ids=[]) => {

    if ( _.isEmpty( ids ) ) {
      return;
    }

    //TODO: Call Delete orders GraphQL API
  }

  return (
    <motion.div>
      <Box sx={{
          display: 'flex',
          border: 1,
          //pb: 2,
          borderColor: 'divider',
          backgroundColor: 'paper' }}>
        { /* Table(s) will take the remaining space to the left */ }

        { loading ?
          <div style={{
              minHeight: 200,
              flex: 0.55,
              justifyContent: 'center',
              alignItems: 'center', display: 'flex', }}>
            <MoonLoader
              color={"green"}
              loading={loading}
              cssOverride={{}}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
          :
          <motion.div style={{ flex: 0.55, flexShrink: 0 }}
            { ...expandAnimationProps.left } ref={ containerRef }>
            { /* GANTT LEFT */ }
            <Typography
              sx={{
                height: `${ GanttUtil.ROW_HEIGHT }px`,
                margin: 0,
                marginLeft: 4
              }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
            </Typography>
            { animatedGroups.map(( group, groupIndex ) => (
              <div style={{
                  marginLeft: 10,
                  marginRight: 20,
                  paddingBottom: 0,
                  marginBottom: GanttUtil.TABLE_MARGIN_HEIGHT
              }}>
                <EnhancedTable
                  showColorIndicator={ isGrouped }
                  enforceMinWidth={ true }
                  showChart={ showChart }
                  allowCollapse={ true }
                  allowsDelete={ false }
                  pageSize={ 100 }
                  rowsPerPageOptions={[
                    group.rows.length
                  ]}
                  totalAmount={ group.amount }
                  page={ 0 }
                  totalRecordCount={ group.rows.length }
                  allowsDispatch={ true }
                  showFinancialInfo={ showFinancialInfo }
                  columns={ columns }
                  renderTableCell={ GanttUtil.renderTableCell }
                  handleExpandRow={ handleExpandRow }
                  expandAnimationProps={ expandAnimationProps }
                  groupIndex={ groupIndex }
                  handleRowClick={ rowIndex => handleRowClick(groupIndex, rowIndex) }
                  handleSort={ handleSort }
                  onDelete={ ids => handleDeleteOrders(groupIndex, ids) }
                  onDispatch={ ids => handleDispatchOrders(groupIndex, ids) }
                  orderByInitial={ ORDER_BY_INITIAL }
                  header={ group.title } /* GanttUtil.TABLE_TOOLBAR_HEIGHT */
                  indicatorColor={ group.indicatorColor }
                  animatedProps={ group.animatedProps }
                  rows={ group.rows }
                />
              </div>
            )) }
        </motion.div>
        }
        { /* GANTT RIGHT */ }
        <GanttChart
          chartAnimationProps={ chartAnimationProps }
          currentTimeOffset={ currentTimeOffset }
          expandAnimationProps={ expandAnimationProps.right }
          handleTimeChange={ handleTimeChange }
          resetTripIndidcator={ resetTripIndidcator }
          scrollXPosition={ scrollXPosition }
          setSelectedDate={ setSelectedDate }
          selectedDate={ selectedDate }
          showChart={ showChart }
          showFinancialInfo={ showFinancialInfo }
          groups={ animatedGroups }
          handleExpandRow={ handleExpandRow }
        />
      </Box>
    </motion.div>
  )
}

const GanttChart = props => {

  const {
    handleTimeChange,
    resetTripIndidcator,
    showFinancialInfo=false,
    currentTimeOffset,
    expandAnimationProps,
    chartAnimationProps,
    scrollXPosition,
    showChart,
    setSelectedDate,
    selectedDate,
    handleExpandRow,
    groups
  } = props

  const theme = useTheme()

  const scrollContainerRef = React.useRef()
  
  useEffect(() => {
    if ( scrollXPosition ) {
      scrollContainerRef.current.scrollTo({
        left: scrollXPosition
      })
    }
  }, [ scrollXPosition ])
  const handlePreviousDateClick = () => {
    setSelectedDate(selectedDate.clone().subtract(1, 'day'))
  }

  const [ anchorEl, setAnchorEl ] = useState(null);
  const handleNextDateClick = () => {
    setSelectedDate(selectedDate.clone().add(1, 'day'))
  }

  const handleDateClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const handleCloseDatePicker = () => {
    setAnchorEl(null)
  }

  const onDateChange = (newDate, shouldClose) => {
    console.log("HERE", newDate)
    setSelectedDate(newDate)

    setTimeout(() => {
      handleCloseDatePicker()
    }, 1000)
  }

  const open = Boolean(anchorEl);

  return (
    <motion.div
      style={{ flex: 0.45, overflow: 'hidden' }}
      { ...expandAnimationProps }>
      <Box
        sx={{ borderLeft: 1, borderColor: 'divider' }} style={{
          //backgroundColor: '#eee',
        }}>

        { /* DATE HEADER */ }
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 1,
            borderColor: 'divider',
            height: GanttUtil.ROW_HEIGHT,
            padding: '0 10px',
            overflowY: 'hidden'
        }}>
          <IconButton size="small" onClick={ handlePreviousDateClick }>
            <ArrowBackIosOutlinedIcon />
          </IconButton>

          <DateTimePickerPopper
            open={ open }
            enableTimeSelection={ false }
            id="order"
            selectedDate={ selectedDate }
            onDateChange={ onDateChange }
            handleCloseDatePicker={ handleCloseDatePicker }
            anchorEl={ anchorEl } />

          <div
            onClick={ handleDateClick }>
            <strong className="noselect pointer" style={{ whiteSpace: 'pre' }}>
              { selectedDate ? selectedDate.format('ddd MMM DD, YYYY') : null }
            </strong>
          </div>
          <IconButton size="small" onClick={ handleNextDateClick }>
            <ArrowForwardIosOutlinedIcon />
          </IconButton>
        </Box>

        { /* CONTAINER FOR A GROUP IN THE CHART */ }
        <motion.div { ...chartAnimationProps } layout>
        <Box
          ref={ scrollContainerRef }
          className="snap-parent-x no-scrollbars"
          style={{ overflowY: 'hidden',
                position: 'relative',
                height: '100%',
          border: 1, borderColor: 'divider',
          }}>

          { /* HOURS */ }
          { currentTimeOffset ?
            <Box sx={{
                width: '1px',
                height: '130%',
                backgroundColor: 'lightblue',
                zIndex: 6,
                display: 'flex',
                padding: 0,
                justifyContent: 'center',
                alignItems: 'start',
                position: 'absolute',
                left: currentTimeOffset
            }}>
              <Box sx={{ margin: 0, marginTop: 4, height: 'fit-content', backgroundColor: 'white', padding: '1px 4px', border: '1px solid lightblue', borderRadius: 1 }}>
                <Typography variant="button">
                  Now
                </Typography>
              </Box>
            </Box>
            :
            null
          }

          <div style={{ position: 'sticky'}}>
          <div style={{ display: 'flex', position: 'absolute' }}>
            {
              HOURS.map( hour => (
                <Box className="snap-child noselect" sx={{
                  width: GanttUtil.COLUMN_WIDTH,
                  height: `200vh`,
                  flexShrink: 0,
                  boxSizing: 'border-box',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                  paddingTop: 1,
                  borderRight: 1,
                  borderColor: 'divider',
                  position: 'sticky',
                }}>
                  <span style={{ fontSize: 13, marginLeft: -30 }}>{ moment().hour(hour).format('h:00a') }</span>
                </Box>
              ))
            }
          </div>
          </div>

          { /* GROUPS */ }

          { groups.map(( group, groupIndex ) => {

            const { indicatorColor, rows, animatedProps } = group

            //GROUP CONTAINER
            return (
              <Box style={{
                  paddingTop: `${
                      GanttUtil.TABLE_TOOLBAR_HEIGHT +
                      GanttUtil.TABLE_HEAD_HEIGHT }px`,
                borderColor: 'divider',
                //Leave space for the toolbar and table header
                //marginTop: GanttUtil.TABLE_TOOLBAR_HEIGHT + GanttUtil.TABLE_HEAD_HEIGHT,
                paddingBottom: groupIndex !== groups.length -1 ?
                  GanttUtil.TABLE_MARGIN_HEIGHT +
                  GanttUtil.TABLE_PAGINATION_HEIGHT + 3 +
                  ( showFinancialInfo ? 3 * GanttUtil.ROW_HEIGHT : 0 )
                : 0
                  //TODO GanttUtil.ROW_HEIGHT //TABLE_FOOTER
              }}>

                { rows.map( ( row, rowIndex ) => {

                  //Row animate props will effect the height of the row...
                  const { animatedProps: rowAnimationPropsForRow }  = row

                  const { collapseProps: extraAnimationPropsForRow } = row

                  //Segments are wrappers around 1 or more trips...
                  const hasWarnings = _.isEmpty( row.warnings ) === false

                  return (
                    <div>
                      <motion.div { ...rowAnimationPropsForRow } style={{
                          position: 'relative',
                          //Regularly 50, changes based on user click...
                          height: 50,
                          //If a trip with deeper depth potentially displays
                          //faster than the time it takes to increase the height
                          //it will be hidden...
                          //overflow: 'hidden',
                          //The groups should appear all on one row...
                          display: 'flex',
                          maxWidth: GanttUtil.COLUMN_WIDTH * 24,
                          overflow: 'hide'
                          //flexDirection: 'column'
                      }}>
                        { (() => {

                          /* Display segments on top of cells */
                          const header = hasWarnings ? null :
                            ( row.indicatorPrefix ? row.indicatorPrefix + ' ' : '' )

                          const headerSecondary = row.data?.time ? '(' + row.data.time + ')' :
                            row.data.id

                          return (
                            /* Indicator for an individual trip OR multiple trips */
                            <TripIndicator
                              rowIndex={ rowIndex }
                              handleTimeChange={ handleTimeChange }
                              resetTripIndidcator={ resetTripIndidcator }
                              id={ row.data?.id }
                              trip={ row.metadata?.order?.trip }
                              header={ header }
                              linkTo={ row.metadata?.orderId ? `/orders/${ row.metadata.orderId }` : null }
                              headerSecondary={ headerSecondary }
                              tripCount={ 1 }
                              indicatorColor={ row.indicatorColor }
                              offsetX={ hasWarnings || _.isNil(row.offset) ? 700 : row.offset }
                              width={ hasWarnings ? 1700 : row.width }
                              hasWarnings={ hasWarnings }
                              containerStyles={ hasWarnings ?
                                //TODO
                                { backgroundColor: orange[ 50 ], color: brown[ 400 ] } : {} }
                              zIndex={5}
                              handleExpandRow={ () => {
                                handleExpandRow(groupIndex, rowIndex)
                              }}
                          />
                        )

                        })() }

                        <Box style={{ display: 'flex' }}>
                          { HOURS.map( ( hour, hourIndex ) => {
                            return (
                              //rowAnimationPropsForRow to keep the height
                              //of the cells the same for the row once expanded...
                              <motion.div { ...rowAnimationPropsForRow } style={{
                                  //Regularly 50, changes based on user click...
                                  height: GanttUtil.TABLE_TOOLBAR_HEIGHT +
                                    rows.length * GanttUtil.ROW_HEIGHT + 
                                    GanttUtil.TABLE_PAGINATION_HEIGHT + 100,
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexShrink: 0,
                                  boxSizing: 'border-box',
                              }}>
                              </motion.div>
                            )
                          }) }
                        </Box>
                      </motion.div>
                    </div>
                  )
                })}
              </Box>
            )
          })}
        </Box>
        </motion.div>
      </Box>
    </motion.div>
  )
}

const TripIndicator = props => {

  const {
    indicatorColor,
    handleTimeChange,
    resetTripIndidcator,
    id,
    headerSecondary,
    linkTo,
    hasWarnings,
    header="",
    containerStyles={},
    visibility,
    trip,
    tripCount=0,
    rowIndex,
    prefix,
    animationProps={},
    handleExpandRow,
    offsetX,
    width
  } = props

  const navigate = useNavigate()

  const [ position, setPosition ]       = React.useState({ x: 0, y: 0 })
  const [ hourHeading, setHourHeading ] = React.useState(null)
  const [ maxWidth, setMaxWidth ]       = React.useState(undefined)

  const handleNavigate = () => {
    if ( linkTo ) {
      navigate(linkTo)
    }
  }

  //Effect to calculate header...
  useEffect(() => {

    if ( trip?.startTime ) {

      const minutes = ( 60/100) * position.x

      const time    = moment(parseFloat(trip.startTime)).add(minutes, 'minutes')
      const hour    = time.hour()
      const minute  = time.minute()

      setHourHeading(time.format("hh:mm a"))
    }
  }, [ width, trip, position.x ])

  const handleDrag = (e, data) => {
    const tripEnd   = moment(parseFloat(trip.endTime))
    const tripTime  = moment(parseFloat(trip.startTime))
    const minutes   = ( 60/100) * position.x

    //New start time of the trip based on position.x
    const newStartTime = moment(parseFloat(trip.startTime)).add(minutes, 'minutes')

    const offset          = newStartTime.hour() * 100 + (( newStartTime.minute() /60 ) * 50)
    const offsetPlusWidth = offset + width
    const maxWidth        = offsetPlusWidth < 2400 ? offsetPlusWidth : Math.min(width, 2400-offset)

    if ( tripEnd.date() == newStartTime.date() && newStartTime.hour() <= 23 ) {
      setPosition({ x: data.x, y: 0 })
    } else {
      //TODO: Open time change modal...
    }
  }

  //Note: offsetX and width will remain unchanged.
  //      Only 'offsetY' will be manipulated when the row is expanded...
  return (
    <Draggable
      grid={[50,50]}
      position={ position }
      onDrag={ handleDrag }
      onStop={(e, data ) => {
        if ( data.x !== 0 ) {
          handleTimeChange(id, data.x )
        }
      }}
      axis="x">
      <div>
        <motion.div layout { ...animationProps } style={{
            position: 'absolute',
            visibility,
            width,
            maxWidth: maxWidth,
            zIndex: 1,
            left: offsetX,
            height: GanttUtil.ROW_HEIGHT,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
          <Paper className={ "noselect pointer" }
            sx={{
              padding: '5px 10px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              width: width,
              height: 25,
              backgroundColor: indicatorColor ? indicatorColor : 'green',
              ...containerStyles
            }}
            onClick={ e => {
              handleNavigate()
              //TODO: Open Popper modal regarding the trip...
            }}
            elevation={0}>
            { hasWarnings ?
              <WarningAmberOutlinedIcon size="small" sx={{ color: orange[ 600 ], mr: 1 }} />
              :
              null
            }
            <div style={{ display: 'flex', flexDirection:"row", alignItems: 'center', overflow: 'hidden' }}>
              <span style={{
                  marginLeft: 0,
                  color: undefined
              }}>
                { hasWarnings ? "Needs Attention" : header }
              </span>
              <span
                style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    borderRadius: 3,
                    height: 14,
                    padding: 2,
                    margin: 4,
                    display: 'flex',
                    alignItems: 'center' }}>
                <Typography variant="button" fontSize={10} sx={{ whiteSpace: 'pre' }}>
                  { hourHeading }
                </Typography>
              </span>
            </div>
          </Paper>
        </motion.div>
      </div>
    </Draggable>
  )
}

const HOURS_START             = 0
const HOURS_END               = 24
const HOURS = [ 0, 1,2, 3, 4, 5, 6, 7, 8, 9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
const TABLE_MARGIN_BOTTOM     = '2px'
const ORDER_BY_INITIAL      = 'name'
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const DASHBOARD_COLUMNS_AR =
  [
    ...GanttUtil.DASHBOARD_COLUMNS,
    {
      field: 'amount',
      label: 'Amount',
      align: 'left'
    }
  ]
  .filter( cell => cell.field !== 'weight' )

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function HorizontalLabelPositionBelowStepper({ stepsInfo }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={stepsInfo.activeStep} alternativeLabel>
        {stepsInfo.records.map(record => (
          <Step key={record.label}>
            <StepLabel>{record.label + ( record.time ? ' (' + record.time + ')' : '' )}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

const HistoryLog = ({ row, open }) => {

  const didLoadRef = React.useRef(false)
  const [ loading, setLoading ]     = useState(false)

  const [ stepsInfo, setStepsInfo ] = useState({ records: [], activeStep: 0 })

  //Effect to load the data (once), when opened...
  useEffect(() => {
    if ( !open || didLoadRef.current ) {
      return
    }

    didLoadRef.current = true

    setLoading(true)

    setTimeout(() => {
      setLoading(false)
    }, 600)

  }, [ open ])

  useEffect(() => {
    //Get a list of significant events...
    const { createdDate, dispatchedDate, deliveredDate } = row

    const records     = []
    let activeStep    = 0
    const dateFormat  = 'MM/DD/YYYY hh:mma'
    if ( createdDate ) {
      activeStep = 1
      records.push({
        label: 'Order Created',
        time: moment(createdDate * 1000).format(dateFormat)
      })
    }

    if ( deliveredDate ) {
      activeStep = 3
    } else if ( dispatchedDate ) {
      activeStep = 2
    }

    records.push({
      label: dispatchedDate ? 'Dispatched' : 'Waiting for dispatch',
      time: dispatchedDate ? moment(dispatchedDate * 1000).format(dateFormat) : null
    })

    records.push({
      label: 'Delivered',
      time: deliveredDate ? moment(dispatchedDate * 1000).format(dateFormat) : null
    })

    setStepsInfo({ records, activeStep })

  }, [ row ])

  return (
    <Box sx={{ margin: 0, p: 1, height: GanttUtil.COLLAPSE_OPEN_HEIGHT, boxSizing: 'border-box' }}>
      { !loading ?
        <>
          <Typography variant="h6" gutterBottom component="div">
            History Log
          </Typography>
          <HorizontalLabelPositionBelowStepper stepsInfo={ stepsInfo }/>
          {/*
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Person</TableCell>
                <TableCell align="left">Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[1,2].map((historyRow) => (
                <TableRow key={historyRow.date}>
                  <TableCell component="th" scope="row">
                    12/22/23
                  </TableCell>
                  <TableCell>P.T.</TableCell>
                  <TableCell align="left">
                    No actions taken
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          */}
        </>
        :
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 200 }}>
          <MoonLoader
            color={"green"}
            loading={loading}
            cssOverride={{}}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      }
    </Box>
  )
}

const DEFAULT_RENDER_TABLE_CELL = (field, value, row) => {
  switch (field) {
    case 'roles':
      const data = _.defaultTo(row?.data, {})
      return (
        <div>
          { data.admin ?
            <Checkbox {...label }
              checked
              checkedIcon={<AdminPanelSettingsIcon />} />
            :
            null
          }
          { data.driver ?
            <Checkbox {...label }
              checked
              checkedIcon={<LocalShippingIcon />} />
            :
            null
          }
          { data.dispatcher ?
            <Checkbox {...label }
              checked
              checkedIcon={<PhoneForwardedIcon />} />
            :
            null
          }
        </div>
      )
    case 'active':
    case 'available':
      return (
        <Checkbox
          size="small"
          color="primary"
          checked={value === true }
        />
      )
    case 'primaryDriverId':
      if ( value?.label ) {
        return value.label
      }
      return 'Not assigned'
    case 'phone':
      const format ="+1 (###) ### ####"
      return (
        <Link href="#">
          <PatternFormat
            format={ format }
            displayType="text"
            value={ parseInt(value) }
            mask="_"
          />
        </Link>
      )
    //Link example...
    case 'email':
    case 'billingEmail':
      return (
        <Link href="#">
          {value}
        </Link>
      )
    //Number Fields...
    case 'weight':
      return (
        <NumericFormat
          value={ parseFloat(value) }
          thousandSeparator=","
          displayType="text"
        />
      )
    default:
      return value
  }
  return value
}
const ONE_MINUTE      = 1000 * 60
const THIRTY_MINUTES  = 1000 * 60 * 30
