import { createSlice } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    loading: false,
    data: {}
  },
  reducers: {
    setContacts(state, action) {
      const { contactsMap } = action.payload
      state.data = {
        ...state.data,
        ...contactsMap
      }
    }
  }
})

export const { setContacts } = contactsSlice.actions
export default contactsSlice.reducer
