import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import * as Utils from '../utils'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    authenticated: null,
    companyId: null,
    token: null,
    finishedPreloadingFonts: false,
    groupId: null,
    //Companies within the app the user has access to...
    companyOptions: [],
    members: {},
    equipment: {},
    //Groups within the company the user has access to...
    groupOptions: [],
    //Equipment belonging to the groupId...
    equipmentOptions: [],
    //Drivers belonging to the groupId...
    driverOptions: [],
    drawerOpen: false,
    initialized: false,
    loading: false,
    orderModalOpen: false,
    orderModalId: null,
    user: null
  },
  reducers: {
    initializeApp(state, action) {
      const { companyId, groupId } = action.payload
      state.companyId   = companyId
      state.groupId     = groupId
      state.initialized = true
    },
    setAuthenticated(state, action) {
      state.authenticated = action.payload
    },
    setCompanyId(state, action) {
      //Called when the user is working for a company
      //they have been invited to...
      state.companyId = action.payload
    },
    setCompany(state, action) {
      state.company = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setFinishedPreloadingGoogleFonts(state, action) {
      state.finishedPreloadingFonts = action.payload
    },
    setCompanyOptions(state, action) {
      state.companyOptions = action.payload
    },
    setMembers(state, action) {
      const members   = action.payload
      const memberMap = Utils.toMap(members)
      state.members   = memberMap
    },
    setDriverOptions(state, action) {
      state.driverOptions = action.payload
    },
    setEquipment(state, action) {
      const equipment     = action.payload
      const equipmentMap  = Utils.toMap(equipment)
      state.equipment     = equipmentMap
    },
    setGroupOptions(state, action) {
      state.groupOptions = action.payload
    },
    setGroupId(state, action) {
      state.groupId = action.payload
    },
    setDrawerOpen(state, action) {
      state.drawerOpen = action.payload
    },
    setInitialized(state, action) {
      state.initialized = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setOrderModalOpen(state, action) {
      state.orderModalOpen = action.payload
    },
    setEmailValid(state, action) {
      state.emailValid = action.payload
    }
  }
})

export const useMember = memberId => {
  const member = useSelector(state => {
    return state.app.members[ memberId ]
  })
  console.log({ memberId, member })

  const memberOption = member ? {
    label: member.name,
    value: member.id
  } : null

  return { member, memberOption }
}

export const useEquipment = equipmentId => {
  const equipment = useSelector(state => state.app.equipment[ equipmentId ])

  const equipmentOption = equipment ? {
    label: equipment.description,
    value: equipment.id
  } : null

  return { equipment, equipmentOption }
}

export const useCompanyId = () => {
  const companyId = useSelector(state => state.app.companyId)
  return companyId
}

export const actions = appSlice.actions
export default appSlice.reducer
