import { createSlice } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState: {
    loading: false,
    data: {},
    pageNum: 1
  },
  reducers: {
    setEquipment(state, action) {
      const { equipmentMap } = action.payload
      state.data = {
        ...state.data,
        ...equipmentMap
      }
    }
  }
})

export const useEquipment = equipmentId => {
  const equipment = useSelector(state => state.equipment.data[ equipmentId ])
  return equipment
}

export const { setEquipment } = equipmentSlice.actions
export default equipmentSlice.reducer
