import { collection, getDocs, orderBy, startAt, query } from "firebase/firestore";
import _ from 'lodash'
import { ErrorBoundary } from "react-error-boundary";

export const NO_OP = () => null
export const EMPTY_ITEM = {
  records: [],
  tags: {},
  value: "",
  label: "",
  quantity: 1
}

//Returns an array
export const filterOnlyDrivers = (members={}) => {
  return toArray(members).filter(member => {
    return member.driver
  })
}

export const withErrorBoundary = Component => {
  return (props) => {

    return (
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Component { ...props} />
      </ErrorBoundary>
    )
  }
}

export const toArray = (map={}) => _.keys(map).map(id => ({
  ...map[ id ]
}))

export const toMap = (array=[]) => array.reduce((map, obj) => {
  return {
    ...map,
    [ obj.id ]: obj
  }
}, {})

export const mapSnapshots = snapshot => {
  const map = {}

  snapshot.forEach(snapshot => {
    map[ snapshot.id ] = snapshot.data()
  }, {})
  return map
}

export const wait = millis => ( new Promise( resolve => {
  setTimeout(() => {
    resolve()
  }, millis)
}))

export const startOfDate = date => {
  return date.clone().hour(0).minute(0).second(0).millisecond(0)
}

export const endOfDate = date => {
  return date.clone().hour(23).minute(59).second(59).millisecond(59)
}

export function truncateId(id) {
  return id.substring(id.length - 5).toUpperCase()
}

export const handleSearch = async options => {

  const {
    db,
    searchField,
    text,
    dbPath,
    existingValue,
    used=[],
    labelKey="name"
  } = options

  const dataRef =
    query(
      collection(db, dbPath), //i.e. customers or equipment
      orderBy(searchField), //name or description...
      startAt(text.toUpperCase())
    )

  return getDocs(dataRef).then(snapshot => {

    const results = snapshot.docs

    const options = results.map( snapshot => {

      const data  = snapshot.data()
      const id    = snapshot.id

      return {
        label: data[ id ][ labelKey ],
        value: id
      }
    })
    .filter( option => {
      const equalToExisting = option.value === existingValue
      const noOtherItemsWithValue = !used.find(obj => obj.value === option.value )

      //Options to display in the list...
      return equalToExisting || noOtherItemsWithValue
    })

    return options
  })
}


export const DRAWER_WIDTH = 80;
