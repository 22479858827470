import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { OrderPageContext } from '../contexts'

export  default function InstructionDialog(props) {

  const { itemMap } = useContext(OrderPageContext)

  const {
    addInstructionOptions,
    instructionDialogOpen,
    handleInstructionDialogOpen,
    handleInstructionDialogClose,
    handleAddSelectedInstructions
  } = props

  const [ instructionQuantities, setInstructionQuantities ] = useState([])

  //Effect to set instruction quantities...
  useEffect(() => {
    setInstructionQuantities(addInstructionOptions.reduce( ( map, instruction, index ) => ({
      ...map,
      [ index ]: instruction.quantity 
    }), {}))

  }, [ addInstructionOptions ])

  //List of itemIds...
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleQuantityChange = ( e, index ) => {
    const value = parseInt(e.target.value)
    if ( value < 1 || value > addInstructionOptions[ index ].quantity ) {
      return; //Ignored...
    }

    setInstructionQuantities({
      ...instructionQuantities,
      [ index ]: value
    })
  }

  const handleClose = () => {

    const selectedInstructions =
      checked.map(index => {
        return { ...addInstructionOptions[ index ], quantity: instructionQuantities[ index ] }
      })

    handleAddSelectedInstructions(selectedInstructions)
  }

  return (
    <Dialog open={ instructionDialogOpen } onClose={handleInstructionDialogClose}>
      <DialogTitle>Select cargo to pick up or drop off</DialogTitle>
      <DialogContent>
        <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>
          {addInstructionOptions.map((option, index ) => {
            const labelId = `checkbox-list-secondary-label-${index}`
            return (
              <ListItem
                key={ index }
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(index)}
                    checked={checked.indexOf(index) !== -1}
                    inputProps={{ 'aria-labelledby': index}}
                  />
                }
                disablePadding
              >
                <ListItemButton disableRipple>
                  <Typography variant="button" sx={{ mr: 2, width: 100 }}>
                    { option.type === 'pickup' ? 'PICK UP' : 'DROP OFF' }
                  </Typography>
                  <TextField
                    variant="standard"
                    id="outlined-number"
                    label=""
                    sx={{ width: 70, mr: 3 }}
                    type="number"
                    onChange={ e => handleQuantityChange(e, index) }
                    value={ instructionQuantities[ index ] }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ListItemText
                    sx={{ fontStyle: "italic" }}
                    id={labelId}
                    primary={itemMap[ option.itemId ] ? itemMap[ option.itemId ].description : '' }
                  />
                  <ListItemAvatar>
                  </ListItemAvatar>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleInstructionDialogClose}>Cancel</Button>
        <Button
          disabled={ _.isEmpty(checked) }
          onClick={ handleClose }>
          Add Selected
        </Button>
      </DialogActions>
    </Dialog>
  )
}

