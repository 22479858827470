import React, { useState, useEffect } from 'react';

import _ from 'lodash'
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { motion } from 'framer-motion'
import validator from "email-validator"
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { actions, useCompanyId } from '../redux/appSlice'

import { HomeIcon } from '../icons'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function(props) {

  const dispatch  = useDispatch()
  const companyId = useCompanyId()

  const [ email, setEmail ]       = useState('')
  const [ password, setPassword ] = useState('')

  const [ getSignInOptions ] = useLazyQuery(GET_SIGN_IN_OPTIONS, {
    fetchPolicy: 'network-only'
  })

  const emailValid = useSelector(state => state.app.emailValid)

  useEffect(() => {
    if ( validator.validate(email) ) {
      dispatch(actions.setEmailValid(true))
    } else {
      dispatch(actions.setEmailValid(false))
    }
  }, [ email ])

  const onEmailChange = e => {
    setEmail(e.target.value)
  }

  const onPasswordChange = e => {
    setPassword(e.target.value)
  }

  const handleSignIn = () => {

    const auth = getAuth()

    function doSignIn() {
      //Allow the user to sign in...
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        })
    }

    if ( _.isEmpty(email) || _.isEmpty(password) || _.isEmpty(companyId) ) {
      return;
    }

    getSignInOptions({
      variables: {
        email
      }
    })
    .then(response => {

      if ( response.data ) {
        const { getSignInOptions: signInAllowed } = response.data

        if ( signInAllowed ) {
          doSignIn()
        }
      }
    })
  }

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div className="header">
        <HomeIcon />
      </div>
      <motion.div layout className="login-body">
        <div className="login-container">
          <Typography
            sx={{ flex: '1 1 100%', fw: 'bold' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            WELCOME BACK
          </Typography>
          <TextField
            placeholder="Email"
            sx={{ mr: 1, width: '100%' }}
            onChange={ onEmailChange }
            value={ email } />
          { emailValid ?
            <TextField
              placeholder="Password"
              type="password"
              onChange={ onPasswordChange }
              sx={{ mr: 1, width: '100%' }}
              value={password} />
            :
            null
          }
          <div className="login-options">
            <div className="login-options-left">
              <Checkbox {...label} defaultChecked /><p>Remember me</p>
            </div>
            <Link underline="none" href="#">
              Forgot Password
            </Link>
          </div>
          <Button
            onClick={ handleSignIn }
            variant="contained"
            sx={{ width: '100%' }}>Log In</Button>
        </div>
      </motion.div>
      <div className="footer" style={{ display: 'flex', justifyContent: 'center' }}>
      </div>
    </div>
  )
}

const GET_SIGN_IN_OPTIONS = gql`
query GetSignInOptionsQuery($email: String!) {
  getSignInOptions(email: $email)
}
`
