/*
 *
 * Copywrite (C) Website School LLC.
 *
 * @author Michael Turner michael@codebase.mx
 *
 * Problem: At any given time, the user needs to know
 * how many of an item they can pickup or dropoff
 */
import moment from 'moment'

import { collection, writeBatch, getDoc, getDocs, doc } from "firebase/firestore";
import { db } from '../config.js'
import * as Util from '../utils/index.js'

import _ from 'lodash'

function generateId() {
  return doc(collection(db, "orders")).id
}

export const addEmptyTrip = ( state, action ) => {

  //Grab order id...
  const companyId = localStorage.getItem("companyId")
  const orderId   = state.activeId
  const tripMap   = state.pages[ orderId ].order.trips
  const order     = _.keys(tripMap).length

  const tripsRef  = collection(db, "companies", companyId, "orders", orderId, "trips")
  const tripRef   = doc(tripsRef)
  const newTripId = tripRef.id

  const newTrip = createEmptyTrip(orderId, newTripId, tripMap)
  return { newTrip, newTripId }

}

export const setPrimaryDriverId = (state, action) => {
  const orderId = state.activeId

  const {
    tripId,
    primaryDriverId
  } = action.payload

  const trip = state.pages[ orderId ].order.trips[ tripId ]
  trip.primaryDriverId = primaryDriverId
}

export const deleteTrip = (state, action ) => {
  const orderId = state.activeId
  const tripId  = action.payload
  const current = state.pages[ orderId ].order.trips

  if ( _.keys(current).length === 1 ) {
    return; //Don't modify state...
  }

  //Remove the trip from the trip map
  state.pages[ orderId ].order.trips = _.omit(current, [ tripId ])
}

export const editLocationMarker = (state, action) => {

  const orderId = state.activeId
  const { tripId, stopId, marker } = action.payload

  const trip = state.pages[ orderId ].order.trips[ tripId ]

  //Update state
  if ( _.isEmpty( marker ) ) {
    trip.stops[ stopId ].locationMarker = null
  } else {
    trip.stops[ stopId ].locationMarker = marker
  }
}

/*
 * Add a stop to the trip map
 */
export const addStop = (state, action) => {

  const orderId     = state.activeId
  const companyId   = localStorage.getItem("companyId")
  const { tripId }  = action.payload
  const tripMap     = state.pages[ orderId ].order.trips
  const stopsRef    = collection(db, "companies", companyId, "orders", orderId, "trips", tripId, "stops")
  const stopRef     = doc(stopsRef)

  const trip        = tripMap[ tripId ]
  const stopsLength = _.keys(trip.stops).length
  const stopId      = stopRef.id

  state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ] = {
    ...EMPTY_STOP_DEFAULT,
    order: stopsLength
  }
}

//Stops
export const handleDrag = (state, action) => {

  const orderId = state.activeId

  const {
    fromIndex,
    toIndex,
    tripId
  } = action.payload

  const trip = state.pages[ orderId ].order.trips[ tripId ]

  const sortedStops = sortStops(trip.stops)
  const data        = [ ...sortedStops ]
  const item        = data.splice(fromIndex, 1)[0]

  data.splice(toIndex, 0, item)

  const [ firstStop ] = data

  if ( firstStop.type !== 'pickup' ) {
    //Don't allow a dropoff at the first stop...
    return;
  }

  data.forEach((stop, index) => {
    trip.stops[ stop.id ].order = index
  })

}

export const removeStop = (state, action) => {

  const orderId = state.activeId
  const tripMap = state.pages[ orderId ].order.trips

  const { tripId, stopId } = action.payload

  //Remove the stop from the trip map

  //Only remove if there are more than 2 stops...
  if ( _.keys(tripMap[ tripId ].stops).length === 2 ) {
    return; //Don't modify state...
  }

  const trip              = tripMap[ tripId ]
  const removedStop       = trip.stops[ stopId ]
  const removedStopOrder  = removedStop.order

  const modifiedStops =
    _.keys(trip.stops).reduce(( map, sId ) => {
      if ( sId === stopId ) {
        return map; //remove the stop from the map
      }

      const stop = trip.stops[ sId ]

      if ( stop.order > removedStopOrder ) {
        return {
          ...map,
          [ sId ]: {
            ...stop,
            //Change order property
            order: stop.order -1
          }
        }
      } else {
        return { ...map, [ sId ]: stop }
      }
    }, {})


  state.pages[ orderId ].order.trips[ tripId ] = {
    ...trip,
    stops: modifiedStops
  }
}

export const updateRouteInfo = (state, action) => {

  const orderId = state.activeId
  const { tripId, routeDetails=[] } = action.payload

  const tripMap   = state.pages[ orderId ].order.trips
  const trip      = tripMap[ tripId ]
  const stopCount = _.keys(trip.stops).length

  _.keys(trip.stops).forEach( stopId => {
    const stop      = trip.stops[ stopId ]
    const routeInfo = routeDetails[ stop.order ]

    if ( stop.order < stopCount -1 && routeInfo ) {
      stop.distanceMeters = routeInfo.distanceMeters
      stop.duration       = routeInfo.duration
    }
  })
}

/*
 * Action: User clicks "Add Instruction"
 * Choose an instruction to add based on the stop
 */
export const editAddress = (state, action) => {
  const orderId = state.activeId
  const { tripId, stopId, address } = action.payload

  const tripMap = state.pages[ orderId ].order.trips

  //Update state...
  tripMap[ tripId ].stops[ stopId ].address = address
}

export const addEquipmentToTrip = (state, action) => {
  const orderId = state.activeId
  const { tripId, equipment } = action.payload

  const trip = state.pages[ orderId ].order.trips[ tripId ]

  if ( trip ) {

    //Try to find the primary driver...
    let primaryDriverId = null

    if ( _.isNil( equipment ) ) {

      trip.equipment = {}

    } else {

      if ( equipment.drivers ) {
        const primaryDriver = equipment.drivers.find(d => d.primary)
        if ( primaryDriver ) {
          primaryDriverId = primaryDriver.id
        }
      }

      trip.equipment = {
        id: equipment.id,
        primaryDriverId
      }
    }
  }
}

export const addDriverToTrip = (state, action) => {
  const orderId = state.activeId
  const { tripId, driverId } = action.payload
  const trip = state.pages[ orderId ].order.trips[ tripId ]

  trip.equipment.primaryDriverId = driverId
}

export const addContactToStop = (state, action) => {
  const orderId = state.activeId
  const { tripId, contactId, stopId } = action.payload

  const stop = state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ]

  //Make sure the contact is in the store...
  const contacts  = state.pages[ orderId ].contacts
  const contact   = contacts.find(c => c.id === contactId)

  if ( contact ) {
    state.contacts[ contactId ] = contact
  }

  stop.contact = {
    id: contactId
  }
}

export const addNoteToStop = (state, action) => {
  const orderId = state.activeId
  const { tripId, stopId, note } = action.payload

  const stop = state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ]
  stop.note = note
}

export const editTime = (state, action) => {
  const orderId = state.activeId
  const { tripId, stopId, time } = action.payload
  const stop = state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ]
  if ( moment.isMoment(time) ) {
    stop.time = time.valueOf()
  }
}

//Create both stopOptions and addInstructionOptions
//For all trips in the trip map, since they are interconnected...
export const createTripOptions = (state, action) => {

  const orderId = state.activeId

  const page = state.pages[ orderId ]

  if ( !page ) {
    return;
  }

  const { selectedItemsMap: availableItems } = page
  const { itemMap } = page

  const tripMap = page.order.trips

  const trips = sortTrips(tripMap)

  //Compute availablilityMap based on what has been picked up
  //at all stops...
  const quantityPickedMap = trips.reduce(( map, trip ) => {

    const updatedIdMap = { ...map }

    //Loop through all stops in the trip...
    for ( const stopId in trip.stops ) {
      const stop = trip.stops[ stopId ]
      if ( !stop.instructions ) {
        continue; //No instructions...
      }

      const pickupInstructions = stop.instructions.pickup ?
        stop.instructions.pickup : {}

      for ( const itemId in pickupInstructions ) {
        if ( updatedIdMap[ itemId ] ) {
          updatedIdMap[ itemId ] = updatedIdMap[ itemId ] +
            pickupInstructions[ itemId ].quantity
        } else {
          updatedIdMap[ itemId ] = pickupInstructions[ itemId ].quantity
        }
      }
    }

    return {
      ...map,
      ...updatedIdMap
    }

  }, {})

  const quantityLeftMap = _.keys(availableItems).reduce(( map, itemId ) => {
    return {
      ...map,
      [ itemId ]: quantityPickedMap[ itemId ] ?
        availableItems[ itemId ] - quantityPickedMap[ itemId ] :
        availableItems[ itemId ]
    }
  }, {})

  const getAllPickedUp = trip => {
    if ( _.isEmpty( trip.stops ) ) {
      return {}
    }

    const allPickedUp = _.keys(trip.stops).reduce(( map, stopId ) => {

      const stop = trip.stops[ stopId ]

      const updatedIdMap = {}

      if ( _.isEmpty( stop.instructions ) ) {
        return map
      }

      const pickupInstructions =
        stop.instructions.pickup ? stop.instructions.pickup : {}

      for ( const itemId in pickupInstructions ) {
        if ( map[ itemId ] ) {
          updatedIdMap[ itemId ] = map[ itemId ] +
            pickupInstructions[ itemId ].quantity
        } else {
          updatedIdMap[ itemId ] = pickupInstructions[ itemId ].quantity
        }
      }
      return {
        ...map,
        ...updatedIdMap
      }

    }, {})

    return allPickedUp

  }

  const availableItemsMap = trips.reduce(( map, trip ) => {

    //Loop through all stops to see what's been picked up...
    const pickedUpAll = getAllPickedUp(trip)

    //Available items =
    //( Whatever has been picked up || 0 ) + (Whatever is left)
    const availableItems = _.keys( quantityLeftMap ).reduce(( map, itemId ) => {

      if ( pickedUpAll[ itemId ] ) { //The item has been picked up
        return {
          ...map,
          [ itemId ]: pickedUpAll[ itemId ] + quantityLeftMap[ itemId ]
        }
      } else { //Not picked up
        return {
          ...map,
          [ itemId ]: quantityLeftMap[ itemId ]
        }
      }
    }, {})

    return {
      ...map,
      [ trip.id ]: availableItems
    }
  }, {})

  let tripErrorsGlobal = []

  //ERROR: No trips defined...
  //Loop through each trip...
  const result = trips.reduce(( tripMapBefore, trip ) => {

    //For each trip, modify the tripMap to include
    //stopOptions and addInstructionOptions...
    const availableItems = availableItemsMap[ trip.id ]

    //First we modify the map to include stopOptions
    let modifiedTripMap =
      createStopOptions({
        tripMap: tripMapBefore,
        availableItems,
        tripId: trip.id,
        itemMap
      })

    tripErrorsGlobal        = modifiedTripMap[ trip.id ].tripErrorsGlobal

    //Note: There should be fewer available items depending on instructions
    //of each stop in the trip...

    //Then we modify it to include addInstructionOptions...
    modifiedTripMap = createAddInstructionOptions({
      tripMap: modifiedTripMap,
      tripId: trip.id,
      availableItems,
    })

    return modifiedTripMap

  }, tripMap)

  const lastTrip                    = trips[ trips.length -1 ]
  const availableItemsAfterLastTrip = availableItemsMap[ lastTrip.id ]

  const tripErrors              = {}
  //TODO Add check for trip.startTime
  //tripErrorsPresave = {}
  const tripWarnings            = {}
  let tripWarningsGlobal        = []

  //VALIDATE: All trips have equipment assigned...
  for (const tripIndex in trips ) {

    const trip = trips[ tripIndex ]

    if ( !trip.equipment?.id ) {

      //Create no-equipment assigned warning for the stop
      tripWarnings[ trip.id ] = [
        ...(tripWarnings[ trip.id ] ? tripWarnings[ trip.id ] : []),
        {
          type: 'warning',
          message: "No equipment assigned to Trip " + (parseInt(tripIndex) + 1)
        }
      ]
    }

    if ( trip.equipment?.id && _.isEmpty(trip.equipment.primaryDriverId ) ) {
      tripWarnings[ trip.id ] = [
        ...(tripWarnings[ trip.id ] ? tripWarnings[ trip.id ] : []),
        {
          type: 'warning',
          message: "Please select a driver for trip " + (parseInt(tripIndex) + 1)
        }
      ]
    }
  }

  //Create warnings for items not picked up at all
  for ( const itemId in availableItemsAfterLastTrip ) {

    if ( availableItemsAfterLastTrip[ itemId ] > 0 ) {

      const description = itemMap[ itemId ] ? itemMap[ itemId ].description : ''

      tripWarningsGlobal = [
        ...tripWarningsGlobal,
        {
          type: 'warning',
          message: `(${ availableItemsAfterLastTrip[ itemId ]}) ` +
            `${ description }(s) never picked up`
        }
      ]
    }
  }

  //Modify the trip map...
  state.pages[ orderId ].order.trips = result

  //Set errors + warnings
  state.pages[ orderId ].tripErrors         = tripErrors
  state.pages[ orderId ].tripErrorsGlobal   = tripErrorsGlobal
  state.pages[ orderId ].tripWarnings       = tripWarnings
  state.pages[ orderId ].tripWarningsGlobal = tripWarningsGlobal

}

export const createAddInstructionOptions = options => {

  const { tripMap, tripId } = options

  const trip = tripMap[ tripId ]

  //Get a list of the stops, sorted...
  const stops = sortStops(trip.stops)

  //Get stop options...
  const { stopOptions=[] } = trip

  if ( _.isEmpty( stopOptions ) ) {
    console.log("No stop options available!!")
    return tripMap
  }

  const addInstructionsByStop = []

  if ( stopOptions.length !== stops.length ) {
    console.log('Options length not equal to stops length')
    return tripMap
  }

  //For each stop...
  for ( const stopIndex in stops ) {

    //Step 1: Map stop options for the stop...
    const optionsForStop  = stopOptions[ stopIndex ]
    const itemKeys        = _.keys(optionsForStop)

    //
    const availableInstructions = itemKeys.reduce(( arr, itemKey ) => {

      const option = optionsForStop[ itemKey ]
      //{
      //  pickup: 4
      //}

      const instructionsForItem =
        _.keys(option)
        .reduce(( arr, type ) => {
          return [
            ...arr,
            {
              itemId: itemKey,
              quantity: option[ type ],
              type: type
            }
          ]
        }, [])
        .filter( instruction => instruction.quantity > 0 )

      return [
        ...arr,
        ...instructionsForItem
      ]

    }, [])

    //Step 2: Map employed instructions for the stop...
    const instructions = stops[ stopIndex ].instructions
    /*
     * Stop from view:
     * {
     *   {
     *     instructions: {
     *       pickup: {
     *         item1: {
     *           quantity: 4
     *         }
     *       }
     *     }
     *   }
     * }
     */
    const takenInstructions =
      _.keys(instructions).reduce(( arr, type ) => { //For each type of instruction

        const instructionsForType = instructions[ type ]
        //i.e. {
        //  item1: {
        //    ...
        //  },
        //  item2: {
        //    ...
        //  }
        //}

        const takenInstructions =
          _.keys(instructionsForType).reduce(( arr, itemId ) => {
            const instruction = instructionsForType[ itemId ]
            //{
            //  quantity: 4
            //}
            return [
              ...arr,
              {
                type: type,
                quantity: instruction.quantity,
                itemId: itemId
              }
            ]
          }, [])

        return [
          ...arr,
          ...takenInstructions
        ]

      }, [])

    //Filter available instructions
    //console.log(availableInstructions.length)
    const addInstructions =
      availableInstructions
      .filter(instruction => {

        const found = takenInstructions.find( takenInstruction => {

          return takenInstruction.type === instruction.type &&
            instruction.itemId === takenInstruction.itemId

        })
        return _.isNil(found)
      })
      .map( instruction => {
        return {
          ...instruction,
          id: generateId()
        }
      })


    addInstructionsByStop.push(addInstructions)

  }

  return {
    ...tripMap,
    [ tripId ]: {
      ...tripMap[ tripId ],
      addInstructionOptions: addInstructionsByStop
    }
  }
}

/*
 * Called when the user wants to
 * switch one item for another...
 */
export const replaceItemId = (state, action) => {

  const orderId = state.activeId

  const {
    tripId,
    itemIdBefore,
    newItemId
  } = action.payload

  const order   = state.pages[ orderId ].order
  const tripMap = order.trips

  const updatedTripMap = _.keys(tripMap).reduce((tripMap, tripId ) => {

    const trip = tripMap[ tripId ]

    const updatedStops = _.keys(trip.stops).reduce(( stops, stopId ) => {

      const stop = trip.stops[ stopId ]

      const { instructions={} }       = stop
      const { pickup={}, dropoff={} } = instructions

      const mapKeys = (value, itemId) => {
        return itemId === itemIdBefore ? newItemId : itemId
      }

      //Map itemIds of pickup and dropoff to to new item id...
      const updatedInstructions = _.omitBy({
        pickup: _.mapKeys(pickup, mapKeys),
        dropoff: _.mapKeys(dropoff, mapKeys)
      }, _.isEmpty)

      return {
        ...stops,
        [ stopId ]: {
          ...stop,
          instructions: updatedInstructions
        }
      }

    }, {})

    return {
      ...tripMap,
      [ tripId ]: {
        ...tripMap[ tripId ],
        stops: updatedStops
      }
    }

  }, tripMap)

  order.trips = updatedTripMap
  //TODO: Replace order items as well...
}

export const changePickupOrDropoffQuantity = (state, action) => {
  const orderId = state.activeId
  const { tripId, stopId, instructionIndex, value } = action.payload

  const { order } = state.pages[ orderId ]
  const stop      = order.trips[ tripId ].stops[ stopId ]
  const tripMap   = order.trips

  const instructionsCurrent = stop.instructions

  if ( _.isEmpty(instructionsCurrent) ) {
    console.log("No instructions available")
    return tripMap
  }

  const trip        = tripMap[ tripId ]
  const stopsSorted = sortStops(trip.stops)
  const stopIndex   = stopsSorted.findIndex( stop => stop.id === stopId )

  if ( _.isNil( stopIndex ) ) {
    console.log("Could not determine stop index")
    return;
  }

  const instructionsSorted  = sortInstructions(instructionsCurrent)
  const targetInstruction   = instructionsSorted[ instructionIndex ]

  if ( !targetInstruction ) {
    console.log("No instruction found", instructionIndex, instructionsSorted)
    return;
  }

  const { stopOptions=[] } = tripMap[ tripId ]

  //Find the options the user has for the current stop...
  const stopOptionsForStop = stopOptions[ stopIndex ]

  const currentQuantity = targetInstruction.quantity

  let maxQuantity     =
    _.at(stopOptionsForStop,
      `${ targetInstruction.itemId }.${ targetInstruction.type }`)[ 0 ]

  if ( !maxQuantity ) {
    //Not able to update the quantity
    return;
  }

  maxQuantity = parseInt(maxQuantity)

  if ( value !== '' && ( value < 1 ||
    //Not fixing errors...
    ( value > maxQuantity && value > currentQuantity))) {
    console.log('Not able to change instruction quantity')
    return;
  }

  const instructionUpdate = {
    ...instructionsCurrent,
    [ targetInstruction.type ]: {
      ...instructionsCurrent[ targetInstruction.type ],
      [ targetInstruction.itemId ]: {
        ...instructionsCurrent[ targetInstruction.type ][ targetInstruction.itemId ],
        quantity: value === '' ? value : parseInt(value)
      }
    }
  }

  stop.instructions = instructionUpdate
}

/*
 * Quick Add Instruction
 * For when the user wants to easily have the application
 * add instructions. Will automatically add instructions
 * based on the type of stop it is, pickup or dropoff
 */
export const quickAddInstruction = (state, action) => {

  const orderId = state.activeId
  const { tripId, stopId } = action.payload

  const tripMap = state.pages[ orderId ].order.trips
  const trip    = tripMap[ tripId ]

  //Sort the stops...
  const stopsSorted = sortStops(trip.stops)
  const stopIndex   = stopsSorted.findIndex( stop => stop.id === stopId )

  const stopOptions =
    trip.addInstructionOptions ? trip.addInstructionOptions[ stopIndex ]  : undefined

  if ( _.isEmpty( stopOptions ) ) {
    console.log('No options available for stop', stopIndex)
    return;
  }

  //Choose the first option...
  const instruction = stopOptions[ 0 ]

  //Update the instructions to include the instruction...
  const value =
    _.at(tripMap, `${ tripId }.stops.${ stopId }.instructions`)[ 0 ]

  if ( !value ) {
    console.log("No instructions present, adding new")
  }

  const instructionsCurrent = value ? value : {}
  const instructionsSorted  = sortInstructions(value)
  const order = instructionsSorted.length

  const instructionUpdate = {
    ...instructionsCurrent,
    [ instruction.type ]: {
      ...( instructionsCurrent[ instruction.type ] ?
        instructionsCurrent[ instruction.type ] : {}),
      [ instruction.itemId ]: {
        quantity: instruction.quantity,
        id: instruction.id,
        //Order is based on the unix timestamp of when it was added...
        order
      }
    }
  }

  trip.stops[ stopId ].instructions = instructionUpdate
}

export const addSelectedInstructions = (state, action) => {

  const orderId = state.activeId
  const {
    tripId,
    stopId,
    selectedInstructions
  } = action.payload

  const tripMap     = state.pages[ orderId ].order.trips
  const trip        = tripMap[ tripId ]
  const stopsSorted = sortStops(trip.stops)
  const stopIndex   = stopsSorted.findIndex( stop => stop.id === stopId )

  //Get add instruction options for the trip...
  const { addInstructionOptions=[] } = trip

  //Get add instruction options for the stop...
  const addInstructionOptionsForStop = addInstructionOptions[ stopIndex ]

  if ( _.isEmpty( addInstructionOptionsForStop ) ) {
    console.log('No instructions to add for stop', stopId)
    return;
  }

  //Validation: Make sure ALL selected options are available...
  const allSelectedAvailable =
    _.every(selectedInstructions, selectedInstruction => {
      return addInstructionOptionsForStop.find(addInstruction => (
        addInstruction.itemId === selectedInstruction.itemId &&
        addInstruction.type === selectedInstruction.type &&
        selectedInstruction.quantity <= addInstruction.quantity &&
        selectedInstruction.quantity >= 1)
      )
    })

  if (! allSelectedAvailable ) {
    return;
  }

  //All selected are available, add each of them to the instructions...
  const stop = stopsSorted[ stopIndex ]
  const instructionsSorted = sortInstructions(stop.instructions)

  //Order will start from this point for added instructions...
  const order = instructionsSorted.length

  const { instructionsCurrent: instructionsUpdate } =
    selectedInstructions.reduce((options, instruction ) => {

      const { instructionsCurrent={}, order } = options

      return {
        //Update instructionsCurrent
        instructionsCurrent: {
          ...instructionsCurrent,
          [ instruction.type ]: {
            ...( instructionsCurrent[ instruction.type ] ?
              instructionsCurrent[ instruction.type ] : {}),
            [ instruction.itemId ]: {
              id: instruction.id,
              quantity: instruction.quantity,
              order
            }
          }
        },
        //Update order
        order: order + 1
      }

    }, { instructionsCurrent: stop.instructions, order })

  const modifiedTripMap = {
    ...tripMap,
    [ tripId ]: {
      ...trip,
      stops: {
        ...trip.stops,
        [ stopId ]: {
          ...trip.stops[ stopId ],
          instructions: instructionsUpdate
        }
      }
    }
  }

  trip.stops[ stopId ].instructions = instructionsUpdate
}

export const toggleTimeChoice = (state, action) => {

  const orderId = state.activeId
  const { tripId, stopId, choice } = action.payload

  const stop = state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ]

  if ( !stop ) {
    return; //No stop present...
  }

  const newTimeChoice = stop.timeChoice === choice ? null : choice

  stop.timeChoice = newTimeChoice
}

export const toggleInstructionIsPick = (state, action) => {

  const orderId = state.activeId
  const tripMap = state.pages[ orderId ].order.trips

  const { tripId, stopId, instructionIndex } = action.payload

  const trip =
    state.pages[ orderId ].order.trips[ tripId ]
  const instructionsCurrent = trip.stops[ stopId ].instructions

  if ( _.isEmpty(instructionsCurrent) ) {
    console.log("No instructions available to change on selected stop")
    return;
  }

  const stopsSorted = sortStops(trip.stops)
  const stopIndex   = stopsSorted.findIndex( stop => stop.id === stopId )

  if ( _.isNil( stopIndex ) ) {
    console.log("Could not determine stop index")
    return;
  }

  const instructionsSorted  = sortInstructions(instructionsCurrent)
  const selectedInstruction = instructionsSorted[ instructionIndex ]

  if ( !selectedInstruction ) {
    console.log("No instruction found", instructionIndex, instructionsSorted)
    return;
  }

  const { stopOptions=[] } = tripMap[ tripId ]

  //Find the options the user has for the current stop...
  const stopOptionsForStop = stopOptions[ stopIndex ]

  const targetType = selectedInstruction.type === 'pickup' ? 'dropoff' : 'pickup'

  let maxQuantity     =
    _.at(stopOptionsForStop,
      `${ selectedInstruction.itemId }.${ targetType }`)[ 0 ]

  if ( !maxQuantity ) {
    return;
  }

  maxQuantity = parseInt(maxQuantity)
  //{
  //  pickup: {
  //    item123: {
  //      quantity: 2
  //    }
  //  }
  //}
  const instructionsWithoutTarget = _.omitBy({
    [ selectedInstruction.type ]: {
      ...(_.omit(instructionsCurrent[ selectedInstruction.type ],
        selectedInstruction.itemId
      ))
    }
  }, _.isEmpty)

  const instructionUpdate = {
    ...instructionsWithoutTarget,
    [ targetType ]: {
      ...instructionsCurrent[ targetType ],
      //Add the selected instruction
      [ selectedInstruction.itemId ]: {
        ...instructionsCurrent[ selectedInstruction.type ][ selectedInstruction.itemId ],
        quantity: maxQuantity
      }
    }
  }

  trip.stops[ stopId ].instructions = instructionUpdate

}

export const togglePickupOrDropoff = (state, action) => {

  const orderId = state.activeId
  const { tripId, stopId, desiredType } = action.payload

  const order = state.pages[ orderId ].order
  const stop =
    state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ]

  if ( _.isNil(desiredType) && //TRUE
    //If either the pickup instructions or dropoff instructions are not empty...
    ( _.isEmpty(stop.instructions?.pickup) === false ||
      _.isEmpty(stop.instructions?.dropoff) === false )) {
    //Ignore the toggle...
    return;
  }

  stop.type = desiredType ? desiredType :
    ( stop.type === 'pickup' ? 'dropoff' : 'pickup')
}

export const changeSelectedItem = (state, action) => {
  const orderId = state.activeId

  const tripMap = state.pages[ orderId ].order.trips
  const {
    tripId,
    stopId,
    instruction,
    selectedType,
    selectedItemId
  } = action.payload

  const trip = state.pages[ orderId ].order.trips[ tripId ]

  const { stopOptions=[] }  = trip

  const stopsSorted = sortStops(trip.stops)
  const stopIndex   = stopsSorted.findIndex( stop => stop.id === stopId )

  //Step 1: Make sure we have options for the selected item id
  //that aren't equal to the same type...

  const instructionsCurrent =
    _.at(tripMap, `${ tripId }.stops.${ stopId }.instructions`)[ 0 ]


  if ( !instructionsCurrent ) {
    return tripMap  //No instruction to remove...
  }

  //Step 2: Remove the instruction of the type (pickup/dropoff) and itemId
  //from the map
  const instructionsModified =
    _.omit(instructionsCurrent, `${ selectedType }.${ instruction.itemId }`)

  //Step 3: Find out the max quantity of the selected item we can
  //automatically select for the user
  const optionsForSelectedItem =
    stopOptions[ stopIndex ][ selectedItemId ] ?
    stopOptions[ stopIndex ][ selectedItemId ] : {}

  //Make sure we have pickup or dropoff option for the selected item
  if ( !optionsForSelectedItem[ selectedType ] ) {
    return;
  }

  //The user can manually adjust the max quantity if they'd like to
  const maxQuantity = optionsForSelectedItem[ selectedType ]

  //Add the other option in it's place, keeping the order the same
  const instructionsUpdated = {
    ...instructionsModified,
    [ selectedType ]: {
      ...( instructionsModified[ selectedType ] ?
        instructionsModified[ selectedType] : {}),
      [ selectedItemId ]: {
        quantity: maxQuantity,
        order: instruction.order
      }
    }
  }

  trip.stops[ stopId ].instructions = instructionsUpdated
}

//Called when an item is no longer
//included in the order
export const removeItemFromOrder = (state, action) => {

  const orderId           = state.activeId
  const { deletedIndex }  = action.payload

  const page = state.pages[ orderId ]

  //Remove the item from the order...
  const { items }   = page
  const removedItem = items[ deletedIndex ]

  page.items = items.filter((item, index) => index !== deletedIndex)

  if ( page.items.length === 0 ) {
    page.items = [ Util.EMPTY_ITEM ]
  }

  //Remove the item from all trips...
  const tripMap = state.pages[ orderId ].order.trips

  _.keys(tripMap).forEach(tripId => {

    const trip = state.pages[ orderId ].order.trips[ tripId ]

    const updatedStops = _.keys(trip.stops).reduce(( stops, stopId ) => {

      const stop = trip.stops[ stopId ]

      const { instructions={} }       = stop
      const { pickup={}, dropoff={} } = instructions

      //Will only include pickup or dropoff properties if they are not empty...
      const updatedInstructions = _.omitBy({
        pickup: _.omit(pickup, removedItem.value),
        dropoff: _.omit(dropoff, removedItem.value)
      }, _.isEmpty)

      return {
        ...stops,
        [ stopId ]: {
          ...stop,
          instructions: updatedInstructions
        }
      }

    }, {})

    trip.stops = updatedStops

  })

}

//Step 1: Consider this array representing items picked up
//and dropped off along a route.
//Returns a list of options for each stop given based on
//what has been picked up and dropped off
//
//Also returns errors indicating what hasn't been picked up

/*
 * stops = [
 * ]
 */
export const createStopOptions = options => {

  const { tripId, tripMap, availableItems={}, itemMap={} } = options

  const trip = tripMap[ tripId ]

  //List of stops...
  const stopsSorted = sortStops(trip.stops)

  //We start with a number of items available from the order

  const pickupQuantityMap   = {}
  const dropoffQuantityMap  = {}
  const tripErrorsPresave   = {}

  //STEP 1: Figure out what's onboard before/after
  const mappedStops = stopsSorted.reduce( ( stops, stop, index ) => {

    const errors        = []
    const errorsPresave = []
    const warnings      = []

    const { instructions={}, address, time } = stop
    const { pickup={}, dropoff={} } = instructions

    //VALIDATE: See if any instrucitons exists
    if ( _.isEmpty( pickup ) && _.isEmpty( dropoff ) ) {
      warnings.push({
        type: 'warning',
        message: 'No cargo picked up or dropped off from stop'
      })
    }

    //VALIDATE: Address selected...
    if ( _.isEmpty(address?.placeId ) ) {
      warnings.push({
        type: 'warning',
        message: 'No address selected'
      })
    }

    //VALIDATE: Time selected...
    if ( _.isNil(time) ) {
      if ( index === 0 ) {
        errorsPresave.push({
          type: 'error',
          message: 'No pickup time selected'
        })
      } else {
        warnings.push({
          type: 'warning',
          message: 'No time selected'
        })
      }
    }

    const previousStopPlaceId = index > 0 ?
      stopsSorted[ index -1 ].address?.placeId : null

    if ( previousStopPlaceId &&
      address?.placeId &&
      previousStopPlaceId === address.placeId ) {

      errors.push({
        type: 'error',
        message: "Destination cannot be the same as the previous stop"
      })
    }

    const previousStopTime = index > 0 ? stopsSorted[ index -1 ].time : null

    if ( previousStopTime && time && moment(time).isAfter(previousStopTime) === false ) {
      errors.push({
        type: 'error',
        message: "Time must be after previous stop's time"
      })
    }

    if ( index === 0 ) {

      if ( _.isEmpty( dropoff ) === false ) {
        errors.push({
          type: 'error',
          message: "Cannot dropff cargo from first stop"
        })
      }

      for ( const itemId in pickup ) {
        pickupQuantityMap[ itemId ] = pickup[ itemId ].quantity

        //Make sure the item is available
        if ( !availableItems[ itemId ] === 0 ) {
          errors.push({ type: 'error', message: "Item not available to pick up" })

        } else if ( pickup[ itemId ].quantity > availableItems[ itemId ] ) {

          const description = itemMap[ itemId ] ? itemMap[ itemId ].description : ''
          errors.push({
            type: 'error',
            message: `Quantity picked up (${ pickup[ itemId ].quantity }) ` +
              `for ${ description } greater than quantity ` +
              `available (${ availableItems[ itemId ]})`
          })

          availableItems[ itemId ] = 0 //No more available...

        } else { //
          availableItems[ itemId ] -= pickup[ itemId ].quantity
        }
      }

      /*
      {
        item0: [{ stop: 0, quantity: 4 }, { stop: 2, quantity: 2}]
      }
      */

      return [
        {
          ...(_.cloneDeep(stop)),
          pickupHistory: buildHistory(pickup, {}, index),
          onboardBefore: {},
          onboardAfter: _.cloneDeep(pickup),
          errors,
          errorsPresave,
          warnings
        }
      ]

    } else {

      const previousStop = stops[ index - 1 ]
      const { onboardAfter: onboardAfterLastStop={} } = previousStop
      const { instructions={} } = stop
      const { pickup={}, dropoff={} } = instructions
      let { pickupHistory={}, dropoffHistory={} } = previousStop

      //Take what was onboard after the last stop
      //and add that to what is being picked up at this stop
      const onboard = _.cloneDeep(onboardAfterLastStop)

      //Remove dropped off items
      for ( const itemId in dropoff) {

        if ( _.isNil( onboard[ itemId ] ) === false ) {

          if ( dropoff[ itemId ].quantity > onboard[ itemId ].quantity ) {

            errors.push({
              type: 'error',
              message: `Quantity dropped off ${ dropoff[ itemId ].quantity} ` +
              `greater than quantity onboard ${ onboard[ itemId ].quantity}`
            })
          }

          //Find out what quantity is safe to dropoff...
          const safeDropoffQuantity =
            dropoff[ itemId ].quantity <= onboard[ itemId ].quantity ?
            dropoff[ itemId ].quantity : onboard[ itemId ].quantity

          onboard[ itemId ].quantity -= safeDropoffQuantity

          //Record the dropped off quantity of the item...
          if ( _.isNil( dropoffQuantityMap[ itemId ] ) ) {
            dropoffQuantityMap[ itemId ] = safeDropoffQuantity
          } else {
            dropoffQuantityMap[ itemId ] += safeDropoffQuantity
          }

          //history = {
          //  item1: [{ stop: 0, quantity: 4 }, { stop: 1, quantity: 5 }]
          //}
          //dropoffHistory = {
          //  item1: [
          //    { pickup: stop0, dropoff: stop1, quantity: 4 }
          //  ]
          //}
          let dropoffQuantityLeft = safeDropoffQuantity

          while ( dropoffQuantityLeft > 0 ) { //9 5

            //See how many we can remove from this index...
            const [ mostRecentPickup, ...pickups ] =
              pickupHistory[ itemId ] ? pickupHistory[ itemId ] : []

            if ( !mostRecentPickup ) {
              break; //TODO
            }
            //{ stop: 0, quantity: 4 }
            //{ stop: 1, quantity: 5 }

            const quantityDropped = //4 <= 9 | 5 <= 5
              mostRecentPickup.quantity <= dropoffQuantityLeft ? //true true
              mostRecentPickup.quantity : dropoffQuantityLeft //4 //5

            //Decrement the quantity 
            dropoffQuantityLeft -= quantityDropped //9 - 4 = 5 | 5 - 5 = 0

            //Push to dropoff history
            if ( !dropoffHistory[ itemId ] ) {
              dropoffHistory[ itemId ] = []
            }

            dropoffHistory[ itemId ].push({
              pickup: mostRecentPickup.stop,
              dropoff: index,
              quantity: quantityDropped,
            })

            //4 < 4 || 5 < 5
            if ( quantityDropped < mostRecentPickup.quantity ) {
              //Place the item back in pickup history
              const modifiedPickup = {
                ...mostRecentPickup,
                quantity: mostRecentPickup.quantity - quantityDropped
              }
              pickupHistory[ itemId ] = [
                modifiedPickup,
                ...pickupHistory[ itemId ]
              ]
              break; //Can't dropoff anymore...
            }

            //More pickups to add...
          }

        } else {
          const description = itemMap[ itemId ] ? itemMap[ itemId ].description : ''
          errors.push({
            type: 'error',
            message: `${ description } not available for dropoff`
          })
        }

      }

      //Add picked up items...
      for ( const itemId in pickup ) {

        if ( _.isNil(availableItems[ itemId ]) === false ) {   //Has the item

          if ( pickup[ itemId ].quantity <= availableItems[ itemId ] ) { //Quantity

            //Safe to pickup item
            if (! onboard[ itemId ] ) {
              onboard[ itemId ] = { quantity: pickup[ itemId ].quantity }
            } else {
              onboard[ itemId ].quantity += pickup[ itemId ].quantity
            }

            availableItems[ itemId ] -= pickup[ itemId ].quantity

            if ( _.isNil( pickupQuantityMap[ itemId ]) ) {  //Record the quantity
              pickupQuantityMap[ itemId ] = pickup[ itemId ].quantity
            } else {
              pickupQuantityMap[ itemId ] += pickup[ itemId ].quantity
            }

          } else {

            const description = itemMap[ itemId ] ? itemMap[ itemId ].description : ''
            errors.push({
              type: 'error',
              message: `Quantity picked up ${ pickup[ itemId ].quantity } ` +
                ` for ${ description } greater than quantity ` +
                `available ${ availableItems[ itemId ]}`
            })

          }
        } else { //Error
          const description = itemMap[ itemId ] ? itemMap[ itemId ].description : ''
          errors.push({
            type: 'error',
            message: `${ description } not available for pickup`
          })
        }
      }

      return [
        ...stops,
        {
          ...stop,
          pickupHistory: buildHistory(pickup, stops[ index -1 ].pickupHistory, index),
          dropoffHistory: dropoffHistory,
          onboardBefore: _.cloneDeep(onboardAfterLastStop),
          onboardAfter: _.cloneDeep(onboard),
          errors,
          errorsPresave,
          warnings
        }
      ]
    }
  }, [])

  //Check the last stop for items onboard
  const tripErrorsGlobal        = []

  if ( mappedStops.length > 1 ) {

    const { onboardAfter={} } = mappedStops[ mappedStops.length -1 ]

    //Create errors for items picked up and not dropped off
    for ( const itemId in onboardAfter ) {
      if ( onboardAfter[ itemId ].quantity ) {
        const notDroppedOffQuantity = onboardAfter[ itemId ].quantity
        const description = itemMap[ itemId ] ? itemMap[ itemId ].description : ''
        tripErrorsGlobal.push({
          type: 'error',
          message: `(${ notDroppedOffQuantity }) ` + 
            `${ description }(s) not dropped off`
        })
      }
    }
  }

  //Step 2: Calculate quantityLeft based on what's been picked up + dropped off
  const quantityLeftMap = {} //Quantity left to dropoff...

  for ( const itemId in pickupQuantityMap ) {

    //If the item was dropped off...
    if ( _.isNil( dropoffQuantityMap[ itemId ]) === false ) {

      //The amount left for the item == pickupQuantity - dropoffQuantity...
      quantityLeftMap[ itemId ] =
        pickupQuantityMap[ itemId ] - dropoffQuantityMap[ itemId ]

    } else { //if it is nil, no items were dropped off

      quantityLeftMap[ itemId ] = pickupQuantityMap[ itemId ]
    }
  }

  //Add all items not picked up at all to the quantity left map...
  for (const itemId in availableItems ) {
    if ( _.isNil( quantityLeftMap[ itemId ] ) ) {
      quantityLeftMap[ itemId ] = availableItems[ itemId ]
    }
  }

  //Step 3: Calculate options for each stop
  const stopsWithOptions = mappedStops.map(( stop, stopIdx ) => {

    const options = { }

    const addPickOption = (itemId, quantity) => {
      if ( !options[ itemId ] ) {
        options[ itemId ] = {}
      }
      options[ itemId ].pickup = quantity
    }

    const addDropoffOption = (itemId, quantity) => {
      if ( !options[ itemId ] ) {
        options[ itemId ] = {}
      }
      options[ itemId ].dropoff = quantity
    }

    //Pickup options
    const { instructions={}, onboardBefore={}, } = stop
    const { pickup={}, dropoff={} } = instructions

    //Add pickup options for items not picked up in current stop...
    _.keys(availableItems).forEach(itemId => {
      if ( !pickup[ itemId ] ) {
        addPickOption(itemId, availableItems[ itemId ] )
      }
    })

    //Add pickup options for items picked up in current stop
    for ( const itemId in pickup ) {

      const quantityAvailable = availableItems[ itemId ] ?
        availableItems[ itemId ] : 0

      //3 3
      const maxPickup = quantityAvailable + pickup[ itemId ].quantity

      addPickOption(itemId, maxPickup)
    }

    //Dropoff options
    for ( const itemId in onboardBefore ) {
      const quantityDroppedOffInThisStop =
        dropoff[ itemId ] ? dropoff[ itemId ].quantity : 0

      //If the quantity the user decided to drop off
      //is less than what was onboard before,
      //the quantity they can dropoff is
      //what quantityDroppedOffInThisStop + (quantityOnboardBefore - )
      //
      //If the quantity the user decided to drop off
      //is less than what was onboard before,
      //and there are
      //the quantity they can dropoff is
      //quantityDroppedOfInThisStop
      //3 + min( 4 - 3 ) = 1
      //3 < 4

      let maxDropoff;

      if ( quantityLeftMap[ itemId ] === 0 ) { //No items remaining...

        //maxDropoff is equal to whatever it is now...
        maxDropoff = dropoff[ itemId ] ? dropoff[ itemId ].quantity : 0

      } else {


        //4 - 2 = 2
        const remainder =
          Math.min(
            //4 - 2 = 2
            onboardBefore[ itemId ].quantity - quantityDroppedOffInThisStop,
            //1
            quantityLeftMap[ itemId ]
          )

        //2 + 2
        maxDropoff = remainder + quantityDroppedOffInThisStop
      }

      //onboardBefore - quantityDroppedOff

      //min(quantity dropped off in this stop, quantityOnboardBefore)
      //0 + 4

      addDropoffOption(itemId, maxDropoff)
    }

    return {
      ...stop,
      options //Add options
    }
  })

  const instructions =
    stopsWithOptions.map(stop => stop.options)

  const stopErrors =
    stopsWithOptions.map(stop => stop.errors)

  const stopErrorsPresave =
    stopsWithOptions.map(stop => stop.errorsPresave)

  const stopWarnings =
    stopsWithOptions.map(stop => stop.warnings)

  const history =
    stopsWithOptions[ stopsWithOptions.length - 1 ]?.dropoffHistory

  //Updated Trip map...
  const modifiedTripMap = {
    ...tripMap,
    [ tripId ]: {
      ...tripMap[ tripId ],
      stopOptions: instructions,
      stopErrors,
      stopErrorsPresave,
      tripErrorsGlobal,
      stopWarnings,
      quantityLeftToDropoff: quantityLeftMap,
      invoiceItems: history,
    }
  }

  return modifiedTripMap

}

export const removeInstruction = (state, action) => {

  const orderId = state.activeId
  const { tripId, stopId, instructionIndex } = action.payload

  const instructions = state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ].instructions

  if ( _.isEmpty(instructions) ) {
    console.log("No instructions available to remove from stop")
    return;
  }

  const instructionsSorted = sortInstructions(instructions)

  //Get a list of sorted instructions without the instruction
  const sortedFilteredInstructions =
    instructionsSorted.reduce((list, instruction, index ) => {
      if ( index === instructionIndex ) {
        return list //Exclude the item
      } else if ( index > instructionIndex ) {
        return [
          ...list,
          { ...instruction, order: index - 1 }
        ]
      } else {
        return [ ...list, instruction ]
      }
    }, [])

  const updatedInstructions =
    sortedFilteredInstructions
    .reduce((map, instruction) => {
      return {
        ...map,
        [ instruction.type ]: {
          ...(map[ instruction.type ] ? map[ instruction.type ]: {}),
          [ instruction.itemId ]: {
            id: instruction.id,
            quantity: instruction.quantity,
            order: instruction.order
          }
        }
      }
    }, {})

  const stop = state.pages[ orderId ].order.trips[ tripId ].stops[ stopId ]

  stop.instructions = updatedInstructions
}

export const sortInstructions = instructions => {

  return _.keys(instructions).reduce((list, instructionType ) => {

    return [
      ...list,
      ...(_.keys(instructions[ instructionType ]).map( itemId => ({
        type: instructionType,
        quantity: instructions[ instructionType ][ itemId ].quantity,
        order: instructions[ instructionType ][ itemId ].order,
        itemId
      })))
    ]
  }, [])
  .sort(( i1, i2 ) => i1.order - i2.order)
}

/*
 *
 */
export const computeInstructionOptions = options => {

  const { tripMap, tripId, stopId, instruction } = options

  //Get the index of the stop...
  const stop      = tripMap[ tripId ].stops[ stopId ]
  const stopIndex = stop.order

  const { addInstructionOptions=[] }  = tripMap[ tripId ]
  const addInstructionOptionsForStop  = addInstructionOptions[ stopIndex ]

  if ( !addInstructionOptionsForStop ) {
    //The only instruction available is the instruction they have...
    return [ instruction ]
  }

  const mappedInstruction = {
    type: instruction.type,
    quantity: instruction.quantity,
    itemId: instruction.itemId
  }

  //Allow the user to select other instructions
  //that haven't already been selected or are the current
  //instruction...
  const availableInstructionOptions =
    [ mappedInstruction, ...addInstructionOptionsForStop ]
    .filter( i => i.type === instruction.type )
    .map( instruction => ({
      type: instruction.type,
      itemId: instruction.itemId,
      quantity: instruction.quantity
    }))

  return availableInstructionOptions

}

//When user wants to copy + reverse the trip
export const reverseTrip = (state, action) => {

  const orderId = state.activeId
  const tripId  = action.payload
  const tripMap = state.pages[ orderId ].order.trips

  const trip = tripMap[ tripId ]

  //Go through all the stops in the trip...
  const stopsSorted = sortStops(trip.stops)

  const stop1 = stopsSorted[ 0 ]
  const stop2 = stopsSorted[ 1 ]

  const stops = {
    [ stop1.id ]: {
      ...stop1,
      address: stop2.address
    },
    [ stop2.id ]: {
      ...stop2,
      address: stop1.address
    }
  }

  trip.stops = stops
}

export const addReturnTrip = (state, action) => {
  const orderId   = state.activeId
  const companyId = localStorage.getItem("companyId")

  const { tripId, returnDate } = action.payload
  const tripMap = state.pages[ orderId ].order.trips

  //Copy this trip...
  const originalTrip = tripMap[ tripId ]

  //Trip id
  const newTripId = doc(collection(db, "companies", companyId, "orders", orderId, "trips")).id

  //Create a copy of the trip's stops
  const stops = originalTrip.stops
  const sortedStops = sortStops(originalTrip.stops)


  const addMillis = calculateAddMillis(sortedStops)
  const newStops  = addTimeToStopsFromDate(sortedStops, returnDate, addMillis)

  const returnTrip = {
    ...originalTrip,
    stops: newStops.reduce((map, stop) => {
      return {
        ...map,
        [ stop.id ]: stop,
      }
    }, {})
  }

  state.pages[ orderId ].order.trips[ newTripId ] = returnTrip
}

//Always keep the trip orded by stop
export const sortStops = (stopMap={}, reverse=false) => {
  return sortMap(stopMap, reverse)
}

export const sortTrips = (tripMap={}, reverse=false) => {
  return sortMap(tripMap, reverse)
}

export const sortMap = (map={}, reverse) => {

  const sorted =
    _.keys(map).map( id => ({ id, ...map[ id ] }))
    .sort( (s1, s2) => reverse ? s2.order - s1.order : s1.order - s2.order)

  return sorted
}

const EMPTY_STOP_DEFAULT = {
  weight: 0,
  address: null,
  type: 'dropoff',
  timeChoice: null,  //AM||PM
  isComplete: false,
  instructions: {},
  contact: {}
}

function buildHistory( pickup, history={}, index ) {

  const metadata = _.keys(pickup).reduce(( map, itemId ) => {

    return {
      ...history,
      [ itemId ]: [
        {
          stop: index,
          quantity: pickup[ itemId ].quantity
        },
        ...(history[ itemId ] ? history[ itemId ] : []),
      ]
    }
  }, history)

  return metadata
}

export function createRouteDistanceArray({ tripMap, tripId }) {
  const trip  = tripMap[ tripId ]
  const stops = sortStops(trip.stops)

  const result = stops.map(stop => {
    return {
      time: stop.time ? moment(stop.time).unix() : null,
      placeId: stop.address?.placeId
    }
  })
  .filter( stop => _.isNil(stop.placeId) === false )

  if ( result.length < 2 ) {
    return null
  } else {
    return result
  }
}

export function calculateGrossWeights(state, action) {
  const orderId     = state.activeId
  const page        = state.pages[ orderId ]
  const tripMap     = page.order.trips
  const { itemMap } = page
  const { tripId }  = action.payload

  _.keys(tripMap).forEach((map, tId ) => {

    if ( tId === tripId ) {
      const trip    = tripMap[ tId ]
      const stops   = sortStops(trip.stops)
      const weights = []

      //Calculate weights at each stop...
      let weight = 0
      let maxGrossWeight = 0

      for ( const stopIdx in stops ) {

        const stop = stops[ stopIdx ]
        const { pickup={}, dropoff={} } = stop?.instructions ? stop.instructions : {}

        for ( const itemId in pickup ) {
          const weightAdded =
            itemMap[ itemId ] ?
            itemMap[ itemId ].weight * pickup[ itemId ].quantity : 0
          weight += weightAdded
        }

        for ( const itemId in dropoff ) {
          const weightRemoved =
            itemMap[ itemId ] ?
            itemMap[ itemId ].weight * dropoff[ itemId ].quantity : 0

          weight -= weightRemoved
        }

        if ( weight > maxGrossWeight ) {
          maxGrossWeight = weight
        }

        weights.push(weight)
      }

      const stopsWithWeights =
        stops.forEach((stop, index ) => {
          trip[ stop.id ].grossWeightAfter = weights[ index ]
        })

    }
  })
}

const calculateAddMillis = sortedStops => {

  //Get an map of millis to add to each stop...
  const addMillis = sortedStops.reduce( ( map, stop, index) => {
    const previousStop = sortedStops[ index - 1 ]

    if ( index === 0 || _.isNil(previousStop) ) {
      return {
        [ index ]: 0 //Whatever the return date is will be where we start...
      }
    }

    if ( previousStop ) {
      return {
        ...map,
        [ index ]: moment.isMoment(stop.time) ?
        stop.time.diff(previousStop.time) : moment(stop.time * 1000).diff(previousStop.time)
      }
    }
  }, {})

  return addMillis
}

const addTimeToStopsFromDate = (sortedStops, returnDate, addMillis ) => {

  const [ firstStop ] = sortedStops

  firstStop.time =
    moment.isMoment(firstStop.time) ?
    firstStop.time : moment(firstStop.time * 1000)

  let currentTime =
    returnDate.clone()
    .hour(firstStop.time.hour())
    .minute(firstStop.time.minute())

  const newStops = sortedStops.map(( stop, index ) => {
    const millis = addMillis[ index ]
    const time   = currentTime.add(millis).second(1) //
    return {
      ...stop,
      //Switch the addresses...
      address: index === 0 ? sortedStops[ 1 ].address : sortedStops[ 0 ].address,
      time: time.clone() //has everything in currentTime
    }
  })

  return newStops
}

const createEmptyTrip = (orderId, newTripId, tripMap={}) => {

  const companyId = localStorage.getItem("companyId")
  const stopsRef  =
    collection(db, "companies", companyId, "orders", orderId, "trips", newTripId, "stops")

  const stopId1   = doc(stopsRef).id
  const stopId2   = doc(stopsRef).id

  const order = _.keys(tripMap).length

  const emptyTrip = {
    order,
    startTime: "",
    endTime: "",
    dispatched: false,
    equipment: {
      drivers: {}
    },
    stops: [ stopId1, stopId2 ].reduce((map, stopId, index) => {
      return {
        ...map,
        [ stopId ]: {
          ...EMPTY_STOP_DEFAULT,
          order: index,
          type: index === 0 ? 'pickup' : 'dropoff'
        }
      }
    }, {}),
    status: null,
    addInstructionOptions: [],
    stopErrors:[],
    stopOptions: [],
    errors: []
  }

  return emptyTrip
}
