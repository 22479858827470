import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { DashboardContext, OrderPageContext } from '../contexts'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { useAPIOrder, useOrderId, useContact } from '../redux/dashboardSlice'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography';
import * as api from '../utils/api'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { useCompanyId } from '../hooks'
import { actions, useCustomer, usePageSelector } from '../redux/dashboardSlice'
import { InvoiceIcon, PencilIcon } from '../icons'


export default function QuoteModal(props) {
  const {
    open,
    handleClose,
    onCreateInvoice,
    refetchCustomerOrders
  } = props

  const apiOrder  = useAPIOrder()
  const companyId = useCompanyId()
  const orderId   = useOrderId()
  const dispatch  = useDispatch()
  const [documentType, setDocumentType] = React.useState('bol');
  

  const orderModalOpen  = useSelector(state => state.dashboard.orderModalOpen)
  const droppedFile     = usePageSelector(page => page.droppedFile)

  const { customer, customerOption }  = useContext(OrderPageContext)
  const { openSnackbar } = useContext(DashboardContext)

  const height        = true ? 'calc(60vh)' : 'calc(80vh)'
  const sectionHeight = true ? 'calc(50vh)' : 'calc(70vh)'
  const handleChange = (event, documentType) => {
    setDocumentType(documentType)
  };

  const handleDocumentUpload = () => {
    let location = `companies/${ companyId }/orders/${ orderId }`

    if (! droppedFile || _.isNil(droppedFile.file) ) {
      throw 'No file'
    }

    if ( documentType === 'bol' ) {
      location += '/bol'
    } else if ( documentType ) {
      location += '/rateConf'
    } else {
      throw 'Unhandled file type'
    }

    api.handleFileUpload(droppedFile.file, location)
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: orderModalOpen ? '60%': '80%',
    transform: 'translate(-50%, -50%)',
    minHeight: height,
    maxHeight: height,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    outline: 0
  }

  return (
    <>
      <Modal
        open={ open }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Box sx={{ ...modalStyle, borderRadius: 2, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Grid container className="invoice-modal">
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
              <Grid item xs={ 12 } sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                <div style={{ ...(noInvoiceStyles), maxHeight: sectionHeight, minHeight: sectionHeight, overflowY: 'scroll', padding: 10 }}>
                  <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" sx={{ mb: 4 }}>
                      Drop files here to upload a document...
                    </Typography>
                    <AttachFileOutlinedIcon sx={{ fontSize: 30, opacity: 0.5, mb: 4 }}/>
                    <Box sx={{ display: 'flex' }}>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ paddingBottom: 20 }}>Type</th>
                            <th style={{ paddingBottom: 20 }}>File</th>
                          </tr>
                        </thead>
                        <tr>
                          <td style={{ paddingRight: 20 }}>
                            <ToggleButtonGroup
                              color="primary"
                              orientation="vertical"
                              value={documentType}
                              exclusive
                              onChange={handleChange}
                              aria-label="Platform">
                              <ToggleButton value="rateConf">Rate Confirmation</ToggleButton>
                              <ToggleButton value="bol">Bill of Lading</ToggleButton>
                            </ToggleButtonGroup>
                          </td>
                          <td>
                            { droppedFile ? droppedFile.file.name : '-' }
                          </td>
                        </tr>
                      </table>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <Button variant="contained"
                        disabled={ _.isNil(droppedFile?.file) }
                        onClick={ () => handleDocumentUpload() }
                        sx={{ mr: 2 }}>Upload</Button>
                      <Button variant="outlined" label="Cancel" onClick={ () => dispatch(actions.setDropzoneModalOpen(false)) }>Cancel</Button>
                  </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
        </Box>
      </Modal>
    </>
  )
}

const noInvoiceStyles = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
