import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { gql, useLazyQuery, } from '@apollo/client';
import { blue } from '@mui/material/colors';
import { PatternFormat, NumericFormat } from 'react-number-format';
import { PencilIcon } from '../icons'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash'
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';

export default function(props) {

  const FIELD_CONTAINER_HEIGHT_INITIAL = 0 //100

  const {
    allowSaves=true,      //Whether to allow create/save (false for Driver)
    entityName,           //i.e. Driver
    emptyEntity,          //Object containing an empty entity...
    existingEntity,       //When the user wants to display existing entity...
    modalLabelAdd,        //i.e. Add Dispatcher, Add Contact etc.
    modalLabelEdit,       //i.e. Edit Dispatcher/ Edit Contact
    modalLabelSelectOnly, //
    options=[],           //List of entities (drivers, contacts, etc)
    onSearch,             //Update the list of options...
    onOptionSelected,     //Inform parent and close modal
    onCreateNewEntity,    //Inform parent, close the modal
    onSaveEntity,         //Inform parent, close the modal
    onDeleteOption,       //Inform parent
    open,
    optionLabelFieldKey,  //Key of the option field to use for the label...
    handleClose,
    selectEntityTooltip="",
    //Has validate prop if the field requires validation
    entityFields=[],      //What fields to display when creating new entity...
    //Field of the entity to perform the search
    //(i.e. name for contact)...
    searchFieldKey,
  } = props

  const [ modalHeading, setModalHeading ] = useState(null)

  //Entity to edit...
  const [ entity, setEntity ] = useState({})

  //Fields of the entity to display for the user to edit...
  const [ displayFields, setDisplayFields ]           = useState([])
  const [ displayCountButton, setDisplayCountButton ] = useState(true)
  const [ submitDisabled, setSubmitDisabled ]         = useState(true)

  //Save state vars...
  const [ isNewEntity, setIsNewEntity ] = useState(null) //We're not sure if it's new

  //Used to instruct the user as they create
  //a new contact or other entity...
  const [ modalButtonText, setModalButtonText ]     = useState(null)
  const [ displaySaveButton, setDisplaySaveButton ] = useState(false)

  //Search
  const ranSearchEffectInitialRef = useRef(false)
  const [ showCircularProgress, setShowCircularProgress ] = useState(false)

  //Animations
  const [ fieldContainerAnimateProps, setFieldContainerAnimateProps ]     = useState({})
  const [ optionsContainerAnimateProps, setOptionsContainerAnimateProps ] = useState({})
  const [ didReset, setDidReset ] = useState(false)

  const resetModal = () => {
    setDisplayFields([ entityFields[ 0 ] ])

    setEntity({}) //New entity...
    setDidReset(true)
  }

  //Effect to always set didReset to false on close...
  useEffect(() => {
    if ( !open ) {
      setDidReset(false)
    }
  }, [ open ])

  //Effect to understand whether this is a new entity or not...
  useEffect(() => {

    const isNewEntity = _.isEmpty( existingEntity ) 
    setIsNewEntity(isNewEntity)

    if (! isNewEntity ) {

      const props = {
        animate: {
          height: 0,
        }
      }

      setOptionsContainerAnimateProps(props)

      return () => {
        //setOptionsContainerAnimateProps({ animate: { height: 'auto' } })
      }
    }

  }, [ existingEntity, open ])

  //Effect to validate the entity
  useEffect(() => {
    //TODO
    if ( _.isEmpty(entity) === false ) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [ entity ])

  //Effect to set the modal label based on...
  useEffect(() => {
    if ( !allowSaves ) {
      setModalHeading(modalLabelSelectOnly)
    } else {
      setModalHeading(isNewEntity ? modalLabelAdd : modalLabelEdit )
    }
  }, [ isNewEntity, modalLabelAdd, modalLabelEdit, allowSaves ])

  //Effect to copy what's in the existing entity to the internal entity...
  useEffect(() => {

    if ( _.isEmpty( existingEntity ) ) {
      setEntity(emptyEntity ? emptyEntity : {})
    } else {
      setEntity({ ...existingEntity })
      setDisplayFields(entityFields)
    }

    //Disable search effect when the entity changes...
    ranSearchEffectInitialRef.current = false

  }, [ open, existingEntity, emptyEntity ])

  //Effect to set the modal button text based on which fields are displayed...
  useEffect(() => {

    if ( allowSaves === false ) { //Only allow searching...

      setModalButtonText('Search')

    } else if ( displayFields.length < entityFields.length && //Not displaying all...
      displayFields.length !== 1 ) { //Not on the first field...

      const nextFieldIndex = displayFields.length

      setModalButtonText('Add ' + entityFields[ nextFieldIndex ].label)

    } else if ( displayFields.length === entityFields.length ) { //Displaying all fields

      setModalButtonText(null) //Hide the modal button...

    } else if ( displayFields.length === 1 ) { //Set the modal button text
      setModalButtonText('Create New') //Allow the user to create new again...
    }

  }, [ displayFields, entityFields, allowSaves ])

  //Effect to animate height based on the number of display fields...
  useEffect(() => {

    //1: Handle transition of the options based on display fields...

    //When there is one field displayed, make sure options are visible...
    if ( displayFields.length === 1 ) {

      const showOptionsContainerAnimateProps = {
        animate: {
          height: 'auto', //visible
          stiffness: 5,
        },
        transition: {
          type: "spring",
        }
      }

      setOptionsContainerAnimateProps(showOptionsContainerAnimateProps)

    } else if ( displayFields.length === 2 ) {

      //User doesn't want to select from existing options...

      const hideOptionsContainerAnimateProps = {
        animate: {
          height: 0,  //hide
          stiffness: 5,
        },
        transition: {
          type: "spring",
        }
      }

      setOptionsContainerAnimateProps(hideOptionsContainerAnimateProps)
    }

    //2: Transition display fields based on the height...
    const desiredHeightForDisplayFields = displayFields.length * 100

    const animateProps = {
      animate: {
        height: desiredHeightForDisplayFields,
        stiffness: 5,
      },
      transition: {
        type: "spring",
      }
    }

    setFieldContainerAnimateProps(animateProps)

  }, [ displayFields ])

  //Effect to handle the display of the save button...
  useEffect(() => {
    if ( displayFields.length > 1 && !displaySaveButton && allowSaves ) {
      setDisplaySaveButton(true)
    } else if ( displayFields.length <= 1 && displaySaveButton ) {
      setDisplaySaveButton(false)
    }
  }, [ displayFields.length, displaySaveButton, allowSaves ])

  //Effect to handle the display of the entity count button...
  useEffect(() => {
    setDisplayCountButton(!displaySaveButton)
  }, [ displaySaveButton ])

  //Effect to show the circular progress based on entity[ searchField ]
  useEffect(() => {

    //Perform the search...
    onSearch(entity[ searchFieldKey ])

    //Handle when the existing contact triggers the effect
    if (! ranSearchEffectInitialRef.current ) {
      //Enable search effect...
      ranSearchEffectInitialRef.current = true

      return; //When the effect runs again, we'll run the effect...
    }

    let animationCancelled = false

    setShowCircularProgress(true)

    //After 400 ms, hide the circular progress...
    const timeout = setTimeout(() => {
      if (animationCancelled === false) {
        setShowCircularProgress(false)
      }
    }, 400)

    return () => {
      //Will cause animationCancelled to be true when the effect
      //runs again before setTimeout function is triggered...
      animationCancelled = true
    }

  }, [ entity[ searchFieldKey ] ])

  //Effect set display fields based on whether the modal is open...
  useEffect(() => {

    if (! open ) {

      //Only show the first field...
      setDisplayFields([ entityFields[ 0 ] ])

      setEntity({}) //New entity...
    }
  }, [ open, entityFields ])

  //Effect to handle validating the entity...
  useEffect(() => {
    //TODO: Validate fields...
  }, [ entity ])

  const addNextField = () => {
    if ( allowSaves === false ) {
      return; //Not allowed to add options...
    }

    if ( displayFields.length === entityFields.length ) {
      return; //No more fields to display... 
    }

    //Add another field to display...
    setDisplayFields(entityFields.filter((_, index) => index <= displayFields.length ))
  }

  const renderTextField = ( field, entity, onEntityChange ) => {
    switch ( field.type ) {
      case 'phone':
        return (
          <PatternFormat
            format={ "+1 (###) ### ####" }
            value={ parseInt(entity[ field.key ]) }
            onValueChange={(values, sourceInfo) => {
              onEntityChange(field, values.value)
            }}
            customInput={ MyCustomInput }
            mask="_"
          />
        )
      default:
        return (
          <TextField
            size="small"
            onChange={ (e) => onEntityChange(field, e.target.value) }
            sx={{ width: '100%', mt: 0, mb: 1 }}
            value={ entity[ field.key ] }
            className="contact-field" id="filled-basic" />
        )
    }
  }

  const onEntityChange = (field, value) => {
    let newValue = value

    setEntity({
      ...entity,
      [ field.key ]: newValue
    })
  }

  const handleSubmit = () => {
    //Inform the parent...
    if ( entity.id ) {
      onSaveEntity(entity)
      .then(contact => {
        //Close the modal...
        handleClose(contact)
      })
    } else {
      onCreateNewEntity(entity)
      .then( contact => {
        //Select the given contact...
        handleClose(contact)
      })
    }

  }

  //Handle when the user clicks an entity...
  const handleListItemClick = option => {
    handleClose(option)
  }

  if ( isNewEntity === null ) {
    return null //Wait until we know whether it's a new entity or not
  }

  return (
    <Modal
      open={ open }
      onClose={ () => handleClose() }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ ...modalStyle }}>
        <motion.div layout style={{ padding: '20px 40px' }}>
          <h2
            style={{
                borderBottom: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
            { modalHeading } <PencilIcon />
          </h2>
          <hr />
          <motion.div { ...fieldContainerAnimateProps }
            style={{ height: FIELD_CONTAINER_HEIGHT_INITIAL, overflow: 'hidden' }}>
            { displayFields.map( field => (
              <>
                <label>
                  { field.label }
                </label>
                { renderTextField(field, entity, onEntityChange)}
                <br />
              </>
            )) }
          </motion.div>
          { ( displayFields.length > 1 && entity.id )  ?
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                onClick={ resetModal }
                >Select a different { entityName.toLowerCase() }</Button>
            </div>
            :
            null
          }
          <div className="noselect" style={{ display: 'flex', justifyContent: 'end' }}>
            { modalButtonText && displayFields.length !== entityFields.length ?
              <Button
                disabled={ _.isEmpty( entity[ searchFieldKey ]) }
                onClick={ addNextField }
                sx={{ mt: 0, alignSelf: 'end' }}>
                { modalButtonText }
              </Button>
              : null
            }
        </div>

          <Divider sx={{
              mb: 2,
              mt: 2,
              opacity: 0.4,
          }}/>

          <motion.div
            { ...optionsContainerAnimateProps }
            style={{
                position: 'relative',
                visibility: ( _.isNil(existingEntity) || didReset ) ? 'visible' : 'hidden',
                overflow: 'scroll',
                maxHeight: 155
            }}
            className={ "snap-parent " + options.length > 1 ? "" : "no-scrollbars" }>
            <div className="contacts-box" style={{
                width: '100%',
                position: 'sticky',
                zIndex: 200,
                top: -4,
                height: 10,
                backgroundImage: 'url(/modal-background.png)',
              }}>
            </div>
          <div className="snap-child" style={{ height: 10, width: 40 }}></div>
            <Paper
              elevation={ 2 }
              sx={{ m: 2, /* minHeight: 20 */ }} className="snap-child">
              {
                options.map(option => (
                  <ListItem disableGutters className="snap-child"
                    secondaryAction={
                      onDeleteOption ?
                      <Tooltip title={ "Delete contact" }>
                        <IconButton variant="outlined" sx={{ mr: 1 }} onClick={ () => {
                          onDeleteOption(option)
                        }}>
                          <CloseOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      :
                      null
                    }
                  >
                    <ListItemButton onClick={() => {
                      handleListItemClick(option)
                    }} key={option[ optionLabelFieldKey ]}>
                      <Tooltip title={ selectEntityTooltip }>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                      </Tooltip>
                      <ListItemText primary={ option[ optionLabelFieldKey ] } />
                    </ListItemButton>
                  </ListItem>
                ))
              }
            </Paper>
            { /* AREA UNDER RESULTS */ }
            <div style={{ display: 'flex' }}>
              <CircularProgress
                size={ 20 }
                sx={{
                    mr: 1,
                    visibility: showCircularProgress ? 'visible' : 'hidden'
                }}
                />

                { options.length >= 1 ?
                  <p style={{
                      padding: 0,
                      margin: 0,
                      fontStyle: 'italic'
                    }}>
                    { options.length } found
                  </p>
                  :
                  null
                }
            </div>
          </motion.div>
          <div style={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 10
          }}>
          { displayCountButton ?
              <Button
                sx={{ mt: 2, borderRadius: 1 }}
                size="small"
                variant="contained">
                { options.length } { entityName }s
              </Button>
            :
            null
          }
          </div>
          { displaySaveButton ?
            <Button
              disabled={ submitDisabled }
              sx={{ mt: 2, width: '100%' }}
              onClick={ handleSubmit }
              variant="contained">
              Save
            </Button>
            : null
          }
        </motion.div>
      </Box>
    </Modal>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  transform: 'translate(-50%, -50%)',
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  borderRadius: 2,
  outline: 0
}

const MyCustomInput = props => {
  return (
    <TextField
      { ...props }
      size="small"
      sx={{ width: '100%', mt: 0, mb: 1 }}
      className="contact-field" id="filled-basic" />
  )
}

const UPDATE_CONTACT = gql`
mutation UpdateContactMutation($contact: UpdateContactInput!) {
  updateContact(contact: $contact) {
    message
    code
    contact {
      id
      customerId
      name
      email
      phone
    }
  }
}
`
const UPDATE_EQUIPMENT = gql`
mutation UpdateEquipmentMutation($equipment: UpdateEquipmentInput!) {
  updateEquipment(equipment: $equipment) {
    message
    code
    equipment {
      id
      drivers {
        id
        primary
        name
        email
        phoneNumber
      }
      axels
      color
      description
      make
      model
    }
  }
}
`
const UPDATE_ITEM = gql`
mutation UpdateItemMutation($item: UpdateItemInput!) {
  updateItem(item: $item) {
    message
    code
    item {
      id
      baseRate
      description
      height
      length
      manufacturer
      model
      weight
      width
    }
  }
}
`
