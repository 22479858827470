import { createSlice } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'

const driversSlice = createSlice({
  name: 'drivers',
  initialState: {
    loading: false,
    data: {}
  },
  reducers: {
    setDrivers(state, action) {
      const { driversMap } = action.payload
      state.data = {
        ...state.data,
        ...driversMap
      }
    }
  }
})

export const useDriver = driverId => {
  return null
}

export const { setDrivers } = driversSlice.actions
export default driversSlice.reducer
