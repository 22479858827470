import React, { useEffect, useState, useRef } from 'react'

import _ from 'lodash'
import { getAuth } from "firebase/auth";
import { orderBy, limit, addDoc, collection, doc, query, getDocs, onSnapshot, setDoc, updateDoc, where } from "firebase/firestore";
import { gql, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import moment from 'moment'

import DriverModal from '../components/DriverModal'

import { ViewTripIcon } from '../icons'
import { useDatabase } from '../hooks'
import { useMember } from '../redux/appSlice'

export default function Messages() {
  const userId = getAuth().currentUser.uid
  
  const [ createChannel ] = useMutation(CREATE_CHANNEL_MUTATION)

  const [ channels, setChannels ]                   = useState([])
  const [ filteredMemberIds, setFilteredMemberIds ] = useState([])
  const [ channelMemberIds, setChannelMemberIds ]   = useState([])
  const [ selectedChannelId, setSelectedChannelId ] = useState(null)
  const [ selectedChannel, setSelectedChannel ]     = useState(null)
  const [ content, setContent ]                     = useState([])
  const [ driverModalOpen, setDriverModalOpen ]     = useState(false)
  const [ driverModalDriver, setDriverModalDriver ] = useState(null)

  const firestoreDb = useDatabase()

  //Effect to listen for changes to the users channels...
  useEffect(() => {
    const channelsQueryRef =
      collection(firestoreDb, "channels")

    const q =
      query(channelsQueryRef,
        where("members", "array-contains", userId),
        orderBy('lastMessage.timestamp', 'desc')
      )

    const unsub = onSnapshot(q, snapshot => {
      const channels = snapshot.docs.map(snapshot => ({
        id: snapshot.id,
        ...snapshot.data()
      }))
      setChannels(channels)
    })

    return () => {
      unsub()
    }

  }, [])

  useEffect(() => {
    const uniqChannelMembers = channels.reduce((ids, channel) => {
      const { members={} } = channel
      return _.uniq([ ...ids, ...(_.keys(members))])
    }, [])
    setFilteredMemberIds(uniqChannelMembers)
  }, [ channels ])

  const handleCreateChannel = (otherUserId) => {
    if ( _.isEmpty( otherUserId ) ) {
      return;
    }

    //If successful, listener will automatically populate
    //the channels map with the new channel...
    createChannel({
      variables: {
        otherUserId
      }
    })
  }

  //Effect to get messages for the selected channel...
  useEffect(() => {
    if ( _.isEmpty( selectedChannelId ) ) {
      return;
    }

    //Display the messages
    const messagesRef =
      query(
        collection(
          firestoreDb,
          "channels", selectedChannelId, "messages"),
          orderBy('timestamp'), limit(50)
      )
      
    const unsub = onSnapshot(messagesRef, snapshot => {

      const results = snapshot.docs

      const content       = []
      let lastDateDivider = null

      results.forEach( snapshot => {

        const message = {
          id: snapshot.id,
          ...snapshot.data()
        }

        const formattedDate = moment(message.timestamp * 1000).format("DD/MM/YYYY")

        //See if we need a divider...
        if ( _.isNil( lastDateDivider ) || lastDateDivider !== formattedDate ) {
          lastDateDivider = formattedDate
          content.push({
            type: 'divider',
            timestamp: message.timestamp
          })
        }

        content.push({
          type: 'message',
          message
        })

      })

      setContent(content)
    })

    return () => {
      unsub()
    }

  }, [ selectedChannelId ])

  useEffect(() => {
    if ( selectedChannelId ) {
      const selectedChannel = channels.find(c => c.id === selectedChannelId )
      setSelectedChannel(selectedChannel)
    } else {
      setSelectedChannel(null)
    }
  }, [ channels, selectedChannelId ])

  const handleSelectDriver = driver => {
  }

  const handleDriverModalClose = driver => {
    if ( driver ) {
      const { id } = driver
      if ( id ) {
        handleCreateChannel(id)
      }
    }
    setDriverModalOpen(false)
    setDriverModalDriver(null)
  }

  return (
    <Grid container sx={{ padding: 0, marginTop: 5, overflow: 'scroll' }}>
      <DriverModal
        existingDriver={ driverModalDriver }
        handleClose={ handleDriverModalClose }
        filteredIds={ filteredMemberIds }
        open={ driverModalOpen }
        handleSelectContact={ handleSelectDriver }
      />
      <Grid
        item xs={ 3 }
        sx={{ padding: '30px !important', borderRight: 1, borderColor: 'divider' }}
        style={{ height: '' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h2>Chats</h2>
          <IconButton onClick={ () => setDriverModalOpen(true) }>
            <AddIcon />
          </IconButton>
        </div>
        <Autocomplete
          id="xxx-free-solo-demo"
          freeSolo
          options={[]}
          renderInput={(params) => <TextField {...params}
          label="" />}
        />
        { channels.map( channel => {
          console.log({ channel })

          return (
            <div
              className="channel-hover"
              style={{
                  ...( selectedChannelId === channel.id ? { backgroundColor: 'rgb(225, 244, 225)' } : {}),
                  marginTop: 5,
                  borderRadius: 10,
                  padding: 5,
                  paddingLeft: 10
              }}
              onClick={ () => setSelectedChannelId(channel.id) }>
              <div style={{
                  padding: '5px 0',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                className="noselect">
                <Channel channel={ channel } />
              </div>
              <p style={{
                  fontSize: 16,
                  fontWeight: 200
                }}
                className="noselect">
                { channel.lastMessage.text }
              </p>
              <hr />
            </div>
          )
        }) }
      </Grid>
      <MessagePannel
        selectedChannelId={ selectedChannelId }
        setDriverModalDriver={ setDriverModalDriver }
        setDriverModalOpen={ setDriverModalOpen }
        channel={ selectedChannel }
        content={ content }/>
    </Grid>
  )
}

const MessagePannel = props => {
  const {
    setDriverModalOpen,
    setDriverModalDriver,
    selectedChannelId,
    channel,
    content
  } = props

  const firestoreDb = useDatabase()
  const userId      = getAuth().currentUser.uid
  const otherUserId = pickOtherMemberId(channel?.members) 

  const { memberOption, member } = useMember(otherUserId)
  console.log({ otherUserId, member, memberOption })

  const [ messageText, setMessageText ]   = useState("")
  const [ showMessages, setShowMessages ] = useState(false)

  const handleDriverChipClick = () => {
    if ( member ) {
      setDriverModalDriver(member)
      setDriverModalOpen(true)
    }
  }

  const showMessagesInitialRef            = useRef()
  const divRef                            = useRef()

  //Effect to show the messages after scroll to bottom...
  useEffect(() => {
    divRef.current.scrollTo(0, divRef.current.scrollHeight)
    showMessagesInitialRef.current = true
  }, [ content ])

  const sendMessage = () => {
    if ( _.isEmpty( messageText ) || _.isEmpty(selectedChannelId) ) {
      return;
    }

    const channelRef = doc(collection(firestoreDb, "channels"), selectedChannelId)
    const messageRef = doc(collection(firestoreDb, "channels", selectedChannelId, "messages"))

    updateDoc(channelRef, {
      lastMessage: {
        text: messageText,
        timestamp: moment().unix()
      }
    })

    setDoc(messageRef, {
      text: messageText,
      timestamp: moment().unix(),
      from: userId
    })

    setMessageText("")
  }


  return (
    <Grid item xs={ 9 } sx={{ }} >
        <div style={{
          borderBottom: '0.5px solid #D5D5D5',
          position: 'sticky',
          padding: 20,
          marginTop: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}>
          <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
            { memberOption?.label ?
              <>
                <Avatar
                  src={ member?.avatarUrl }
                  onClick={ handleDriverChipClick }
                />
                <Typography sx={{ ml: 2 }}>
                  { memberOption?.label }
                </Typography>
              </>
              :
              null
            }
          </Box>
          <div style={{ visibility: 'hidden' }}>
            <ViewTripIcon />
          </div>
        </div>
      <div style={{
        display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 220px)',
          overflowY: 'scroll',
          justifyContent: 'space-between'
      }} ref={ divRef }>
        <div style={{
            flexGrow: 1,
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end' }}>
          { content.map( content => {

            if ( content.type === 'message' ) {
              const { message } = content

              return (
                <div style={{
                    alignSelf: message.from === userId ? 'end' : 'start',
                    maxWidth: '300px',
                    visibility: showMessages,
                    marginBottom: 20,
                    display: 'flex',
                    alignItems: 'end',
                    flexDirection: 'column',
                    borderRadius: 5,
                    backgroundColor: message.from === userId ? '#E1F4E1' : '#EAEFF6',
                    padding: 10
                }}>
                  <p style={{ margin: 0, padding: 0 }}>{ message.text }</p>
                  <p style={{ margin: 0, padding: 0, color: '#9A9A9A', fontSize: 12, alignSelf: 'right' }}>
                    { moment(message.timestamp * 1000).format("hh:mm a") }
                  </p>
                </div>
              )
            }

            const dividerDate = moment(content.timestamp * 1000)

            const dividerDateIsToday =
              dividerDate.clone().startOf('day')
              .isSame(moment().startOf('day'))

            return (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 40, }}>
                <Divider sx={{ flex: 1 }}/>
                <p style={{ color: '#9A9A9A', margin: 20 }}>
                  { dividerDateIsToday ? 'Today' : dividerDate.format("ddd LL") }
                </p>
                <Divider sx={{ flex: 1 }}/>
              </div>
            )
          }) }
        </div>
      </div>
        <div style={{ padding: 20, width: '100%', display: 'flex', alignItems: 'center' }}>
          <TextField
            value={ messageText }
            size="small"
            onKeyDown={ e => {
              if ( e.key === 'Enter' ) {
                sendMessage()
              }
            }}
            onChange={ e => setMessageText(e.target.value) }
            style={{ width: '100%', padding: '2px !important' }}
            sx={{ mr: 1 }} />
          <div>
            <IconButton onClick={ sendMessage } color="primary">
              <SendIcon />
            </IconButton>
          </div>
        </div>
    </Grid>
  )
}

const Channel = ({ channel }) => {

  const { member, memberOption } = useMember(pickOtherMemberId(channel.members))
  console.log({ member, memberOption })

  return (
    <Typography
        sx={{ mt: 1 }}
        style={{
        fontSize: 16,
        fontWeight: 400,
        paddingBottom: 0
      }}>
      { memberOption?.label }
    </Typography>
  )
}

function pickOtherMemberId(members=[]) {
  const userId  = getAuth().currentUser.uid
  const otherId = members.filter(id => id != userId)[ 0 ]
  console.log({ userId, otherId })
  return otherId
}

const CREATE_CHANNEL_MUTATION = gql`
mutation CreateChannelMutation($otherUserId: String!) {
  createChannel(otherUserId: $otherUserId) {
    message
    code
  }
}
`
