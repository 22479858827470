import React, { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Link } from "react-router-dom";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as HomeIcon } from '../assets/simple-dispatch-logo.svg'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';

import { DRAWER_WIDTH } from '../utils'

const SideDrawer = React.forwardRef(({ toggleDrawer, window, headerLabel }, ref ) => {

  const navigate  = useNavigate()
  const [ mobileOpen, setMobileOpen ] = React.useState(false);
  const handleDrawerToggle            = () => setMobileOpen(!mobileOpen)

  const handleSignOut = () => {
    const auth = getAuth()
    signOut(auth)
    navigate('/')
  }

  const drawer = (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
          overflow: 'visible',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
      }}>
      <div>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: 20, height: 20 }}>
            <HomeIcon />
          </Box>
        </Toolbar>
        <List>
          { menuItems.map((item, index) => (
            <Link to={ item.linkTo }>
              <ListItem key={ index } disablePadding>
                  <Tooltip title={ item.label } placement="right-end">
                    <ListItemButton sx={{ display: 'flex', alignItems: 'center', paddintTop: 2, paddingBottom: 2 }}>
                      <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                        { <item.Icon
                            sx={{ ...(
                                item.label.toLowerCase().replace(' ','').includes(headerLabel.toLowerCase().replace(' ', '')) ?
                                { color: 'green' } : {}) }}
                          />
                        }
                      </ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
              </ListItem>
            </Link>
          )) }
        </List>
        <Divider />
      </div>
      <List>
        <ListItem disablePadding>
          <ListItemButton
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0.7,
                  paddintTop: 1,
                  paddingBottom: 1 }}
              onClick={ null}>
            <LogoutOutlinedIcon onClick={ handleSignOut }/>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>

  )

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
})

const menuItems = [
  { Icon: SpeedOutlinedIcon, label: 'Dashboard', linkTo: '/' },
  { Icon: ContactMailOutlinedIcon, label: 'Customers', linkTo: '/customers' },
  { Icon: CallOutlinedIcon, label: 'Members', linkTo: '/members' },
  { Icon: LocalShippingOutlinedIcon, label: 'Equipment', linkTo: '/equipment' },
  { Icon: Inventory2OutlinedIcon, label: 'Cargo', linkTo: '/cargo' },
  { Icon: ChatOutlinedIcon, label: 'Messages', linkTo: '/messages' },
  /*{ Icon: <ProfileIcon />, label: 'Profile', linkTo: '/profile' },*/
  /* { Icon: <SettingsOutlinedIcon />, label: 'Settings', linkTo: '/settings' }, */
  { Icon: SettingsOutlinedIcon, label: 'Settings', linkTo: '/settings' }
]

export default SideDrawer
