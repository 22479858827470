import React, { useRef } from 'react'
import { GoogleMap, MarkerF, DirectionsService, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';

export default function (props) {

  const {
    zoom=2,
    center,
    showMarker=false,
    directionsResponse,
    travelMode
  } = props

  const [ map, setMap ] = React.useState(null)
  //const [ directionsResponse, setDirectionsResponse ] = React.useState(null)

  const elementRef = useRef()
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(DENVER_CENTER);
    //map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const directionsCallback = response => {

    if (response !== null) {
      if (response.status === 'OK') {
        //setDirectionsResponse(response)
      } else {
        //console.log('response: ', response)
      }
    }
  }

  const [ mapCenter ] = React.useState(center ? center : DENVER_CENTER)

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={ MAP_DEFAULT_OPTIONS }
      center={mapCenter}
      //center={center}
      zoom={zoom}
      //onLoad={onLoad}
      onUnmount={onUnmount}>
      { showMarker && <MarkerF position={mapCenter} /> }
      {
        directionsResponse !== null && (
          <DirectionsRenderer
            options={{
              directions: directionsResponse
            }}
          />
      ) }
    </GoogleMap>
  )
}

const containerStyle = {
  width: '100%',
  height: '100%',
}

//const CENTER = { lat: 39.113014, lng: 105.358887}
//const CENTER = { lat: 39.7392, lng: 104.9903 }
//const SEATTLE_CENTER = { lat: 47.606209, lng: -122.332069 }
const DENVER_CENTER = { lat: 39.739235, lng: -104.990250 }

const MAP_STYLES = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#add1d9"
            },
            {
                "visibility": "on"
            }
        ]
    }
]

const DEFAULT_MIN_ZOOM = 8
const MAP_DEFAULT_OPTIONS = {
    overviewMapControl: false,
    streetViewControl: false,
    rotateControl: true,
    mapTypeControl: false,
    // disable poi
    styles: MAP_STYLES,
    minZoom: DEFAULT_MIN_ZOOM, // dynamically recalculted if possible during init
}

