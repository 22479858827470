import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDK-0jw3J1WTOJZ_LC6Xj91UJkz3qjFD9s",
  authDomain: "simple-dispatch-app.firebaseapp.com",
  projectId: "simple-dispatch-app",
  storageBucket: "simple-dispatch-app.appspot.com",
  messagingSenderId: "96881661687",
  appId: "1:96881661687:web:eb7128019799c85a4125f2",
  measurementId: "G-TZXW8KV38M"
}

// Initialize Firebase
export const app      = initializeApp(firebaseConfig);
export const db       = getFirestore(app)
export const storage  = getStorage(app)
