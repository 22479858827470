import { createTheme } from '@mui/material/styles';
import { brown, orange } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3A963A'
    },
    secondary: {
      main: '#ccc'
    },
    pickup: {
      main: '#298EFF'
    },
    dropoff: {
      main: '#3A963A'
    },
    alert: {
      main: orange[ 600 ]
    }
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: -15
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          boxShadow: 'none'
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          //borderRadius: 0,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 30,
          paddingRight: 30
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '2px 5px !important',
        }
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingLeft: '0 !important'
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#202020',
              color: '#fff',
            }),
          //borderRadius: 0,
          backgroundColor: '#fff',
          paddingLeft: '0 !important',
        }),
        input: {
          height: '1.9em',
          //borderRadius: '0 !important',
          //padding: '10px 20px'
        }
      },
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  }
})
