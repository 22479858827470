import _ from 'lodash'
import { useCallback } from 'react'
import { useCompanyId, useGroupId, useDatabase } from '../hooks'
import { collection, limit, getDocs, orderBy, query } from "firebase/firestore";
import { gql, useLazyQuery, } from '@apollo/client';
import BusinessIcon from '@mui/icons-material/Business';

import { CustomAutocomplete } from '../components/RouteStopInputBase'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../redux/dashboardSlice'
import * as Utils from '../utils'

export const EquipmentAutocomplete = props => {
  const {
    onValueChange,
    equipmentOption,
    loading,
    textFieldProps={}
  } = props

  const companyId = useCompanyId()
  const groupId   = useGroupId()
  const db        = useDatabase()

  const equipment = useSelector(state => state.app.equipment)

  const fetchInitialOptions = useCallback(async () => {
    return Utils.toArray(equipment).map(e => ({
      value: e.id,
      label: e.description,
      lastUsedTime: e.lastUsedTime
    }))
  }, [ equipment ])

  const fetchAutocompleteOptions = useCallback(async ({ text }) => {
    if ( _.isEmpty( text ) ) {
      return []
    }

    const textUpper = text.toUpperCase()

    return (
      Utils.toArray(equipment)
      .filter(e => {
        const matches =
          e.description.toUpperCase().includes(textUpper) ||
          ( e.make ? e.make.toUpperCase().includes(textUpper) : false ) ||
          ( e.model ? e.model.toUpperCase().includes(textUpper) : false )
        return matches
      })
      .map(equipment => ({
        value: equipment.id,
        label: equipment.description,
        lastUsedTime: equipment.lastUsedTime
      }))
    )
  }, [ equipment ])

  return (
    <CustomAutocomplete
      searchField="searchTerm"
      textFieldProps={ textFieldProps }
      Icon={ BusinessIcon }
      fetchInitialOptions={ fetchInitialOptions }
      fetchAutocompleteOptions={ fetchAutocompleteOptions }
      dbPath={ `companies/${ companyId }/equipment` }
      noOptionsText="No equipment to display"
      optionKey="description"
      value={ equipmentOption }
      loadingValue={ loading }
      onValueChange={ onValueChange } />
  )
}

export const CustomerAutocomplete = props => {
  const {
    onValueChange,
    customerOption,
    loading,
    textFieldProps={}
  } = props

  const companyId = useCompanyId()
  const groupId   = useGroupId()
  const db        = useDatabase()
  const dispatch  = useDispatch()

  const [getAutocompleteCustomers] = useLazyQuery(GET_AUTOCOMPLETE_CUSTOMERS_QUERY)
  const [getRecentlyUsedCustomers] = useLazyQuery(GET_RECENTLY_USED_CUSTOMERS_QUERY)

  const onValueChangeWrapper = option => {
    dispatch(actions.setAutocompleteCustomer(option?.value))
    onValueChange(option)
  }

  const fetchAutocompleteOptions = useCallback(async ({ text }) => {
    if ( _.isEmpty( text ) ) {
      return []
    }

    return getAutocompleteCustomers({
      variables: { text }
    })
    .then( response => {

      const { data } = response

      if ( data ) {
        const { getAutocompleteCustomers: customers } = data

        dispatch(actions.setAutocompleteCustomers(customers))

        const options = customers ? customers.map( c => ({
          value: c.id,
          label: c.name,
          timestamp: c.lastUsedTime
        })) : []

        return options
      }

    })
  }, [])

  const fetchInitialOptions = useCallback(async () => {

    return getRecentlyUsedCustomers()
    .then( response => {

      const { data } = response

      if ( data ) {
        const { getRecentlyUsedCustomers: customers } = data

        dispatch(actions.setAutocompleteCustomers(customers))

        const options = customers ? customers.map( c => ({
          value: c.id,
          label: c.name,
          timestamp: c.lastUsedTime
        })) : []

        return options
      }

    })

    /*
     * OPTIONS FORMAT
     * return {
     *   value: snapshot.id,
     *   label: customer.name,
     *   timestamp: customer[ groupUsageKey ]
     * }
     */

  }, [ getRecentlyUsedCustomers ])

  return (
    <CustomAutocomplete
      searchField="searchTerm"
      textFieldProps={ textFieldProps }
      Icon={ BusinessIcon }
      fetchInitialOptions={ fetchInitialOptions }
      fetchAutocompleteOptions={ fetchAutocompleteOptions }
      noOptionsText="No customers to display"
      optionKey="name"
      value={ customerOption }
      loadingValue={ loading }
      onValueChange={ onValueChangeWrapper } />
  )
}

const GET_AUTOCOMPLETE_CUSTOMERS_QUERY = gql`
query GetAutocompleteCustomersQuery($text: String!) {
  getAutocompleteCustomers(text: $text) {
    id
    lastUsedTime
    name
    phone
    billingEmail
    addressHistory {
      placeId
      description
      textShort
      lastUsedTime
      structured_formatting {
        main_text
        secondary_text
      }
    }
  }
}
`
const GET_RECENTLY_USED_CUSTOMERS_QUERY = gql`
query GetRecentlyUsedCustomers {
  getRecentlyUsedCustomers {
    id
    lastUsedTime
    createdTime
    name
    phone
    billingEmail
    addressHistory {
      placeId
      description
      textShort
      lastUsedTime
      structured_formatting {
        main_text
        secondary_text
      }
    }
  }
}
`
