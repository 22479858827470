import Grid from '@mui/material/Grid';

export default function OrderInputContainer({ elementRef, children, ...props }) {
  return (
    <Grid ref={ elementRef }
      container
      sx={{ margin: 2, mb: 3, alignItems: 'center' }}
      { ...props }
      rowSpacing="2">
      { children }
    </Grid>
  )
}
