import { getAuth } from "firebase/auth"
import { gql } from '@apollo/client';
import { storage } from '../config'
import { getStorage, uploadBytes, ref } from "firebase/storage";

export const getIdToken = async () => {
  return getAuth().currentUser.getIdToken()
}

export const handleFileUpload = (file, location) => {
  const parts = file.name.split(".")

  if ( parts.length < 2 ) {
    return;
  }

  const extension = parts[ parts.length -1 ]

  const reader        = new FileReader();
  const fileByteArray = []

  reader.readAsArrayBuffer(file)
  reader.onloadend = function (evt) {

    if (evt.target.readyState == FileReader.DONE) {

       const arrayBuffer  = evt.target.result;
       const array        = new Uint8Array(arrayBuffer);

       for (var i = 0; i < array.length; i++) {
         fileByteArray.push(array[i]);
      }

      //Now we can upload
      //const bytes       = new Uint8Array(file.readAsArrayBuffer())
      const metadata = { contentType: file.type }

      const storageRef  = ref(storage, `${ location }.${ extension}`)
      uploadBytes(storageRef, array, metadata).then((snapshot) => {
        //File upload done...
        console.log("Successfully uploaded file")
      })
    }
  }
}


export async function generateReport(refDate) {

  const reportEndpoint = process.env.REACT_APP_BACK_END_URI + '/download'

  const token     = await getIdToken()
  const companyId = localStorage.getItem("companyId")
  const groupId   = localStorage.getItem("groupId")

  fetch(reportEndpoint + `?refDate=${ refDate }`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Company-Id': companyId,
      'Group-Id': groupId,
      'Content-Type': 'text/plain',
    },
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `report.csv`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });

}

export async function getQuote(orderId) {

  const endpoint  = process.env.REACT_APP_BACK_END_URI + '/quote'
  const token     = await getIdToken()
  const companyId = localStorage.getItem("companyId")
  const groupId   = localStorage.getItem("groupId")

  return fetch(endpoint + `?orderId=${ orderId }`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Company-Id': companyId,
      'Group-Id': groupId,
      'Content-Type': 'text/html',
    },
  })
  .then((response) => response.json())
}

export const getDistance = async (stops) => {

  const token = await getIdToken()

  const routeEndpoint = process.env.REACT_APP_BACK_END_URI + '/route'
  return fetch(routeEndpoint, {
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({ stops })
  })
  .then( response => response.json())
}

export async function connectToQuickbooks(groupId) {
  const authEndpoint =
    process.env.REACT_APP_BACK_END_URI + '/connectQuickbooks'

  const token     = await getIdToken()
  const companyId = localStorage.getItem("companyId")

  return fetch(authEndpoint, {
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + token,
      'Company-Id': companyId,
      'Group-Id': groupId,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
}

export async function sendAuthCode(code, realmId, groupId) {

  const confirmationEndpoint =
    process.env.REACT_APP_BACK_END_URI +
      `/confirmConnectQuickbooks?code=${ code }&realmId=${ realmId }`

  const token     = await getIdToken()
  const companyId = localStorage.getItem("companyId")

  return fetch(confirmationEndpoint, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Company-Id': companyId,
      'Group-Id': groupId,
      'Accept': 'application/json'
    }
  })
}

export const GET_ORDERS_QUERY = gql`
query GetOrdersQuery($query: OrderQuery!) {
  getOrders(query: $query) {
    id
    bolUpload {
      timestamp
      location
    }
    lastUpdatedTime
    invoice {
      id
      pdfUrl
      lineItems {
        id
        description
        amount
        quantity
        metadata {
          tripId
        }
      }
    }
    groupId
    createdTime
    customer {
      id name phone billingEmail lastUsedTime
    }
    dispatcher {
      id name phone email profileThumb
    }
    items {
      id
      description
      weight
      quantity
      manufacturer
      model
      records {
        id
        values {
          key
          value
        }
      }
      tags {
        id
      }
    }
    status
    trips {
      id
      completed
      deliveryStatus {
        timestamp type durationMinutes notes
      }
      dispatcher {
        id name phone email profileThumb
      }
      equipment {
        id
        createdTime
        primaryDriverId
        axels
        color
        description
        make
        model
      }
      destination
      dispatched
      dispatchedTime
      startTime
      endTime
      maxGrossWeight
      order
      origin
      stops {
        id
        deliveryStatus {
          timestamp type durationMinutes notes
        }
        locationMarker
        duration
        distanceMeters
        note
        isComplete
        contact {
          id name phone email
        }
        order
        time
        timeChoice
        address {
          description
          placeId
          textShort
        }
        instructions {
          id
          order
          type
          itemId
          quantity
        }
      }
    }
  }
}
`
export const GET_ORDER_QUERY = gql`
query GetOrderQuery($orderId: String!) {
  getOrder(orderId: $orderId) {
    bolUpload {
      timestamp
      location
    }
    id
    lastUpdatedTime
    invoice {
      id
      pdfUrl
      lineItems {
        id
        description
        amount
        quantity
        metadata {
          tripId
        }
      }
    }
    groupId
    createdTime
    customer {
      id name phone billingEmail lastUsedTime
    }
    dispatcher {
      id name phone email profileThumb
    }
    items {
      id
      description
      weight
      quantity
      manufacturer
      model
      records {
        id
        values {
          key
          value
        }
      }
      tags {
        id
      }
    }
    status
    trips {
      id
      completed
      deliveryStatus {
        timestamp type durationMinutes notes
      }
      dispatcher {
        id name phone email profileThumb
      }
      equipment {
        id
        createdTime
        primaryDriverId
        axels
        color
        description
        make
        model
      }
      destination
      dispatched
      dispatchedTime
      startTime
      endTime
      maxGrossWeight
      order
      origin
      stops {
        id
        deliveryStatus {
          timestamp type durationMinutes notes
        }
        locationMarker
        duration
        distanceMeters
        note
        isComplete
        contact {
          id name phone email
        }
        order
        time
        timeChoice
        address {
          description
          placeId
          textShort
        }
        instructions {
          id
          order
          type
          itemId
          quantity
        }
      }
    }
  }
}
`
export const DELETE_CONTACTS_MUTATION = gql`
mutation Mutation($contactIds: [String!]) {
  deleteContacts(contactIds: $contactIds) {
    message
    code
  }
}
`
