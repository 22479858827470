import { useContext } from 'react'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { useSelector } from 'react-redux'

export default function SaveAndDispatchDialog(props) {

  const { orderId, requestedDispatch, dialogTitle, handleClose, handleConfirm } = props
  console.log({ orderId })

  const confirmMessage  = useSelector(state => state.dashboard.confirmationMessage)

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        { dialogTitle }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          { confirmMessage }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleConfirm} autoFocus>
          { requestedDispatch ? "Dispatch" : "Save" }
        </Button>
      </DialogActions>
    </>
  )
}

