import * as React from 'react';
import { useEffect, useState } from 'react';
import { collection, doc } from "firebase/firestore";
import _ from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Popper from '@mui/material/Popper';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ItemTags from './ItemTagSelector'

import { db } from '../config.js'

export default function TableInput(props) {
  const {
    max,
    records,
    tags,
    handleUpdateAdditionalItemData,
    open,
    handleClose
  } = props

  const [ recordsInternal, setDataInternal ] = useState([])
  const [ tagsInternal, setTagsInternal ]    = useState([])

  const handleTagsChanged = ( e, values ) => {
    setTagsInternal(values)
  }

  //Effect to keep records internal in-sync with records from props...
  useEffect(() => {
    setDataInternal(records)
  }, [ records ])

  //In the database - {
  //  additionalItemInfo: {
  //    item123: {
  //      tags: {
  //        "backhoe": {
  //        }
  //      }
  //
  useEffect(() => {

    //Tags by default are just text... Map the text into options
    const options = _.keys(tags).map(tagId => ({
      label: tagId,
      value: tagId,
      //Later will have quantity or other properties...
    }))

    setTagsInternal(options)

  }, [ open, tags ])

  useEffect(() => {
    if ( open && _.isEmpty(recordsInternal) ) {
      const newRecordId = generateNewRecordId()
      setDataInternal([{ id: newRecordId }])
      setTagsInternal([])
    }
  }, [ open, records ])

  const handleDataChange = ( rowIndex, change ) => {
    setDataInternal( records => {
      return records.map(( records, index ) => {
        if ( index !== rowIndex ) {
          return records
        } else {
          return {
            ...records,
            ...change
          }
        }
      })
    })
  }

  function generateNewRecordId() {
    const companyId = localStorage.getItem("companyId")

    return doc(collection(db, "companies", companyId, "orders")).id
  }

  const addRow = () => {
    if ( recordsInternal.length < max ) {
      const newRecordId = generateNewRecordId()
      setDataInternal([ ...recordsInternal, { id: newRecordId } ])
    }
  }

  const handleDoneClick = () => {
    handleClose(recordsInternal, tagsInternal)
  }

  const [ additionalFieldOptions, setAdditionalFieldOptions ] = useState([
    { label: 'S/N', key: 'serialNumber', checked: true },
    { label: 'Stock #', key: 'stockNumber', checked: true },
    { label: 'PO #', key: 'poNumber', checked: true }
  ])

  const toggleCheck = changed => {

    setAdditionalFieldOptions( () => {
      return additionalFieldOptions.map( option => {
        if ( option.key === changed.key ) {
          return {
            ...changed,
            checked: !changed.checked
          }
        } else {
          return option
        }
      })
    })
  }

  return (
    <AnimatePresence>
      { open && recordsInternal && recordsInternal.length > 0 ?
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}>
          <Box sx={{ height: 10 }}>
          </Box>
          <Paper
            sx={{ p: 1, }}>
            <TableContainer component="div" sx={{ p: 1 }}>
              <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Id
                    </TableCell>
                    { additionalFieldOptions.map(( option, index ) => (
                      <TableCell key={ index }>
                      { option.label }
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { recordsInternal ? recordsInternal.map((row, rowIndex ) => {
                    return (
                      <TableRow>
                        <TableCell>
                          { rowIndex + 1 }
                        </TableCell>

                        { additionalFieldOptions.map(( option, index ) => {
                          return (
                            <TableCell>
                              <TextField
                                value={ recordsInternal[ rowIndex ] ? recordsInternal[ rowIndex ][ option.key ] : '' }
                                onChange={ e => handleDataChange(rowIndex, { [ option.key ]: e.target.value }) }
                                autoComplete="off"
                                size="small"
                                variant="standard" />
                            </TableCell>
                          )
                        }) }
                      </TableRow>
                    )
                  }) : null }
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="text"
                disabled={ recordsInternal.length === max }
                onClick={ addRow }>
                Add row
              </Button>
            </div>
            <ItemTags
              tags={ tagsInternal }
              handleTagsChanged={ handleTagsChanged }/>
            {/*
            <FormGroup sx={{ display: 'flex', flexDirection: 'row', mt: 1, ml: 1 }}>
              { additionalFieldOptions.map(option => (
                <FormControlLabel control={
                  <Checkbox
                    defaultChecked
                    onChange={ () => toggleCheck(option) }
                    checked={ option.checked }
                  />}
                  label={option.label} />
              ))}
            </FormGroup>
            */}
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                onClick={ handleDoneClick }
                variant="contained" endIcon={<CheckIcon />}>Done</Button>
            </div>
          </Paper>
        </motion.div>
        :
        null
      }
    </AnimatePresence>
  )
}
