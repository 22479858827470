import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';

export default function LocationMarkerDialog(props) {

  const {
    open,
    stopIndex,
    handleClose,
    handleConfirm,
    onChange,
    value="",
  } = props

  const [ valuePrevious, setValuePrevious ] =  useState(value)
  const [ newValue, setNewValue ]           = useState(value)
  const textFieldRef = useRef()

  useEffect(() => {
    setValuePrevious(value)
    setNewValue(value)
  }, [ value, open ])

  return (
    <Dialog
      open={ open }
      TransitionComponent={ Transition }
      onClose={ handleClose }>
      <DialogTitle id="alert-dialog-title">
        Add Google Maps Pin for Stop { stopIndex + 1}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Add a marker so the driver can see exactly where they should pick up or dropoff the items at this stop.
        </DialogContentText>
        <TextField
          ref={ textFieldRef }
            autoFocus
            margin="dense"
            id="name"
            sx={{ mt: 4 }}
            label="Google Maps Marker URL"
            value={ newValue }
            onChange={ (e, value) => setNewValue(e.target.value) }
            type="url"
            fullWidth
            variant="standard"
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => handleClose(valuePrevious) }>Cancel</Button>
        <Button variant="contained" onClick={ () => handleConfirm(newValue) } autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})
