import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { OrderPageContext } from '../contexts'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MapComponent from '../components/MapComponent'
import Paper from '@mui/material/Paper';
import * as TripUtils from '../utils/TripUtils'

export default function FloatingMap() {

  const [ anchorEl, setAnchorEl ] = useState()
  const { tripMap } = useContext(OrderPageContext)

  const [ tripId, setTripId ]           = useState(null)
  const [ travelMode ]                  = useState('DRIVING')
  const [ origin, setOrigin ]           = useState(null)
  const [ destination, setDestination ] = useState(null)
  const [ directionsResponse, setDirectionsResponse ] = useState(null)

  const open  = Boolean(anchorEl)

  //Effect to set the trip for which the user can choose stops
  useEffect(() => {
    if ( _.keys(tripMap).length > 0 ) {
      setTripId(_.keys(tripMap)[0])
    }
  }, [ tripMap.length, tripMap ])

  //Effect to set the stop origin and destination to the first stop...
  useEffect(() => {
    if ( tripId && tripMap[ tripId ]) {

      const trip = tripMap[ tripId ]

      const [ stop1, stop2 ] = TripUtils.sortStops(trip.stops)
      if ( stop1.address && stop2.address ) {
        //Have origin and destination...
        setOrigin(stop1.address.description)
        setDestination(stop2.address.description)
      }
    }
  }, [ tripId, tripMap ])

  useEffect(() => {
    if ( open && origin && destination && window.google ) {
      const { google } = window
      const DirectionsService = new google.maps.DirectionsService()

      DirectionsService.route({
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(response);
        } else {
          console.error(`error fetching directions ${response}`);
        }
      })
    }
  }, [ open, origin, destination ])

  const handleToggleOpen = e => {
    setAnchorEl(open ? null : e.currentTarget)
  }

  return (
    <>
      <Box sx={{
          position: 'fixed',
          zIndex: 2000,
          top: '90vh',
          right: 20
        }}>
        <Fab
          onClick={ handleToggleOpen }>
          <MapOutlinedIcon />
        </Fab>
        <Paper elevation={ 2 } sx={{
            position: 'absolute',
            backgroundColor: 'paper',
            visibility: anchorEl ? 'visible' : 'hidden',
            bottom: 60,
            right: 40,
            width: 350,
            height: 400,
        }}>
          <MapComponent
            zoom={ 2 }
            origin={ origin }
            destination={ destination }
            directionsResponse={ directionsResponse }
            travelMode={ travelMode }
          />
        </Paper>
      </Box>
    </>
  )
}
