import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash"
import { gql, useQuery, useLazyQuery, } from '@apollo/client';
import { getDownloadURL } from "firebase/storage";
import { useJsApiLoader } from '@react-google-maps/api';

import { styled, alpha } from '@mui/material/styles';
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import MapComponent from './components/MapComponent.js'

import { motion } from 'framer-motion'

import { useDispatch, useSelector } from 'react-redux'
import { DashboardContext, AppContext } from './contexts'
import { theme } from './theme'
import { storage, db } from './config.js'
import NestedList from './components/AppSidebar'
import SideDrawer from './components/SideDrawer'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { DRAWER_WIDTH } from './utils'
import { useDatabase } from './hooks'
import * as api from './utils/api'
import * as CorePages from './pages/Core'
import MessagesPage from './pages/ChatPage'
import DashboardPage from './pages/DashboardPage'
import LogoutPage from './pages/LogoutPage'
import LoginPage from './pages/LoginPage'
import OrderDetailsPage from './pages/OrderDetailsPage'
import QuickbooksConfirmationPage from './pages/QuickbooksConfirmationPage'
import PrivacyPage from './pages/PrivacyPage'
import { ref } from "firebase/storage";

import './App.css';
import { actions } from './redux/appSlice'

export default function App({ window }) {

  const dispatch  = useDispatch()
  const auth      = getAuth()

  const authenticated = useSelector(state => state.app.authenticated)
  const companyId     = useSelector(state => state.app.companyId)

  const { data } = useQuery(GET_COMPANY_ID_QUERY, {
    fetchPolicy: 'network-only'
  })

  //Effect to check the company id on load
  //and set it in the redux store...
  useEffect(() => {
    const companyId = localStorage.getItem("companyId")
    if ( companyId ) {
      dispatch(actions.setCompanyId(companyId))
    }
  }, [])

  //Effect to set the company id...
  useEffect(() => {
    if ( data ) {

      const companyId = _.defaultTo(data.getCompanyId, null)
      //Set the company id in local storage...
      //and then set the companyId in redux...
      localStorage.setItem("companyId", companyId)
      dispatch(actions.setCompanyId(companyId))
    }
  }, [ data ])

  useEffect(() => {

    //Listen for changes to the token...
    const unsub = getAuth().onIdTokenChanged(user => {
      if ( user ) {
        user.getIdToken().then(token => {
          dispatch(actions.setToken(token))
          localStorage.setItem("token", token)
        })
      } else {
        localStorage.setItem("token", null)
      }
    })

    onAuthStateChanged(auth, (user) => {

      if (user) {
        //TODO: Call getCompanies API + getGroups API to
        //set companyId and groupId for all requests...
        //
        //IF process.env.companyId is set, we automatically know the
        //company and don't need to call getCompanies API.
        dispatch(actions.setAuthenticated(true))
      } else {
        // User is signed out
        dispatch(actions.setAuthenticated(false))
        dispatch(actions.setUser(null))
        localStorage.removeItem("token", null)
        dispatch(actions.setInitialized(false))
      }
    })

    return () => {
      unsub()
    }

  }, [])

  //Prevent jump when waiting to understand whether
  //the user is already logged in...
  if ( _.isNil( authenticated ) || !companyId ) {
    console.log({ authenticated, companyId })
    return null
  }

  console.log({ authenticated, companyId })

  return (
    <ThemeProvider theme={ theme }>
      <BrowserRouter>
        <Routes>
          <Route path="/privacy"
            element={<PrivacyPage />}>
          </Route>
          { authenticated ?
            <>
              <Route path="/confirmQuickbooks"
                element={<QuickbooksConfirmationPage/>}>
              </Route>
              <Route path="/" element={<AuthorizedLayout window={ window } />}>
                <Route index element={<DashboardPage />}>
                </Route>
                <Route path="orders" element={<DashboardPage/>}>
                  <Route path=":orderId" element={<OrderDetailsPage/>}>
                  </Route>
                </Route>
                <Route path="members" element={<CorePages.DriverPage />}>
                </Route>
                <Route path="customers" element={<CorePages.CustomerPage />}>
                </Route>
                <Route path="equipment" element={<CorePages.EquipmentPage />}>
                </Route>
                <Route path="cargo" element={<CorePages.ItemPage />}>
                </Route>
                <Route path="messages" element={<MessagesPage />}>
                </Route>
                <Route path="profile" element={<CorePages.DriverPage />}>
                </Route>
                <Route path="learning" element={<LearningPage />}>
                </Route>
                <Route path="documents" element={<LearningPage />}>
                </Route>
                <Route path="metrics" element={<LearningPage />}>
                </Route>
                <Route path="settings" element={<LogoutPage />}>
                </Route>
              </Route>
            </>
            :
            <Route path="/" element={<LoginPage />} />
          }
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

const withInitializedApp = Component => {

  return props => {

    const { isLoaded: googleMapsLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      //preventGoogleFontsLoading: true,
      libraries
    })

    const apiRetryCountRef = useRef(0)

    const dispatch                = useDispatch()
    const appInitialized          = useSelector(state => state.app.initialized)
    const finishedPreloadingFonts = useSelector(state => state.app.finishedPreloadingFonts)
    const groupId                 = useSelector(state => state.app.groupId)
    const companyId               = useSelector(state => state.app.companyId)
    const {
      data: groupsData,
      error,
      refetch: refetchGetGroups
    } = useQuery(GET_GROUPS_QUERY)

    useEffect(() => {
      if ( error && apiRetryCountRef.current === 0 ) {
        apiRetryCountRef.current += 1
        //Wait one second and rety the initialization...
        setTimeout(() => {
          refetchGetGroups()
        }, 1000)
      }
    }, [ error ])

    //Effect to set the selected group...
    useEffect(() => {

      let group = null

      if ( groupsData ) {
        const { getGroups: groups } = groupsData
        group = groups[ 0 ]
      }

      if ( group ) {
        dispatch(actions.setGroupId(group.id))
        localStorage.setItem("groupId", group.id)
      } else {
        dispatch(actions.setGroupId(null))
        localStorage.setItem("groupId", null)
      }
    }, [ groupsData ])

    //Effect to switch the initialization status
    //based on groupId and companyId...
    useEffect(() => {
      if ( groupId && companyId ) {
        dispatch(actions.setInitialized(true))
      } else {
        dispatch(actions.setInitialized(false))
      }
    }, [ groupId, companyId ])

    //Effect to load the map component/Google Fonts
    //to prevent page jump...
    useEffect(() => {
      if ( appInitialized ) {
        setTimeout(() => {
          dispatch(actions.setFinishedPreloadingGoogleFonts(true))
        }, 600)
      }
    }, [ appInitialized ])

    if ( !appInitialized || !googleMapsLoaded ) {
      return (
        <Box sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center' }}>
            <Typography variant="button" sx={{ mb: 4 }}>
              Loading... Please wait
            </Typography>
          <CircularProgress size={30} />
        </Box>
      )
    }

    return (
      <>
        <Component { ...props }>
          { props.children }
        </Component>
        { !finishedPreloadingFonts ?
          <MapComponent />
          :
          null
        }
      </>
    )
  }
}


const AuthorizedLayout = withInitializedApp(({ window }) => {

  const location    = useLocation()
  const dispatch    = useDispatch()

  const [ headerLabel, setHeaderLabel ] = useState("")

  const drivers     = useSelector(state => state.app.drivers)
  const companyId   = useSelector(state => state.app.companyId) //null
  const groupId     = useSelector(state => state.app.groupId)
  const drawerOpen  = useSelector(state => state.app.drawerOpen)//false

  const { data: companyData } = useQuery(GET_COMPANY_QUERY, {
    fetchPolicy: 'network-only'
  })

  const { loading, error, data }  = useQuery(GET_MEMBERS_QUERY)
  const { data: equipmentData }   = useQuery(GET_EQUIPMENT, {
    fetchPolicy: 'network-only'
  })

  const elementRef = useRef()

  const [ animatedProps, setAnimatedProps ] =
    useState({ side: { animate: { flex: 0 }}, main: {}})

  //Effect to set the drivers from the fetch into redux...
  useEffect(() => {
    if ( data ) {
      const { getMembers: members } = data
      if (! _.isEmpty( members )) {

        (async () => {

          const mappedMembers =
            await Promise.all(members.map(async member => {

              return {
                ...member,
                avatarUrl: member.profileThumb ?
                  (await getDownloadURL(ref(storage, member.profileThumb))) : null
              }
            }))
          console.log({ mappedMembers })
          dispatch(actions.setMembers(mappedMembers))

        })()
      } else {
        dispatch(actions.setMembers([]))
      }
    }
  }, [ data ])

  //Effect to set the equipment from fetch into store...
  useEffect(() => {
    if ( equipmentData ) {
      const { getEquipment: equipment } = equipmentData
      dispatch(actions.setEquipment(equipment))
    }
  }, [ equipmentData ])

  //Effect to set the equipment from fetch into store...
  useEffect(() => {
    if ( companyData ) {
      const { company } = companyData
      console.log({ company })
      dispatch(actions.setCompany(company))
    }
  }, [ companyData ])

  useEffect(() => {
    if ( drawerOpen ) {

      //Close the drawer...
      setAnimatedProps({
        side: {
          animate: {
            flex: 0.2
          }
        },
        main: {
          animate: {
            flex: 0.8
          }
        },
      })

      return () => {

        setAnimatedProps({
          side: {
            animate: {
              flex: 0,
              opacity: 0
            }
          },
          main: {
            animate: {
              flex: 1
            }
          },
        })
      }
    }
  }, [ drawerOpen ])

  const toggleDrawer = () => {
    dispatch(actions.setDrawerOpen(!drawerOpen))
  }

  const changeSelectedCompany = (companyId, groupId) => {
    dispatch(actions.changeAppState({ companyId, groupId }))
  }

  //TODO: Testing
  useEffect(() => {
    if ( process.env.REACT_APP_BACK_END_URI.includes("localhost") ) {
      api.getIdToken().then(token => console.log(token, "token"))
    }
  }, [])

  //Effect to determine group options available for
  //the selected company...
  useEffect(() => {
  }, [ ])

  useEffect(() => {
    const pathname = location.pathname
    if ( pathname.includes("members") ) {
      setHeaderLabel("Members")
    } else if ( pathname.includes("/customers") ) {
      setHeaderLabel("Customers")
    } else if ( pathname.includes("/equipment") ) {
      setHeaderLabel("Equipment")
    } else if ( pathname.includes("/messages") ) {
      setHeaderLabel("Messages")
    } else if ( pathname.includes("/settings") ) {
      setHeaderLabel("Settings")
    } else if ( pathname.includes("/cargo") ) {
      setHeaderLabel("Cargo")
    } else if ( pathname.includes("/") ) {
      setHeaderLabel("Dashboard")
    }
  }, [ location.pathname ])

  const handleClickAway = () => {
    if ( drawerOpen ) {
      dispatch(actions.setDrawerOpen(false))
    }
  }


  return (
    <AppContext.Provider
      value={{
          appHeaderTitle: headerLabel,
          groupId,
      }}>
        <Box sx={{ backgroundColor: 'white', display: 'flex' }}>
          <CssBaseline />
          <SideDrawer
            toggleDrawer={ toggleDrawer }
            headerLabel={ headerLabel }
            window={ window } ref={ elementRef }
          />
          <motion.div
            component="main"
            style={{ flexGrow: 1, p: 0, width: `calc(100% - ${ DRAWER_WIDTH }px)` }}>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'end' }}>
              { animatedProps.side.animate?.flex !== 0 ?
                <motion.div { ...animatedProps.side }
                  style={{ flex: 0, zIndex: 2000, }}>
                  <Box sx={{
                      width: '300px',
                      top: 0,
                      overflow: 'hidden',
                      height: '100%',
                      minHeight: '100vh',
                      boxSizing: 'border-box',
                      borderColor: 'divider',
                      position: 'relative',
                      backgroundColor: 'white' }}>
                    <Box sx={{
                      position: 'fixed',
                      width: "299px",
                      boxSizing: 'border-box',
                    }}>
                      <NestedList />
                    </Box>
                  </Box>
                </motion.div>
                : null
              }
              <motion.div onClick={ handleClickAway } style={{ flex: 1 }} { ...animatedProps.main }>
                <SearchAppBar headerLabel={ headerLabel }/>
                <Outlet />
              </motion.div>
            </div>
            <div id="editorjs"></div>
          </motion.div>
      </Box>
    </AppContext.Provider>
  )
})

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function SearchAppBar({ headerLabel }) {
 const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  

  return (
    <AppBar position="fixed" sx={{ width: `calc(100% - ${ DRAWER_WIDTH }px)`}}>
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
          className="header-label"
          sx={{ flexGrow: 1, color: '#555', fontWeight: 400, display: { xs: 'none', sm: 'block' } }}
        >
          { headerLabel }
        </Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <IconButton size="large" onClick={ handleClick }>
            <NotificationsIcon sx={{ color: '#ccc' }}/>
          </IconButton>
          <Popper id={id} open={open} anchorEl={anchorEl} sx={{}}>
            <ClickAwayListener onClickAway={ handleClick }>
            <Paper sx={{ p: 1, width: 400, height: 350, bgcolor: 'background.paper', mt: 2, mr: 2 }}>
              No recent notifications
            </Paper>
            </ClickAwayListener>
          </Popper>
          {
          /*
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            />
            */
          }
        </Search>
      </Toolbar>
    </AppBar>
  );
}

const LearningPage = () => {
  return null
}

const GET_MEMBERS_QUERY = gql`
query GetMembersQuery {
  getMembers {
    id
    name
    email
    admin
    dispatcher
    driver
    phone
    profileThumb
  }
}
`
const GET_COMPANY_QUERY = gql`
query GetCompanyQuery {
  company {
    id
    name
  }
}
`

const GET_GROUPS_QUERY = gql`
query GetGroupsQuery {
  getGroups {
    id
    name
    members {
      id
      name
      email
      phone
    }
  }
}
`
const GET_COMPANY = gql`
query GetCompanyQuery {
  getCompany {
    id
    name
  }
}
`
const GET_EQUIPMENT = gql`
query GetEquipmentQuery {
  getEquipment {
    id
    createdTime
    axels
    color
    description
    make
    model
    drivers {
      id
      primary
      name
      email
      phone
    }
  }
}
`
const GET_COMPANY_ID_QUERY = gql`
query GetCompanyIdQuery {
  getCompanyId
}
`
const libraries = ['places','core','maps','geocoding', 'drawing']
