import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment'
import InventoryIcon from '@mui/icons-material/Inventory';
import _ from 'lodash'
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useGroupId } from '../hooks'
import { collection, limit, getDocs, query, where } from "firebase/firestore";

import { useDatabase } from '../hooks'
import { NO_OP } from '../utils'

export const RouteStopInputBase = React.forwardRef((props, ref ) => {
  const { stopType, onIconLeftClick } = props

  return (
    <Paper
      ref={ ref }
      component="form"
      elevation={ 1 }
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <IconButton
        onClick={ onIconLeftClick }
        sx={{ p: '10px' }} color={ stopType } aria-label="menu">
        <DirectionsIcon />
      </IconButton>
      <InputBase
        inputProps={ props.inputProps }
        { ...props }
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search location"
        //inputProps={ props }
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton sx={{ p: '10px' }} aria-label="directions">
        <MenuIcon />
      </IconButton>
    </Paper>
  );
})

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export function GoogleMapsAutocomplete(props) {
  const {
    stopType,
    onIconLeftClick,
    onValueChange,
    value,
    fetchInitialOptions
  } = props

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions]       = React.useState([]);
  const [ open, setOpen ]           = React.useState(false)
  const [ loading, setLoading ]     = React.useState(false)
  const loaded    = React.useRef(false);
  const valueRef  = React.useRef()

  //Effect to load recent orders when the autocomplete is opened...
  useEffect(() => {
    if ( open && fetchInitialOptions ) {
      setLoading(true)
      fetchInitialOptions()
      .then(options => {
        setOptions(options)
        setLoading(false)
      })
    }
  }, [ open, fetchInitialOptions ])

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value?.value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value?.value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 300 }}
      loading={ loading }
      onOpen={ () => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.description
      }}
      filterOptions={(x) => x}
      options={ options }
      isOptionEqualToValue={ (option, value ) => {
        const answer = _.isNil(value.placeId ) === false && ( option.placeId === value.placeId )
        return answer
      }}
      autoComplete
      includeInputInList
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue.value ? [newValue, ...options] : options);

        const value = {
          description: newValue.description,
          placeId: newValue.place_id ? newValue.place_id : newValue.placeId,
          textShort: newValue.description,
          structured_formatting: {
            main_text: '',
            secondary_text: '',
          }
        }

        if ( newValue?.structured_formatting?.secondary_text ) {
          const text      = newValue.structured_formatting.secondary_text.replace(', USA', '')
          value.textShort = text
          value.structured_formatting.main_text      = newValue.structured_formatting?.main_text
          value.structured_formatting.secondary_text = newValue.structured_formatting?.secondary_text
        }

        onValueChange(value)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        return (
          <RouteStopInputBase
            stopType={ stopType }
            ref={ params.InputProps.ref }
            onIconLeftClick={ onIconLeftClick }
            inputProps={ params.inputProps }
            label="Add a location"
            fullWidth />
        )
      }}
      renderOption={(props, option) => {
        let matches;
        if ( option.timestamp ) {
          matches = [
            { offset: 0, length: option.description.length }
          ]
        } else {
          matches =
            option.structured_formatting.main_text_matched_substrings || [];
        }

        const parts = parse(
          option.structured_formatting?.main_text ? option.structured_formatting.main_text : "",
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props} style={{ marginTop: -10, marginBottom: props['aria-selected' ] ? 10 : 0 }}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting?.secondary_text}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  { option.timestamp ?
                    <em>{ 'Used ' + moment(option.timestamp * 1000).fromNow() }</em>
                    :
                    null
                  }
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  )
}

export function CustomAutocomplete(props) {
  const {
    disabled=false,
    fetchAutocompleteOptions,
    getOptionLabel,
    sx={ },
    dep, //Optional dependency to reset whether the initial fetch should re-run...
    textFieldProps={},
    getSecondaryLabel=NO_OP,
    optionSecondaryText=null,
    placeholder="",
    optionKey,
    searchField,
    loadingValue=false,
    onValueChange,
    used=[],
    value,
    noOptionsText,
    fetchInitialOptions,
    Icon=InventoryIcon
  } = props

  const groupId = useGroupId()
  const [ initialOptions, setInitialOptions ] = React.useState([])
  const [ inputInfo, setInputInfo ]   = React.useState({ lastCharacterWasTab: false, inputValue: '' });
  const [ highlighted, setHighlighted] = React.useState({ index: null, label: null })
  const [ options, setOptions ]       = React.useState([]);
  const [ open, setOpen ]             = React.useState(false)
  const [ loading, setLoading ]       = React.useState(false)

  const usedRef     = React.useRef([])
  const valueRef    = React.useRef([])
  const firestoreDb = useDatabase()

  const doFilter = useCallback((newOptions, used, currentValue) => {

    if ( _.isEmpty( newOptions ) && currentValue?.value ) {
      return [ currentValue ]
    }

    const options = newOptions.filter( option => {

      const equalToExisting       = option.value === currentValue?.value
      const noOtherItemsWithValue = !used.find(obj => obj.value === option.value )

      //Options to display in the list...
      return equalToExisting || noOtherItemsWithValue
    })

    return options
  }, [])

  const didInitialFetchRef = React.useRef(false)

  useEffect(() => {
    if ( open && didInitialFetchRef.current === false &&
      _.isNil(fetchInitialOptions) === false ) {

      //do load...
      setLoading(true)

      fetchInitialOptions()
      .then(options => {
        const filteredOptions = doFilter(options, usedRef.current, valueRef.current)
        setOptions(filteredOptions)
        setInitialOptions(filteredOptions)
        setLoading(false)
      })

      didInitialFetchRef.current = true
    }
  }, [ open, fetchInitialOptions ])

  useEffect(() => {
    if ( dep ) {
      didInitialFetchRef.current = false
    }
  }, [ dep ])

  useEffect(() => {
    usedRef.current = used
  }, [ used ])

  useEffect(() => {
    valueRef.current = value
  }, [ value ])

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        const { text } = request
        //TODO: Make request

        //Options can have label, value and secondary text...
        fetchAutocompleteOptions({ text }).then(options => {

          /*
          const options = results.map( snapshot => {
            const data = snapshot.data()
            const timestamp = data[ groupUsageKey ] ? data[ groupUsageKey ] : null
            return {
              ...data,
              timestamp,
              label: data[ optionKey ],
              value: snapshot.id,
            }
          })
          */

          const filteredOptions = doFilter(options, usedRef.current, valueRef.current)

          return filteredOptions
        })
        .then(options => {
          callback(options)
        })
      }, 400),
    [],
  )

  //
  useEffect(() => {
    let active = true;

    const { inputValue, lastCharacterWasTab } = inputInfo

    if ( lastCharacterWasTab ) {
      return;
    }

    if (inputValue === '') {
      setOptions(_.isEmpty(inputValue) === false && value?.value ? [value] : []);
      return
    }

    fetch({ text: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (value?.value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        const sortedNewOptions = newOptions.map( option => ({
            ...option,
            order: getSecondaryLabel(option) ? 0 : 1
          }))
          .sort(( o1, o2 ) => o1.order - o2.order)

        newOptions = _.uniqBy(sortedNewOptions, 'value')

        setOptions(newOptions);
      }
    })

    return () => {
      active = false;
    }

  }, [ inputInfo, fetch ])

  return (
    <Tooltip title={ disabled ? "Select customer first" : null }>
    <Autocomplete
      variant="standard"
      classes="noselect"
      disabled={ disabled }
      loading={ loading || loadingValue }
      onOpen={ () => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false);
      }}
      sx={{ minWidth: 350, ...sx }}
      id="my-special-input"
      getOptionLabel={(option) => {
        if ( getOptionLabel ) {
          return getOptionLabel(option)
        }
        return typeof option === 'string' ? option : option.label
      }}
      filterOptions={(x) => x}
      options={ options }
      isOptionEqualToValue={ (option, value ) => option.value === value.value }
      autoComplete
      autoSelect={false}
      value={value}
      inputValue={ inputInfo.inputValue }
      noOptionsText={ noOptionsText }
      onChange={(event, newValue) => {
        setOptions(newValue?.value ? [newValue, ...options] : options);
        onValueChange(newValue)
        setInputInfo({ lastCharacterWasTab: false, inputValue: newValue ? newValue.label : '' })
      }}
      onInputChange={(event, newInputValue) => {
          setInputInfo({ lastCharacterWasTab: false, inputValue: newInputValue });
      }}
      renderInput={(params) => {

        return (
        <TextField
          {...params}
          { ...textFieldProps }
          value={ inputInfo.inputValue }
          onFocus={ () => {
            if (inputInfo.inputValue == '' && _.isEmpty(initialOptions) == false) {
              setOptions(initialOptions)
            } else if ( value ) {
              onValueChange(null)
            }
          }}
          InputProps={{
            ...params.InputProps,
            placeholder,
            inputValue: inputInfo.inputValue,
            onKeyDown: e => {
              if ( e.keyCode == 9 ) {
                if ( _.isEmpty( options ) === false ) {

                  //Change the query text to the tabed option...
                  const selectedIndex = highlighted.index ? 0 :
                    ( highlighted.index + 1 < options.length ? highlighted.index + 1 : 0 )

                  const selectedOption = options[ selectedIndex ]
                  setInputInfo({ lastCharacterWasTab: true, inputValue: selectedOption.label + ', ' })

                  //Set the new highlighted index to be the option...
                  setHighlighted({ index: selectedIndex, label: selectedOption.label })
                  e.preventDefault()
                }
              }
            },
            endAdornment: (
              <React.Fragment>
                <div style={{ display: 'flex', width: 80}}>
                {loading || loadingValue ?
                  <CircularProgress color="inherit" size={20} sx={{ }} />
                  :null
                }
                { params.InputProps.endAdornment }
                </div>
              </React.Fragment>
            ),
          }}
        />
      )}}
      renderOption={(props, option, state, ownerState) => {
        //Determine matched substrings for option based on input value...

        let inputValue = inputInfo.inputValue

        const indexOfComma = inputValue.indexOf(",")

        if ( indexOfComma != -1 ) {
          inputValue = inputValue.substring(0, indexOfComma)
        }

        //Parts of the text the user is searching for...
        let tokens =
          _.uniq(inputValue.split(" ")) //501, Nor
          .filter(v=> _.isEmpty(v) === false)

        //For each token we want to know
        //the length and the offset of where
        //it exists in the actualText
        const matches = tokens.reduce(( matches, token, index ) => {

          const tokenMatches = []

          //Find out how many times the token appears in the actual text...
          const reg = RegExp(token, 'gi')
          //token -> "No"
          //token -> "501 Fairview Avenue North, Seattle WA"
          let arr;
          while ( ( arr = reg.exec(option.label) ) !== null ) {
            //arr = [ 'North' ]
            tokenMatches.push({
              offset: arr.index,    //index of where it occurred in actualText
              length: token.length  //No.length === 2
            })
          }

          return [
            ...matches,
            ...tokenMatches
          ]

        }, [])

        /*
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        */

        const parts = parse(
          option.label,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <li {...props} style={{
            marginTop: 0,
            ...(highlighted.label === option.label ? { backgroundColor: '#eee' } : {})
          }}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <Icon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}

                <Typography variant="body2" color="text.secondary">
                  { getSecondaryLabel ?
                    getSecondaryLabel(option)
                    :
                    "Space"
                  }
                  </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ visibility: option.timestamp ? 'visible' : 'hidden' }}>
                  { option.timestamp ?
                    'Used ' + moment(option.timestamp * 1000).fromNow()
                    : ( optionSecondaryText ? optionSecondaryText : null)
                  }
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
    </Tooltip>
  )
}
