import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { createSlice, createListenerMiddleware } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import * as TripUtils from '../utils/TripUtils'
import * as OrderUtil from '../utils/OrderUtil'
import * as Utils from '../utils'
import { OrderContext } from '../contexts'

//Next:
// 1. Redux
// 2. Trip changes
// 3. 
// - Finish converting TripUtils apis to support calls from reducer
// - Work on Gantt refactor changes to start to make room for dragging the
//   orders...
// - Switch back to Mobile App for integration changes...

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    autocompleteCustomers: [], //Customers from API, set on search...
    autocompleteItems: [],
    activeId: null,
    activeId: null,
    orderModalId: null,
    orderModalOpen: false,
    orders: [], //From API
    groups: [], //Dashboard
    pages: {},
    equipmentOptions: [],
    saveDialogOpen: false,
    saveDialogTitle: null,
    requestedDispatch: false,
    loadingPrintPreview: false,
    loadingModalTitle: null,
    loadingModalOpen: false,
    selectedTabValue: 0,
    selectedEquipment: [],
    snackbarMessage: '',
    snackbarOpen: false,
    showChart: false,
    showFinancialInfo: false,
    groupOrders: true,
    drawerIsOpen: false,
    tabs: [],
    warnings: [],
    driverOptions: [],
    searchCustomerId: null,
    selectedDate: null,
    selectedWeek: null,
    loadingOrders: false,
    weekHistory: {},
    dayTripCountMap: {},
    sortProperty: 'startDate',
    unsavedDialogOpen: false,
    unselectedEquipment: [],
    //Related fields...
    contacts: {},
    customers: {},
    drivers: {},
    equipment: {},
    items: {},
  },
  reducers: {
    //--------- DASHBOARD ---------//
    setActivePage(state, action) {
      state.activeId = action.payload
    },
    setAutocompleteCustomers(state, action) {
      if ( action.payload ) {
        state.autocompleteCustomers = action.payload
      }
    },
    setAutocompleteItems(state, action) {
      state.autocompleteItems = action.payload
    },
    setData(state, action) {
      state.data = action.payload
    },
    loadedOrders(state, action) {
      const orders = action.payload //orders from API

      const result = OrderUtil.getRelatedFields(orders)

      const activeId = state.activeId

      const {
        customers,
        equipment,
        drivers,
        contacts,
        items
      } = result

      const { unselectedEquipment } = state

      //Determine equipment options
      const equipmentOptions =
        _.keys(equipment)
        .map(equipmentId => {
          const value = equipment[ equipmentId ]
          return {
            value: value.id,
            label: value.description
          }
        })

      //Determine selected equipment...
      const selectedEquipment =
        equipmentOptions
        .filter( option => unselectedEquipment.includes(option.value) === false)

      //Make sure any customer or item that is active
      //is not removed from the store...
      const activeOrder     = activeId ? state.orders.find(order => order.id === activeId) : null
      const activeCustomers = activeOrder?.customer ? { [ activeOrder.customer.id ]: activeOrder.customer } : {}
      const activeItems     = activeOrder ? mapOrderItemsToObject(activeOrder.items) : {}

      state.contacts          = contacts
      state.drivers           = drivers
      state.customers         = { ...customers, ...activeCustomers }
      state.items             = { ...items, ...activeItems }
      state.equipment         = equipment
      state.equipmentOptions  = equipmentOptions
      state.selectedEquipment = selectedEquipment
      //Make sure the active order remains in state...
      state.orders            = [ ...orders, ...(activeOrder ? [ activeOrder ] : []) ]
      state.orders            = _.uniqBy(state.orders, 'id')
    },
    changeEquipmentFilter(state, action) {
      const { selected, unselected } = action.payload
      state.selectedEquipment = selected
      state.unselectedEquipment = unselected
    },
    changeItemQuantity(state, action) {
      OrderUtil.changeItemQuantity(state, action)
    },
    setGroups(state, action) {
      state.groups = action.payload
    },
    setSaveDialogOpen(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].saveDialogOpen = action.payload
    },
    setSaveDialogTitle(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].saveDialogTitle = action.payload
    },
    setRequestedDispatch(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].requestedDispatch = action.payload
    },
    setSelectedTabValue(state, action) {
      state.selectedTabValue = action.payload
    },
    setShowChart(state, action) {
      state.showChart = action.payload
    },
    setShowFinancialInfo(state, action) {
      state.showFinancialInfo = action.payload
    },
    setGroupOrders(state, action) {
      state.groupOrders = action.payload
    },
    setDrawerIsOpen(state, action) {
      state.drawerIsOpen = action.payload
    },
    setDriverOptions(state, action) {
      const driverMap = action.payload
      const drivers = _.keys(driverMap).map(driverId => ({
        label: driverMap[ driverId ].name,
        value: driverId
      }))
      state.driverOptions = drivers
    },
    setTabs(state, action) {
      state.tabs = action.payload
    },
    setWarnings(state, action) {
      state.warnings = action.payload
    },
    setAutocompleteCustomer(state, action) {
      //Whenever the searchCustomerId is set, pull the
      //customer from state.autocompleteCustomers and
      //put into dashboard customers
      const customerId  = action.payload
      const customer    = state.autocompleteCustomers.find( c => c.id === customerId)

      if ( customer ) {
        state.customers[ customer.id ]  = customer
      }
    },
    setSearchCustomerId(state, action) {
        state.searchCustomerId = action.payload
    },
    setSelectedDate(state, action) {
      state.selectedDate = action.payload
    },
    setSelectedWeek(state, action) {
      state.selectedWeek = action.payload
    },
    setLoadingOrders(state, action) {
      state.loadingOrders = action.payload
    },
    setLoadingModalOpen(state, action) {
      state.loadingModalOpen = action.payload
    },
    openLoadingModal(state, action) {
      state.loadingModalTitle = action.payload
      state.loadingModalOpen  = true
    },
    closeLoadingModal(state, action) {
      state.loadingModalTitle = null
      state.loadingModalOpen  = false
    },
    //Set the selected date to be the date
    //of the first trip in the copied order...
    setCopiedOrder(state, action) {
      const order = action.payload
    },
    setFinishedOrderCopy(state, action) {
      const order = action.payload

      state.loadingModalOpen  = false
      state.orderModalOpen    = false
      state.drawerIsOpen      = false

      const trip = order.trips[ 0 ]

      if ( trip && trip.startTime ) {
        state.selectedDate =
          moment(parseFloat(trip.startTime))
      }
    },
    setLoadingModalTitle(state, action) {
      state.loadingModalTitle = action.payload
    },
    setWeekHistory(state, action) {
      state.weekHistory = action.payload
    },
    setDayTripCountMap(state, action) {
      state.dayTripCountMap = action.payload
    },
    setSortProperty(state, action) {
      state.sortProperty = action.payload
    },
    setOrders(state, action) {
      state.data = action.payload
    },
    setUnsavedDialogOpen(state, action) {
      state.unsavedDialogOpen = action.payload
    },
    //-------- PAGE --------//
    initializePage(state, action) {
      const { orderId } = action.payload

      //Set the active page to the orderId
      state.activeId   = orderId
      state.activePage = orderId

      const initialState = OrderUtil.orderPageStateDefault()

      //Set the created time for the order...
      initialState.order.createdTime = moment()

      //Update state
      state.pages[ orderId ] = initialState

      //Create a new trip...
      const { newTrip, newTripId } =
        TripUtils.addEmptyTrip(state, action)

      state.pages[ orderId ].order.trips[ newTripId ] = newTrip

    },
    clearPageState(state, action) {
      state.pages = {}
    },
    openOrderModal(state, action) {
      const orderId         = action.payload
      state.orderModalId    = orderId
      state.activeId        = orderId
      state.orderModalOpen  = true
    },
    closeOrderModal(state, action) {
      state.orderModalId    = null
      state.activeId        = state.activePage
      state.orderModalOpen  = false
    },
    //Called when the user wants to open an existing order...
    hydratePage(state, action) {

      const { activeId, orderModalOpen } = state
      //Rules:
      //- IF we're on the customer tab, keep the current tab position...

      let { orderId, openOrderModal=false, closeOrderModal=false, fetchedOrder } = action.payload

      const doSetActivePage = ( state.orderModalOpen === false && openOrderModal === false )

      if ( openOrderModal ) {
        const customerRow = state.pages[ activeId ].customerOrders
          .find(order => order.metadata?.order?.id === orderId)
        fetchedOrder = customerRow ? customerRow.metadata.order : null

        if ( fetchedOrder ) {
          state.orderModalOpen  = true
          state.orderModalId    = orderId
          state.activeId        = orderId
        }
      }

      if ( fetchedOrder ) {

        //First remove the old order if present...
        state.orders = _.filter(state.orders, o => o.id !== fetchedOrder.id)

        const activeItems = mapOrderItemsToObject(fetchedOrder.items)
        state.items = { ...state.items, ...activeItems }

        //Add the order...
        state.orders.push(fetchedOrder)

        //Still make sure orders is unique by id
        state.orders = _.uniqBy(state.orders, 'id')
      }

      let orderFromAPI  = _.defaultTo(fetchedOrder, state.orders.find( order => order.id === orderId))

      if ( !orderFromAPI ) {
        return;
      }

      //Make sure the customer is in the store incase from refresh...
      if ( orderFromAPI.customer ) {
        state.customers[ orderFromAPI.customer.id ] = orderFromAPI.customer
      }

      const currentPage           = state.pages[ orderId ] ? state.pages[ orderId ] : {}
      const tabValue              = _.at(state.pages[ orderId ], 'tabValue')[ 0 ] === 1 ? 1 : 0
      const currentCustomerOrders = _.at(state.pages[ orderId ], 'customerOrders')[ 0 ]
      const customerOrders        = _.isEmpty(currentCustomerOrders) ? [] : currentCustomerOrders

      //Now without making any API calls we can map the order...
      const page = OrderUtil.mapAPIOrderToPage(orderFromAPI)

      const {
        invoice,
        quoteModalOpen,
        invoiceUrl,
        invoiceModalOpen,
        orderErrors,
        orderErrorsPreSave,
        tripErrors,
        showWarnings,
        tripWarnings,
        tripErrorsGlobal,
        tripWarningsGlobal
      } = currentPage

      //Make sure we keep the errors
      page.orderErrors        = _.defaultTo(orderErrors, page.orderErrors)
      page.orderErrorsPreSave = _.defaultTo(orderErrorsPreSave, page.orderErrorsPreSave)
      page.tripErrors         = _.defaultTo(tripErrors, page.tripErrors)
      page.tripWarnings       = _.defaultTo(tripWarnings, page.tripWarnings)
      page.tripErrorsGlobal   = _.defaultTo(tripErrorsGlobal, page.tripErrorsGlobal)
      page.tripWarningsGlobal = _.defaultTo(tripWarningsGlobal, page.tripWarningsGlobal)
      page.showWarnings       = _.defaultTo(showWarnings, page.showWarnings)
      page.invoice            = _.defaultTo(invoice, page.invoice)
      page.invoiceModalOpen   = _.defaultTo(invoiceModalOpen, page.invoiceModalOpen)
      page.quoteModalOpen     = _.defaultTo(quoteModalOpen, page.quoteModalOpen)
      page.invoiceUrl         = _.defaultTo(invoiceUrl, page.invoiceUrl)
      page.tabValue           = tabValue
      page.customerOrders     = customerOrders

      //If we have already fetched contacts for the page, keep those present...
      const currentContacts = _.defaultTo(_.at(state.pages[ orderId ], 'contacts')[0], [])

      //Set the active page
      state.activeId = orderId
      if ( doSetActivePage ) {
        state.activePage = orderId
      }

      state.pages[ orderId ] = page
      state.pages[ orderId ].contacts = currentContacts

      if ( closeOrderModal ) {
        state.orderModalOpen = false
        state.activeId = state.activePage
      }
    },
    setChangesExist(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].changesExist = action.payload
    },
    setLoadingPrintPreview(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].loadingPrintPreview = action.payload
    },
    setCustomerOrders(state, action) {
      const orderId = state.activePage
      if ( action.payload ) {
        state.pages[ orderId ].customerOrders = action.payload
      }
    },
    openSnackbar(state, action) {
      state.snackbarMessage = action.payload
      state.snackbarOpen    = true
    },
    setBackdropOpen(state, action) {
      state.backdropOpen = action.payload
    },
    setLoadingInvoice(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].loadingInvoice = action.payload
    },
    setInvoice(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].invoice = action.payload
      state.pages[ orderId ].loadingInvoice = false
    },
    setInvoiceId(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].invoiceId = action.payload
    },
    setInvoiceUrl(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].invoiceUrl = action.payload
      state.pages[ orderId ].loadingInvoice = false
    },
    setContacts(state, action) {
      const orderId   = state.activeId
      const contacts  = action.payload

      //Add the contacts to the store for use everywhere...
      for (const contact of contacts ) {
        state.contacts[ contact.id ] = contact
      }

      //For use for the specific customer...
      state.pages[ orderId ].contacts = contacts
    },
    setCopyOrderDate(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].copyOrderDate = action.payload
    },
    setCopyModalOpen(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].copyModalOpen = action.payload
    },
    setContactModalOpen(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].contactModalOpen = action.payload
    },
    setDriverModalOpen(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].driverModalOpen = action.payload
    },
    setInvoiceModalOpen(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].invoiceModalOpen = action.payload
    },
    setQuoteModalOpen(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].quoteModalOpen = action.payload
    },
    setDropzoneModalOpen(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].dropzoneModalOpen = action.payload
    },
    setDroppedFile(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].droppedFile = action.payload
    },
    setModalContact(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].modalContact = action.payload
    },
    setModalDriver(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].modalDriver = action.payload
    },
    setOrderMenuAnchorEl(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].orderMenuAnchorEl = action.payload
    },
    setOverrideShowErrors(state, action) {
      const orderId  = state.activeId
      state.pages[ orderId ].overrideShowErrors = action.payload
    },
    setItemMap(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].itemMap = action.payload
    },
    setRequestedDispatch(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].requestedDispatch = action.payload
    },
    setSelectedItemsMap(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].selectedItemsMap = action.payload
    },
    setShowPresaveErrors(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].showPresaveErrors = action.payload
    },
    setShowWarnings(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].showWarnings = action.payload
    },
    setSaveTimestamp(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].saveTimestamp = action.payload
    },
    setSnackbarOpen(state, action) {
      const orderId = state.activeId
      state.snackbarOpen = action.payload
    },
    setSnackbarMessage(state, action) {
      const orderId = state.activeId
      state.snackbarMessage = action.payload
    },
    setSortedPickupDates(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].sortedPickupDates = action.payload
    },
    setTabValue(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].tabValue = action.payload
    },
    //modals
    setConfirmationMessage(state, action) {
      const orderId = state.activeId
      state.confirmationMessage = action.payload
    },
    //-------- ORDER --------//
    addDispatcher(state, action) {
      const orderId = state.activeId
      const { contact } = action.payload
      state.pages[ orderId ].order.dispatcher = {
        id: contact.id
      }
      state.contacts[ contact.id ] = contact
    },
    setContactInStore(state, action) {
      const { contact } = action.payload
      state.contacts[ contact.id ] = contact
    },
    addItem(state, action) {
      const { orderId, tripId } = action.payload
    },
    changeAdditionalItemInfo(state, action) {
      OrderUtil.changeAdditionalItemInfo(state, action)
    },
    addEmptyItem(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].items.push(Utils.EMPTY_ITEM)
    },
    setShowFieldsItemId(state, action) {
      const orderId = state.activeId
      state.pages[ orderId ].showFieldsItemId = action.payload
    },
    removePresaveError(state, action) {
      const orderId       = state.activeId
      const removedError  = action.payload

      const { orderErrorsPreSave } = state.pages[ orderId ]
      //Filter for the removed error...
      const updatedErrors = _.filter(
        orderErrorsPreSave,
        err => err.message !== removedError.message
      )

      state.pages[ orderId ].orderErrorsPreSave = updatedErrors
    },
    addPresaveError(state, action) {
      const orderId    = state.activeId
      const addedError = action.payload

      const { orderErrorsPreSave } = state.pages[ orderId ]

      const updatedErrors =
        _.uniqBy([ ...orderErrorsPreSave, addedError ], 'message')

      state.pages[ orderId ].orderErrorsPreSave = updatedErrors
    },
    removeItemFromOrder(state, action) {
      TripUtils.removeItemFromOrder(state, action)
    },
    setCustomerId(state, action) {
      const orderId    = state.activeId
      const customerId = action.payload
      state.pages[ orderId ].order.customer = {
        id: customerId
      }
    },
    setCreatedTime(state, action) {
    },
    updateAdditionalItemInfo(state, action) {
      OrderUtil.changeAdditionalItemInfo(state, action)
    },
    //-------- TRIP --------//
    createTripOptions(state, action) {
      TripUtils.createTripOptions(state, action)
    },
    calculateGrossWeights(state, action) {
      TripUtils.calculateGrossWeights(state,action)
    },
    addDriverToTrip(state, action) {
      TripUtils.addDriverToTrip(state, action)
    },
    addEmptyTrip(state, action) {
      const orderId = state.activeId

      const { newTrip, newTripId } =
        TripUtils.addEmptyTrip(state, action)

      state.pages[ orderId ].order.trips[ newTripId ] = newTrip
    },
    addEquipmentToTrip(state, action) {
      TripUtils.addEquipmentToTrip(state, action)
    },
    addReturnTrip(state, action) {
      TripUtils.addReturnTrip(state, action)
    },
    changePickupOrDropoffQuantity(state, action) {
      TripUtils.changePickupOrDropoffQuantity(state, action)
    },
    deleteTrip(state, action) {
      TripUtils.deleteTrip(state, action)
    },
    quickAddInstruction(state, action) {
      TripUtils.quickAddInstruction(state, action)
    },
    replaceItemId(state, action) {
      TripUtils.replaceItemId(state, action)
    },
    reverseTrip(state, action) {
      TripUtils.reverseTrip(state, action)
    },
    //Called when the VALUE of the item changes...
    setItemValue(state, action) {
      const orderId = state.activeId
      const { option, index } = action.payload

      if (! option.value ) {
        return
      }

      //See if the value is in state.autocompleteItems
      const item = state.autocompleteItems.find(item => item.id === option.value )

      if ( item ) {
        //Make sure it's available globally
        state.items[ item.id ]  = item 

        //Make sure to put the item in the itemMap...
        state.pages[ orderId ].itemMap[ item.id ] = _.omit(item, 'id')
      }

      state.pages[ orderId ].items[ index ].value = option.value
    },
    setPrimaryDriverId(state, action) {
      TripUtils.setPrimaryDriverId(state, action)
    },
    updateRouteInfo(state, action) {
      TripUtils.updateRouteInfo(state, action)
    },
    //-------- STOPS -------//
    handleDrag(state, action) {
      TripUtils.handleDrag(state, action)
    },
    addContactToStop(state, action) {
      TripUtils.addContactToStop(state, action)
    },
    addNoteToStop(state, action) {
      TripUtils.addNoteToStop(state, action)
    },
    addSelectedInstructions(state, action) {
      TripUtils.addSelectedInstructions(state, action)
    },
    addStop(state, action) {
      TripUtils.addStop(state, action)
    },
    changeSelectedItem(state, action) {
      TripUtils.changeSelectedItem(state, action)
    },
    editAddress(state, action) {
      TripUtils.editAddress(state, action)
    },
    editLocationMarker(state, action) {
      TripUtils.editLocationMarker(state, action)
    },
    editTime(state, action) {
      TripUtils.editTime(state, action)
    },
    removeInstruction(state, action) {
      TripUtils.removeInstruction(state, action)
    },
    removeStop(state, action) {
      TripUtils.removeStop(state, action)
    },
    setPage(state, action) {
      const { page } = action.payload
      //Set the page in the store...
      state.page[ action.payload.orderId ] = page
    },
    togglePickupOrDropoff(state, action) {
      TripUtils.togglePickupOrDropoff(state, action)
    },
    toggleInstructionIsPick(state, action) {
      TripUtils.toggleInstructionIsPick(state, action)
    },
    toggleTimeChoice(state, action) {
      TripUtils.toggleTimeChoice(state, action)
    }
  },
})

export const actions = dashboardSlice.actions

const ORDER_RELATED_ACTIONS = [
  //Order
  'dashboard/addDispatcher',
  'dashboard/addItem',
  'dashboard/changeItemQuantity',
  'dashboard/removeItemFromOrder',
  'dashboard/changeAdditionalItemInfo',
  'dashboard/updateAdditionalItemInfo',
  'dashboard/setCustomerId',
  //Trip
  'dashboard/addDriverToTrip',
  'dashboard/addEquipmentToTrip',
  'dashboard/addEmptyTrip',
  'dashboard/changePickupOrDropoffQuantity',
  'dashboard/deleteTrip',
  'dashboard/quickAddInstruction',
  'dashboard/replaceItemId',
  'dashboard/setItemValue',
  'dashboard/setPrimaryDriverId',
  'dashboard/updateRouteInfo',
  //Stops
  'dashboard/handleDrag',
  'dashboard/addContactToStop',
  'dashboard/addNoteToStop',
  'dashboard/addSelectedInstructions',
  'dashboard/addStop',
  'dashboard/changeSelectedItem',
  'dashboard/editAddress',
  'dashboard/editLocationMarker',
  'dashboard/editTime',
  'dashboard/removeInstruction',
  'dashboard/removeStop',
  'dashboard/toggleInstructionIsPick',
  'dashboard/toggleTimeChoice',
]

export const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return ORDER_RELATED_ACTIONS.includes( action.type )
  },
  effect: async ( action, listenerApi ) => {

    //Get the api order from state...

    const state         = listenerApi.getState()
    const activeId      = state.dashboard.activeId
    const apiOrder      = state.dashboard.orders.find(o => o.id === activeId)
    const page          = state.dashboard.pages[ activeId ]
    const changesExist  = page.changesExist

    const { orderDiff } =
      OrderUtil.getOrderDifferences({ apiOrder, page, dispatching: false })

    if ( _.isEmpty( orderDiff ) && changesExist ) {
      listenerApi.dispatch(actions.setChangesExist(false))
    } else if ( _.isEmpty( orderDiff ) === false && changesExist === false ) {
      listenerApi.dispatch(actions.setChangesExist(true))
    }
  }
})

export const useOrderId = () => {
  const { orderId } = useContext(OrderContext)
  return orderId
}

//Will determine where to pull values from...
export const usePageSelector = selector => {
  const orderId = useOrderId() //Incorrect for Dashboard...

  const state = useSelector(state => state.dashboard.pages[ orderId ])
  return selector(state)
}

export const useAPIOrder = () => {
  const orderId   = useOrderId()
  const apiOrders = useSelector(state => state.dashboard.orders)

  //TODO: Fix this
  const [ order, setOrder ] = useState()

  useEffect(() => {
    const order = orderId ? apiOrders.find(order => order.id === orderId) : null
    setOrder(order)
  }, [ orderId, apiOrders ])

  return order
}

export const useCustomer = customerId => {
  const storedCustomer = useSelector(state => state.dashboard.customers[ customerId ])
  const autocompleteCustomers = useSelector(state => state.dashboard.autocompleteCustomers)

  const [ customerInfo, setCustomerInfo ] = useState({ customer: null, customerOption: null })

  useEffect(() => {
    if ( customerId ) {

      const customer = //If we already have the customer...
        storedCustomer ? storedCustomer : //Or find customer in the autocomplete customers...
        _.defaultTo(autocompleteCustomers.find(c => c.id === customerId ), null)

      const customerOption = customer ? {
        label: customer.name,
        value: customer.id,
        timestamp: customer.lastUsedTime
      } : null

      setCustomerInfo({ customerOption, customer })
    } else {
      setCustomerInfo({ customerOption: null, customer: null })
    }

  }, [ customerId, storedCustomer, autocompleteCustomers ])

  return customerInfo
}

export const useItem = itemId => {
  const item = useSelector(state => state.dashboard.items[ itemId ])

  //TODO: Memoize values...
  const itemOption = item ? {
    label: `${ item.description }${ item.manufacturer ? ', ' + item.manufacturer : '' }` +
      `${ item.model ? ' ' + item.model : '' }`,
    value: item.id,
    timestamp: item.lastUsedTime
  } : null

  return { item, itemOption }
}

export const useContact = contactId => {
  const contact = useSelector(state => state.dashboard.contacts[ contactId ])

  const contactOption = contact ? {
    label: contact.name,
    value: contact.id
  } : null

  return { contact, contactOption }
}

const mapOrderItemsToObject = (arr=[]) => arr.reduce((map, obj) => {
  return {
    ...map,
    [ obj.id ]: obj
  }
}, {})


export default dashboardSlice.reducer
