import { createSlice } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'

const itemsSlice = createSlice({
  name: 'items',
  initialState: {
    loading: false,
    data: {}
  },
  reducers: {
    setItems(state, action) {
      const { itemsMap } = action.payload
      state.data = {
        ...state.data,
        ...itemsMap
      }
    }
  }
})

export const useItem = itemId => {
  const item = useSelector(state => state.items.data[ itemId ])
  return item
}

export const { setItems } = itemsSlice.actions
export default itemsSlice.reducer
