import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams, Link, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ApolloConsumer } from '@apollo/client';
import Chip from '@mui/material/Chip';
import Backdrop from '@mui/material/Backdrop';
import Portal from '@mui/material/Portal';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import useWindowFocus from 'use-window-focus';
import _ from 'lodash'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux'

import { CustomerAutocomplete } from '../components/CustomerAutocomplete'
import { brown, orange } from '@mui/material/colors';
import { gql, useMutation, useLazyQuery, } from '@apollo/client';
import Alert from '@mui/material/Alert';
import AlertDialog from '../components/Dialog'
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Stack from '@mui/material/Stack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ToggleButton from '@mui/material/ToggleButton';
import useHover from '@react-hook/hover'
import Badge from '@mui/material/Badge';
import { motion } from 'framer-motion'
import Drawer from '@mui/material/Drawer';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { CustomAutocomplete } from '../components/RouteStopInputBase'
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { GanttIcon, SearchIcon } from '../icons'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import moment from 'moment'

import { DashboardContext, ResolverContext, AppContext } from '../contexts'
import { GanttDisplay } from '../components/Table'
import { useCompanyId, useGroupId, useDatabase } from '../hooks'
import { onSnapshot, collection, doc, getDocs, orderBy, query, where } from "firebase/firestore";
import {
  actions,
  usePageSelector,
  useCustomer,
  useEquipment,
  useDriver,
  useAPIOrder
} from '../redux/dashboardSlice'
import * as Utils from '../utils'
import * as TripUtils from '../utils/TripUtils'
import * as OrderUtil from '../utils/OrderUtil'
import * as GanttUtil from '../utils/GanttUtil'
import { NO_OP } from '../utils'
import MapComponent from '../components/MapComponent'
import FloatingMap from '../components/FloatingMap'
import LoadingModal from '../components/LoadingModal'
import { generateReport, GET_ORDER_QUERY, GET_ORDERS_QUERY } from '../utils/api'
import * as api from '../utils/api'
import OrderDetailsPage from './OrderDetailsPage'

//TOMORROW:
//- Setup simpledispatch.io Google account
//- Setup new Firebase project
//- Add indexes
//- Start working on createOrder APIs/additional bug fixes...
const withApolloClient = Component => {
  return (props) => {
    return  (
      <ApolloConsumer>
        {client => <Component client={ client } { ...props }></Component>}
      </ApolloConsumer>
    )
  }
}
const DashboardPage = withApolloClient(({ client }) => {

  const location      = useLocation()
  const navigate      = useNavigate()
  const dispatch      = useDispatch()
  const windowFocused = useWindowFocus();
  const { orderId }   = useParams()

  const [getDashboardOrders, { loading, error, data, refetch }] =
    useLazyQuery(GET_ORDERS_QUERY, { /* fetchPolicy: 'network-only' */ })

  const [getOrder] = useLazyQuery(GET_ORDER_QUERY, {
    fetchPolicy: 'network-only'
  })

  const companyId = useCompanyId()
  const db        = useDatabase()
  const groupId   = useGroupId()
  const apiOrder  = useAPIOrder()
  const [ tripChangesMap, setTripChangesMap ] = useState({})
  const [ resetTripIndidcator, setResetTripIndicator ] = useState(null)
  const tripChangesMapRef = useRef(tripChangesMap)

  const members   = useSelector(state => state.app.members)
  const company   = useSelector(state => state.app.company)

  //Orders from API
  const orders    = useSelector(state => state.dashboard.orders)
  const page      = usePageSelector(page => page)
  const itemMap   = useSelector(state => state.dashboard.items)
  const backdropOpen      = useSelector(state => state.dashboard.backdropOpen)

  const orderModalId      = useSelector(state => state.dashboard.orderModalId)//null
  const orderModalOpen    = useSelector(state => state.dashboard.orderModalOpen)//false
  const unsavedDialogOpen = useSelector(state => state.dashboard.unsavedDialogOpen)
  const loadingModalTitle = useSelector(state => state.dashboard.loadingModalTitle)
  const loadingModalOpen  = useSelector(state => state.dashboard.loadingModalOpen)
  const selectedTabValue  = useSelector(state => state.dashboard.selectedTabValue)
  const snackbarMessage   = useSelector(state => state.dashboard.snackbarMessage)
  const snackbarOpen      = useSelector(state => state.dashboard.snackbarOpen)

  //Gantt
  const showChart         = useSelector(state => state.dashboard.showChart)
  const showFinancialInfo = useSelector(state => state.dashboard.showFinancialInfo)
  const groups            = useSelector(state => state.dashboard.groups)
  const groupOrders       = useSelector(state => state.dashboard.groupOrders)

  //Order
  const drawerIsOpen      = useSelector(state => state.dashboard.drawerIsOpen)
  const tabs              = useSelector(state => state.dashboard.tabs)
  const warnings          = useSelector(state => state.dashboard.warnings)

  //Autocompletes
  const selectedEquipment = useSelector(state => state.dashboard.selectedEquipment)

  const searchCustomerId  = useSelector(state => state.dashboard.searchCustomerId)
  const selectedDate      = useSelector(state => state.dashboard.selectedDate)
  const selectedWeek      = useSelector(state => state.dashboard.selectedWeek)
  const loadingOrders     = useSelector(state => state.dashboard.loadingOrders)
  const dayTripCountMap   = useSelector(state => state.dashboard.dayTripCountMap)
  const sortProperty      = useSelector(state => state.dashboard.sortProperty)

  const itemMapRef                    = useRef()
  const memberMapRef                  = useRef()
  const orderModalRef                 = useRef()
  const headerOverrideRef             = useRef()
  const shouldResetTableAnimationsRef = useRef(true)
  const didInitialfetchRef            = useRef({})

  const { customer: customerFromSearch, customerOption } =
    useCustomer(searchCustomerId)

  const ordersPreviousRef     = useRef()

  const openSnackbar = message => {
    dispatch(actions.openSnackbar(message))
  }

  const handleBackdropClose = () => {
    dispatch(actions.setBackdropOpen(false))
  }
  const handleBackdropOpen = () => {
    dispatch(actions.setBackdropOpen(true))
  }

  const handleTimeChange = ( tripId, x ) => {
    setTripChangesMap( map => {
      return {
        ...map,
        [ tripId ]: x
      }
    })
  }

  const handleSaveTimeChanges = () => {
    openSnackbar("Pending Post launch ✨")
    setTripChangesMap({})
    setResetTripIndicator(moment().unix())
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(actions.setSnackbarOpen(false))
  }

  const toggleShowGantt = () => {
    dispatch(actions.setShowChart(!showChart))
  }

  const setSelectedDate = date => {
    dispatch(actions.setSelectedDate(moment(date)))
  }

  const onSearchCustomerChange = option => {
    //Set the customer id...
    dispatch(actions.setSearchCustomerId(option?.value ? option.value : null))
  }

  //toggleDrawer(left, true)
  const toggleDrawer = ( event={}, open, confirmationOverride=false) => {

    //Make sure the apiOrder actually returned...
    if ( confirmationOverride === false && open === false && apiOrder ) {

      const orderChangesExist =
        OrderUtil.checkIfChangesExists(apiOrder, page)

      if (! orderChangesExist ) {
        dispatch(actions.setDrawerIsOpen(open))
      } else {
        dispatch(actions.setDrawerIsOpen(open))
        //TODO: Automatically save changes and show confirmation...
        //dispatch(actions.setUnsavedDialogOpen(true))
        return;
      }

    } else {

      if (event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      dispatch(actions.setDrawerIsOpen(open))
    }

  }

  const handleSort = property => {
    dispatch(actions.setSortProperty(property))
  }

  const startNewOrder = () => {
    const newOrderId = "new"
      //doc(collection(db, "company", companyId, "orders")).id

    dispatch(actions.initializePage({
      orderId: newOrderId
    }))

    navigate(`orders/${ newOrderId }`)
  }

  const generateReport = () => {
    const refDate = selectedDate.format()
    api.generateReport(refDate)
  }

  const handleSelectedTabChange = (event, newValue) => {
    dispatch(actions.setSelectedTabValue(newValue))

    //newValue is the selected tab index
    const dateKey = tabs[ newValue ].dateKey

    dispatch(actions.setSelectedDate(moment(dateKey)))

  }

  const hasWarnings = day => {
    return false
  }

  const getTripCount = day => {

    if ( _.isNil( dayTripCountMap[ day ] )) {
      return ''
    } else {
      return '(' + dayTripCountMap[ day ] + ')'
    }
  }

  const handleUnsavedConfirm = () => {
    dispatch(actions.setUnsavedDialogOpen(false))
  }

  //User wants to discard order edits...
  const handleUnsavedDiscard = () => {
    dispatch(actions.setUnsavedDialogOpen(false))
    toggleDrawer({}, false, true)
  }

  const handleCloseOrderModal = () => {
    dispatch(actions.closeOrderModal())
  }

  const handleMetricClick = () => {
    openSnackbar("Pending Post launch ✨")
  }

  //Effect to set the title to the title
  //of the page based on the company...
  useEffect(() => {
    if ( company?.name ) {
      document.title = company.name + ' | Simple Dipspatch'
    }
  }, [ company ])

  useEffect(() => {
    if ( _.isNil(orderId ) ) {
      dispatch(actions.setActivePage(null))
    }
  }, [ orderId ])

  //Effect to keep itemMapRef in check...
  useEffect(() => {
    itemMapRef.current = itemMap
  }, [ itemMap ])

  useEffect(() => {
    memberMapRef.current = members
  }, [ members ])

  useEffect(() => {
    if ( customerFromSearch?.name ) {
      headerOverrideRef.current = customerFromSearch.name
    }
  }, [ customerFromSearch ])

  //Effect to understand when the selected week changes...
  useEffect(() => {
    if ( !selectedDate ) {
      return;
    }

    if ( weekFormat(selectedDate) !== selectedWeek ) {
      dispatch(actions.setSelectedWeek(weekFormat(selectedDate)))
    }

  }, [ dispatch, selectedDate, selectedWeek ])

  //Effect to read in the order counts for the current week...
  useEffect(() => {
    if (! selectedWeek || !windowFocused ) {
      return;
    }

    const startOfWeek = moment(selectedWeek).startOf('week')
    const endOfWeek   = moment(selectedWeek).endOf('week')

    //Metadata for the trips for the given company/group
    //starting sometime this week...
    const metadataQueryRef =
      query(
        collection(db, "companies", companyId, "trip-metadata"),
          where('startTime', ">=", startOfWeek.valueOf()),
          where('startTime', "<", endOfWeek.valueOf()),
          where('groupId', '==', groupId) //Can remove conditionally
      )

    const unsub = onSnapshot(metadataQueryRef, snapshot => {
      const dayTripCountMap = {}

      //week: 2023-W4
      //date: 1023123123

      const docs = snapshot.docs.forEach( snapshot => {

        const metadata = snapshot.data()
        const { startTime, endTime, hasWarnings } = metadata
        if ( !startTime || !endTime ) {
          return;
        }

        const m = moment(startTime)
        const selectedWeek = m.week()

        while ( true ) {

          //Add the day for the given time...
          const day       = m.day()
          const dayFormat = 'YYYY-MM-DD'
          const dayKey    = m.format(dayFormat)

          if ( dayTripCountMap[ day ] ) {
            dayTripCountMap[ day ] += 1
          } else {
            dayTripCountMap[ day ] = 1
          }

          //Check if there is not another day the trip belongs to
          //or the next day is in another week...
          if ( day === moment(endTime).day() ||
            m.clone().add(1, 'day').week() !== selectedWeek ) {
            break;
          } else {
            m.add(1, 'day')
          }
        }

      })

      dispatch(actions.setDayTripCountMap(dayTripCountMap))

    })

    return unsub

  }, [ dispatch, companyId, groupId, selectedWeek, windowFocused ])

  //Effect to show the chart. Will loose animation
  //without showChart being false initially
  useEffect(() => {
    dispatch(actions.setShowChart(true))
  }, [ dispatch ])

  useEffect(() => {
    if ( location.pathname !== '/' ) {
      toggleDrawer(undefined, true)
    } else {
      toggleDrawer(undefined, false)
    }
  }, [ location ])

  //Effect to keep the state clean based
  //on the location...
  useEffect(() => {
    if ( location.pathname === '/' ) {
      dispatch(actions.clearPageState())
    }
  }, [ location ])

  //Effect to set the selected date
  useEffect(() => {
    dispatch(actions.setSelectedDate(moment()))
  }, [ dispatch ])

  //Effect to set the selected tab (initially)
  useEffect(() => {

    if ( !selectedDate ) {
      return //Don't run if we've already set the initial tab...
    }

    //Index of the selected tab is === the day index
    dispatch(actions.setSelectedTabValue(selectedDate.day()))

  }, [ dispatch, selectedDate ])

  //Effect to set the tabs according to the selected date...
  useEffect(() => {
    if ( !selectedDate ) {
      return;
    }

    //Set current date to the first week day of the selected date...
    const current = selectedDate.clone().day(0)

    const tabs        = []
    const DAYS_COUNT  = 7

    for ( let i = 0; i < DAYS_COUNT; i++ ) {
      tabs.push({
        index: i,
        dateKey: current.format(DATE_KEY_FORMAT),
        label: current.format('ddd')
      })
      current.add(1, 'day')
    }

    dispatch(actions.setTabs(tabs))

  }, [ dispatch, selectedDate ])

  //Effect to set the related fields in their respective slices...
  useEffect(() => {
    if ( data ) {
      //Set all related fields, orders etc...
      dispatch(actions.loadedOrders(data.getOrders))
    }
  }, [ dispatch, data?.getOrders ])

  //Effect to create groups for the orders of the selected day...
  useEffect(() => {

    const headerOverride  = headerOverrideRef.current
    const shouldGroup     = _.isNil(headerOverride) && groupOrders

    const groups = OrderUtil.mapAPIOrdersToGanttGroups({
      apiOrders: orders,
      itemMap: itemMapRef.current,
      memberMap: memberMapRef.current,
      selectedEquipment,
      shouldGroup,
      sortProperty,
      headerOverride,
      selectedDate
    })

    groups.shouldResetAnimations = shouldResetTableAnimationsRef.current
    dispatch(actions.setGroups(groups))

    //Flip back to true if false...
    if ( shouldResetTableAnimationsRef.current === false ) {
      shouldResetTableAnimationsRef.current = true
    }

  }, [ dispatch, selectedEquipment, selectedDate, orders, groupOrders, sortProperty ])

  //Effect to records the orders right after the customer is selected...
  useEffect(() => {
    if ( searchCustomerId && !ordersPreviousRef.current ) {
      ordersPreviousRef.current = orders
    }
  }, [ orders, searchCustomerId ])

  //Effect to reset the orders to what they were previously...
  useEffect(() => {
    if ( !searchCustomerId && ordersPreviousRef.current ) {
      const orders = [ ...ordersPreviousRef.current ]
      dispatch(actions.setOrders(orders))
      ordersPreviousRef.current = null
    }

    if ( !searchCustomerId ) {
      headerOverrideRef.current = null
    }
  }, [ dispatch, searchCustomerId ])

  //Effect to fetch orders belonging to the selected customer...
  useEffect(() => {

    if ( customerFromSearch?.id ) {

      //Override the header to match customer name
      headerOverrideRef.current = customerFromSearch.name

      setTimeout(() => { //Wait for the Gantt Chart to close...
        getDashboardOrders({
          variables: {
            query: {
              customerId: customerFromSearch.id
            }
          }
        })
      }, 500)
    }

  }, [ customerFromSearch ])
  /*
   * 1 Effect - to change the selected week.
   *
   * 1 Effect - Listen to changes on order-trips
   * for the selected week
   *  - When something is added or modified, call GetOrder with the id
   */

  useEffect(() => {
    tripChangesMapRef.current = tripChangesMap
  }, [ tripChangesMap ])

  //Effect to fetch orders based on  the selected date...
  useEffect(() => {
    if (! selectedDate || !windowFocused ) {
      return
    }

    //Get rid of any time changes...
    if ( _.isEmpty( tripChangesMapRef.current ) === false ) {
      setTripChangesMap({})
    }

    const queryStart  = selectedDate.clone().startOf('day')
    const queryEnd    = selectedDate.clone().endOf('day')

    //Every time we query, reset the animated groups...
    shouldResetTableAnimationsRef.current = true

    getDashboardOrders({
      variables: {
        query: {
          startTime: queryStart.unix(),
          endTime: queryEnd.unix()
        }
      }
    })

    return () => {
      dispatch(actions.setOrders([]))
    }

  }, [ dispatch, selectedDate, windowFocused ])

  //Effect to listen for changes based on the selected week...
  useEffect(() => {
    if ( _.isNil(selectedWeek) ) {
      return;
    }

    const queryStart  = moment(selectedWeek).startOf('week')
    const queryEnd    = moment(selectedWeek).endOf('week')

    const q = query(
      collection(db, "companies", companyId, "order-trips"),
      where("startTime", ">=", queryStart.valueOf()),
      where("startTime", "<=", queryEnd.valueOf()),
      where('groupId', '==', groupId) //Can remove conditionally
    )

    let didRecordInitialChanges = false

    const unsubscribe = onSnapshot(q, snapshot => {

      if ( didRecordInitialChanges ) { //We already acknowledged the initial changes...

        snapshot.docChanges().forEach(change => {

          const trip        = change.doc.data()
          const { orderId } = trip

          //Determine the queryStart and queryEnd
          //based on the startTime of the trip
          const queryStart  = moment(trip.startTime).startOf('day')
          const queryEnd    = moment(trip.startTime).endOf('day')

          if ( change.type === "added" || change.type === "modified" ) {

            openSnackbar("Order " + Utils.truncateId(orderId) + " updated")
            //First get the order...
            getOrder({
              variables: {
                orderId
              }
            }).then( response => {

              const order = response.data?.getOrder

              if ( !order ) {
                return;
              }

              client.cache.updateQuery({
                query: GET_ORDERS_QUERY,
                variables: {
                  query: {
                    startTime: queryStart.unix(),
                    endTime: queryEnd.unix()
                  }
                }
              }, data => {
                if ( data?.getOrders ) {
                  const orders = data.getOrders

                  //We've previously cached the values for this API call...
                  if ( change.type === 'modified' ) {
                    return {
                      getOrders: orders.map( o => o.id === orderId ? order : o)
                    }
                  } else {
                    return {
                      getOrders: [ ...orders, order ]
                    }
                  }
                }
              })
            })

          } else if (change.type === "removed") {
            refetch()
          }
        })
      } else {
        didRecordInitialChanges = true
      }
    })

    return () => {
      unsubscribe()
    }

  }, [ selectedWeek, refetch ])

  //Effect to set warnings for the different orders...
  useEffect(() => {
    if ( _.isEmpty(groups) ) {
      return;
    }

    const warnings = OrderUtil.getWarningsForGroups(groups)
    dispatch(actions.setWarnings(warnings))

  }, [ dispatch, groups ])

  //Effect to toggle the gantt display when the
  //user is searching for a customer...
  useEffect(() => {

    if ( searchCustomerId ) {
      dispatch(actions.setShowChart(false))
    } else {
      dispatch(actions.setShowChart(true))
    }

    document.activeElement.blur()

  }, [ dispatch, searchCustomerId ])

  return (
    <DashboardContext.Provider value={{
      openSnackbar,
      handleBackdropClose,
      handleBackdropOpen,
      toggleDrawer,
      drawerIsOpen,
      loading }}>
      <Portal>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }}
          open={backdropOpen}
          onClick={handleBackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Portal>
      <OrderModal
        ref={ orderModalRef }
        handleClose={ handleCloseOrderModal }
        open={ orderModalOpen }
        orderId={ orderModalId } />
      <AlertDialog
        open={ unsavedDialogOpen }
        title="Discard edits?"
        handleConfirm={ handleUnsavedConfirm }
        handleClose={ handleUnsavedConfirm }
        handleCancel={ handleUnsavedDiscard }
      />
      <LoadingModal
        open={ loadingModalOpen }
        title={ loadingModalTitle }
        handleClose={ NO_OP }
      />
      <div className="page" style={{ marginTop: 25 }}>
        <Box sx={{ width: '100%', height: 250, mb: 2, }}>
          <MapComponent zoom={ 12 }/>
        </Box>
        <Box sx={{ mb: 2 }}>
          { _.keys(members).map(memberId => {
            const member = members[ memberId ]
            return (
            <Chip
              onClick={ () => {
                openSnackbar("No location data for " + member.name)
              }}
                label={member.name}
                variant="outlined"
                sx={{ ml: 1, cursor: 'pointer' }} />
            )
          })
          }
        </Box>
      {/*
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{ color: "#666", cursor: "pointer", mr: 0, p: 0 }}
            variant="button"
            onClick={ handleMetricClick }>
            Metrics
          </Typography>
        </Box>
        */}
        { false ?
          <div style={{ display: 'flex' }}>
            <Metric title="Loads" />
            <Metric title="Delivery" />
          </div>
          :
          null
        }
        <Divider sx={{ mb: 4 }} />
        <TemporaryDrawer open={ drawerIsOpen } toggleDrawer={ toggleDrawer } />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            <CustomerAutocomplete
              textFieldProps={{size: 'small'}}
              loading={ false }
              customerOption={ customerOption }
              onValueChange={ onSearchCustomerChange }/>
            <Button
              variant="outlined"
              onClick={ generateReport }
              sx={{ ml: 2 }}>
              Generate Report
            </Button>
          </Box>
          <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            className="dash-menu-right">

            {/*
            <CompanyAutocomplete />
            <GroupAutocomplete />
            */
            }
            { /* <EquipmentAutocomplete /> TODO: POST-LAUNCH */ }
            <GroupOrdersToggle />

            { _.isNil(searchCustomerId) ?
              <ToggleButton
                value="check"
                size="small"
                sx={{ mr: 2 }}
                selected={ showChart }
                onChange={() => {
                  toggleShowGantt()
                }}
              >
                <GanttIcon />
              </ToggleButton>
              :
              null
            }
            <ToggleButton
              size="small"
              value="check"
              sx={{ mr: 2 }}
              selected={ showFinancialInfo }
              onChange={() => {
                //openSnackbar("Pending post launch: View invoice by opening the order ✨")
                dispatch(actions.setShowFinancialInfo(!showFinancialInfo))
              }}
            >
              <AttachMoneyIcon />
            </ToggleButton>
            <Button
              variant="contained"
              onClick={ startNewOrder }
              sx={{ mr: 2 }}>
              Add New Order
            </Button>
          </div>
        </div>

        <Box sx={{ width: '100%', mt: 2 }}>
          <Box sx={{ /*borderBottom: 1, */ borderColor: 'divider', pt: searchCustomerId ? 2 : 0 }}>
            { _.isNil( searchCustomerId ) ?
              <Tabs
                value={selectedTabValue}
                onChange={handleSelectedTabChange}
                sx={{ marginBottom: 2 }}
                aria-label="basic tabs example">
                { tabs.map( ( tab, index ) => (
                  <Tab
                    iconPosition={ 'right' }
                    icon={
                      <>
                        { ( index == selectedTabValue && loading ) ?
                          <CircularProgress size={ 20 } sx={{ ml: 2 }}/>
                          :
                          null
                        }
                        { hasWarnings(index) ?
                          <WarningAmberOutlinedIcon
                            /*variant="dot"*/
                            sx={{
                              ml: '7px',
                              position: 'relative',
                              top: -3,
                              color: selectedTabValue === index ? "primary" : "#ccc"
                            }}>
                          </WarningAmberOutlinedIcon>
                          : null
                        }
                      </>
                    }
                    label={ `${ tab.label } ${ getTripCount(index) }` }
                    {...a11yProps(tab.index)} />
                ))}
              </Tabs>
              :
              null
            }
            { /* ALERTS */ }
            { _.isEmpty(tripChangesMap) === false ?
              <Stack sx={{ width: '100%', mb: 2 }} spacing={2}>
                <Alert
                  severity="info"
                  action={
                    <Button color="inherit"
                      onClick={ () => handleSaveTimeChanges() }
                      size="small">
                    Save changes
                    </Button>
                  }>
                  You have unsaved changes
                </Alert>
              </Stack>
              :
              null
            }
            { _.isEmpty(warnings) === false ?
              <Stack sx={{ width: '100%', mb: 2 }} spacing={2}>
                { warnings.map( warning => (
                  <Alert
                    severity="warning"
                    action={
                      <Button color="inherit"
                        size="small"
                        onClick={ () => navigate(`/orders/${ warning.orderId }?showWarnings=true`) }>
                      FIX IT
                      </Button>
                    }>{ getWarningText(warning) }</Alert>
                ))}
              </Stack>
              : null
            }
            <GanttDisplay
              header="Orders"
              loading={ loadingOrders }
              handleSort={ handleSort }
              shouldResetTableAnimationsRef={ shouldResetTableAnimationsRef }
              isGrouped={ !headerOverrideRef.current && groupOrders }
              setSelectedDate={ setSelectedDate }
              selectedDate={ selectedDate }
              showChart={ showChart }
              handleTimeChange={ handleTimeChange }
              resetTripIndidcator={ resetTripIndidcator }
              showFinancialInfo={ showFinancialInfo }
              groups={ groups }
            />
          </Box>
        </Box>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={handleSnackbarClose}
        message={ snackbarMessage }
        action={null}
      />

    </DashboardContext.Provider>
  )
})

export default DashboardPage

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function TemporaryDrawer({ open, toggleDrawer }) {
  const [ size, setSize ] = useState(1100)
  const navigate          = useNavigate()

  useEffect(() => {
    return;
    if (open) {
      setTimeout(() => {
        setSize(3000)
      }, 3000)
      return () => {
        setSize(1200)
      }
    }
  }, [ open ])

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={ (e) => {
        navigate('/')
      }}>
      <Box
        sx={{ width: size, p: 2, mt: 2 }}
        role="presentation"
        onClick={ null/* toggleDrawer(anchor, false) */}
        onKeyDown={ null /* toggleDrawer(anchor, false) */}
      >
        <Outlet />
      </Box>
    </Drawer>
  )
}


function a11yProps(tab) {
  return {
    id: `simple-tab-${tab.index}`,
    'aria-controls': `simple-tabpanel-${tab.index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function createHeadCells(template, associates=[]) {
  return _.keys(template).map( field => ({
    id: field,
    center: field === 'chart',//numeric.includes(field),
    disablePadding: true,
    label: template[ field ],
    link: associates.includes(field)
  }))
}

function getWarningText(warning) {
  const warningCount = warning.warnings.length

  //No items picked up for order123 + 2 additional errors.
  const text = warning.warnings[ 0 ].message +
    ` for order ${ Utils.truncateId(warning.orderId) }`

  const additionalText = warningCount > 1 ?
    ` + ${ warningCount -1 } additional warnings.` : ''

  const warningText = text + additionalText
  return warningText
}

const Metric = ({ title }) => {
  return (
    <Paper elevation={0} variant="outlined" sx={{ width: 'fit-content', mr: 2, p: 1, mb: 4 }}>
      <Typography variant="p" sx={{ mb: 2 }}>
        { title }
      </Typography>
      <div style={{ width: 150 }}>
        <Line options={options} data={data} />
      </div>
    </Paper>
  )
}

const options = {
  responsive: true,
  scales: {
    y: {
      display: false,
      grid: {
        //display: false
      }
    }
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
  },
}

const labels = ['Th', 'Fr', 'S' ];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      data: [ 24, 28, 30 ],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
}

const DATE_KEY_FORMAT = 'YYYY-MM-DD'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const WEEK_FORMAT = 'YYYY-ww'
const weekFormat = date => {
  return date.format(WEEK_FORMAT).replace('-','-W')
}

const EquipmentAutocomplete = () => {

  const dispatch = useDispatch()

  //All available equipment
  const equipmentOptions = useSelector(state => state.dashboard.equipmentOptions)

  const unselectedEquipment =
    useSelector(state => state.dashboard.unselectedEquipment)

  //A function of equipment that is available and not unselected...
  const selectedEquipment = useSelector(state => state.dashboard.selectedEquipment)

  //Function to set unselected equipment...
  const handleSelectedEquipmentChange = (e, values ) => {

    //equipmentOptions = [ 1, 2, 3]
    //unselected = []
    //values = [1, 2] //3 is unselected
    const unselected =
      equipmentOptions.filter(equipment => values.includes(equipment) === false)

    dispatch(actions.changeEquipmentFilter({ unselected, selected: values }))
  }

  return (
    <Autocomplete
      multiple
      style={{ width: 300 }}
      value={ selectedEquipment }
      sx={{ maxHeight: 50 }}
      size="small"
      onChange={ (e,values) => dispatch(actions.setSelectedDrivers(values)) }
      getOptionLabel={ option => option.label }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          { option.label }
        </li>
      )}
      disableCloseOnSelect
      groupBy={ option => option.tag }
      options={ equipmentOptions }
      renderInput={params => (
        <TextField {...params} placeholder="Equipment" label="" />
      )}
    />
  )
}

const GroupOrdersToggle = () => {
  const dispatch    = useDispatch()
  const groupOrders = useSelector(state => state.dashboard.groupOrders)

  const toggleGroupOrders = () => {
    dispatch(actions.setGroupOrders(!groupOrders))
  }

  return (
    <ToggleButton
      value="group"
      size="small"
      selected={ groupOrders }
      onChange={() => {
        toggleGroupOrders()
      }}
      sx={{ mr: 2 }}>
      Group
    </ToggleButton>
  )
}


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

const OrderModal = ({ orderId, open, handleClose }) => {

  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ ...modalStyle, width: '80%', borderRadius: 2 }}>
        <Box sx={{ position: 'relative' }}>
          <OrderDetailsPage orderId={ orderId } showWarnings={ true } disableCustomerTab={ true }/>
        </Box>
      </Box>
    </Modal>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 600,
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(80vh)',
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  outline: 0
}

const SNACKBAR_DURATION = 2000
const DENVER_CENTER = { lat: 39.739235, lng: -104.990250 }
