import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress';
import { DashboardContext, OrderPageContext } from '../contexts'
import { Document, Page, pdfjs } from 'react-pdf';
import { gql, useMutation, useLazyQuery, } from '@apollo/client';
import { useAPIOrder, useOrderId, useContact } from '../redux/dashboardSlice'
import { useSelector, useDispatch } from 'react-redux'
import InvoiceStatus from '../components/InvoiceStatus'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { useCompanyId } from '../hooks'
import { actions, useCustomer, usePageSelector } from '../redux/dashboardSlice'
import { InvoiceIcon, PencilIcon } from '../icons'

//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

export default function InvoiceModal(props) {
  const {
    open,
    handleClose,
    onCreateInvoice,
    refetchCustomerOrders
  } = props

  const apiOrder  = useAPIOrder()
  const companyId = useCompanyId()
  const orderId   = useOrderId()
  const dispatch  = useDispatch()

  const [generateInvoiceURL]  = useMutation(GENERATE_INVOICE_URL_MUTATION)
  const [sendInvoice]         = useMutation(SEND_INVOICE_MUTATION)

  const didCreateInvoiceRef = useRef(false)

  const orderModalOpen  = useSelector(state => state.dashboard.orderModalOpen)

  const { customer, customerOption }  = useContext(OrderPageContext)
  const { openSnackbar } = useContext(DashboardContext)

  const EMPTY_LINE_ITEM = {
    description: '',
    quantity: null
  }

  const [createInvoice] =
    useMutation(CREATE_INVOICE_MUTATION)

  const [ numPages, setNumPages ]     = useState(null);
  const [ pageNumber, setPageNumber ] = useState(1);

  const invoiceId       = usePageSelector(page => page.invoiceId)
  const invoiceUrl      = usePageSelector(page => page.invoiceUrl)
  const invoice         = usePageSelector(page => page.invoice)
  const loadingInvoice  = usePageSelector(page => page.loadingInvoice)

  const [ lineItems, setLineItems ]       = useState([ EMPTY_LINE_ITEM ])
  const [ invoiceItems, setInvoiceItems ] = useState({})
  const [ hasTripsWithInvoiceItems, setHasTripsWithInvoiceItems ] = useState(false)

  const { tripMap, itemMap } = useContext(OrderPageContext)

  //Effect to set the invoice id based on the apiOrder...
  useEffect(() => {

    if ( apiOrder?.invoice ) { //Invoice already created...
      dispatch(actions.setInvoice(apiOrder.invoice))

    }
  }, [ apiOrder ])

  useEffect(() => {
    if ( !invoice ) {
      return;
    }

    if ( invoice.lineItems ) {
      setLineItems(items => {
        return [ ...invoice.lineItems ]
      })
    }

    if ( invoice.pdfUrl ) {
      const params              = new URL(invoice.pdfUrl).searchParams
      const expirationTimestamp = params.get('Expires')
      const expiresTime         = moment(parseInt(expirationTimestamp) * 1000)

      if ( expiresTime.isAfter(moment()) ) {
        dispatch(actions.setInvoiceUrl(invoice.pdfUrl))
      } else {
        dispatch(actions.setLoadingInvoice(true))
        generateInvoiceURL({
          variables: {
            orderId,
            invoiceId: invoice.id
          }
        })
        .then( response => {
          if ( response.data ) {
            const { url } = response.data.generateInvoiceURL
            dispatch(actions.setInvoiceUrl(url))
            onCreateInvoice()
          }
        })
      }
    }
  }, [ invoice ])

  function handleCreateInvoice() {
    console.log("Creating invoice for order id", orderId)

    dispatch(actions.setLoadingInvoice(true))
    createInvoice({
      variables: {
        orderId
      }
    })
    .then(response => {
      const { data } = response
      if ( data.createInvoice?.invoice ) {
        didCreateInvoiceRef.current = true
        const { invoice } = data.createInvoice
        dispatch(actions.setInvoice(invoice))
        onCreateInvoice()
      }
    })
  }

  function handleSendInvoice() {
    openSnackbar("Pending post-launch testing. Edit invoice via Quickbooks ✨")
    return;
    if ( orderId ) {
      sendInvoice({
        variables: {
          orderId
        }
      })
      .then( response => {
        if ( response.data ) {
          openSnackbar("Successfully sent invoice ✨") 
        }
      })
    }
  }

  function addLineItem() {
    setLineItems( items => [ ...items, EMPTY_LINE_ITEM ])
  }

  function addLineItemsFromRoute() {

    const additionalItems = _.keys(invoiceItems).reduce(( list, itemId ) => {

      const allActions = invoiceItems[ itemId ].map( info => ({
        description:
        `(${ info.quantity }) ${ itemMap[ itemId ].description } from ` +
        `${ info.pickup + 1} to ${ info.dropoff + 1 }`,
        amount: 200
      }))

      return [
        ...list,
        ...allActions
      ]

    }, [])

    setLineItems( items => [ ...additionalItems ])
  }

  function onDocumentTripSuccess({ numPages }) {
    console.log({ numPages })
    setNumPages(numPages);
  }

  const height        = orderModalOpen ? 'calc(60vh)' : 'calc(80vh)'
  const sectionHeight = orderModalOpen ? 'calc(50vh)' : 'calc(70vh)'

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: orderModalOpen ? '60%': '80%',
    transform: 'translate(-50%, -50%)',
    minHeight: height,
    maxHeight: height,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    outline: 0
  }

  return (
    <>
      <Modal
        open={ open }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Box sx={{ ...modalStyle, borderRadius: 2, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Grid container className="invoice-modal">
              <Grid
                item
                xs={ 4 }
                style={{
                    paddingBottom: 40,
                    paddingRight: 20,
                    paddingLeft: 40,
                    height: height,
                    overflowY: 'scroll',
                    boxShadow: '4px 0px 16px rgba(0, 0, 0, 0.06)'
                }}>
                  <div style={{ padding: 40 }}>
                    <h2
                      style={{
                          borderBottom: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                      }}>
                      INVOICE<PencilIcon />
                    </h2>
                    <h3>
                      Review Invoice
                    </h3>
                    <div className="document-title">
                      <InvoiceIcon />&nbsp;
                      <p style={{ fontSize: 20 }}>
                        { customerOption?.label }
                      </p>
                    </div>
                    <hr />
                    { invoiceUrl ?
                      <>
                        <InvoiceStatus />
                        <h3>
                          Bill To
                        </h3>
                        <hr />
                        <h3>
                          Line Items
                        </h3>
                        {
                          lineItems.map(( item, index ) => (
                            <div>
                              <h5><em>Line Item { index + 1}</em></h5>
                              { [{ label: 'Description', field: 'description' },
                                { label: 'Amount', field: 'amount' },
                                { label: 'Quantity', field: 'quantity' },
                              ].map( e =>
                                <>
                                  <label>{ e.label }</label><br />
                                  <TextField
                                    value={ item[ e.field ] }
                                    size="small"
                                    sx={{ width: '100%' }}
                                    className="contact-field"
                                    id="filled-basic" label="" />
                                  <br />
                                </>
                              )
                              }
                            </div>
                          ))
                        }
                        <Button
                          onClick={ addLineItem }
                          sx={{ backgroundColor: 'transparent !important', p: 0, mb: 3 }}>Add line item</Button>
                        <h3>
                          Notes
                        </h3>
                        <div>
                          <TextField
                            multiline
                            minRows={ 3 } style={{ width: '100%', marginTop: 5 }} sx={{
                                mt: 4,
                                '& textarea': {
                                  padding: '10px'
                                }

                            }} />
                        </div>
                        <Button sx={{ mt: 3, width: '100%' }}
                          onClick={ () => openSnackbar("Pending post-launch testing. Edit invoice via Quickbooks ✨") }
                          variant="contained">
                          Update
                        </Button>
                      </>
                      :
                      <Button variant="contained"
                        onClick={ handleCreateInvoice }
                        sx={{ mb: 1,mt:1, mr: 3, width: '100%' }}>
                        Create Invoice
                      </Button>
                    }
                  </div>
              </Grid>
              <Grid item xs={ 8 } sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                <div style={{ ...( invoiceUrl ? {} : noInvoiceStyles), maxHeight: sectionHeight, minHeight: sectionHeight, overflowY: 'scroll', padding: 10 }}>
                  { loadingInvoice ?
                    <CircularProgress size={ 60 } />
                    :
                    <Document
                      loading={ <div></div> }
                      noData="Create the invoice to get started 💸"
                      file={ invoiceUrl }
                      onLoadSuccess={onDocumentTripSuccess}>
                      <Page pageNumber={pageNumber}
                        renderMode='svg'
                        loading={ <div></div> }
                        renderTextLayer={ false } />
                    </Document>
                  }
                </div>
                { invoiceUrl ?
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained"
                      onClick={ handleSendInvoice }
                      sx={{ mb: 1,mt:1, mr: 3 }}>
                      Send Invoice
                    </Button>
                  </div>
                  :
                  null
                }
              </Grid>
            </Grid>
        </Box>
      </Modal>
    </>
  )
}

const PDF_URL = process.env.REACT_APP_BACK_END_URI

//https://www.chartjs.org/docs/latest/charts/bubble.html
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CREATE_INVOICE_MUTATION = gql`
mutation Mutation($orderId: String!) {
  createInvoice(orderId: $orderId) {
    message
    code
    invoice {
      id
      pdfUrl
      lineItems {
        id description amount quantity metadata { tripId }
      }
    }
  }
}
`
const GENERATE_INVOICE_URL_MUTATION = gql`
mutation Mutation($invoiceId: String!, $orderId: String!) {
  generateInvoiceURL(invoiceId: $invoiceId, orderId: $orderId) {
    message
    code
    url
  }
}
`
const SEND_INVOICE_MUTATION = gql`
mutation SendInvoiceMutation($orderId: String!) {
  sendInvoice(orderId: $orderId) {
    message
    code
  }
}
`
const noInvoiceStyles = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
