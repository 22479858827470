import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import * as TripUtils from '../utils/TripUtils'
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import { OrderPageContext } from '../contexts'
import { actions } from '../redux/dashboardSlice'

export default function StopInstruction(props) {

  const {
    tripId,
    stopIndex,
    stopId,
    options,
    instruction,
    isHoveringOnStop,
    index,
    toggleInstructionIsPick,
    removeInstruction
  } = props

  const dispatch = useDispatch()
  const { itemMap, tripMap, setTripMap } = useContext(OrderPageContext)

  const menuOptions =
    TripUtils.computeInstructionOptions({
      tripMap,
      tripId,
      stopId: stopId,
      instruction
    })

  const handleQuantityChange = e => {
    const value = e.target.value

    if ( value < 1 && value !== '') {
      return; //Can't pickup/dropoff negative items
    }

    dispatch(actions.changePickupOrDropoffQuantity({
      tripId,
      stopId,
      instruction,
      value: value ? parseInt(value) : '',
      instructionIndex: instruction.order
    }))
  }

  const onClose = e => {
    e.target.blur()
  }

  const changeSelectedItem = (e, currentInstruction) => {
    const options = {
      tripId,
      stopId,
      selectedType: currentInstruction.type,
      selectedItemId: e.target.value,
      instruction: currentInstruction
    }

    dispatch(actions.changeSelectedItem(options))
  }

  const instructionOptions = []

  return (
    <div>
      <div
        className="instruction-row"
        style={{
            marginTop: 5,
            textDecorationLine: instruction.isComplete ? 'none': 'none'
        }}>
        <Button
          variant="outlined"
          className="instruction-option"
          sx={{ mr: 1 }}
          onClick={ () => toggleInstructionIsPick(index) }>
          { instruction.type === 'pickup' ? "PICKUP" : "DROPOFF" }
        </Button>
        <TextField
          size="small"
          value={ instruction.quantity }
          onChange={ handleQuantityChange }
          sx={{ width: 100 }}
          type="number"/>
        <FormControl
          sx={{ m: 1, minWidth: 200 }}
          variant="standard" size="small">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onClose={ onClose }
            value={ instruction.itemId ? instruction.itemId : "" }
            onChange={ e => changeSelectedItem(e, instruction) }
            label="Item"
          >
            { menuOptions.map(( option, key ) => (
              <MenuItem key={ key } value={ option.itemId }>
                { itemMap[ option.itemId ] ? computeLabel(itemMap, option.itemId ) : null }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DeleteIcon
          sx={{ visibility: isHoveringOnStop ? 'visible' : 'hidden' }}
          onClick={ () => removeInstruction(index) }
          className="delete-instruction" fontSize="small" />
      </div>
      { instruction.isComplete ?
        <p style={{ margin: 0 }}>
          COMPLETED BY JOHN D. 12/3/2023
        </p>
      : null 
      }
    </div>
  )
}

function computeLabel(itemMap, itemId) {
  if ( itemMap[ itemId ] ) {
    const { manufacturer, model, description } = itemMap[ itemId ]
    return `${ description }${ manufacturer ? ', ' + manufacturer : ''}` +
      `${ model ? ' ' + model : ''}`
  }
  return ''
}
