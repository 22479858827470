import React, { useCallback } from 'react'
import { useDatabase } from '../hooks'

import { useCustomer } from '../redux/dashboardSlice'
import { CustomerAutocomplete } from '../components/CustomerAutocomplete'
import OrderInputContainer from './OrderInputContainer'
import LabelLeftContainer from './LabelLeftContainer'
import InputRightContainer from './LabelRightContainer'
import { handleSearch } from '../utils'

export default function CustomerInput(props) {

  const db = useDatabase()

  const { handleSelectCustomerId, customer } = props

  //Object with { label: "", value: "" }
  const { customerOption }    = useCustomer(customer.id)
  const handleCustomerSearch  = useCallback(handleSearch, [])

  const onValueChange = option => {
    //Set the customer id...
    handleSelectCustomerId(option?.value ? option.value : null)
  }

  return (
    <OrderInputContainer>
      <LabelLeftContainer label="Customer">
      </LabelLeftContainer>
      <InputRightContainer>
        <CustomerAutocomplete
          customerOption={ customerOption }
          onValueChange={ onValueChange }/>
      </InputRightContainer>
    </OrderInputContainer>
  )
}
