import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { getDownloadURL } from "firebase/storage";
import useMeasure from 'react-use-measure'
import {useDropzone} from 'react-dropzone'
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux'
import MapComponent from '../components/MapComponent'
import CheckIcon from '@mui/icons-material/Check';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { saveAs } from 'file-saver';
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'
import _ from 'lodash'
import { useGroupId } from '../hooks'
import CustomerInput from '../components/CustomerInput'
import TurnRightIcon from '@mui/icons-material/TurnRight'
import SaveAndDispatchDialog from '../components/SaveDialog'
import Dialog from '@mui/material/Dialog';
import Portal from '@mui/base/Portal';
import Fab from '@mui/material/Fab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LockIcon from '@mui/icons-material/Lock';
import CustomerTab from '../components/CustomerTab'
import ActivityTab from '../components/ActivityTab'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import { getStorage, uploadBytes, ref } from "firebase/storage";
import AddIcon from '@mui/icons-material/Add';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import { ContactIcon } from '../icons'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InvoiceModal from '../components/InvoiceModal'
import QuoteModal from '../components/QuoteModal'
import DropzoneModal from '../components/DropzoneModal'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { createReactEditorJS } from 'react-editor-js'
import { motion, AnimatePresence } from 'framer-motion'
import moment from 'moment'

import { OrderContext, OrderPageContext, DashboardContext, AppContext } from '../contexts'
import * as TripUtils from '../utils/TripUtils'
import * as OrderUtil from '../utils/OrderUtil'
import * as Utils from '../utils'
import CalendarDialog from '../components/CalendarDialog'
import DriverModal from '../components/DriverModal'
import FloatingMap from '../components/FloatingMap'
import ContactModal from '../components/ContactModal'
import MultiItemInput from '../components/MultiItemInput'
import Trip from '../components/Trip'

import { useAPIOrder, usePageSelector, useContact } from '../redux/dashboardSlice'
import { wait } from '../utils'
import InputRightContainer from '../components/LabelRightContainer.js'
import LabelLeftContainer from '../components/LabelLeftContainer'
import OrderInputContainer from '../components/OrderInputContainer'
import { actions } from '../redux/dashboardSlice'
import { useOrderId, useCustomer } from '../redux/dashboardSlice'
import { useCompanyId } from '../hooks'
import { GET_ORDER_QUERY, GET_ORDERS_QUERY } from '../utils/api'
import * as api from '../utils/api'
import { storage } from '../config'

const ReactEditorJS = createReactEditorJS()

const withHydratedOrder = Component => {

  return props => {

    const { orderId: orderIdFromProps, ...rest }  = props
    const { orderId: orderIdFromParams }          = useParams()

    const orderId = orderIdFromProps ? orderIdFromProps : orderIdFromParams

    const dispatch      = useDispatch()
    const pageHydrated  = useSelector( state => state.dashboard.pages[ orderId ] )

    const { data, refetch: refetchOrder } = useQuery(GET_ORDER_QUERY, {
      variables: { orderId },
      skip: pageHydrated
    })

    useEffect(() => {
      if ( !data ) {
        return;
      }

      const order = data.getOrder

      if ( order ) {
        dispatch(actions.hydratePage({ orderId, fetchedOrder: order }))
      } else {
        dispatch(actions.initializePage({ orderId }))
      }
    }, [ data ])

    if ( pageHydrated ) {
      return (
        <OrderContext.Provider value={{ orderId }}>
          <Component refetchOrder={ refetchOrder } { ...rest }>
          </Component>
        </OrderContext.Provider>
      )
    }

    return (
      <Box
        sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={ 60 } />
      </Box>
    )
  }
}

/*
 * Order details page
 */
const OrderDetailsPage = withHydratedOrder(props => {

  const {
    orderModalRef,
    refetchOrder,
    showWarnings: showWarningsFromProps,
    disableCustomerTab=false
  } = props

  const isDrawerDisplay = true

  const dispatch  = useDispatch()
  const navigate  = useNavigate()
  const groupId   = useGroupId()
  const orderId   = useOrderId()
  const companyId = useCompanyId()

  const [ searchParams ]        = useSearchParams()
  const [containerRef, bounds]  = useMeasure()
  const [width, height]         = useWindowSize()
  const [updatePrintPreview]    = useMutation(UPDATE_PRINT_PRIVIEW_MUTATION)
  const [deleteOrders]          = useMutation(DELETE_ORDERS_MUTATION)

  const [copyOrder ]      = useMutation(COPY_ORDER_MUTATION)

  const {
    drawerIsOpen,
    openSnackbar,
    refreshDashboardOrders,
  } = useContext(DashboardContext)

  const apiOrder      = useAPIOrder()
  const page          = usePageSelector(page => page)
  const order         = usePageSelector(page => page.order)

  //Order
  const dispatched      = usePageSelector(page => page.dispatched)
  const dispatchedTime  = usePageSelector(page => page.dispatchedTime)
  const items           = usePageSelector(page => page.items)
  const customer        = usePageSelector(page => page.order.customer)    //resolve
  const createdTime     = usePageSelector(page => page.order.createdTime) //resolve
  const tripMap         = usePageSelector(page => page.order.trips)       //resolve

  const [createOrder]     = useMutation(CREATE_ORDER_MUTATION, {
    update(cache, { data }) {
      const updatedOrder = data?.createOrder?.order
      updateCachedOrder(cache, updatedOrder)
    }
  })

  const [updateOrder] = useMutation(UPDATE_ORDER_MUTATION, {
    update(cache, { data }) {
      const updatedOrder = data?.updateOrder?.order
      updateCachedOrder(cache, updatedOrder)
    }
  })

  const onDrop = useCallback(acceptedFiles => {
    const [ file ]  = acceptedFiles
    const reader    = new FileReader()

    reader.onabort  = () => console.log('file reading was aborted')
    reader.onerror  = () => console.log('file reading has failed')
    reader.onload   = () => {
      const binaryStr = reader.result
      dispatch(actions.setDroppedFile({
        file,
        binaryStr
      }))
    }

    reader.readAsArrayBuffer(file)
    
  }, [])

  const { getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  function updateCachedOrder(cache, updatedOrder) {

    //Get the customer id to update the
    //customer orders list if necessary...
    const customerId = customer.id

    //Read the customer orders query that we may have previously ran...
    const getOrdersQuery = cache.readQuery({
      query: GET_ORDERS_QUERY,
      variables: {
        query: {
          customerId: customer?.id
        }
      }
    })

    const existingCustomerOrders = getOrdersQuery?.getOrders

    //If there are existing customer orders, update the cache...
    if ( existingCustomerOrders ) {

      const updatedOrders =
        existingCustomerOrders.map(o => ( o.id !== updatedOrder.id ) ? o : updatedOrder)

      cache.writeQuery({
        query: GET_ORDERS_QUERY,
        variables: {
          query: {
            customerId
          }
        },
        data: {
          getOrders: updatedOrders
        },
      })
    }
  }

  const [revokeOrderDispatch]   = useMutation(REVOKE_ORDER_DISPATCH_MUTATION, {
    update(cache, { data }) {
      const updatedOrder = data?.revokeOrderDispatch?.order
      if ( ! updatedOrder ) {
        return;
      }
      updateCachedOrder(cache, updatedOrder)
    }
  })
  //const loadingCustomerOrders = usePageSelector(page => page.loadingCustomerOrders)

  //Save
  const saveDialogOpen    = usePageSelector(page => page.saveDialogOpen)
  const saveDialogTitle   = usePageSelector(page => page.saveDialogTitle)
  const requestedDispatch = usePageSelector(page => page.requestedDispatch)

  //Page
  const contacts            = usePageSelector(page => page.contacts)
  const customerOrders      = usePageSelector(page => page.customerOrders)
  const orderMenuAnchorEl   = usePageSelector(page => page.orderMenuAnchorEl)
  const confirmationMessage = usePageSelector(page => page.confirmationMessage)
  const sortedPickupDates   = usePageSelector(page => page.sortedPickupDates)
  const pickupDateMap       = usePageSelector(page => page.pickupDateMap)
  const selectedItemsMap    = usePageSelector(page => page.selectedItemsMap)
  const overrideShowErrors  = usePageSelector(page => page.overrideShowErrors)
  const showPresaveErrors   = usePageSelector(page => page.showPresaveErrors)
  const tabValue            = usePageSelector(page => page.tabValue)
  const saveTimestamp       = usePageSelector(page => page.saveTimestamp)
  const unsavedDialogOpen   = usePageSelector(page => page.unsavedDialogOpen)
  const itemMap             = usePageSelector(page => page.itemMap) //resolve

  //Modals
  const copyModalOpen     = usePageSelector(page => page.copyModalOpen)
  const copyOrderDate     = usePageSelector(page => page.copyOrderDate)
  const invoiceModalOpen  = usePageSelector(page => page.invoiceModalOpen)
  const quoteModalOpen    = usePageSelector(page => page.quoteModalOpen)
  const dropzoneModalOpen = usePageSelector(page => page.dropzoneModalOpen)
  const contactModalOpen  = usePageSelector(page => page.contactModalOpen)
  const driverModalOpen   = usePageSelector(page => page.driverModalOpen)
  const orderModalOpen    = useSelector(state => state.dashboard.orderModalOpen)

  const [dateValue, setDateValue] = useState(dayjs('2014-08-18T21:11:54'))

  const handleDateChange = newValue => setDateValue(newValue)

  const modalContact        = usePageSelector(page => page.modalContact)
  const modalDriver         = usePageSelector(page => page.modalDriver)
  const loadingPrintPreview = usePageSelector(page => page.loadingPrintPreview)

  //Errors + Warnings
  const showWarnings        = usePageSelector(page => page.showWarnings)
  const orderErrorsPreSave  = usePageSelector(page => page.orderErrorsPreSave)
  const orderErrors         = usePageSelector(page => page.orderErrors)
  const tripErrors          = usePageSelector(page => page.tripErrors)
  const tripWarnings        = usePageSelector(page => page.tripWarnings)
  const tripErrorsGlobal    = usePageSelector(page => page.tripErrorsGlobal)
  const tripWarningsGlobal  = usePageSelector(page => page.tripWarningsGlobal)
  const changesExist        = usePageSelector(page => page.changesExist)
  const orderMenuOpen       = Boolean(orderMenuAnchorEl)

  const orderOpenedTimestamp = useRef()
  const tripMapRef        = useRef()
  const createdInvoiceRef = useRef(false)
  tripMapRef.current      = tripMap

  const { customerOption } = useCustomer(customer.id)

  const { data: ordersData, networkStatus, loading: loadingCustomerOrders, refetch: refetchCustomerOrders } =
    useQuery(GET_ORDERS_QUERY, {
      variables: {
        query: {
          customerId: customer.id
        }
      },
      notifyOnNetworkStatusChange: true,
      skip: tabValue === 0 || _.isEmpty(customer.id)
    })

  const {
    data: contactQueryData,
    networkStatus: contactQueryNetworkStatus,
    refetch: refetchContacts
  } = useQuery(GET_CONTACTS, {
      variables: {
        customerId: customer.id
      },
      skip: _.isEmpty(customer.id),
      notifyOnNetworkStatusChange: true,
    })

  //Dialog
  const handleOpenSaveDialog = () => {
    dispatch(actions.setSaveDialogOpen(true))
  }

  const handleCloseSaveDialog = () => {
    dispatch(actions.setSaveDialogOpen(false))
  }

  const handleSave = requestedDispatch => {
    dispatch(actions.setRequestedDispatch(requestedDispatch))

    //We always show errrors when we save regardless of whether we're
    //dispatching...
    dispatch(actions.setShowPresaveErrors(true))

    //Always show warnings...
    dispatch(actions.setShowWarnings(true))

    //Determine whether we can save...
    const { hasWarnings, hasErrors } = OrderUtil.getValidationInfo(page)

    const { dispatched: hasDispatchedTrips } = OrderUtil.hasDispatchedTrips(apiOrder)

    const dispatchedOrDispatching = requestedDispatch || hasDispatchedTrips

    if ( dispatchedOrDispatching ) {

      if ( hasWarnings || hasErrors ) {
        //Please address errors & warnings prior to saving...
        const message = "Please address all issues before proceeding"
        openSnackbar(message)
      } else {
        //Do save...
        const title = OrderUtil.hasDispatchedTrips(apiOrder) ? 'Save and Dispatch' : 'Save'
        dispatch(actions.setSaveDialogTitle(title))

        const confirmationMessage = hasDispatchedTrips ?
          "This order has already been dispatched. Saving the order will update what your drivers see in the Titan mobile app." :
          "Dispatching the trip will allow the driver to see it on their dashboard and will send the customer a notification that the trip has been scheduled."
        dispatch(actions.setConfirmationMessage(confirmationMessage))
        handleOpenSaveDialog()
      }
    } else {
      dispatch(actions.setSaveDialogTitle('Save'))

      if ( hasErrors ) {
        openSnackbar("Please address all errors before proceeding")
      } else if ( hasWarnings ) {
        dispatch(actions.setConfirmationMessage("This order has warnings. Save anyway?"))
        handleOpenSaveDialog()
      } else if ( !hasErrors ) {
        const message = "Save the order in the database. Your drivers will not be able to see the order until it is dispatched."
        dispatch(actions.setConfirmationMessage(message))
        handleOpenSaveDialog()
      }
    }
  }

  const sendQuote = () => {
  }

  const handleConfirmSave = (overrideDispatchStatus=false) => {

    handleCloseSaveDialog()

    //Determine whether the user is dispatching
    //the order for the first time...
    const dispatching   =
      saveDialogTitle.toLowerCase().includes('dispatch') &&
      ( _.isNil( apiOrder ) || _.isNil( apiOrder.trips.find(t => t.dispatched )))

    const result        = OrderUtil.getOrderDifferences({ apiOrder, page, dispatching, groupId })
    const { orderDiff } = result

    if ( _.isEmpty( orderDiff ) && !dispatching ) {
      openSnackbar("No changes exist")
      return; //No updates...
    }

    const isNewOrder  = _.isNil(apiOrder)
    const orderUpdate = OrderUtil.getOrderUpdate({ orderDiff, orderId, requestingDispatch: dispatching })

    const onSaveFinsished = (response, api) => {
      const { data } = response

      if ( data[ api ] ) {

        //Update the GraphQL cache...
        const { order } = data[ api ]

        if ( order ) {

          if ( orderId === 'new' ) {
            navigate(`/orders/${ order.id }` + '?showWarnings=true')

            dispatch(actions.hydratePage({
              fetchedOrder: order,
              orderId: order.id,
              closeOrderModal: true
            }))

          } else {

            dispatch(actions.hydratePage({
              fetchedOrder: order,
              orderId: order.id,
              closeOrderModal: false
            }))
          }
        }

        openSnackbar("Saved order " + orderId)
      }
    }

    if ( isNewOrder ) {
      createOrder({
        variables: {
          order: orderUpdate
        }
      })
      .then( response => onSaveFinsished(response, 'createOrder'))
    } else {
      updateOrder({
        variables: {
          order: orderUpdate
        }
      })
      .then( response => onSaveFinsished(response, 'updateOrder'))
    }
  }

  const copyToNew = async ({ orderId, copyOrderDate }) => {

    const title = "Copying order to " +
      copyOrderDate.format('ddd, MMMM DD, YYYY')

    dispatch(actions.openLoadingModal(title))

    const referenceDate =
      copyOrderDate
      .clone()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)

    copyOrder({
      variables: {
        orderId,
        copyToDate: referenceDate.format("YYYY-MM-DD")
      }
    })
    .then( response => {
      if ( response.data ) {
        const { copyOrder: { order } } = response.data

        navigate('/') //Close the side drawer...

        dispatch(actions.setFinishedOrderCopy(order))

        //Now that the date has been changed
        //open the order...
        setTimeout(() => {
          navigate('/orders/' + order.id)
        }, 4000)
      }
    })
    .catch(err => {
      dispatch(actions.setLoadingModalOpen(false))
    })

  }

  const handleAddEmptyTrip = useCallback(() => {
    //Create one empty trip...
    dispatch(actions.addEmptyTrip())
  }, [ dispatch ])

  //Add an additional item
  const handleAddItem = () => {
    dispatch(actions.addEmptyItem())
  }

  const handleSelectCustomerId = id => {
    dispatch(actions.setCustomerId(id))
  }

  const handleUpdatePrintPreview = () => {
    if ( orderId ) {
      dispatch(actions.setLoadingPrintPreview(true))
      updatePrintPreview({
        variables: {
          orderId
        }
      })
      .then(response => {
        dispatch(actions.setLoadingPrintPreview(false))
        const { data } = response
        if ( data?.updatePrintPreview ) {
          const printPreviewData = data.updatePrintPreview
          const { url } = printPreviewData

          if ( url ) {
            const title = orderId
            const win = window.open(url, "myWindow", "_blank")
          }
        }
      })
    }
  }

  useEffect(() => {
    if ( !dropzoneModalOpen && isDragActive ) {
      dispatch(actions.setDropzoneModalOpen(true))
    }
  }, [ dropzoneModalOpen, isDragActive ])

  useEffect(() => {
    if ( !invoiceModalOpen && createdInvoiceRef.current ) { //User created invoice, refresh the order...
      refetchOrder({
        variables: {
          orderId
        }
      })
      createdInvoiceRef.current = false
    }
  }, [ orderId, refetchOrder, invoiceModalOpen ])

  useEffect(() => {
    if ( apiOrder ) {
      dispatch(actions.hydratePage({ fetchedOrder: apiOrder, orderId: apiOrder.id }))
    }
  }, [ apiOrder ])

  useEffect(() => {
    if ( drawerIsOpen ) {
      orderOpenedTimestamp.current = moment().unix()
    }
  }, [ drawerIsOpen ])

  useEffect(() => {
    if ( overrideShowErrors ) {
      dispatch(actions.setShowWarnings(false))
    }

    const showWarningsFromParams =
      searchParams.get("showWarnings")

    if ( showWarningsFromParams === 'true' || showWarningsFromProps ) {
      dispatch(actions.setShowWarnings(true))
    }

  }, [ overrideShowErrors, searchParams, showWarningsFromProps ])

  const onCreateInvoice = useCallback(() => {
    createdInvoiceRef.current = true
  }, [])

  const handlePickupDateChange = useCallback((tripId, date) => {
    if ( _.isEmpty( tripId ) || _.isEmpty( date ) ) {
      return;
    }

    return;
    //TODO
    dispatch(actions.updatePickupDate({
      tripId,
      date
    }))

  }, [])

  //Value has label, value, quantity etc.
  const handleItemValueChange = async ( option, index ) => {

    const valuePrevious = items[ index ]?.value

    if ( _.isEmpty(option) ) {
      return;
    }

    if ( _.isEmpty(valuePrevious) === false && valuePrevious !== option ) {

      //TODO: Ask user whether they want to change all references from this to that...
      dispatch(actions.replaceItemId({
        itemIdBefore: valuePrevious,
        newItemId: option.value
      }))

    }

    dispatch(actions.setItemValue({ index, option }))

  }

  //Set the quantity to the new value...
  const handleQuantityChange = ({ itemId, quantity }) => {
    dispatch(actions.changeItemQuantity({ itemId, quantity }))
  }

  const handleQuoteModalOpen = () => {
    dispatch(actions.setBackdropOpen(true))
    setTimeout(() => {
      dispatch(actions.setQuoteModalOpen(true))
      setTimeout(() => {
        dispatch(actions.setBackdropOpen(false))
      }, 100)
    }, 300)
  }

  //Modals
  const handleInvoiceModalOpen  = () => {
    dispatch(actions.setBackdropOpen(true))
    setTimeout(() => {
      dispatch(actions.setInvoiceModalOpen(true))
      setTimeout(() => {
        dispatch(actions.setBackdropOpen(false))
      }, 100)
    }, 300)
  }

  const handleInvoiceModalClose   = () => dispatch(actions.setInvoiceModalOpen(false))
  const handleQuoteModalClose     = () => dispatch(actions.setQuoteModalOpen(false))
  const handleDropzoneModalClose  = () => dispatch(actions.setDropzoneModalOpen(false))

  const contactModalSubscriberRef = useRef()
  const driverModalSubscriberRef  = useRef()
  const inputFile                 = useRef()

  const handleBolClick = () => {
    console.log("Handle bol")
    if ( apiOrder?.bolUpload ) {
      getDownloadURL(ref(storage, apiOrder.bolUpload.location ))
      .then((url) => {

        console.log('url', { url })
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          saveAs(blob, 'bol.pdf')
        };
        xhr.open('GET', url);
        xhr.send();
      })
    } else {
      inputFile.current.click();
    }
  }

  const handleBolUpload = e => {
    const location = `companies/${ companyId }/orders/${ orderId }/bol`
    console.log("HANDLE")

    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0]
    api.handleFileUpload(file, location)
  }

  const handleDriverModalOpen = (subscriber=null, driver=null) => {
    dispatch(actions.setModalDriver(driver))
    dispatch(actions.setDriverModalOpen(true))
    driverModalSubscriberRef.current = subscriber
  }

  const handleDriverModalClose = selectedDriver => {
    dispatch(actions.setDriverModalOpen(false))
    if ( selectedDriver && driverModalSubscriberRef.current ) {
      driverModalSubscriberRef.current(selectedDriver)
    }
  }

  const addDispatcherToOrder = contact => {
    dispatch(actions.addDispatcher({ contact }))
  }

  const handleContactModalOpen = (subscriber=null, contact=null) => {
    dispatch(actions.setModalContact(contact))
    dispatch(actions.setContactModalOpen(true))
    contactModalSubscriberRef.current = subscriber
  }

  const handleContactModalClose = selectedContact => {
    dispatch(actions.setContactModalOpen(false))
    if ( selectedContact && contactModalSubscriberRef.current ) {
      //Avoid expensive state updates on user click...
      //TODO
      contactModalSubscriberRef.current(selectedContact)
    }
  }

  const navigateToTab = tabValue => {
    dispatch(actions.setTabValue(tabValue))
  }

  const handleTabChange = (event, newValue) => {
    if ( newValue === 2 ) {
      openSnackbar("Pending Post launch ✨")
    } else {
      dispatch(actions.setTabValue(newValue))
    }
  }

  useEffect(() => {
    //TODO: Move to reducer...
    const sortedPickupDates =
      _.keys(pickupDateMap).map(tripId => pickupDateMap[ tripId ].unix())
      .sort((d1, d2) => d1 - d2 )
      .map(d => moment(d * 1000))

    dispatch(actions.setSortedPickupDates(sortedPickupDates))
  }, [ pickupDateMap ])

  useEffect(() => {
    if ( ordersData ) {
      const { getOrders: apiOrders } = ordersData

      const {
        equipment: equipmentMap,
        items: itemMap
      } = OrderUtil.getRelatedFields(apiOrders)

      const rows = OrderUtil.mapOrdersToOrderRows({
        apiOrders,
        includeDisplayData: false,
        equipmentMap,
        itemMap
      })
      .filter( row => {
        return row.metadata?.order?.id !== orderId
      })
      .sort(OrderUtil.sortBy('time', true))

      dispatch(actions.setCustomerOrders(_.cloneDeep(rows)))
    }
  }, [ ordersData, networkStatus, orderId ])

  //VALIDATE: User assigned customer to order...
  useEffect(() => {
    if ( _.isEmpty( customer.id ) ) {
      dispatch(actions.addPresaveError(OrderErrors.NO_CUSTOMER))
    } else {
      dispatch(actions.removePresaveError(OrderErrors.NO_CUSTOMER))
    }
  }, [ customer.id ])

  useEffect(() => {

    const nonEmptyOrderItem =
      items.find(i => _.isEmpty( i.value) === false )

    const orderHasAtleastOneItem = _.isNil(nonEmptyOrderItem) === false

    if ( !orderHasAtleastOneItem ) {
      dispatch(actions.addPresaveError(OrderErrors.NO_ITEMS))
    } else {
      dispatch(actions.removePresaveError(OrderErrors.NO_ITEMS))
    }
  }, [ items ])

  const didLoadCustomerOrdersRef = useRef(false)

  const handleOrderMenuClose = () => {
    dispatch(actions.setOrderMenuAnchorEl(null))
  }

  const handleDeleteOrder = () => {
    dispatch(actions.setOrderMenuAnchorEl(null))
    openSnackbar("Deleting order...")
    if ( orderId ) {
      deleteOrders({
        variables: {
          orderIds: [ orderId ]
        }
      })
      .then(() => {
        if ( ! orderModalOpen ) {
          navigate('/')
        } else {
          //refetchCustomerOrders()
        }
        dispatch(actions.closeOrderModal())
        openSnackbar("Successfully deleted order")
      })
    }
  }

  function useStopOptions(deps) {
    useEffect(() => {
      dispatch(actions.createTripOptions())
    }, deps)
  }

  const deps = [ itemMap, selectedItemsMap, _.keys(tripMap).length ]

  useStopOptions(deps)

  //Effect to calculate selected items in the order
  useEffect(() => {

    //Note: Items will never be empty
    const selectedItemsMap = items.reduce(( map, item ) => {

      //The autocomplete value could be empty
      if ( _.isEmpty( item?.value ) === false ) {
        return {
          ...map,
          [ item.value ]: item.quantity
        }
      }

      return map
    }, {})

    dispatch(actions.setSelectedItemsMap(selectedItemsMap))

  }, [ items ])

  useEffect(() => {

    if ( contactQueryData ) {
      const { getContacts: contacts } = contactQueryData

      dispatch(actions.setContacts(contacts))
    }
  }, [ contactQueryData, contactQueryNetworkStatus ])

  const handleSelectCopyOrderDate = date => {
    console.log('here', { date })
    dispatch(actions.setCopyOrderDate(date))
  }

  const handleCloseOrderCopyModal = () => {
    dispatch(actions.setCopyModalOpen(false))
  }

  const handleConfirmCopyOrder = () => {
    dispatch(actions.setCopyModalOpen(false))
    copyToNew({ orderId, copyOrderDate })
  }

  const handleRevokeDispatch = () => {

    const tripIds = _.keys(tripMap)

    if ( _.isEmpty( tripIds ) === false ) {
      openSnackbar("Revoking trip from driver's dashboard")
      
      revokeOrderDispatch({
        variables: {
          orderId
        }
      })
      .then( response => {
        const updatedOrder = response.data?.revokeOrderDispatch?.order
        if ( updatedOrder ) {
          dispatch(actions.hydratePage({
            fetchedOrder: updatedOrder,
            orderId: order.id,
            closeOrderModal: false
          }))
          openSnackbar("Removed trip from drivers view")
        }
      })
    }
  }

  const handleCopyOrder = () => {
    dispatch(actions.setCopyModalOpen(true))
  }

  const { contactOption: dispatcherOption, contact: dispatcher } =
    useContact(order.dispatcher?.id)

  return (
    <OrderPageContext.Provider value={{
        tripMapRef,
        handleQuantityChange,
        overrideShowErrors,
        orderId,
        handlePickupDateChange,
        confirmationMessage,
        customer,
        customerOption,
        showWarnings,
        tripErrorsGlobal,
        tripWarningsGlobal,
        handleInvoiceModalOpen,
        availableItems: selectedItemsMap,
        handleContactModalOpen,
        handleDriverModalOpen,
        itemMap,
        orderErrors,
        tripMap,
        useStopOptions
    }}>
      <motion.div
        ref={ containerRef }
        className="page"
        style={{
            position: 'relative',
            marginTop: 20,
            maxHeight: 'unset'
        }}>
        <div>
          <input {...getInputProps()} accept=".pdf" />
          { tabValue === 0 ?
            <Portal>
              <FloatingMap />
            </Portal>
            :
            null
          }
          <Dialog
            open={ saveDialogOpen }
            onClose={handleCloseSaveDialog}>
            <SaveAndDispatchDialog
              requestedDispatch={ requestedDispatch }
              dialogTitle={ saveDialogTitle }
              handleClose={ handleCloseSaveDialog }
              handleConfirm={ () => handleConfirmSave() }
              handleConfirmSaveAndDispatch={ () => handleConfirmSave() }
              orderId={ orderId } />
          </Dialog>
          <CalendarDialog
            title="Copy to new order"
            date={ copyOrderDate }
            handleDateChange={ handleSelectCopyOrderDate }
            handleClose={ handleCloseOrderCopyModal }
            handleConfirm={ handleConfirmCopyOrder }
            text=""
            open={ copyModalOpen } />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tabs
              sx={{ m: 0, mb: 3, p: 0, pl: 0 }}
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example">
              <Tab label={
                <motion.div layout style={{ display: 'flex' }}>
                  Order
                </motion.div>} {...a11yProps(0)}/>
                <Tooltip title={ _.isNil(customer.id) ? "Select customer first" : null }>
                  <Tab sx={ disableCustomerTab ? { display: 'none' } : {} } label={
                    <div style={{ display: 'flex' }}>
                      Customer { loadingCustomerOrders ? <CircularProgress size={ 20 } sx={{ ml: 1 }}/> : null }
                    </div>} {...a11yProps(1)}
                    disabled={ _.isNil(customer.id) }
                  />
                </Tooltip>
              { false ?
                <Tab label={
                  <motion.div layout style={{ display: 'flex' }}>
                    Activity ✨
                  </motion.div>} {...a11yProps(2)}
                />
                :
                null
              }
            </Tabs>
            {
              tabValue === 0 && apiOrder ?
                <Button
                  disabled={_.isNil(apiOrder)}
                  variant="outlined" sx={{ height: 'fit-content' }} onClick={ handleUpdatePrintPreview }>
                  Print
                  { loadingPrintPreview ?
                    <CircularProgress size={ 10 } />
                    :
                    null
                  }
                </Button>
              :
              null
            }
          </Box>
          <TabPanel value={tabValue} index={0}>
            { dispatched === true ?
              <Alert severity="success"
                sx={{ mb: 2 }}
                action={
                  <Tooltip title="Remove trip from driver's dashboard">
                    <Button color="inherit"
                      onClick={ handleRevokeDispatch }
                      size="small">
                      Undo
                    </Button>
                  </Tooltip>
                }>
                This order has been dispatched
              </Alert>
              :
              null
            }

            <ContactModal
              contacts={ contacts }
              customer={ customer }
              refetchContacts={ refetchContacts }
              open={ contactModalOpen }
              handleClose={ handleContactModalClose }
              existingContact={ modalContact }
            />

            <DriverModal
              open={ driverModalOpen }
              handleClose={ handleDriverModalClose }
              existingDriver={ modalDriver }
            />

            <InvoiceModal
              open={ invoiceModalOpen }
              onCreateInvoice={ onCreateInvoice }
              handleClose={ handleInvoiceModalClose }
              />
            <QuoteModal
              open={ quoteModalOpen }
              handleClose={ handleQuoteModalClose }
              />
            <DropzoneModal
              open={ dropzoneModalOpen }
              handleClose={ handleDropzoneModalClose }
              />
            <Grid container spacing={0}>
              <Grid item xs={ isDrawerDisplay ? 12 : 8 }>
                <Box
                  className="flex"
                  sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <div>
                      <div
                        className="flex"
                          style={{
                              alignItems: 'center',
                          }} >
                        <h1 style={{ margin: 0, fontWeight: 500 }}>
                          Order: { Utils.truncateId(orderId) }
                        </h1>
                        { dispatched === true ?
                          null
                          :
                          <Tooltip title={"This order has not been dispatched" }>
                            <LockIcon color="secondary" sx={{ ml: 1 }}/>
                          </Tooltip>
                        }
                        { apiOrder ?
                          <Tooltip title={"Copy to new"}>
                            <IconButton
                              size="small"
                              sx={{ ml: 1,  color: 'green', mb: 0, mr: 1 }}>
                              <ContentCopyIcon
                                size="small"
                                onClick={ handleCopyOrder }
                              />
                            </IconButton>
                          </Tooltip>
                          :
                          null
                        }
                        { apiOrder ?
                          <IconButton
                            size="small"
                            onClick={ e => {
                              if ( ! orderMenuAnchorEl ) {
                                dispatch(actions.setOrderMenuAnchorEl(e.currentTarget))
                              }
                            }}
                            sx={{ mb: 0, mr: 1 }}>
                            <MoreHorizIcon size="small" />
                            <Menu
                              id="basic-menu"
                              anchorEl={orderMenuAnchorEl}
                              open={orderMenuOpen}
                              onClose={ handleOrderMenuClose }>
                              <MenuItem onClick={ handleDeleteOrder }>Delete order</MenuItem>
                            </Menu>
                          </IconButton>
                          :
                          null
                        }
                      </div>
                      { apiOrder?.lastUpdatedTime ?
                        <Typography variant="button" display="block" gutterBottom sx={{ mt: 1, color: '#ccc' }}>
                          Last Update: { moment(apiOrder.lastUpdatedTime * 1000).format("LLLL") }
                        </Typography>
                        :
                        null
                      }
                      <Typography variant="button" display="block" gutterBottom sx={{ mt: 2 }}>
                        { ! _.isEmpty(sortedPickupDates) ?
                          <>
                            Pickup
                            {
                              sortedPickupDates
                                .filter(( d, i ) => i < 2 )
                                .map(d => (
                                  <Chip
                                    sx={{ ml: 1 }}
                                    variant="outlined"
                                    label={ d.format('LLLL') }
                                  />
                                ))
                            }
                          </>
                          : null
                        }

                        { sortedPickupDates.length > 2 ?
                          <Chip
                            sx={{ ml: 1 }}
                            variant="outlined"
                            label={ "+" + ( sortedPickupDates.length - 2 ) }
                          />
                          :
                          null
                        }
                      </Typography>
                    </div>
                    <Paper
                      elevation={2}
                      sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                      }}>
                    <motion.p style={{ margin: 0, height: 20, padding: 0, textAlign: 'right' }}>
                      { customerOption?.label ?
                        <strong>{ customerOption.label }</strong>
                        :
                        null
                      }
                    </motion.p>
                    { apiOrder ?
                      <div
                        className="invoice-items"
                        style={{ padding: 0, textAlign: 'right'}}>
                        <span
                          className="">
                          Invoice:</span>
                        <IconButton
                          sx={{ mb: 0 }}
                          onClick={ handleInvoiceModalOpen }>
                          <ContactIcon 
                            sx={{ fontSize: 20 }}
                          />
                        </IconButton>
                        / BOL:
                        <input
                        type='file'
                        id='file'
                        accept=".pdf"
                        onChange={ handleBolUpload }
                        ref={inputFile} style={{display: 'none'}}/>
                        <IconButton
                          sx={{ mb: 0 }}
                          onClick={ handleBolClick }>
                        { apiOrder?.bolUpload ?
                          <CloudDownloadOutlinedIcon color="primary"/>
                          :
                          <ContactIcon 
                            sx={{ fontSize: 20 }}
                          />
                        }
                        </IconButton>
                      </div>
                      :
                      null
                    }
                    <Button
                      disabled={ _.isNil(customer.id) }
                      onClick={ () => {
                        handleContactModalOpen(addDispatcherToOrder, dispatcher)
                      }}
                      endIcon={ order.dispatcher ? <PhoneEnabledIcon /> : null }
                      sx={{ }}>
                      {
                        dispatcherOption?.label ? dispatcherOption.label :
                        'Add Dispatcher'
                      }
                    </Button>
                    { apiOrder && !apiOrder.invoice ?
                      <Button
                        variant="contained"
                        onClick={ handleQuoteModalOpen }>
                        Create Quote
                      </Button>
                      :
                      null
                    }
                  </Paper>
                </Box>
                <Grid xs={ 11 }>
                { showWarnings ?
                  <Stack sx={{ width: '100%' }} spacing={1}>
                    { orderErrorsPreSave.map(error => (
                      <Alert severity={ error.type }>
                        { error.message }
                      </Alert>
                    )) }
                  </Stack>
                  :
                  null
                }
                </Grid>
                <CustomerInput
                  handleSelectCustomerId={ handleSelectCustomerId }
                  customer={ customer }
                />
                <MultiItemInput
                  handleItemValueChange={ handleItemValueChange }
                  handleAddItem={ handleAddItem }
                  handleQuantityChange={ handleQuantityChange }
                  customerId={ customer.id }
                  items={ items }
                />

                { false ?
                  <>
                    <hr style={{
                        width: '90%',
                        alignSelf: 'start',
                        marginTop: 20,
                        marginBottom: 40,
                        marginRight: 0,
                        marginLeft: 0 }} />

                    <LocationTracker />
                  </>
                  :
                  null
                }

                <hr style={{
                    width: '90%',
                    alignSelf: 'start',
                    marginTop: 20,
                    marginBottom: 40,
                    marginRight: 0,
                    marginLeft: 0 }} />

                { TripUtils.sortTrips( tripMap ).map(( trip, tripIndex ) => (
                  <Trip
                    tripErrors={ tripErrors[ trip.id ] }
                    tripWarnings={ tripWarnings[ trip.id ] }
                    showWarnings={ showWarnings }
                    key={ trip.id }
                    trip={ trip }
                    itemMap={ itemMap }
                    tripId={ trip.id }
                    tripIndex={ tripIndex }
                    selectedItemsMap={ selectedItemsMap }
                    stops={ trip.stops }
                  />
                )) }

                <div className="action-buttons">
                  <Button
                    variant="contained"
                    disabled={ !changesExist }
                    onClick={ () => handleSave(false) }>
                    Save
                  </Button>
                  { dispatched === true ?
                    <Tooltip
                      title={ "Dispatched " + 
                        ( dispatched ?
                          moment(dispatchedTime * 1000).format('LLL') : '') }
                      sx={{ display: 'inline' }}>
                     <div style={{ width: 'fit-content', display: 'inline' }}>
                      <Button variant="outlined" disabled>
                        This order has been dispatched
                      </Button>
                     </div>
                    </Tooltip>:
                    <Button
                      onClick={ () => handleSave(true) }
                      variant="outlined">
                      Save & Dispatch
                    </Button>
                  }
                  <Portal>
                    <AnimatePresence>
                      { changesExist ?
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: _.isNil(apiOrder) ? .5 : 1 }}
                          exit={{ opacity: 0 }}
                          style={{
                            position: 'fixed',
                            display: 'flex',
                            alignItems: 'center',
                            zIndex: 2000,
                            bottom: orderModalOpen ? 100 : 10,
                            right: bounds.width * 0.5,
                            borderRadius: 20,
                            border: '3px solid #ddd',
                            //opacity: 0.5,
                            padding: 20,
                            backgroundColor: '#E1F4E1' }} >
                          <Typography sx={{ mr: 1 }}variant="button">
                            { changesExist ? "Unsaved changes" : "All changes saved" }
                          </Typography>
                          <CheckIcon />
                        </motion.div>
                      :
                      null
                    }
                  </AnimatePresence>
                  </Portal>
                </div>
              </Grid>
            </Grid>
            <Toolbar />
          </TabPanel>
          <TabPanel disabled={ disableCustomerTab } value={tabValue} index={1}>
            <CustomerTab
              navigateToTab={ navigateToTab }
              orders={ customerOrders }
              contacts={ contacts }
              refetchContacts={ refetchContacts }
              customerId={ customer.id }/>
          </TabPanel>
          {/*
          <TabPanel value={tabValue} index={2}>
            <ActivityTab
              navigateToTab={ navigateToTab }
            />
          </TabPanel>
          */}
        </div>
      </motion.div>
    </OrderPageContext.Provider>
  )
})

export default OrderDetailsPage

const LocationTracker = () => {
  return (
    <h3 style={{ marginBottom: 40 }}>

    <div>
    <i>DRIVER LOCATION</i>
    <Tooltip
      title="Driver updates occur every 10 miles and if the user has granted the app location access.">
      <IconButton
        sx={{ mr: 2 }}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
    </div>

    <Box sx={{
        width: '100%',
        mt: 2,
        height: 150,
        overflow: 'hidden',
        borderRadius: 2,
        mb: 2 }}>
      <MapComponent
        showMarker
        center={{
            lat: 37.33403923,
            lng: -122.07123245
        }}
        zoom={ 12 }
      />

    </Box>


      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <Slider
            aria-label="Custom marks"
            sx={{ width: '90%' }}
            aria-value-text="Hello"
            defaultValue={50}
            getAriaValueText={valuetext}
            valueLabelFormat={(value) => 'Feature pending' }
            step={null}
            valueLabelDisplay="auto"
            marks={PENDING_CHANGES_MARKS}
          />
      </Box>
    </h3>
  )
}

/*----------------- HELPERS -----------------*/

//Return options when the user searches...
const OrderErrors = {
  NO_PICKUP_DATE: {
    type: 'error',
    message: 'No pickup date assigned'
  },
  NO_CUSTOMER: {
    type: 'error',
    message: 'No customer assigned to order'
  },
  NO_ITEMS: {
    type: 'error',
    message: 'No cargo added to order'
  },
  DELIVERY_DATE_NOT_AFTER_PICKUP_DATE: {
    type: 'error',
    message: 'Delivery date not after pickup date'
  },
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const GET_CONTACTS = gql`
query GetContactsQuery($customerId: String!) {
  getContacts(customerId: $customerId) {
    id
    customerId
    name
    email
    phone
  }
}
`
const UPDATE_PRINT_PRIVIEW_MUTATION = gql`
mutation UpdatePrintMutation($orderId: String!) {
  updatePrintPreview(orderId: $orderId) {
    message
    code
    url
  }
}
`
const DELETE_ORDERS_MUTATION = gql`
mutation DeleteOrdersMutation($orderIds: [String!]!) {
  deleteOrders(orderIds: $orderIds) {
    message
    code
  }
}
`
const CREATE_ORDER_MUTATION = gql`
mutation Mutation($order: CreateOrderInput!) {
  createOrder(order: $order) {
    message
    code
    order {
      id
      bolUpload {
        timestamp
        location
      }
      lastUpdatedTime
      invoice {
        id
        pdfUrl
        lineItems {
          id
          description
          amount
          quantity
          metadata {
            tripId
          }
        }
      }
      groupId
      createdTime
      customer {
        id name phone billingEmail lastUsedTime
      }
      dispatcher {
        id name phone email profileThumb
      }
      items {
        id
        manufacturer
        model
        description
        weight
        quantity
        records {
          id
          values {
            key
            value
          }
        }
        tags {
          id
        }
      }
      status
      trips {
        id
        completed
        deliveryStatus {
          timestamp type durationMinutes notes
        }
        equipment {
          id
          createdTime
          primaryDriverId
          axels
          color
          description
          make
          model
        }
        destination
        dispatched
        dispatchedTime
        startTime
        endTime
        maxGrossWeight
        order
        origin
        stops {
          id
          deliveryStatus {
            timestamp type durationMinutes notes
          }
          locationMarker
          duration
          distanceMeters
          note
          isComplete
          contact {
            id name phone email
          }
          order
          time
          timeChoice
          address {
            description
            placeId
            textShort
          }
          instructions {
            id
            order
            type
            itemId
            quantity
          }
        }
      }
    }
  }
}
`
const UPDATE_ORDER_MUTATION = gql`
mutation Mutation($order: UpdateOrderInput!) {
  updateOrder(order: $order) {
    message
    code
    order {
      id
      bolUpload {
        timestamp
        location
      }
      lastUpdatedTime
      invoice {
        id
        pdfUrl
        lineItems {
          id
          description
          amount
          quantity
          metadata {
            tripId
          }
        }
      }
      groupId
      createdTime
      customer {
        id name phone billingEmail lastUsedTime
      }
      dispatcher {
        id name phone email profileThumb
      }
      items {
        id
        description
        weight
        manufacturer
        model
        quantity
        records {
          id
          values {
            key
            value
          }
        }
        tags {
          id
        }
      }
      status
      trips {
        id
        completed
        deliveryStatus {
          timestamp type durationMinutes notes
        }
        equipment {
          id
          createdTime
          primaryDriverId
          axels
          color
          description
          make
          model
        }
        destination
        dispatched
        dispatchedTime
        startTime
        endTime
        maxGrossWeight
        order
        origin
        stops {
          id
          deliveryStatus {
            timestamp type durationMinutes notes
          }
          locationMarker
          duration
          distanceMeters
          note
          isComplete
          contact {
            id name phone email
          }
          order
          time
          timeChoice
          address {
            description
            placeId
            textShort
          }
          instructions {
            id
            order
            type
            itemId
            quantity
          }
        }
      }
    }
  }
}
`
const COPY_ORDER_MUTATION = gql`
mutation CopyOrder($orderId: String!, $copyToDate: String!) {
  copyOrder(orderId: $orderId, copyToDate: $copyToDate) {
    message
    code
    order {
      id
      bolUpload {
        timestamp
        location
      }
      lastUpdatedTime
      invoice {
        id
        pdfUrl
        lineItems {
          id
          description
          amount
          quantity
          metadata {
            tripId
          }
        }
      }
      groupId
      createdTime
      customer {
        id name phone billingEmail lastUsedTime
      }
      dispatcher {
        id name phone email profileThumb
      }
      items {
        id
        description
        manufacturer
        model
        weight
        quantity
        records {
          id
          values {
            key
            value
          }
        }
        tags {
          id
        }
      }
      status
      trips {
        id
        completed
        deliveryStatus {
          timestamp type durationMinutes notes
        }
        equipment {
          id
          createdTime
          primaryDriverId
          axels
          color
          description
          make
          model
        }
        destination
        dispatched
        dispatchedTime
        startTime
        endTime
        maxGrossWeight
        order
        origin
        stops {
          id
          deliveryStatus {
            timestamp type durationMinutes notes
          }
          locationMarker
          duration
          distanceMeters
          note
          isComplete
          contact {
            id name phone email
          }
          order
          time
          timeChoice
          address {
            description
            placeId
            textShort
          }
          instructions {
            id
            order
            type
            itemId
            quantity
          }
        }
      }
    }
  }
}
`
const REVOKE_ORDER_DISPATCH_MUTATION = gql`
mutation RevokeOrderDispatchMutation($orderId: String!) {
  revokeOrderDispatch(orderId: $orderId) {
    message
    code
    order {
      id
      bolUpload {
        timestamp
        location
      }
      lastUpdatedTime
      invoice {
        id
        pdfUrl
        lineItems {
          id
          description
          amount
          quantity
          metadata {
            tripId
          }
        }
      }
      groupId
      createdTime
      customer {
        id name phone billingEmail lastUsedTime
      }
      dispatcher {
        id name phone email profileThumb
      }
      items {
        id
        description
        manufacturer
        model
        weight
        quantity
        records {
          id
          values {
            key
            value
          }
        }
        tags {
          id
        }
      }
      status
      trips {
        id
        completed
        deliveryStatus {
          timestamp type durationMinutes notes
        }
        equipment {
          id
          createdTime
          primaryDriverId
          axels
          color
          description
          make
          model
        }
        destination
        dispatched
        dispatchedTime
        startTime
        endTime
        maxGrossWeight
        order
        origin
        stops {
          id
          deliveryStatus {
            timestamp type durationMinutes notes
          }
          locationMarker
          duration
          distanceMeters
          note
          isComplete
          contact {
            id name phone email
          }
          order
          time
          timeChoice
          address {
            description
            placeId
            textShort
          }
          instructions {
            id
            order
            type
            itemId
            quantity
          }
        }
      }
    }
  }
}
`
const PENDING_CHANGES_MARKS = [
  {
    value: 0,
    label: '',
  },
  {
    value: 100,
    label: '',
  },
]

const marks = [
  {
    value: 0,
    label: '3:01PM',
  },
  {
    value: 20,
    label: '5:22PM',
  },
  {
    value: 40,
    label: '6:30PM',
  },
  {
    value: 100,
    label: '(IN PROGRESS)',
  },
]

function valuetext(value: number) {
  return `3:01pm`;
}
