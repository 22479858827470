import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import OrderInputContainer from './OrderInputContainer'
import DateTimePickerPopper from './DateTimePickerPopper'
import InputRightContainer from './LabelRightContainer.js'
import LabelLeftContainer from './LabelLeftContainer'
import DateSplitButton from './DateSplitButton'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

export default function ComparableDateInput(props) {

  const {
    buttonSx={},
    comparable=false,
    compareToDate,
    label="Pick Up Date",
    showLabel,
    //tell the parent when the selectedDate changes...
    handleSelectedDateChange,
    handleToggleUnspecified,
    selectedDate,
    selectedDateSameAsCompareTo,
    toggleSelectedDateSameAsCompareTo,
    //Whether the user has opted not to specify the date...
    unspecified,
  } = props

  const [ menuOptions, setMenuOptions ] = useState(DEFAULT_COMPARABLE_DATE_OPTIONS)
  const [ selectedOptionIndex, setSelectedOptionIndex ] = useState(0)
  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ nudge, setNudge ]       = useState(null)

  const open                      = Boolean(anchorEl);
  //Comparable has a different ref to mount the date popper...
  const anchorRefForComparable    = useRef()
  const fixedButtonContainerRef   = useRef()

  const handleSelectedIndexChange = index => {
    setSelectedOptionIndex(index)

    const unspecified = index === 1
    handleToggleUnspecified(unspecified)
  }

  const incrementNudge = () => {
    if ( _.isNil( nudge ) ) {
      setNudge(1)
    } else {
      setNudge(nudge + .5)
    }
  }

  //Effect to change the selected date each time the user nudges...
  useEffect(() => {
    let interrupted = false

    if ( nudge ) {
      if ( _.isNil( selectedDate ) && compareToDate ) {
        handleSelectedDateChange(compareToDate.clone().add(1, 'hours'))
      } else if ( selectedDate ) {
        handleSelectedDateChange(selectedDate.clone().add(.5, 'hours'))
      }
    }

    //If duration is able to pass without the nudger being clicked,
    //reset the nudge to null...
    setTimeout(() => {
      if ( interrupted === false ) {
        setNudge(null)
      }
    }, 4000)

    return () => {
      interrupted = true
    }

  }, [ nudge, selectedDate, compareToDate ])

  //Effect to change the option index if the date is 'unspecified'
  useEffect(() => {
    if ( !comparable ) {
      return;
    }

    if ( unspecified ) {
      setSelectedOptionIndex(1)
    } else {
      setSelectedOptionIndex(0)
    }
  }, [ comparable, unspecified ])

  //Effect to determine if this date is the same as compareToDate
  useEffect(() => {
    if ( ! comparable ) {
      return
    }

    if (
      //Both dates non-nil and the same...
      ( selectedDate && compareToDate && selectedDate.isSame(compareToDate)) ||
      //Other date non-nil and this date is nil...
      ( compareToDate && _.isNil( selectedDate ) ) ||
      //Both nil...
      ( _.isNil(selectedDate) && _.isNil(compareToDate) )) {

      toggleSelectedDateSameAsCompareTo(true)

    } else {
      toggleSelectedDateSameAsCompareTo(false)
    }

  }, [ comparable, selectedDate, compareToDate ])

  //Set menu options appropriately...
  useEffect(() => {

    if ( comparable ) { //Yes

      if ( selectedDateSameAsCompareTo || unspecified ) {

        setMenuOptions(DEFAULT_COMPARABLE_DATE_OPTIONS)

      } else if ( selectedDate ) { //User has selected a different date...

        const options =
          DEFAULT_COMPARABLE_DATE_OPTIONS.map(( option, index ) => {
            if ( index === 0 ) {
              return (
                moment(selectedDate).format('ddd MMM DD, YYYY') +
                  ( moment(selectedDate).second() === 1 ?
                    ' at ' + moment(selectedDate).format('h:mma') : ''))
            } else {
              return option
            }
          })

        setMenuOptions(options)
      }
    }

  }, [ selectedDateSameAsCompareTo, selectedDate, comparable, unspecified ])

  //The anchor ref for the menu date is different than the default...
  const handleMenuDateClick = useCallback(( e, index ) => {
    if ( comparable && compareToDate && _.isNil(selectedDate) ) {
      handleSelectedDateChange(compareToDate)
    }
    setAnchorEl(anchorEl ? null : anchorRefForComparable.current);
  }, [])

  const handleDateClick = event => {
    //Open or reopen the date popper
    setAnchorEl(anchorEl ? null : fixedButtonContainerRef.current)
  }

  const handleCloseDatePicker = () => {
    setAnchorEl(null)
  }

  const onDateChange = date => {
    handleSelectedDateChange(date)
  }

  return (
    <OrderInputContainer onBlur={ () => {
      if ( anchorEl ) {
        //setAnchorEl(null)
      }
    }}>

      <DateTimePickerPopper
        open={ open }
        id="order"
        disablePast={true}
        onDateChange={ onDateChange }
        selectedDate={ selectedDate }
        enableTimeSelection={ true }
        handleCloseDatePicker={ handleCloseDatePicker }
        anchorEl={ anchorEl } />

      { showLabel ?
        <LabelLeftContainer label={ label }>
        </LabelLeftContainer>
        :
        null
      }

      <InputRightContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            comparable ?
              <DateSplitButton
                selectedDate={ selectedDate }
                anchorRefForComparable={ anchorRefForComparable }
                selectedIndex={ selectedOptionIndex }
                onSelectedIndexChange={ handleSelectedIndexChange }
                onDateClick={ handleMenuDateClick }
                options={menuOptions} />
              :
              <div>
                <Button
                  /* disabled={ sameAsDateEnabled } TODO*/
                  onClick={ handleDateClick } sx={ buttonSx }>
                  {
                    //Feb 24, 2023 at 9:00am
                    selectedDate ?
                      `${ moment(selectedDate).format('ddd MMM DD, YYYY') }` +
                        ( moment(selectedDate).second() === 1 ?
                          ' AT ' + moment(selectedDate).format('h:mma') : '')
                      :
                      label
                  }
                </Button>
                <Box ref={ fixedButtonContainerRef } sx={{ width: 200 }}>
                </Box>
              </div>
          }
          { comparable ?
            <Chip
              variant="outlined"
              label={ nudge ? `+${ nudge + .5 } hr` : "+1 hr" }
              size="small" icon={<QueryBuilderIcon />} onClick={ incrementNudge } />
            :
            null
          }
        </div>
      </InputRightContainer>
    </OrderInputContainer>
  )
}

const DEFAULT_COMPARABLE_DATE_OPTIONS = [
  'Same as pickup date',
  'Unspecified'
]
