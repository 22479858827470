import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  const {
    title,
    open,
    handleConfirm,
    handleClose,
    handleCancel,
  } = props

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          { title }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Discard</Button>
          <Button onClick={handleConfirm} autoFocus>
            Continue editing
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
