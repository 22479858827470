import React, { useContext, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash'
import { gql, useMutation, useLazyQuery, } from '@apollo/client';
import { debounce } from '@mui/material/utils';
import { useSelector, useDispatch } from 'react-redux'
import { motion } from "framer-motion"
import { documentId, orderBy, collection, doc, limit, getCountFromServer, getDoc, getDocs, onSnapshot, setDoc, startAfter, updateDoc, query, where } from "firebase/firestore";
import BusinessIcon from '@mui/icons-material/Business';
import Autocomplete from '@mui/material/Autocomplete';
import generator from 'generate-password-browser'

import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import { AppContext, FirestoreContext } from '../contexts'
import { CustomAutocomplete } from '../components/RouteStopInputBase'
import { SearchIcon } from '../icons'
import { ColorBox } from '../components/ColorSelector'
import { useCompanyId, useGroupId, useDatabase } from '../hooks'
import EnhancedTable from '../components/Table'
import EntityModal from '../components/modals/EntityModal'

export const DriverPage = () => {

  const firestoreDb = useDatabase()

  const companyId   = useCompanyId()

  //Create a reference to the users collection
  const [ pageQuery ] =
    useState(() => query(
      collection(
        firestoreDb,
        `companies/${ companyId }/members`),
        where('archived', "==", false),
        orderBy(documentId())
    ))

  const [createUser]    = useMutation(CREATE_USER_MUTATION)
  const [updateUser]    = useMutation(UPDATE_USER_MUTATION)
  const [deleteMembers] = useMutation(DELETE_MEMBERS_MUTATION)

  const mapDataToRows = (data=[]) => {
    return data.map( snapshot => ({
      id: snapshot.id,
      data: {
        id: snapshot.id,
        ...( snapshot.exists ? snapshot.data() : {})
      }
    }))
  }

  const mapEntityForSave = entity => {
    return _.pick(entity, [ ...DRIVER_FIELDS, 'id', 'admin', 'dispatcher', 'driver' ]) 
  }

  const handleSave = useCallback(async user => {

    const saveUser = mapEntityForSave(user)

    if ( user.id ) {
      return updateUser({
        variables: {
          user: {
            ...saveUser,
          }
        }
      })
    } else {
      return createUser({
        variables: {
          user: {
            ...saveUser,
          }
        }
      })
    }
  }, [ createUser, updateUser ])

  const handleDelete = memberIds => {

    if ( _.isEmpty( memberIds ) ) {
      return;
    }

    deleteMembers({
      variables: {
        memberIds
      }
    })
  }

  return (
    <EntityPage
      entityName="Member"
      pageQuery={ pageQuery }
      dbPath={ `companies/${ companyId }/members` }
      optionKey="name"
      handleSave={ handleSave }
      handleDelete={ handleDelete }
      searchField="searchTerm"
      emptyEntity={ EMPTY_DRIVER }
      entityFields={ DRIVER_TABLE_COLUMNS }
      columns={ DRIVER_TABLE_COLUMNS }
      mapDataToRows={ mapDataToRows }
      mapEntityForSave={ mapEntityForSave }
    />
  )
}

export const CustomerPage = () => {

  const firestoreDb = useDatabase()
  const companyId   = useCompanyId()

  //Create a reference to the users collection
  const [ pageQuery ] =
    useState(() => query(
      collection(
        firestoreDb,
        `companies/${ companyId }/customers`),
        where('archived', "==", false),
        orderBy(documentId())
    ))

  const [createCustomer]  = useMutation(CREATE_CUSTOMER_QUERY)
  const [updateCustomer]  = useMutation(UPDATE_CUSTOMER_QUERY)
  const [deleteCustomers] = useMutation(DELETE_CUSTOMERS_MUTATION)

  const mapEntityForSave = entity => {
    return _.omitBy(_.pick(entity, [ ...CUSTOMER_FIELDS, 'id' ]), _.isNil)
  }

  /*
   * Function to handle when the entity
   * should be saved...
   */
  const handleSave = useCallback(async (customer, customerBefore) => {

    //Might have a quickbooks id...
    let saveCustomer = mapEntityForSave(customer)

    //If the quickbooksCustomerId is present and
    //the same as it was before, remove it...
    if ( saveCustomer.quickbooksCustomerId &&
      customerBefore.quickbooksCustomerId === saveCustomer.quickbooksCustomerId ) {
      saveCustomer = _.omit(saveCustomer, 'quickbooksCustomerId')
    }

    if ( customer.id ) {
      return updateCustomer({
        variables: {
          customer: saveCustomer
        }
      })
    } else {
      return createCustomer({
        variables: {
          customer: saveCustomer
        }
      })
    }
  }, [ createCustomer, updateCustomer ])

  const handleDelete = customerIds => {

    if ( _.isEmpty(customerIds ) ) {
      return;
    }

    deleteCustomers({
      variables: {
        customerIds
      }
    })
  }

  //Choose what to display in the table...
  const mapDataToRows = (data=[]) => {
    return data.map( snapshot => ({
      id: snapshot.id,
      data: {
        id: snapshot.id,
        ...( snapshot.exists ? snapshot.data() : {})
      }
    }))
  }

  return (
    <EntityPage
      entityName="Customer"
      pageQuery={ pageQuery }
      dbPath={ `companies/${ companyId }/customers` }
      searchField="searchTerm"
      optionKey="name"
      handleSave={ handleSave }
      handleDelete={ handleDelete }
      emptyEntity={ EMPTY_CUSTOMER }
      entityFields={ CUSTOMER_TABLE_COLUMNS }
      columns={ CUSTOMER_TABLE_COLUMNS }
      mapDataToRows={ mapDataToRows }
    />
  )
}

export const ItemPage = () => {

  const firestoreDb = useDatabase()

  const companyId   = useCompanyId()

  //Create a reference to the users collection
  const [ pageQuery ] =
    useState(() => query(
      collection(
        firestoreDb,
        `companies/${ companyId }/items`),
        where('archived', "==", false),
        orderBy(documentId())
    ))

  const [createItem]  = useMutation(CREATE_ITEM_MUTATION)
  const [updateItem]  = useMutation(UPDATE_ITEM_MUTATION)
  const [deleteItems] = useMutation(DELETE_ITEMS_MUTATION)

  const mapEntityForSave = entity => {
    return _.omitBy(_.pick(entity, [ ...ITEM_FIELDS, 'id' ]), _.isNil)
  }

  //Choose what to display in the table...
  const mapDataToRows = (data=[]) => {
    return data.map( snapshot => ({
      id: snapshot.id,
      data: {
        id: snapshot.id,
        ...( snapshot.exists ? snapshot.data() : {})
      }
    }))
  }

  const handleSave = useCallback(async item => {

    const saveItem = mapEntityForSave(item)

    if ( item.id ) {
      return updateItem({
        variables: {
          item: saveItem
        }
      })
    } else {
      return createItem({
        variables: {
          item: saveItem
        }
      })
    }
  }, [ createItem, updateItem ])

  const handleDelete = itemIds => {

    if ( _.isEmpty(itemIds) ) {
      return;
    }

    deleteItems({
      variables: {
        itemIds
      }
    })
  }

  return (
    <EntityPage
      entityName="Cargo"
      pageQuery={ pageQuery }
      searchField="searchTerm"
      optionKey="description"
      handleSave={ handleSave }
      handleDelete={ handleDelete }
      dbPath={ `companies/${ companyId }/items` }
      emptyEntity={ EMPTY_ITEM }
      entityFields={ ITEM_TABLE_COLUMNS }
      columns={ ITEM_TABLE_COLUMNS }
      mapDataToRows={ mapDataToRows }
    />
  )
}

export const EquipmentPage = () => {

  const firestoreDb = useDatabase()

  const companyId = useCompanyId()
  const members   = useSelector(state => state.app.members)

  //Create a reference to the users collection
  const [ pageQuery ] =
    useState(() => query(
      collection(
        firestoreDb,
        `companies/${ companyId }/equipment`),
        where("archived", "==", false),
        orderBy(documentId())
    ))

  const [createEquipment] = useMutation(CREATE_EQUIPMENT_MUTATION)
  const [updateEquipment] = useMutation(UPDATE_EQUIPMENT_MUTATION)
  const [deleteEquipment] = useMutation(DELETE_EQUIPMENT_MUTATION)

  const getOptionLabel = option => {
    if ( _.isString(option) && members[ option ] ) {
      return members[ option ].name
    } else {
      return option.label
    }
  }

  const mapEntityForSave = entity => {
    const data = _.pick(entity, [ ...EQUIPMENT_FIELDS, 'id' ])
    if ( _.isObject(data.primaryDriverId) ) { //User selected driver...
      data.primaryDriverId = data.primaryDriverId.value
    }
    return data
  }

  //Choose what to display in the table...
  const mapDataToRows = (data=[]) => {
    return data.map( snapshot => {
      const data          = snapshot.exists ? snapshot.data() : {}
      let primaryDriverId = null

      if ( data.primaryDriverId ) {
        primaryDriverId = {
          label: members[ data.primaryDriverId ] ? members[ data.primaryDriverId ].name : "",
          value: data.primaryDriverId
        }
      }

      return {
        id: snapshot.id,
        data: {
          id: snapshot.id,
          ...data,
          primaryDriverId
        }
      }
    })
  }

  const handleSave = useCallback(async equipment => {

    const saveEquipment = mapEntityForSave(equipment)

    if ( equipment.id ) {
      return updateEquipment({
        variables: {
          equipment: saveEquipment
        }
      })
    } else {
      return createEquipment({
        variables: {
          equipment: saveEquipment
        }
      })
    }
  }, [ createEquipment, updateEquipment ])

  const handleDelete = equipmentIds => {

    if ( _.isEmpty(equipmentIds) ) {
      return;
    }

    deleteEquipment({
      variables: {
        equipmentIds
      }
    })
  }

  return (
    <EntityPage
      entityName="Equipment"
      searchField="searchTerm"
      optionKey="description"
      getOptionLabel={ getOptionLabel }
      pageQuery={ pageQuery }
      handleSave={ handleSave }
      handleDelete={ handleDelete }
      dbPath={ `companies/${ companyId }/equipment` }
      emptyEntity={ EMPTY_EQUIPMENT }
      columns={ EQUIPMENT_TABLE_COLUMNS }
      entityFields={ EQUIPMENT_TABLE_COLUMNS }
      mapDataToRows={ mapDataToRows }
    />
  )
}

export default function EntityPage(props) {
  const {
    dbPath,     //i.e. users
    searchField,
    entityName, //i.e. Driver
    emptyEntity,
    columns,
    optionKey,
    getOptionLabel,
    entityFields,
    mapDataToRows,
    handleSave,
    handleDelete,
    pageQuery,
  } = props

  const firestoreDb = useDatabase()

  const gotTotalRecordCountRef            = useRef(false)
  const mapRef                            = useRef({})
  const startAfterKeyRef                  = useRef(undefined)
  const [ filteredRows, setFilteredRows ] = useState(null)
  const [ pageNum, setPageNum ]           = useState(0)
  const [ rows, setRows ]                 = useState([])
  const [ modalOpen, setModalOpen ]       = useState(false)
  const [ pageSize, setPageSize ]         = useState(10)
  const [ totalRecordCount, setTotalRecordCount ] = useState(0)
  const [ hasAdditionalRecords, setHasAdditionalRecords ] = useState(false)

  //obj can be a driver, equipment, customer etc.
  const [ entity, setEntity ] = useState({})
  const [ entityBefore, setEntityBefore ] = useState(null)

  const onSave = entity => {
    handleSave(entity, entityBefore).then(() => {
      setModalOpen(false)
    })
  }

  const fetchInitialOptions = useCallback(async () => {

    if ( !dbPath ) {
      return []
    }

    const dataRef =
      query(
        collection(firestoreDb, dbPath), //users || equipment || customers || items
        //orderByChild('timestamp'),
        limit(10)
      )

    const slightDelay = Promise.resolve()

    const data = getDocs(dataRef).then(snapshot => {

      const records = snapshot.docs

      const options = records.map( snapshot => {
        const data = snapshot.data()
        return {
          label: data[ optionKey ],
          value: snapshot.id
        }
      })

      return options
    }, [])

    return (
      Promise
      .all([ slightDelay, data ])
      .then(() => {
        return data
      })
    )

  }, [ dbPath ])

  //Called when the user changes the values inside the modal...
  const onChange = (field, value) => {
    setEntity({
      ...entity,
      [ field ]: value
    })
  }
  //page = 1
  //queryMethods = [ ref(driver) ]
  //gotTotalRecordCountRef  = false
  //lastChildKeyRef         = null
  //totalRecordCount        = 0 | 5

  //Every time the pageSize changes, reset the page to 0
  useEffect(() => {
    startAfterKeyRef.current = undefined
    setPageNum(0)
  }, [ pageSize ])

  //Every time the page changes, listen for changes starting
  //from the lastChildKey...
  useEffect(() => {

    const getAllQueryRef = query(pageQuery) //no limit...

    //First, if we haven't got the total # of records, get that...
    if ( gotTotalRecordCountRef.current === false ) {

      const getAllQueryRef = query(pageQuery) //no limit...

      getCountFromServer(getAllQueryRef).then(snapshot => {

        const totalRecordCount = snapshot.data().count
        setTotalRecordCount(totalRecordCount)

        gotTotalRecordCountRef.current = true
      })
    }

    //Determine the key to start at...

    //Now, let's get the first page of information...
    const queryRef =
      query(pageQuery,
        limit(pageSize),
        ...( startAfterKeyRef.current ?
          [ startAfter(startAfterKeyRef.current) ] : []
        )
      )

    const unsubscribe = onSnapshot(queryRef, snapshot => {

      const results       = !snapshot.empty ? snapshot.docs : []
      const rows          = mapDataToRows(results)
      const lastChildKey  = rows[ rows.length -1 ] ? rows[ rows.length -1 ].id : null

      //Update the record count...
      //setTotalRecordCount(rows.length)

      //Set lastChildKeyRef to start from this child in the next batch...
      mapRef.current = {
        ...mapRef.current,
        [ pageNum ]: {
          lastChildKey,
        }
      }

      setRows(rows)

    })

    return () => {
      //Stop listening to changes to the users...
      unsubscribe()
    }

  }, [ pageNum, pageQuery, pageSize ])

  //Effect to determine if there are any additonal records...
  useEffect(() => {

    const rowCountOnThisPage = rows.length

    const totalDisplayed =
      pageNum > 1 ? (( pageNum -1 ) * pageSize ) + rowCountOnThisPage : rowCountOnThisPage

    if ( totalDisplayed < totalRecordCount ) {
      setHasAdditionalRecords(true)
    } else {
      setHasAdditionalRecords(false)
    }

  }, [ pageNum, pageSize, totalRecordCount, rows ])

  //Effect to clear the driver each time the modal is closed...
  useEffect(() => {
    if ( !modalOpen ) {
      setEntity(emptyEntity)
    }
  }, [ modalOpen ])

  const handleNextPageClick = () => {
    if ( hasAdditionalRecords ) {
      startAfterKeyRef.current = mapRef.current[ pageNum ].lastChildKey
      setPageNum(pageNum + 1)
    }
  }

  const handlePrevPageClick = () => {
    if ( pageNum === 0 ) {
      return; //Nothing to do
    }

    let startAfterKey = null

    if ( pageNum >= 2 ) {
      startAfterKey = mapRef.current[ pageNum-2 ] ? mapRef.current[ pageNum-2 ].lastChildKey : null
    } else {
      startAfterKey = null //Start from the beginning...
    }

    startAfterKeyRef.current = startAfterKey
    setPageNum(pageNum - 1)
  }

  //Function to open the modal for the driver with the given id...
  const handleRowClick = index => {
    const row = rows[ index ]
    if ( row ) {
      setEntity({ ...row.data, id: row.id })
      setEntityBefore(row.data)
      setModalOpen(true)
    }
  }

  //Delete entities...
  const onDelete = (ids=[]) => {
    if ( _.isEmpty( ids ) ) {
      return;
    }

    if ( handleDelete ) {
      handleDelete(ids)
    }
  }

  const handleChangePage = (e, newPage ) => {
    if ( newPage > pageNum ) {
      handleNextPageClick()
    } else {
      handlePrevPageClick()
    }
  }

  const [ searchValue, setSearchValue ] = useState({
    label: '',
    value: ''
  })

  const onValueChange = newValue => {
    if ( !newValue ) {
      return;
    }
    const { value } = newValue
    const q = doc(collection(firestoreDb, dbPath), value)

    getDoc(q).then(snapshot => {

      const rows = [{
        ...snapshot.data(),
        id: snapshot.id,
      }]
      setFilteredRows(rows)
    })

  }

  return (
      <motion.div className="page">
        <CustomAutocomplete
          searchField={"searchTerm"}
          textFieldProps={{ size: "small" }}
          Icon={ BusinessIcon }
          fetchInitialOptions={ fetchInitialOptions }
          dbPath={ dbPath }
          noOptionsText="No customers to display"
          optionSecondaryText={ entityName }
          optionKey={ optionKey }
          sx={{ mb: 3, width: 300 }}
          value={ searchValue }
          onValueChange={ onValueChange } />
      { filteredRows ?
        <Button sx={{ mb:2 }} onClick={() => {
          setFilteredRows(null)
        }}>Remove filter</Button>
        :
        null
      }
      <EntityModal
        open={ modalOpen }
        onChange={ onChange }
        entity={ entity }
        entityName={ entityName }
        entityFields={ entityFields }
        getOptionLabel={ getOptionLabel }
        handleSave={ onSave }
        handleClose={ () => setModalOpen(false) }/>

      <EnhancedTable
        onDelete={ onDelete }
        rowsPerPageOptions={[5, 10, 25]}
        handleRowClick={ handleRowClick }
        handleChangePage={ handleChangePage }
        rows={ filteredRows ? filteredRows : rows }
        pageSize={ pageSize }
        page={ pageNum }
        totalRecordCount={ totalRecordCount }
        hasAdditionalRecords={ hasAdditionalRecords }
        handleNextPageClick={ handleNextPageClick }
        columns={ columns }>
      </EnhancedTable>

      <Button onClick={ () => setModalOpen(true) }>
        Add a new { entityName }
      </Button>
    </motion.div>
  )
}

const CUSTOMER_TABLE_COLUMNS = [
  {
    field: 'name',
    label: 'Customer Name',
    type: 'text',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'quickbooksCustomerId',
    hide: true,
    disabled: true,
    label: 'Quickbooks ID',
    type: 'text',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'phone',
    label: 'Customer Phone',
    type: 'phone'
    //renderCell
    //isNumeric
  },
  {
    field: 'fax',
    label: 'Fax Number',
    type: 'phone'
  },
  {
    field: 'extension',
    label: 'Extension',
    type: 'phone',
    options: {
      format: '####'
    }
    //renderCell
    //isNumeric
  },
  {
    field: 'billingEmail',
    type: 'email', //TODO
    label: 'Billing email',
  }
]

const CUSTOMER_FIELDS = CUSTOMER_TABLE_COLUMNS.map(field => field.field )

//Is the field numeric?
//Does it render differently?

const DRIVER_TABLE_COLUMNS = [
  {
    field: 'name',
    label: 'Name',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'email',
    label: 'Email',
    type: 'email',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'phone',
    type: 'phone',
    label: 'Phone'
  },
  /*
  {
    field: 'password',
    hide: true,
    label: 'Password',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  */
  {
    field: 'active',
    label: 'Active',
    type: 'check'
  },
  {
    field: 'roles',
    label: 'Roles',
    type: 'roles',
  },
  /* TODO
  {
    field: 'roles',
    label: 'Roles',
  },
  */
]

const DRIVER_FIELDS =
  DRIVER_TABLE_COLUMNS.map(field => field.field )
  .filter(field => field !== 'roles')

const EQUIPMENT_TABLE_COLUMNS = [
  {
    field: 'description',
    label: 'Description',
    maxLength: 6,
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'color',
    label: 'Color',
    type: 'color',
    renderCell: value => {
      if ( _.isEmpty( value )) {
        return '-'
      }
      return <ColorBox colorValue={ value } />
    },
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'make',
    label: 'Make',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'model',
    label: 'Model',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'year',
    label: 'Year',
    type: 'number',
    options: {
      showThousandsSeparator: false
    },
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'weight',
    label: 'Weight (LB)',
    type: 'number'
  },
  {
    field: 'axels',
    label: 'Axels',
    type: 'number'
  },
  {
    field: 'primaryDriverId',
    label: 'Primary Driver',
    type: 'select',
    renderCell: value => {
      if ( _.isEmpty( value?.label )) {
        return 'Not assigned'
      }

      return (
        <Chip
          variant="outlined"
          label={ value.label }
          size="small"
          icon={ <CheckIcon /> }>
        </Chip>
      )
    },
    getOptions: debounce(async (request, callback) => {
      //label value
      const { text }    = request
      const { members } = request.context

      const options = _.keys(members).map(memberId => {
        const member = members[ memberId ]
        return {
          label: member.name,
          value: memberId
        }
      })
      callback(options)

    }, 400)
  },
  {
    field: 'available',
    label: 'Available',
    type: 'check',
  }
]

const MyEquipmentAutocomplete = () => {
  return (
    <div>Hello</div>
  )
}

const EQUIPMENT_FIELDS = EQUIPMENT_TABLE_COLUMNS.map(cell => cell.field )

const ITEM_TABLE_COLUMNS = [
  {
    field: 'description',
    label: 'Description',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'manufacturer',
    label: 'Manufacturer',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'model',
    label: 'Model',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  },
  {
    field: 'weight',
    label: 'Weight (LB)',
    type: 'number'
  },
  {
    field: 'width',
    label: 'Width (FT)',
    type: 'number'
  },
  {
    field: 'length',
    label: 'Length (FT)',
    type: 'number'
  },
  {
    field: 'height',
    label: 'Height (FT)',
    type: 'number'
  },
  {
    field: 'baseRate',
    label: 'Base Rate (HR)',
    type: 'number'
  },
  {
    field: 'notes',
    label: 'Notes',
    validate: DEFAULT_TEXT_FIELD_VALIDATION
  }
]

const ITEM_FIELDS = ITEM_TABLE_COLUMNS.map(field => field.field )

//When creating a new object, will use this template...
const EMPTY_CUSTOMER = {
  name: "",
  phone: "",
  billingEmail: "",
  quickbooksCustomerId: null,
  fax: ""
}

const EMPTY_DRIVER = {
  name: "",
  phone: "",
  email: "",
  admin: false,
  driver: true,
  dispatcher: false,
  active: true
}

const EMPTY_EQUIPMENT = {
  description: null,
  make: null,
  model: null,
  year: null,
  type: null,
  weight: null,
  axels: 6,
  isTrailer: false,
  available: true
}

const EMPTY_ITEM = {
  make: null,
  model: null,
  year: null,
  weight: null,
}

function DEFAULT_TEXT_FIELD_VALIDATION(text) {
  return text.length > 4 && text.length < 50
}

const CREATE_CUSTOMER_QUERY = gql`
mutation CreateCustomerMutation($customer: CreateCustomerInput!) {
  createCustomer(customer: $customer) {
    code
    customer {
      billingEmail
      id
      name
      phone
    }
    message
    error
  }
}
`
const UPDATE_CUSTOMER_QUERY = gql`
mutation UpdateCustomerMutation($customer: UpdateCustomerInput!) {
  updateCustomer(customer: $customer) {
    message
    code
    customer {
      id
      lastUsedTime
      createdTime
      name
      phone
      billingEmail
    }
  }
}
`
const CREATE_EQUIPMENT = gql`
mutation CreateEquipmentMutation($equipment: CreateEquipmentInput!) {
  createEquipment(equipment: $equipment) {
    code
    equipment {
      drivers {
        email
        id
        name
        phone
        primary
      }
      axels
      color
      description
      id
      make
      model
    }
  }
}
`
const CREATE_USER_MUTATION = gql`
mutation CreateUserMutation($user: CreateUserInput!) {
  createUser(user: $user) {
    code
    message
    user {
      email
      id
      name
      phone
      roles
    }
  }
}
`
const UPDATE_USER_MUTATION = gql`
mutation UpdateUserMutation($user: UpdateUserInput!) {
  updateUser(user: $user) {
    message
    code
  }
}
`
const CREATE_ITEM_MUTATION = gql`
mutation CreateItemMutation($item: CreateItemInput!) {
  createItem(item: $item) {
    message
    code
    item {
      id
      createdTime
      baseRate
      lastUsedTime
      description
      height
      length
      manufacturer
      model
      weight
      width
    }
  }
}
`
const UPDATE_ITEM_MUTATION = gql`
mutation UpdateItemMutation($item: UpdateItemInput!) {
  updateItem(item: $item) {
    message
    code
    item {
      id
      createdTime
      baseRate
      lastUsedTime
      description
      height
      length
      manufacturer
      model
      weight
      width
    }
  }
}
`
const CREATE_EQUIPMENT_MUTATION = gql`
mutation CreateEquipmentMutation($equipment: CreateEquipmentInput!) {
  createEquipment(equipment: $equipment) {
    message
    code
    equipment {
      id
      createdTime
      drivers {
        id
        primary
        primaryEquipmentId
        name
        email
        phone
      }
      axels
      color
      description
      make
      model
    }
  }
}
`
const UPDATE_EQUIPMENT_MUTATION = gql`
mutation Mutation($equipment: UpdateEquipmentInput!) {
  updateEquipment(equipment: $equipment) {
    message
    code
    equipment {
      id
      createdTime
      drivers {
        id
        primary
        primaryEquipmentId
        name
        email
        phone
      }
      axels
      color
      description
      make
      model
    }
  }
}
`
const DELETE_CUSTOMERS_MUTATION = gql`
mutation DeleteCustomersMutation($customerIds: [String!]!) {
  deleteCustomers(customerIds: $customerIds) {
    message
    code
  }
}
`
const DELETE_ITEMS_MUTATION = gql`
mutation DeleteItemsMutation($itemIds: [String!]!) {
  deleteItems(itemIds: $itemIds) {
    message
    code
  }
}
`
const DELETE_EQUIPMENT_MUTATION = gql`
mutation DeleteEquipmentMutation($equipmentIds: [String!]!) {
  deleteEquipment(equipmentIds: $equipmentIds) {
    message
    code
  }
}
`
const DELETE_MEMBERS_MUTATION = gql`
mutation DeleteMembersMutation($memberIds: [String!]!) {
  deleteMembers(memberIds: $memberIds) {
    message
    code
  }
}
`
